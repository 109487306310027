.maha-loader {
  flex: 1;
  position: relative;
	.ui.active.inverted.dimmer {
		background-color: transparent !important;
    .ui.medium.text.loader {
      color: fadeout($black, 40);
    }
  }
}

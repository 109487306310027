.websites-social-links-token {
  display: flex;
}
.websites-social-links-token-icon {
  padding: 0.4rem 0 0.4rem 0.8rem;
  display: flex;
}
.websites-social-links-token-image {
  background-color: $black;
  border-radius: 4px;
  margin: auto;
  img {
    width: 24px;
    height: 24px;
    display: block;
  }
}
.websites-social-links-token-label {
  padding: 0.8rem;
}

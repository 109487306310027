.maha-badge {
  flex: 1;
  position: relative;
}
.maha-badge-label {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 1.2rem;
  padding: 0;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  text-align: center;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  background-color: $red;
  border-color: #333;
  color: $white;
}

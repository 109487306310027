.store-token {
  display: flex;
  flex: 1;
}
.store-token-logo {
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 1.8rem;
    font-size: 0.8rem;
  }
}
.store-token-label {
  padding: 0.8rem;
  color: fadeout($black, 40);
}

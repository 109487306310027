.maha-criteria-designer {
  border-top: 1px solid fadeout($black, 90);
  display: flex;
  flex: 1;
}
.maha-criteria-designer-filter {
  border-right: 1px solid fadeout($black, 90);
  flex: 0 0 350px;
  display: flex;
  position: relative;
}
.maha-criteria-designer-results {
  display: flex;
  flex: 1;
}

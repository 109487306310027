.preview {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.preview-header {
  padding: 0.35rem 0.8rem;
  background-color: fadeout($grey, 97);
  border-bottom: 1px solid fadeout($black, 90);
  text-align: center;
  .ui.dropdown {
    padding: 0;
    margin: 0 0.4rem;
  }
}
.preview-body {
  flex: 1;
  display: flex;
  background-color: fadeout($black, 10);
  overflow: hidden;
  &.desktop {
    .preview-frame {
      flex: 1;
    }
  }
  &:not(.desktop) {
    .preview-frame {
      transition: all .25s ease-in-out;
    }
    padding: 0.8rem;
    @include vscroll;
    .preview-frame {
      margin: auto;
    }
  }
}
.preview-frame {
  background-color: $white;
  display: flex;
  overflow: hidden;
  iframe {
    background-color: $white;
    border: none;
    flex: 1;
    width: 100%;
    height: 100%;
  }
}

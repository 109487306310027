.maha-steps {
  background-color: $darkblue;
  display: flex;
  flex: 1;
  .maha-step:first-child {
    &:before {
      content: none;
    }
  }
  .maha-step:last-child {
    &:after {
      content: none;
    }
  }
}
.maha-step {
  padding: 0.8rem 0.6rem;
  text-align: center;
  position: relative;
  flex: 1;
  &:before, &:after {
    background-color: lighten($black, 90);
    content: ' ';
    position: absolute;
    height: 2px;
    top: calc(30% + 1px);
  }
  &:before {
    width: calc(50% - 0.8rem + 1px);
    left: 0;
  }
  &:after {
    width: calc(50% - 0.8rem + 1px);
    left: calc(50% + 0.8rem);
  }
  &.completed {
    .maha-step-marker,
    &:before, &:after {
      background-color: $green;
      color: $white;
    }
    .maha-step-label {
      color: fadeout($white, 10);
    }
  }
  &.current {
    .maha-step-marker,
    &:before {
      background-color: $green;
      color: $white;
    }
    .maha-step-label {
      color: fadeout($white, 10);
    }
  }
}
.maha-step-marker {
  background-color: $white;
  color: lighten($black, 60);
  border-radius: 50%;
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
}
.maha-step-label {
  color: fadeout($white, 40);
  margin-top: 0.4rem;
  font-size: 0.8rem;
  line-height: 1.2rem;
}

.token {
  padding: 0.8rem;
  line-height: normal;
  display: inline-block;
  word-break: break-word;
  line-height: 1.6rem;
  span {
    color: fadeout($black, 60);
  }
  img {
    display: block;
  }
  &.nobreak {
    white-space: nowrap;
  }
}
.token-link {
  color: $black;
  .error {
    color: $red;
  }
}
.token-small {
  color: fadeout($black, 60);
  line-height: 1.4rem;
  font-size: 0.9rem;
}
.token-stats {
  .voice-token {
    color: fadeout($black, 60);
  }  
  color: fadeout($black, 60);
  span {
    display: inline;
    &:after {
      content: '\2022';
      margin: 0 5px;
      color: $black;
    }
    &:last-child:after {
      content: '';
    }
    &.inactive,
    &.draft {
      text-transform: uppercase;
      color: fadeout($black, 80);
    }
    &.unpublished,
    &.error,
    &.retired,
    &.failed,
    &.canceled {
      text-transform: uppercase;
      color: $red;
    }
    &.differentiated,
    &.published {
      text-transform: uppercase;
      color: $blue;
    }
    &.pending,
    &.translating,
    &.scheduled {
      text-transform: uppercase;
      color: $teal;
    }
    &.posting,
    &.queuing,
    &.warming,
    &.sending {
      text-transform: uppercase;
      color: $yellow;
    }
    &.ready,
    &.active,
    &.posted,
    &.sent {
      text-transform: uppercase;
      color: $green;
    }
  }
}
.token-alert {
  font-size: 0.8rem;
  color: $red;
}
.compact-token {
  display: inline-block;
  word-break: break-word;
}

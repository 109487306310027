.membership-token {
  display: flex;
}
.membership-token-item {
  flex: 1;
}
.membership-token-type {
  padding: 0.8rem 0.8rem 0.8rem 0;
}
.membership-type-token {
  display: block;
  color: $white;
  padding: .4rem .8rem;
  font-size: 11px;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
  width: 7.2rem;
  &.owner {
    background-color: $red;
  }
  &.approver {
    background-color: $orange;
  }
  &.member {
    background-color: $yellow;
  }
  &.active {
    background-color: $green;
  }
  &.inactive {
    background-color: $grey;
  }
}

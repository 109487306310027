
.assignmentfield-field {
  color: fadeout($black, 70);
  flex: 1;
  padding: 0.2rem;
  min-height: 2.5rem;
  line-height: 2.5rem;
}
.assignmentfield-token {
  display: inline-block;
  margin: 0.2rem;
  padding: 0.2rem 0.4rem;
  background-color: fadeout($green, 90);
  color: fadeout($green, 40);
  border-radius: 0.2rem;
  line-height: 1.7rem;
}
.assignmentfield-prompt {
  color: fadeout($black, 70);
  display: inline-block;
  margin: 0.2rem;
  padding: 0.2rem 0.4rem;
  line-height: 1.7rem;
}
.assignmentfield-clear {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.8rem 0.4rem 0;
  .maha-icon {
    @include hover {
      color: fadeout($black, 70);
    };
    color: fadeout($black, 80);
    cursor: pointer;
  }
}

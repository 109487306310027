.variantsfield-variants {
  &.selectable {
    tr {
      cursor: pointer;
      @include hover {
        background-color: fadeout($black, 97);
      };
    }
  }
}
.variantsfield-variant {
  &.inactive td {
    color: fadeout($black, 80);
  }
  th, td {
    padding: 0.8rem !important;
    &.unpadded {
      padding: 0 !important;
    }
  }
  .fa-check-square {
    color: $blue;
  }
  .fa-chevron-right {
    color: fadeout($black, 80);
  }
  .maha-numberfield {
    margin: -0.4rem;
    width: 80px;
  }
  .fa-check {
    font-size: 1.4rem;
    color: $green
  }
}
.variantsfield-variant-action {
  cursor: pointer;
  color: fadeout($black, 80);
  @include hover {
    color: fadeout($black, 40);
  };
}

.ticket-status-token {
  display: inline;
  text-transform: uppercase;
  &.pending {
    color: $yellow;
  }
  &.active {
    color: $green;
  }
  &.voided {
    color: $red;
  }
}

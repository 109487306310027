.campaign-social-campaign-comment-token {
  display: flex;
  flex: 1;
}
.campaign-social-campaign-comment-token-logo {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 1.8rem;
    font-size: 0.8rem;
  }
}
.campaign-social-campaign-comment-token-preview {
  padding: 0.8rem 0 0.8rem 0.8rem;
  display: flex;
}
.campaign-social-campaign-comment-token-preview-inner {
  width: 6rem;
}
.campaign-social-campaign-comment-token-label {
  position: relative;
  padding: 0.8rem;
  flex: 1;
}
.campaign-social-campaign-comment-token-user {
  font-weight: bold;
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.campaign-social-campaign-comment-token-date {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  color: $blue;
  font-size: 0.8rem;
}
.campaign-social-campaign-comment-token-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.8rem;
  line-height: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  &.empty {
    font-style: italic;
    color: fadeout($black, 60);
  }
}
.campaign-social-campaign-comment-token-action {
  display: inline;
  font-size: 0.8rem;
  margin-right: 0.8rem;
}

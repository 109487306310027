.crm-recordingfield-recording {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  display: flex;
}
.crm-recordingfield-recording-asset {
  flex: 1;
}
.crm-recordingfield-panel {
  flex: 1;
  padding: 0.8rem;
}
.crm-recordingfield-field {
  display: flex;
  &:focus {
    outline: none !important;
  }
}
.crm-recordingfield-field-phone {
  flex: 1;
  .maha-input {
    border-radius: 0.4rem 0 0 0.4rem;
    border-right: none;
  }
}
.crm-recordingfield-field-button {
  .ui.button {
    padding: 1.1rem 1.6rem;
    border-radius: 0 0.4rem 0.4rem 0;
  }
}
.crm-recordingfield-status {
  border-radius: 0.4rem;
  padding: 0.6rem 0.8rem;
  &.calling {
    border: 1px solid fadeout($blue, 70);
    background-color: fadeout($blue, 90);
  }
  &.recording {
    border: 1px solid fadeout($green, 70);
    background-color: fadeout($green, 90);
  }
  &.reviewing {
    border: 1px solid fadeout($yellow, 70);
    background-color: fadeout($yellow, 90);
  }
  &.processing {
    border: 1px solid fadeout($green, 70);
    background-color: fadeout($green, 90);
  }
}

.crm-recordingfield-strategy-type {
  flex: 1;
  display: flex;
}
.crm-recordingfield-strategy-type-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  flex: 0 0 3rem;
}
.crm-recordingfield-strategy-type-label {
  padding: 0.8rem;
}

.phone-call-activities {
  background-color: lighten($blue, 50);
  position: relative;
  padding: 0.8rem;
  &:before {
    position: absolute;
    width: 1px;
    background-color: $blue;
    content: ' ';
    bottom: calc(0.8rem + 10px);
    top: calc(0.8rem + 10px);
    left: calc(0.8rem + 2px);
  }
}
.phone-call-activities-activity {
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.8rem;
  .maha-icon {
    color: $blue;
    display: inline-block;
    font-size: 6px;
  }
  span {
    color: $blue;
    display: inline-block;
    margin: 0 0.4rem;
  }
  &.active {
    .maha-icon {
      transition: color 2s;
      animation: pulse 2s infinite;
    }
  }
}
@keyframes pulse {
	0% {
    color: lighten($black, 80);
	}
	50% {
    color: $green;
	}
	100% {
    color: lighten($black, 80);
	}
}

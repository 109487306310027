.maha-omnisearch-section-header {
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.maha-omnisearch-result {
  border-bottom: 1px solid fadeout($black, 95);
  background-color: $white;
  cursor: pointer;
  display: flex;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-omnisearch-result-details {
  color: fadeout($black, 40);
  padding: 0.8rem;
  flex: 1;
}
.crm-contact-note {
  border-top: 1px solid fadeout($black, 95);
  display: flex;
}
.crm-contact-note-avatar {
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo,
  .maha-avatar {
    width: 36px;
  }
}
.crm-contact-note-details {
  flex: 1;
  padding: 0.8rem;
  .maha-logo {
    width: 24px;
  }
  .maha-icon {
    color: fadeout($black, 60);
  }
  .maha-asset-token {
    border: 1px solid fadeout($black, 90);
    display: inline-block;
    border-radius: 0.4rem;
    width: auto;
    min-width: 150px;
    margin: 0.4rem 0.4rem 0 0;
  }
}
.crm-contact-note-user {
  font-weight: bold;
  display: inline;
  margin-right: 0.4rem;
}
.crm-contact-note-timestamp {
  display: inline;
  font-size: 0.8rem;
  color: fadeout($black, 60);
  display: inline;
  cursor: pointer;
  span {
    display: inline;
  }
}
.crm-contact-note-program {
  color: $blue;
  .maha-logo {
    display: inline-block;
    margin: 0 0 -1px 0;
    overflow: hidden;
    width: 13px;
  }
  .maha-logo-wrapper {
    border-radius: 2px;
  }
  .maha-logo-text {
    font-size: 0.6rem;
    line-height: 1.6rem;
  }
}
.crm-contact-note-extra {
  color: $blue;
  padding: 0.8rem;
  display: flex;
}
.crm-contact-note-attachment {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: inline-flex;
  margin: 0.4rem 0.4rem 0.4rem 0;
  min-width: 150px;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.crm-contact-note-attachment-icon {
  padding: 0.4rem;
}
.crm-contact-note-attachment-label {
  padding: 0.4rem 0.8rem 0.4rem 0;
  flex: 1;
  color: $blue;
}

.file-type-token {
  .fa-file-pdf-o { color: $red; }
  .fa-file-word-o { color: $blue; }
  .fa-file-excel-o { color: $green; }
  .fa-file-powerpoint-o { color: $orange; }
  .fa-file-image-o { color: $purple; }
  .fa-file-audio-o { color: $teal; }
  .fa-play-circle { color: $red; }
  .fa-file-text-o { color: $black; }
  .fa-file-text { color: $blue; }
  .fa-map-o { color: $red; }
  .fa-file-video-o { color: $red; }
}

.service-token {
  display: flex;
}
.service-token-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  img {
    border-radius: 0.2rem;
    display: block;
    height: 1.4rem;
    width: 1.4rem;
  }
}
.service-token-details {
  padding: 0.8rem 0.8rem 0.8rem 0;
  flex: 1;
  span {
    color: fadeout($black, 60);
  }
}

.maha-icon-token {
  display: flex;
  flex: 1;
}
.maha-icon-token-image {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  .icon-token {
    width: 24px;
  }
}
.maha-icon-token-label {
  padding: 0.8rem 0.8rem 0.8rem 0;
  flex: 1;
  span {
    color: fadeout($black, 40);
  }
}
.access-type-token {
  display: block;
  color: $white;
  padding: .4rem .8rem;
  font-size: 11px;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
  width: 9rem;
  text-transform: uppercase;
  &.administrator,
  &.manage,
  &.owner {
    background-color: $red;
  }
  &.approver,
  &.contribute,
  &.edit {
    background-color: $orange;
  }
  &.contributor {
      background-color: $yellow;
  }
  &.member,
  &.view {
      background-color: $green;
  }
}

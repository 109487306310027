.seo-preview {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  padding: 0.8rem;
}
.seo-preview-title {
  font-size: 1.4rem;
  color: #1a0dab;
}
.seo-preview-link {
  color: #202124;
  font-size: 0.8rem;
  line-height: 1.2rem;
}

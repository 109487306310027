.maha-attachments-explorer {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  &.reviewable {
    padding-bottom: 3rem;
  }
}
.maha-attachments-explorer-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.8rem;
  background-color: $yellow;
  color: $white;
  text-align: center;
  cursor: pointer;
}

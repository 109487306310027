.crm-form-card,
.crm-workflow-card {
  .crm-workflow-actions {
    padding: 0;
  }
}
.crm-workflow-actions {
  border-top: 1px solid fadeout($black, 90);
  background-color: lighten($blue, 50);
  padding: 0.8rem;
  .crm-workflow-action:last-child {
    padding-bottom: 0;
    &:after {
      content: none;
    }
  }
}
.crm-workflow-action {
  display: flex;
  padding-bottom: 0.6rem;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 2px solid fadeout($black, 85);
    transform: translate(0.8rem, 0.8rem);
  }
}
.crm-workflow-action-icon {
  flex: 0 0 1.6rem;
  display: flex;
}
.crm-workflow-action-action {
  @include flex-justify-middle-center;
  border: 2px solid fadeout($black, 80);
  margin: 0 auto auto;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  color: $white;
  z-index: 2;
  .maha-icon {
    font-size: 0.8rem;
  }
  &.trigger {
    background-color: $green;
  }
  &.voice,
  &.sms {
    background-color: $violet;
  }
  &.administrative {
    background-color: $pink;
  }
  &.control {
    background-color: $blue;
  }
  &.communication {
    background-color: $purple;
  }
  &.contact {
    background-color: $teal;
  }
  &.ending {
    background-color: $red;
  }
  &.action {
    background-color: $blue;
  }
}
.crm-workflow-action-details {
  padding-left: 0.8rem;
  font-size: 0.8rem;
  line-height: 1.4rem;
  flex: 1;
}
.crm-workflow-action-header {
  display: flex;
}
.crm-workflow-action-label {
  flex: 1;
  color: $black;
  strong {
    text-transform: uppercase;
    cursor: pointer;
  }
  .link {
    display: inline;
  }
  .maha-icon {
    width: 0.6rem;
    margin-right: 0.4rem;
  }
}
.crm-workflow-action-timestamp {
  color: fadeout($black, 60);
  font-size: 0.8rem;
}
.crm-workflow-action-body {
  padding: 0.4rem 0 0 0;
  color: fadeout($black, 40);
  line-height: 1.6rem;
  .phone-call-activities {
    margin: -0.4rem -0.8rem;
  }
}

.maha-asset-info {
  background-color: $white;
  flex: 1;
  display: flex;
  flex-direction: column;
  .maha-comments {
    flex: 1;
  }
}
.maha-asset-info-header {
  background-color: $white;
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
  .maha-asset-token {
    overflow: hidden;
    border: none;
    flex: 1;
  }
}

.maha-asset-info-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  .maha-comments {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .maha-comments-body {
    @include vscroll;
    flex: 1;
  }
}

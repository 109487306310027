.fa-voicemail {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(0,0,0,0.4)' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M18.5 6C15.46 6 13 8.46 13 11.5c0 1.33.47 2.55 1.26 3.5H9.74c.79-.95 1.26-2.17 1.26-3.5C11 8.46 8.54 6 5.5 6S0 8.46 0 11.5 2.46 17 5.5 17h13c3.04 0 5.5-2.46 5.5-5.5S21.54 6 18.5 6zm-13 9C3.57 15 2 13.43 2 11.5S3.57 8 5.5 8 9 9.57 9 11.5 7.43 15 5.5 15zm13 0c-1.93 0-3.5-1.57-3.5-3.5S16.57 8 18.5 8 22 9.57 22 11.5 20.43 15 18.5 15z'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
}
.maha-finder-item-icon .fa-voicemail {
  background-position: 0 0;
  width: 24px;
  height: 24px;
}
.maha-message .fa-voicemail {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(0,0,0,0.6)' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M18.5 6C15.46 6 13 8.46 13 11.5c0 1.33.47 2.55 1.26 3.5H9.74c.79-.95 1.26-2.17 1.26-3.5C11 8.46 8.54 6 5.5 6S0 8.46 0 11.5 2.46 17 5.5 17h13c3.04 0 5.5-2.46 5.5-5.5S21.54 6 18.5 6zm-13 9C3.57 15 2 13.43 2 11.5S3.57 8 5.5 8 9 9.57 9 11.5 7.43 15 5.5 15zm13 0c-1.93 0-3.5-1.57-3.5-3.5S16.57 8 18.5 8 22 9.57 22 11.5 20.43 15 18.5 15z'/></svg>");
  background-size: 1.2rem;
}
.maha-handset-client-footer-item.selected .fa-voicemail {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(33,133,208)' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M18.5 6C15.46 6 13 8.46 13 11.5c0 1.33.47 2.55 1.26 3.5H9.74c.79-.95 1.26-2.17 1.26-3.5C11 8.46 8.54 6 5.5 6S0 8.46 0 11.5 2.46 17 5.5 17h13c3.04 0 5.5-2.46 5.5-5.5S21.54 6 18.5 6zm-13 9C3.57 15 2 13.43 2 11.5S3.57 8 5.5 8 9 9.57 9 11.5 7.43 15 5.5 15zm13 0c-1.93 0-3.5-1.57-3.5-3.5S16.57 8 18.5 8 22 9.57 22 11.5 20.43 15 18.5 15z'/></svg>");
}
.flowchart-designer-block-icon .fa-voicemail {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(255,255,255)' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M18.5 6C15.46 6 13 8.46 13 11.5c0 1.33.47 2.55 1.26 3.5H9.74c.79-.95 1.26-2.17 1.26-3.5C11 8.46 8.54 6 5.5 6S0 8.46 0 11.5 2.46 17 5.5 17h13c3.04 0 5.5-2.46 5.5-5.5S21.54 6 18.5 6zm-13 9C3.57 15 2 13.43 2 11.5S3.57 8 5.5 8 9 9.57 9 11.5 7.43 15 5.5 15zm13 0c-1.93 0-3.5-1.57-3.5-3.5S16.57 8 18.5 8 22 9.57 22 11.5 20.43 15 18.5 15z'/></svg>");
  background-size: 1.3rem;
  margin: 0 !important;
}
.crm-workflow-action-action  .fa-voicemail, .flowchart-box-icon .fa-voicemail {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(255,255,255)' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M18.5 6C15.46 6 13 8.46 13 11.5c0 1.33.47 2.55 1.26 3.5H9.74c.79-.95 1.26-2.17 1.26-3.5C11 8.46 8.54 6 5.5 6S0 8.46 0 11.5 2.46 17 5.5 17h13c3.04 0 5.5-2.46 5.5-5.5S21.54 6 18.5 6zm-13 9C3.57 15 2 13.43 2 11.5S3.57 8 5.5 8 9 9.57 9 11.5 7.43 15 5.5 15zm13 0c-1.93 0-3.5-1.57-3.5-3.5S16.57 8 18.5 8 22 9.57 22 11.5 20.43 15 18.5 15z'/></svg>");
  width: 1.8rem;
  text-align: center;
  background-size: 1.3rem;
  margin: 0 !important;
}
.maha-handset {
  display: flex;
  flex: 1;
}
.maha-handset-main {
  display: flex;
  flex: 1;
}
.maha-handset-sidebar {
  flex: 0 0 350px;
  display: flex;
  position: relative;
}

.maha-handset-container {
  flex: 1;
  position: relative;
  .maha-modal-panel-header {
    background-color: $violet !important;
  }
  .maha-modal-panel-body {
    border-left: 1px solid fadeout($black, 90);
  }
  .maha-handset-container-panel {
    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
  }
}

.maha-handset-client {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-handset-client-header {
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
}
.maha-handset-client-body {
  overflow: hidden;
  display: flex;
  flex: 1;
}
.maha-handset-client-footer {
  border-top: 1px solid fadeout($black, 90);
  display: flex;
}
.maha-handset-client-footer-item {
  flex: 1;
  padding: 0.8rem;
  text-align: center;
  cursor: pointer;
  color: fadeout($black, 60);
  position: relative;
  &.selected {
    color: $blue;
  }
  @include hover {
    background-color: fadeout($black, 95);
  };
  img {
    font-size: 1.4rem;
  }
  .maha-icon {
    font-size: 1.4rem;
    display: block;
    margin: 0 auto;
  }
  .fa-voicemail {
    height: 1rem;
  }
  span {
    font-size: 0.7rem;
  }
}
.maha-handset-client-footer-item-count {
  position: absolute;
  top: 0.4rem;
  left: calc(50% + 5px);
  background-color: $red;
  color: $white;
  border-radius: 7px;
  display: inline-block;
  font-size: 0.6rem;
  min-width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  padding: 0 4px;
}


.maha-handset-search {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-handset-search-header {
  border-bottom: 1px solid fadeout($black, 90);
  padding: 0.6rem;
  .maha-searchbox {
    background-color: fadeout($black, 95);
    border-radius: 0.4rem;
    padding: 0.4rem;
  }
}
.maha-handset-search-body {
  flex: 1;
  display: flex;
}
.maha-handset-search-result {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  display: flex;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-handset-search-result-token {
  display: flex;
  flex: 1;
}
.maha-handset-search-result-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 60);
  }
}

.maha-handset-detail-container {
  flex: 1;
  @include vscroll;
}
.maha-handset-detail {
  flex-direction: column;
  display: flex;
}
.maha-handset-detail-header {
  text-align: center;
  .contact-avatar-token,
  .maha-avatar {
    width: 8rem;
    margin: 1.6rem auto;
  }
  .maha-avatar-text {
    font-size: 3rem;
  }
  h2 {
    margin: 0;
  }
  p {
    line-height: 1.6rem;
    color: fadeout($black, 60);
    margin: 0;
  }
  .maha-handset-actions {
    margin-bottom: 1.4rem;
  }
}
.maha-handset-detail-title {
  padding: 0.2rem 0.8rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  background-color: fadeout($black, 70);
  color: $white;
}
.maha-handset-detail-body {
  border-top: 1px solid fadeout($black, 90);
  flex: 1;
}
.maha-handset-actions {
  display: flex;
}
.maha-handset-action {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0.8rem;
}
.maha-handset-debug {
  padding: 0.8rem;
  cursor: pointer;
  text-align: center;
  background-color: fadeout($black, 97);
  border-top: 1px solid fadeout($black, 95);
}

.crm-email-leaderboard {
  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    width: 90px;
  }
  strong {
    line-height: 1rem;
    display: block;
    color: $red;
  }
  span {
    color: fadeout($black, 60);
    line-height: 1rem;
    font-size: 0.9rem;
  }
}
.crm-email-leaderboard-header {
  background-color: fadeout($black, 40);
  color: $white;
  padding: 0.8rem;
}

.automation-workflow-token {
  display: flex;
}
.automation-workflow-token-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 1.8rem;
    font-size: 0.8rem;
  }
}
.automation-workflow-token-label {
  flex: 1;
  line-height: 1.6rem;
  padding: 0.8rem;
}
.automation-workflow-token-target {
  color: $blue;
}

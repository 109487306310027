.designer-token {
  display: flex;
  @include hover {
    .designer-token-icon {
      visibility: visible;
    }
  }
}
.designer-token-label {
  flex: 1;
  user-select: text;
}
.designer-token-icon {
  visibility: hidden;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}

.maha-reactions {
  position: relative;
  display: inline-block;
}
.maha-reactions-reaction {
  border: 1px solid lighten($blue, 30);
  background-color: lighten($blue, 50);
  color: lighten($blue, 20);
  padding: 0.2rem;
  position: relative;
  margin: 0.4em 0.4rem 0 0;
  border-radius: 0.3rem;
  position: relative;
  display: inline-block;
  font-size: 0.8rem;
  line-height: 1.1rem;
  vertical-align: middle;
  cursor: pointer;
  @include hover {
    border-color: lighten($blue, 10);
    color: lighten($blue, 10);
  };
  img {
    width: 1.1rem;
    height: 1.1rem;
    line-height: 1.2rem;
    display: inline-block;
    margin-right: 0.2rem;
    margin-bottom: -0.1rem;
  }
}
.maha-reactions-reaction-users {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -10px;
  width: 120px;
  background-color: lighten($black, 20);
  color: $white;
  padding: 0.3rem;
  z-index: 1;
  font-size: 1rem;
  line-height: 1.6rem;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10px;
    margin-left: -4px;
    border-width: 4px;
    border-style: solid;
    border-color: lighten($black, 20) transparent transparent transparent;
  }
}

.maha-gallery-list {
  flex: 1;
  @include vscroll;
}
.maha-gallery-list-item {
  margin: 0.8rem auto 0;
  width: 100%;
  max-width: 450px;
  cursor: pointer;
}
.maha-gallery-list-item-caption {
  padding: 0 0.8rem 0.8rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: fadeout($black, 60);
  text-align: center;
}

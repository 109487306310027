.maha-form {
  display: flex;
  flex: 1;
  .ui.form {
    font-size: 1rem;
    &.loading {
      &:before {
        content: none;
      }
      .maha-form-sections:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        background: fadeout($white, 20);
        width: 100%;
        height: 100%;
        z-index: 100;
      }
    }

  }
  .maha-menus-header {
    background-color: $blue;
    padding-top: 0;
    .ui.menu:not(.pointing) {
      background-color: $blue;
      border-color: $white;
      box-shadow: none !important;
      .item {
        border-color: $white !important;
        color: $white !important;
        &:before {
          background-color: fadeout($white, 60) !important;
        }
        &.active {
          background-color: $white !important;
          color: $blue !important;
        }
      }
    }
  }
}
.maha-form-segment {
  border: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 98);
  border-radius: 0.4rem;
  padding: 0.4rem 0;
  & > .maha-form-field {
    & > .maha-form-field-control {
      & >.maha-form-segment {
        background-color: $white;  
      }
    }
  }
}
.maha-modal-panel-body {
  display: flex;
  .ui.form {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .maha-menus {
    flex: 1;
  }
}
.maha-modal-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: fadeout($black, 98);
}
.maha-form-before {
  line-height: 1.6rem;
}
.maha-form-sections {
  position: relative;
  flex: 1;
  &:not(.inline) {
    @include vscroll;
  }
  .maha-form-section:last-child:not(.collapsing) {
    border-bottom: none;
  }
}
.maha-form-section-after {
  padding: 0.8rem;
}
.maha-form-section {
  position: static;
  padding: 0;
  margin: 0 0 2px;
  &.collapsing {
    .maha-form-section-header {
      cursor: pointer;
    }
  }
  &.collapsed {
    .maha-form-section-body {
      display: none;
    }
  }
  &.expanded {
    .maha-form-section-header {
      .maha-icon {
        transform: rotate(180deg);
      }
    }
  }
  &.padded {
    .maha-form-section-header-label {
      padding: 0.4rem 0.8rem;
    }
  }
	input, textarea {
		color: lighten($black, 40) !important;
	}
	textarea {
		line-height: 1.5rem !important;
	}
}
.maha-form-section-header {
  display: flex;
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.maha-form-section-header-label {
  font-weight: bold;
  flex: 1;
}
.maha-form-message {
  background-color: fadeout($red, 90);
  margin: 0.8rem 0.8rem 0;
  border-radius: 0.4rem;
  padding: 0.8rem;
  color: $red;
}
.maha-form-section-header-label.error span,
.maha-form .maha-menus .item.error span {
  position: relative;
  &:after {
    content: '!';
    position: absolute;
    top: -5px;
    right: -8px;
    background-color: $red;
    color: $white;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
  }
}

.maha-form-section-header-icon {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.8rem;
  .maha-icon {
    transition: transform .15s ease-in-out;
    color: fadeout($black, 60);
    font-size: 0.7rem;
  }
}
.error {
  .maha-control > div {
    border-color: $red !important;
  }
  .maha-form-segment {
    border-color: $red !important;
  }
  table, input, textarea, select, .lookup-field, .dropdown {
    border-color: $red !important;
    background-color: $white !important;
  }
  & > label {
    color: $red !important;
  }
  .error-message {
    color: $red !important;
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }
  .ui.label {
    background-color: $red !important;
    color: $white !important;
  }
  .ui.dropdown {
    background: none !important;
  }
}
.maha-form-section-fields {
  padding: 0.4rem 0;
}
.maha-form-section-instructions {
  padding: 0.8rem 0.8rem 0 0.8rem;
  color: fadeout($black, 40);
  line-height: 1.8rem;
  font-size: 0.9rem;
  strong {
    color: $darkblue;
  }
  ul {
    padding: 0 1.6rem;
  }
}
.maha-form-field {
  display: flex;
  padding: 0.4rem 0.8rem;
  line-height: 1.6rem;
  margin: 0 !important;
  flex-direction: column !important;
  &.hidden {
    display: none !important;
  }
  &.collapsing {
    .maha-form-field-label label {
      cursor: pointer;
      @include hover {
        color: lighten($darkblue, 20);
      };
    }
  }
  &.collapsed {
    .maha-form-field-control {
      display: none;
    }
  }
  &.expanded {
    & > .maha-form-field-label .maha-icon {
      transform: rotate(90deg);
    }
  }
  &.required > .maha-form-field-label:after {
    margin: -.2rem 0 0 .2rem;
    content: '*';
    color: $red;
  }
  &.fields {
    padding: 0 0.8rem;
  }
  .fields {
    margin-bottom: 0 !important;
  }
}
.maha-form-field-label {
  font-size: 0.8rem;
  line-height: 1.6rem;
  min-height: 1.6rem;
  label {
    text-transform: uppercase;
    font-weight: bold;
    color: $darkblue;
  }
  .maha-icon {
    transition: transform .15s ease-in-out;
    color: fadeout($black, 80);
  }
}
.maha-form-field-label-icon {
  display: inline;
}
.maha-form-field-instructions {
  color: fadeout($black, 40);
  font-size: 0.9rem;
  line-height: 1.8rem;
}
.maha-form-field-control {
  flex: 1;
  padding: 0;
}
.maha-form.inline {
  flex: none;
  display: block;
  .maha-form,
  .maha-stack,
  .maha-stack-inner,
  .maha-stack-card,
  .maha-modal-panel {
    flex: none;
    display: block;
    overflow: visible;
  }
  .maha-stack-card {
    position: static;
    transform: none;
    background: none;
  }
  .maha-modal-panel {
    position: static;
    background: none;
  }
}
.maha-input {
  border: 1px solid lighten($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  display: flex;
  position: relative;
  &.focused,
  &:focus {
    outline: none !important;
    border-color: $lightblue;
    box-shadow: none;
  }
  &.disabled {
    background-color: fadeout($black, 98);
    color: fadeout($black, 40);
    * {
      color: fadeout($black, 40) !important;
    }
    .maha-icon {
      color: fadeout($black, 80) !important;
    }
  }
  &.unlabeled {
    margin-top: 1.5rem;
  }
}
.maha-input-extra {
  position: absolute;
  right: 0;
  top: -2rem;
}
.maha-input-placeholder {
  flex: 1;
  color: fadeout($black, 80);
  cursor: pointer;
  margin: 0.2rem;
  padding: 0.4rem;
  line-height: 2rem;
}
.maha-input-icon {
  @include flex-justify-middle-center;
  &:first-child {
    padding: 0.8rem 0 0.8rem 0.8rem;
  }
  &:last-child {
    padding: 0.8rem 0.8rem 0.8rem 0;
  }
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.maha-input-field {
  flex: 1;
  display: flex;
  input, textarea {
    width: 100%;
    flex: 1;
    border: none !important;
    outline: none !important;
    background: none !important;
    padding: 0.8rem !important;
    line-height: 1.6rem !important;
  }
}
.maha-input-action {
  padding: 0.8rem 0.8rem 0.8rem 0;
  display: flex;
  .maha-icon {
    @include hover {
      color: fadeout($black, 70);
    };
    color: fadeout($black, 80);
    margin: 0.2rem auto auto;
    cursor: pointer;
  }
}
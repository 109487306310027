.email-type-token {
  text-transform: uppercase;
  &.system { color: $purple; }
  &.campaign { color: $green; }
  &.automation { color: $red; }
  &.thread { color: $blue; }
  font-size: 0.6rem;
  line-height: 1rem;
  display: inline;
}

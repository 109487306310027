.maha-session {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  transition: 1s;
	transform-style: preserve-3d;
  backface-visibility: hidden;
  background-color: $white;
  &.flip-exit {
    z-index: 1;
  	transform: rotateY(0deg);
  }
  &.flip-exit-active {
    transform: rotateY(180deg) !important;
  }
  &.flip-enter {
    z-index: 2;
    transform: rotateY(-180deg);
  }
  &.flip-enter-active,
  &.flip-enter-done {
    transform: rotateY(0deg) !important;
  }
}
.maha-session-body {
  flex: 1;
  display: flex;

}

.crm-mappolygon {
  flex: 1;
  padding: 0.8rem;
}
.crm-mappolygon-preview {
  display: flex;
  flex-direction: column;
  border: 1px solid fadeout($black, 90);
}
.crm-mappolygon-preview-image {
  img {
    background-color: fadeout($black, 40);
    width: 300px;
    height: 225px;
  }
}
.crm-mappolygon-preview-button {
  background-color: fadeout($black, 97);
  padding: 0.4rem;
}
.crm-mappolygon-map {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.crm-mappolygon-map-canvas {
  flex: 1;
}
.crm-mappolygon-map-button {
  border-top: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}

  .mjson-designer-media-results {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}
.mjson-designer-media-result {
  &.icon {
    flex: 0 0 calc(100%/5);
  }
  &.image {
    flex: 0 0 calc(100%/2);
  }
  &.video {
    flex: 0 0 100%;
  }
  display: flex;
  cursor: pointer;
  padding: 0.8rem;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.mjson-designer-media-icon {
  @include flex-justify-middle-center;
  flex: 1;
  img {
    width: 32px;
    height: 32px;
  }
}
.mjson-designer-media-video {
  background-color: lighten($black, 97);
}
.mjson-designer-media-video-details {
  color: fadeout($black, 60);
  padding: 0.8rem;
  strong {
    color: $black;
  }
}

.task-token {
  display: flex;
}
.task-token-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  @include hover {
    .complete {
      color: $red !important;
    }
    .incomplete {
      color: $green !important;
    }
  };
  .maha-icon {
    font-size: 1.4rem;
    .complete {
      color: $green;
    }
    .incomplete {
      color: fadeout($black, 80);
    }
  }
}
.task-token-label {
  padding: 0.8rem;
  flex: 1;
}

.maha-image {
  background-color: fadeout($black, 90);
  position: relative;
  overflow: hidden;
  color: $white;
  .fa-spin {
    position: absolute;
    top: calc(50% - 0.4rem);
    left: calc(50% - 0.6rem);
    font-size: 2rem;
    color: $white;
    text-shadow: 0 0 3px fadeout($black, 15);
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.segmentsfield-segment {
  display: flex;
  flex: 1;
  &.container .segmentsfield-segment-icon .maha-icon {
    color: $teal;
  }
  &.section .segmentsfield-segment-icon .maha-icon {
    color: $teal;
  }
  &.row .segmentsfield-segment-icon .maha-icon {
    color: $violet;
  }
  &.column .segmentsfield-segment-icon .maha-icon {
    color: $purple;
  }
  &.block .segmentsfield-segment-icon .maha-icon {
    color: $pink;
  }
  &.content .segmentsfield-segment-icon .maha-icon {
    color: $red;
  }
}
.segmentsfield-segment-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.segmentsfield-segment-label {
  input {
    border: none;
    box-shadow: none;
    outline: none;
    padding: 0.8rem;
    width: 100%;
  }
  flex: 1;
}
.maha-platform {
  flex-direction: column;
  position: relative;
  display: flex;
  flex: 1;
  padding-top: env(safe-area-inset-top);
  padding-bottom: calc(env(safe-area-inset-bottom) - 1.6rem);
}
.software-keyboard {
  .maha-platform  {
    padding: 0;
  }
}

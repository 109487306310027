.import-token {
  line-height: 1.4rem;
  display: flex;
}
.import-token-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  img {
    border-radius: 0.4rem;
    overflow: hidden;
    width: 2rem;
  }
}
.import-token-label {
  flex: 1;
  font-size: 0.9rem;
  padding: 0.8rem;
}

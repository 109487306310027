.maha-portal {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  & > .maha-loader {
    background: $white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.maha-portal-main {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  @media (orientation: landscape) {
    border-right: env(safe-area-inset-left) solid $black;
  }
}
.maha-portal-help {
  flex: 0 0 350px;
  display: flex;
  position: relative;
}
.maha-portal-frame {
  flex: 1;
  display: flex;
}
.maha-portal-ribbon {
  display: flex;
}
.maha-portal-sidebar {
  border-right: 1px solid $blue;
  flex: 0 0 350px;
  position: relative;
  background-color: $white;
  display: flex;
  overflow: hidden;
  .maha-toggle-list-header .maha-searchbox,
  .maha-modal-panel-header,
  .maha-tabs-header {
    background-color: $blue !important;
  }
}
.maha-portal-body {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  & > div:first-child {
    z-index: 0;
  }
  & > .maha-stack {
    flex: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}
.maha-portal-navigation,
.maha-portal-account {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  max-width: 350px;
  width: calc(100vw - 3.5rem);
  display: flex;
  transition: transform .25s ease-in-out 0s;
}
.maha-portal-navigation {
  left: 0;
  transform: translateX(-100%);
}
.maha-portal {
  &.navigation .maha-portal-navigation {
    transform: translateX(0);
  }
  &.account .maha-portal-account {
    transform: translateX(0);
  }
}
.maha-portal-overlay {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: $black;
}
.maha-notice {
  background-color: $teal;
  color: $white;
  text-align: center;
  padding: 0.8rem;
  .link {
    color: $white;
    text-decoration: underline;
  }
}
.software-keyboard {
  .maha-portal-ribbon {
    display: none;
  }
}
@media (max-width: 768px) {
  .maha-portal-frame {
    flex-direction: column;
  }
  .maha-portal-ribbon {
    order: 2;
  }
  .maha-portal-body {
    order: 1;
  }
  .maha-notice {
    display: flex;
    flex-direction: column;
    p {
      margin:auto;
      width: 300px;
    }
  }
  .maha-portal-account {
    right: 0;
    transform: translateX(100%);
  }
}
@media (min-width: 769px) {
  .maha-portal-body {
    position: relative;
  }
  .maha-portal-account {
    left: 0;
    transform: translateX(-100%);
  }
}

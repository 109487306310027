


.maha-composer-attachments {
  display: flex;
}

.maha-composer-items {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.4rem 0.4rem;
  overflow: hidden;
}
.maha-composer-item {
  flex: 0 0 80px;
  position: relative;
  padding: 0.2rem;
}

.maha-composer-gallery {
  overflow: hidden;
  max-width: 370px;
  width: 100%;
}
.maha-composer-gallery-1 {
  .maha-composer-gallery-item {
    width: 100%;
  }
}
.maha-composer-gallery-2 {
  .maha-composer-gallery-item {
    width: calc(100% / 2);
  }
}
.maha-composer-gallery-3 {
  .maha-composer-gallery-item {
    &:nth-child(1) {
      width: calc(100% / 3 * 2);
    }
    &:nth-child(2),
    &:nth-child(3) {
      width: calc(100% / 3);
    }
  }
}
.maha-composer-gallery-4 {
  .maha-composer-gallery-item {
    width: calc(100% / 2);
  }
}
.maha-composer-gallery-item {
  position: relative;
  cursor: pointer;
  float: left;
  padding: 0.2rem;
}
.maha-composer-gallery-more {
  @include flex-justify-middle-center;
  background-color: fadeout($black, 50);
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
  left: 0.2rem;
  &:after {
    content: "+" attr(data-count);
    text-align: center;
    color: $white;
    font-size: 2rem;
  }
}

.maha-composer-image {
  overflow: hidden;
  padding-bottom: 100%;
  height: 0;
  position: relative;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.maha-composer-file {
  overflow: hidden;
  padding-bottom: 100%;
  height: 0;
  position: relative;
}
.maha-composer-file-detail {
  background-color: fadeout($black, 90);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.maha-composer-file-icon {
  @include flex-justify-middle-center;
  flex: 1;
  .maha-asset-icon {
    .maha-icon {
      font-size: 2.4rem;
      display: block;
    }
  }
}
.maha-composer-file-name {
  flex: 0 0 3rem;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2rem;
  padding: 0 0.4rem;
  overflow: hidden;
}

.maha-composer-file,
.maha-composer-image {
  .fa-circle-o-notch {
    text-shadow: 0 0 1px fadeout($black, 25);
    font-size: 1.6rem;
    position: absolute;
    color: $white;
    margin: auto;
    left: 35%;
    top: 35%;

  }
  .fa-times {
    text-shadow: 0 0 1px fadeout($black, 25);
    position: absolute;
    color: darken($white, 5);
    right: 0.4rem;
    top: 0.4rem;
    cursor: pointer;
    @include hover {
      color: $white;
    };
  }
}

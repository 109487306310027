.maha-ribbon  {
  display: flex;
  flex: 1;
}
.maha-ribbon-item {
  cursor: pointer;
  &.active {
    .maha-badge {
      background-color: $blue !important;
    }
  }
  .maha-icon {
    font-size: 1.4rem;
  }
}
@media (max-width: 768px) {
  .maha-ribbon  {
    border-top: 1px solid fadeout($black, 90);
    background-color: $darkblue;
    right: 0;
  }
  .maha-ribbon-item {
    color: $white;
    text-align: center;
    flex: 1;
    padding: 0.8rem 0;
    span {
      color: fadeout($white, 10);
      font-size: 0.6rem;
      line-height: 1.2rem;
    }
  }
  .maha-badge {
    display: block;
    .maha-avatar {
      margin: auto;
      width: 1.5rem;
      height: 1.5rem;
      margin-bottom: 2px;
    }
  }
}
@media (min-width: 769px) {
  .maha-ribbon  {
    background-color: $black;
    flex-direction: column;
    padding: 0.6rem;
    bottom: 0;
  }
  .maha-ribbon-item {
    flex: 0 0 3.5rem;
    display: flex;
    color: $white;
    span {
      display: none;
    }
  }
  .maha-ribbon-spacer {
    flex: 1;
  }
  .maha-badge {
    @include flex-justify-middle-center;
    border-radius: 0.4rem;
    padding: 0.4rem;
    width: 3rem;
    height: 3rem;
    @include hover {
      background-color: fadeout($white, 80);
    };
    .maha-avatar {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.maha-datefield-handle {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  .maha-icon {
    @include hover {
      color: fadeout($black, 70);
    };
    line-height: 1rem;
    color: fadeout($black, 80);
    cursor: pointer;
  }
}
.maha-datefield-field {
  flex: 1;
  display: flex;
}
.maha-datefield-input {
  display: flex;
  align-items: center;
  flex: 1;
  input {
    border: none !important;
    padding: 0.8rem 0 !important;
  }
}
.maha-datefield-token {
  display: inline-block;
  padding: 0.2rem 0.4rem;
  background-color: fadeout($black, 95);
  color: fadeout($black, 40);
  border-radius: 0.2rem;
  line-height: 1.6rem;
}
.maha-datefield-chooser {
  padding: 1rem;
}
.maha-datefield-month {
  display: flex;
  flex-direction: column;
}
.maha-datefield-header {
  background-color: fadeout($black, 80);
  flex: 1;
  display: flex;
}
.maha-datefield-weekdays {
  background-color: fadeout($black, 90);
  flex: 1;
  display: flex;
}
.maha-datefield-previous,
.maha-datefield-title,
.maha-datefield-next,
.maha-datefield-weekday,
.maha-datefield-day {
  flex: 0 0 calc(100% / 7);
  border: 1px solid $white;
  text-align: center;
}
.maha-datefield-weekday {
  padding: 0.4rem 0;
  font-size: 0.9rem;
  text-transform: uppercase;
}
.maha-datefield-previous,
.maha-datefield-title,
.maha-datefield-next,
.maha-datefield-day {
  padding: 0.4rem 0;
  text-align: center;
}
.maha-datefield-previous,
.maha-datefield-next {
  @include hover {
    background-color: fadeout($black, 85);
  };
  cursor: pointer;
}
.maha-datefield-title {
  flex: 1;
}
.maha-datefield-week {
  flex: 1;
  display: flex;
}
.maha-datefield-day {
  @include hover {
    background-color: fadeout($black, 90);
  };
  flex: 1;
  background-color: fadeout($black, 95);
  cursor: pointer;
  color: fadeout($black, 40);
  &.notmonth {
    color: fadeout($black, 80);
  }
  &.today {
    color: $black;
    font-weight: bold;
  }
  &.selected {
    @include hover {
      background-color: fadeout($yellow, 80);
    };
    background-color: fadeout($yellow, 85);
  }
}

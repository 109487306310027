.crm-contact-chooser {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.crm-contact-chooser-search {
  display: flex;
  flex: 1;
}
.crm-contact-chooser-button {
  border-top: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}

.maha-notification-types {
  background-color: $white;
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  .maha-notification-types-app:first-child {
    border-radius: 0.4rem 0.4rem 0 0;
  }
  .maha-notification-types-notification:last-child {
    border-radius: 0 0 0.4rem 0.4rem;
    border: none;
  }
}
.maha-notification-types-app {
  background-color: fadeout($black, 97);
  padding: 0.8rem;
  border-bottom: 1px solid fadeout($black, 90);
  .maha-icon {
    margin-right: 0.4rem;
    color: $blue;
  }
}
.maha-notification-types-notification {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.maha-notification-types-notification-label {
  padding: 0.8rem;
  color: fadeout($black, 40);
  flex: 1;
}
.maha-notification-types-notification-methods {
  display: flex;
}
.maha-notification-types-notification-method {
  cursor: pointer;
  margin: 0.4rem;
}
.maha-notification-types-notification-method-display,
.maha-notification-types-notification-method {
  padding: 0.4rem;
  .maha-icon {
    font-size: 1.2rem;
    margin-right: 5px;
  }
  .fa-circle-o {
    color: fadeout($black, 80);
  }
  .fa-check-circle {
    color: $blue;
  }
}
@media only screen and (max-width: 768px) {
  .maha-notification-types-notification {
    flex-direction: column;
  }
  .maha-notification-types-notification-method {
    flex: 1;
  }
}

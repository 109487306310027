.finance-line-items {
  table.ui.table {
    border: 1px solid fadeout($black, 90);
    overflow: hidden;
    border-radius: 0.4rem;
    margin: 0 !important;
    table-layout: fixed;
  }
}
.finance-line-items-empty {
  td {
    text-align: center !important;
  }
}
.finance-line-items-header {
  th:nth-child(2),
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5) {
    width: 70px;
  }
}
.finance-line-items-row {
  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    text-align: right;
    width: 70px;
  }
  td:nth-child(5),
  td:nth-child(6) {
    width: 35px;
  }
}
.finance-line-items-empty {
  td {
    width: 100%;
  }
}
.finance-line-items-total {
  td:nth-child(2) {
    font-weight: bold;
    text-align: right;
  }
  td:first-child,
  td:nth-child(3) {
    background-color: fadeout($black, 97);
  }
  td:nth-child(3) {
    width: 70px;
  }
}
.finance-line-items-addon {
  td:nth-child(2) {
    text-align: right;
  }
}

.finance-line-items-row,
.finance-line-items-addon {
  td:nth-child(5),
  td:nth-child(6) {
    width: 35px;
    text-align: center;
    cursor: pointer;
    &.disabled .maha-icon {
      color: fadeout($black, 80);
    }
    @include hover {
      .maha-icon {
        color: fadeout($black, 40);
      }
    };
    .maha-icon {
      color: fadeout($black, 60);
    }
  }
}
.finance-line-items-actions {
  padding: 0.8rem 0;
}

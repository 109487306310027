.mediafield {
  border: 1px solid fadeout($black, 90);
  border-radius: 5px;
  background: $white;
}
.mediafield-image {
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
}
.mediafield-image-handle {
  padding: 0.8rem 0 0.8rem 0.8rem;
  .fa:hover  {
    color: fadeout($black, 40);
  }
  .maha-icon {
    color: fadeout($black, 80);
    cursor: grab;
  }
}
.mediafield-image-image {
  padding: 0.8rem 0 0.8rem 0.8rem;
  img {
    border: 1px solid fadeout($black, 90);
    width: 6rem;
    height: 6rem;
    display: block;
    overflow: hidden;
  }
}
.mediafield-image-details {
  padding: 0.8rem;
  flex: 1;
}
.mediafield-image-details-filename {
  line-height: 3.2rem;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mediafield-image-details-action {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .fa:hover  {
    color: fadeout($black, 40);
  }
  .maha-icon {
    color: fadeout($black, 80);
    cursor: pointer;
  }
}
.mediafield-add {
  background-color: fadeout($black, 97);
  padding: 0.8rem;
  cursor: pointer;
  overflow: hidden;
  color: $blue;
  @include hover {
    background-color: fadeout($black, 97);
  };
}

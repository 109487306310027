.maha-host {
  position: relative;
  display: flex;
  flex: 1;
  a {
    cursor: pointer;
  }
}
.maha-browser-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: fadeout($black, 60);
}
.maha-browser-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.linkedfield {
  border: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 98);
  border-radius: 0.4rem;
  display: flex;
  padding: 0.8rem;
}
.linkedfield-link {
  padding: 0.6rem 0 0 0.4rem;
  cursor: pointer;
  .maha-icon {
    color: fadeout($black, 80);
  }
  &.linked .maha-icon {
    color: $blue;
  }
  .maha-icon {
    font-size: 1.2rem;
  }
}
.linkedfield-fields {
  flex: 1;
  .linkedfield-field {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.linkedfield-field {
  margin-bottom: 0.4rem;
  display: flex;
}
.linkedfield-field-label {
  padding: 0.4rem 0.4rem 0.4rem 0;
}
.linkedfield-field-input {
  flex: 1;
}

.maha-handset-dialer {
  background-color: fadeout($black, 97);
  display: flex;
  flex-direction: column;
  flex: 1;
  .maha-handset-button.call {
    width: 4rem;
    height: 4rem;
    .maha-icon {
      font-size: 2rem;
    }
  }
}
.maha-handset-dialer-header {
  padding: 0.8rem;
}
.maha-handset-dialer-contact {
  padding: 0 0.8rem;
  text-align: center;
  height: 1rem;
  color: $blue;
  font-weight: bold;
}
.maha-handset-dialer-body {
  padding: 0 0.8rem;
  display: flex;
  flex: 1;
}
.maha-handset-dialer-buttons {
  margin: auto;
}
.maha-handset-dialer-action {
  display: flex;
  padding: 0.8rem;
}

.social-metric-token-total {
  text-align: right;
}
.social-metric-token-subtotals {
  text-align: right;
  color: fadeout($black, 60);
  img {
    opacity: 0.5;
    width: 10px;
    height: 10px;
  }
}
.maha-tooltip {
  position: relative;
  @include hover {
    .maha-tooltip-bubble {
      display: block;
    }
  };
}
.maha-tooltip-bubble {
  display: none;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background: $black;
  color: $white;
  font-size: 0.6rem;
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    right: calc(50% - 5px);
    border-width: 5px;
    border-style: solid;
    border-color: $black transparent transparent transparent;
  }
}

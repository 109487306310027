.maha-gallery {
  overflow: hidden;
  width: 100%;
}
.maha-gallery-photo {
  cursor: pointer;
  float: left;
  position: relative;
  .maha-image {
    overflow: hidden;
    background-color: fadeout($black, 90);
    padding-bottom: 100%;
    height: 0;
    img {
      width: 100%;
      display: block;
      @include hover {
        opacity: 0.9;
      };
    }
  }
}
.maha-gallery-photo-more {
  @include flex-justify-middle-center;
  background-color: fadeout($black, 50);
  position: absolute;
  top: 0.1rem;
  right: 0;
  bottom: 0;
  left: 0.1rem;
  &:after {
    content: "+" attr(data-count);
    text-align: center;
    color: $white;
    font-size: 2rem;
  }
}

.maha-gallery-photo-source {
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.2rem;
}
.maha-gallery-1 {
  .maha-gallery-photo {
    width: 100%;
  }
}
.maha-gallery-2 {
  .maha-gallery-photo {
    width: calc(100% / 2);
    &:nth-child(1) {
      padding: 0 0.1rem 0 0;
    }
    &:nth-child(2) {
      padding: 0 0 0 0.1rem;
    }
  }
}
.maha-gallery-3 {
  .maha-gallery-photo {
    &:nth-child(1) {
      padding: 0 0.1rem 0 0;
      width: calc(100% / 3 * 2);
    }
    &:nth-child(2) {
      padding: 0 0 0.1rem 0.1rem;
      width: calc(100% / 3);
    }
    &:nth-child(3) {
      padding: 0.1rem 0 0 0.1rem;
      width: calc(100% / 3);
    }
  }
}
.maha-gallery-4 {
  .maha-gallery-photo {
    width: calc(100% / 2);
    &:nth-child(1) {
      padding: 0 0.1rem 0.1rem 0;
    }
    &:nth-child(2) {
      padding: 0 0 0.1rem 0.1rem;
    }
    &:nth-child(3) {
      padding: 0.1rem 0.1rem 0 0;
    }
    &:nth-child(4) {
      padding:  0.1rem 0 0 0.1rem;
    }
  }
}

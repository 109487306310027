.designer-content-types {
  flex: 1;
  padding: 0.8rem;
  @include vscroll;
}
.designer-content-type {
  display: flex;
  background-color: $white;
  margin-bottom: 0.6rem;
  cursor: pointer;
  @include hover {
    background-color: lighten($black, 97);
  };
  overflow: hidden;
}
.designer-content-type-icon {
  @include flex-justify-middle-center;
  padding: 0.6rem;
  background-color: $teal;
  color: $white;
}
.designer-content-type-label {
  border: 1px solid fadeout($black, 90);
  border-left: none;
  color: fadeout($black, 40);
  text-transform: uppercase;
  flex: 1;
  padding: 0.8rem;
  font-size: 0.8rem;
  font-weight: bold;
}

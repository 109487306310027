.maha-mjson-screenshot {
  width: 100%;
  background-color: lighten($black, 95);
  position: relative;
  display: flex;
  overflow: hidden;
  height: 0;
  &.loaded img {
    visibility: visible;
  }
  img {
    display: block;
    visibility: hidden;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}
.maha-mjson-screenshot-inner {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  .maha-icon {
    font-size: 1.4rem;
    margin: auto !important;
  }
  .fa-circle-o-notch {
    color: fadeout($black, 90);
  }
  .fa-exclamation-triangle {
    color: $red;
  }
}

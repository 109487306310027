.campaign-token {
  display: flex;
  flex: 1;
  &.compact .campaign-token-preview-inner {
    width: 50px;
  }
}
.campaign-token-logo {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 1.8rem;
    font-size: 0.8rem;
  }
}
.campaign-token-preview {
  padding: 0.8rem 0 0.8rem 0.8rem;
  display: flex;
}
.campaign-token-preview-inner {
  align-items: center;
  display: flex;
  width: 80px;
  img {
    width: 100%;
  }
}
.campaign-token-label {
  padding: 0.8rem;
  line-height: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  .service-token {
    margin: -0.8rem;
  }
}
.campaign-token-metric {
  padding: 0.8rem 0.8rem 0.8rem 0;
  @include flex-justify-middle-center;
  color: $blue;
}

.designer-page-sections {
  flex: 1;
}
.designer-page-section {
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.designer-page-section-label {
  padding: 0.8rem;
  flex: 1;
}
.designer-page-section-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    font-size: 0.8rem;
    color: fadeout($black, 40);
  }
}

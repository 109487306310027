.unsplash-photos {
  padding: 0.4rem;
  display: flex;
  flex-wrap: wrap;
}
.unsplash-photo {
  flex: 1;
  min-width: 150px;
  padding: 0.4rem;
  &.selected {
    .unsplash-token:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 2px $blue;
      border-radius: 0.4rem;
    }
  }
}

.maha-scrollpane {
  position: relative;
  flex-direction: column;
  display: flex;
  flex: 1;
}
.maha-scrollpane-loader {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  .maha-icon {
    font-size: 1.4rem;
    color: $blue;
  }
}
.maha-scrollpane-inner {
  overscroll-behavior: contain !important;
  @include vscroll;
  flex: 1;
}
.maha-scrollpane-signpost {
  position: absolute;
  bottom: 0.4rem;
  right: calc(0.4rem + 17px);
  padding: 0.4rem;
  background-color: $black;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  color: $white;
  cursor: pointer;
  box-shadow: 0 2px 6px 0 fadeout($black, 75);
  @include hover {
    background-color: lighten($black, 25);
  };
}

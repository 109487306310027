.store-order {
  @include vscroll;
  padding: 0.8rem;
  flex: 1;
}
.store-order-inner {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
}
.store-order-header {
  margin-bottom: 1rem;
  background-color: $white;
  padding: 0.8rem;
  position: relative;
  line-height: 1.8rem;
  .store-order-status {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
  }
}
.store-order-header-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.store-order-body {
  display: flex;
}
.store-order-panel {
  margin-bottom: 0.8rem;
  tr {
    td:nth-child(2) {
      width: 100px;
    }
    td:nth-child(3) {
      width: 80px;
    }
  }
  .highlight td {
    font-weight: bold;
  }
}
.store-order-panel {
  background-color: $white;
  margin-bottom: 1rem;
  box-shadow: 0 0 4px fadeout($black, 95);
  &.unfulfilled .store-order-panel-header { border-left: 0.4rem solid $yellow; }
  &.fulfilled .store-order-panel-header { border-left: 0.4rem solid $green; }
  &.refunded .store-order-panel-header { border-left: 0.4rem solid $red; }
}
.store-order-panel-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}
.store-order-panel-body {
  padding: 0.8rem;
}

@media (min-width: 769px) {
  .store-order-main {
    flex: 1;
  }
  .store-order-body {
    order: 1;
  }
  .store-order-sidebar {
    order: 2;
    flex: 0 0 calc(100% / 3);
    max-width: 300px;
    padding-left: 0.8rem;
  }
}
@media (max-width: 768px) {
  .store-order-body {
    flex-direction: column;
  }
}

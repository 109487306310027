.media-preview {
  background-color: fadeout($black, 25);
  width: 100%;
  position: relative;
  margin: auto;
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  padding: 0.8rem;
  height: calc(360px + 1.6rem);
  .maha-image {
    background: none;
    height: 100%;
    position: absolute;
    transform: translateX(-50%);
    display: block;
    margin: auto;
    left: 50%;
    width: auto !important;
    height: 100%;
  }
}

.maha-rating {
  &.disabled {
    .fa-star,
    .fa-star-o {
      color: fadeout($black, 80) !important;
    }
  }
  .maha-input-field {
    padding: 0 0.8rem;
  }
}
.maha-rating-star {
  display: inline-block;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.6rem 0.2rem 0.6rem 0;
  .fa-star-o {
    color: fadeout($black, 80);
  }
  .fa-star {
    color: $yellow;
  }
}

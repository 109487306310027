.drive-move {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.drive-move-body {
  flex: 1;
  display: flex;
}
.drive-move-footer {
  padding: 0.8rem;
  border-top: 1px solid fadeout($black, 95);
  background-color: fadeout($black, 97);
}

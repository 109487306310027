.compact-project-token-activity {
  color: $red !important;
  font-size: 0.8rem;
  line-height: 2rem;
}
.maha-assignment-unassigned {
  .compact-project-token {
    padding: 0.8rem;
  }
}
.maha-assignment-assigned {
  .compact-project-token {
    padding: 1.1rem;
  }
  .maha-assignment-item {
    cursor: pointer;
    @include hover {
      background-color: fadeout($black, 98);
    };
  }
}
.maha-table {
  .compact-project-token,
  .compact-vendor-token,
  .compact-expense-type-token {
    padding: 0.8rem;
  }
}

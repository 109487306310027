
.maha-social-preview {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.maha-social-preview-header {
  border-bottom: 1px solid fadeout($black, 90);
  .maha-input {
    border-radius: 0;
    border: none;
  }
}
.maha-social-preview-body {
  display: flex;
  flex: 1;
  iframe {
    border: 0;
    flex: 1;
  }
}
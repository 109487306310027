.maha-placesfield {
  position: relative;
  .maha-input {
    border-radius: 0.4rem 0.4rem 0 0;
  }
}
.maha-placesfield-places {
  max-height: calc((0.8rem * 6) + (1.6rem * 6) + 3px);
  border: 1px solid fadeout($black, 90);
  border-radius: 0 0 0.4rem 0.4rem;
  background-color: $white;
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  z-index: 3;
  @include vscroll;
  .maha-placesfield-place:last-child {
    border-bottom: none;  
  }
}
.maha-placesfield-place {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  display: flex;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-placesfield-place-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    font-size: 1.4rem;
    color: $red;
  }
}
.maha-placesfield-place-details {
  line-height: 1.6rems;
  padding: 0.8rem;
  flex: 1;  
}
.maha-placesfield-empty {
  padding: 0.8rem;
  color: fadeout($black, 60);
}
.element-token {
  display: flex;
  flex: 1;
}
.element-token-image {
  display: flex;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-mjson-screenshot {
    width: 75px;
  }
}
.element-token-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.element-token-label {
  padding: 0.8rem;
  align-items: center;
  display: flex;
  flex: 1;
}
.element-token-content {
  color: $black;
  span {
    color: fadeout($black, 60);
  }
}
.element-token-status {
  text-transform: uppercase;
  &.published {
    color: $green;
  }
}
.element-token-status {
  @include flex-justify-middle-center;
  flex: 0 0 100px;
}

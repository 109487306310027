.alignmentfield {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.3rem;
  display: inline-flex;
  .alignmentfield-option:last-child {
    border: none;
  }
}
.alignmentfield-option {
  @include flex-justify-middle-center;
  border-right: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  cursor: pointer;
  flex: 1;
  &.selected {
    background-color: fadeout($blue, 90) !important;
    .maha-icon {
      color: $blue;
    }
  }
  @include hover {
    background-color: fadeout($black, 95);
  };
  .maha-icon {
    color: fadeout($black, 40);
  }
}

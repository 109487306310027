.landing-page-token {
  display: flex;
  flex: 1;
}
.landing-page-token-logo {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 1.8rem;
    font-size: 0.8rem;
  }
}
.landing-page-token-label {
  padding: 0.8rem;
  line-height: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

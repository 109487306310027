.maha-tasks-list > div:first-child > .new-item-task {
  border: none;
}
.new-item-task {
  display: flex;
  color: fadeout($black, 50);
  text-align: left;
}
.new-item-task-icon {
  @include flex-justify-middle-center;
  flex: 0 0 3.5rem;
}
.new-item-task-description {
  flex: 1;
  padding: 0.8rem 0.8rem 0.8rem 0;
  strong {
    color: $black;
  }
}

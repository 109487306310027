.maha-camera {
  flex: 1;
  display: flex;
  cursor: pointer;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 60);
    font-size: 1.2rem;
    margin: 0 auto;
    line-height: 1.1;
  }
  input {
    display: none;
    opacity: 0;
  }
}

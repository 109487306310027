.maha-daterangefield {
  border: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  border-radius: 0.4rem;
}
.maha-daterangefield-type {
  padding: 0.8rem;
}
.maha-daterangefield-dates {
  padding: 0 0.4rem 0.4rem;
  display: flex;
}
.maha-daterangefield-date {
  flex: 1;
  padding: 0 0.4rem 0.4rem;
}
.maha-daterangefield-step {
  padding: 0 0.8rem 0.8rem;
}
.maha-daterangefield-label {
  padding: 0 1rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  color: $darkblue;
}

.multiform {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.multiform-header {
  display: flex;
}
.multiform-body {
  display: flex;
  flex: 1;
}

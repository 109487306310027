.contact-avatar-token {
  @include colorize(background-color, '.maha-avatar-text-wrapper');
  display: block;
  .maha-avatar {
    width: 100%;
  }
  .maha-avatar-wrapper {
    box-shadow: -1px 1px 1px fadeout($black, 90);
  }
  .maha-icon {
    font-size: 1em;
  }
}

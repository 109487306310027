.mjson-designer-iconfield {
  img {
    display: block;
  }
  img, svg {
    width: 24px;
    height: 24px;
  }
}
.mjson-designer-iconfield-chooser {
  flex: 1;
  @include vscroll;
}
.mjson-designer-iconfield-chooser-icons {
  display: flex;
  flex-wrap: wrap;
}
.mjson-designer-iconfield-chooser-icon {
  @include flex-justify-middle-center;
  flex: 0 0 25%;
  cursor: pointer;
  padding: 0.8rem;
  @include hover {
    background-color: fadeout($black, 97);
  };
  img {
    display: block;
  }
  img, svg {
    width: 24px;
    height: 24px;
  }
}

.maha-support-assignment {
  display: flex;
  flex: 1;
}
.maha-support-assignment-unassigned {
  flex: 0 0 350px;
  border-right: 1px solid fadeout($black, 90);
  flex-direction: column;
  display: flex;
  .maha-search {
    flex: 1;
  }
}
.maha-support-assignment-assigned {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.maha-support-assignment-assigned-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
  text-align: right;
}
.maha-support-assignment-assigned-body {
  @include vscroll;  
  flex: 1;
}
.maha-support-assignment-assigned-footer {
  border-top: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}

.maha-support-assignment-assigned-team {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: $white;
  transition: opacity 250ms ease-in-out;
  display: flex;
}
.maha-support-assignment-assigned-team-details {
  align-items: center;
  display: flex;
  flex: 1;
}
.maha-support-assignment-assigned-team-action {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.8rem 0.4rem 0;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.maha-accessfield {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  font-size: 0.9rem;
  &:last-child {
    border-bottom: none;
  }
  .maha-icon {
    margin-right: 5px;
    font-size: 1.2rem;
  }
  .fa-check-circle {
    color: $blue;
  }
  .fa-circle-o {
    color: fadeout($black, 80);
  }  
}
.maha-accessfield-app {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  color: fadeout($black, 40);
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  @include hover {
    background-color: fadeout($black, 95);
  };
}
.maha-accessfield-app-label {
  flex: 1;
  font-weight: bold;
}
.maha-accessfield-app-input {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    font-size: 1.4rem;
  }
  .fa-toggle-off {
    color: fadeout($black, 70);
  }
  .fa-toggle-on {
    color: $green;
  }
}
.maha-accessfield-app-title {
  border-bottom: 1px solid fadeout($black, 90);
  color: fadeout($black, 50);
  text-transform: uppercase;
  display: flex;
}
.maha-accessfield-body {
  transition: max-height 0.25s ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
  &.expanded-enter,
  &.expanded-exit.expanded-exit-active {
    max-height: 0;
  }
  &.expanded-enter.expanded-enter-active,
  &.expanded-exit {
    max-height: 1000px; 
  }
}
.maha-accessfield-right {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.maha-accessfield-right-label {
  padding: 0.8rem;
  flex: 1;
}
.maha-accessfield-right-input {
  padding: 0.6rem;
}
.maha-accessfield-right-actions {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: inline-flex;
}
.maha-accessfield-right-action {
  border-right: 1px solid fadeout($black, 90);
  text-align: center;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  color: fadeout($black, 60);
  text-transform: uppercase;
  font-size: 0.9rem;
  width: 80px;
  &.active {
    background-color: fadeout($blue, 90);
    color: fadeout($blue, 30);
  }
  &:last-child {
    border: none;
  }
  @include hover {
    color: fadeout($black, 40);
  };
}
@media (max-width: 768px) {
  .maha-accessfield-right {
    flex-direction: column;
  }
  .maha-accessfield-right-label {
    padding-bottom: 0;
  }
}
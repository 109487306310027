.filename-token {
  display: flex;
  flex: 1;
}
.filename-token-icon {
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.filename-token-label {
  padding: 0.8rem;
  flex: 1;
}

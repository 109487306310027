.maha-performance-header {
  padding: 0.8rem;
  text-align: right;
}
.maha-performance-header {
  .maha-performance-range:last-child:after {
    content: '';
    margin: 0;
  }
}
.maha-performance-range {
  display: inline-block;
  &:after {
    content: '|';
    margin: 0 0.4rem;
  }
}
.maha-performance-body {
  padding: 0.8rem;
  .maha-performance-canvas {
    height: 0;
    padding-bottom: calc(100% / 3);
    position: relative;
    max-height: 200px !important;
  }
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.maha-chart {
  position: relative;
}

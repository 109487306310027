.maha-dmarc-status-token {
  &.none {
    color: fadeout($black, 60);
  }
  &.quarantine {
    color: $purple;
  }
  &.pass {
    color: $green;
  }
  &.fail {
    color: $red;
  }

}
.domain-status-token {
  display: flex;
  margin: -0.8rem;
}
.domain-status-token-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  .fa-circle-o-notch {
    color: $blue;
  }
  .fa-check-circle-o {
    color: $green;
  }
}
.domain-status-token-details {
  padding: 0.8rem 0.8rem 0.8rem 0;
  flex: 1;
}

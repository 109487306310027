.mjson-designer-buttonfield-preview {
  border-bottom: 1px solid fadeout($black, 90);
  border-radius: 0.4rem 0.4rem 0 0;;
  overflow: hidden;
  padding: 0.8rem;
  &.light {
    background-color: $white;
  }
  &.dark {
    background-color: $black;
  }
}
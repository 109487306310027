.maha-reaction-add {
  flex: 1;
  display: flex;
  position: relative;
}
.maha-reaction-add-button {
  color: lighten($black, 60);
  padding: 0.4rem;
  flex: 1;
  display: flex;
  cursor: pointer;
  @include hover {
    .maha-icon {
      background-color: fadeout($yellow, 40);
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    font-size: 1rem;
    line-height: 1rem;
    width: 1rem;
    height: 1rem;
    margin: 0 auto;
    border-radius: 50%;
    color: fadeout($black, 60);
  }
}
.maha-reaction-add-items {
  background-color: $white;
  position: absolute;
  top: 2.2rem;
  right: -0.2rem;
  line-height: 1rem;
  border: 1px solid lighten($black, 90);
  border-radius: 0.4rem;
  position: absolute;
  border: 1px solid lighten($black, 90);
  background-color: $white;
  display: flex;
  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent lighten($black, 90) transparent;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 9px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $white transparent;
  }
  .maha-reaction-add-item:first-child {
    border-radius: 0.4rem 0 0 0.4rem;
  }
  .maha-reaction-add-item:last-child {
    border-right: none;
    border-radius: 0 0.4rem 0.4rem 0;
  }
}

.maha-reaction-add-item {
  border-right: 1px solid lighten($black, 90);
  padding: 0.4rem;
  display: inline-block;
  font-size: 0.85rem;
  cursor: pointer;
  @include hover {
    background-color: lighten($black, 95);
  };
  img {
    width: 1rem;
  }
}

.maha-email {
  padding: 0.8rem;
}
.maha-email-interactions {
  border: 1px solid fadeout($black, 95);
  margin-bottom: 0.8rem;
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
}
.maha-email-status {
  margin-bottom: 0.8rem;
  border-radius: 0.4rem;
  padding: 0.8rem;
  &.unimported {
    background-color: fadeout($yellow, 80);
  }
  &.imported {
    background-color: fadeout($green, 80);
  }
}
.maha-email-header {
  background: fadeout($blue, 93);
  position: relative;
  border-radius: 0.4rem;
  padding: 0.8rem;
  line-height: 1.6rem;
  margin-bottom: 0.8rem;
}
.maha-email-header-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
}
.maha-email-header-action {
  padding: 0.8rem 0.8rem 0 0;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    font-size: 1.2rem;
    color: fadeout($black, 80);
  }
}
.maha-email-header-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
}
.maha-email-header-action {
  padding: 0.8rem 0.8rem 0 0;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    font-size: 1.2rem;
    color: fadeout($black, 80);
  }
}
.maha-email-header-tokens {
  display: inline;
  .maha-email-header-token:last-child {
    &:after {
      content: '';
    }
  }
}
.maha-email-header-token {
  display: inline-block;
  &:after {
    content: ';';
    margin-right: 0.4rem;
  }
  .fa-exclamation-circle {
    color: $red !important;
  }
}
.maha-email-priority {
  background-color: fadeout($black, 95);
  margin-bottom: 0.8rem;
  border-radius: 0.4rem;
  padding: 0.8rem;
  .maha-icon {
    margin-right: 0.4rem;
  }
  &.high .maha-icon {
    color: $red;
  }
  &.low .maha-icon {
    color: $blue;
  }
}
.maha-email-attachments {
  @include hscroll;
}
.maha-email-attachment {
  border: 1px solid fadeout($black, 90);
  margin: 0 0.8rem 0.8rem 0;
  display: inline-flex;
  border-radius: 0.4rem;
  width: 200px;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-email-attachment-icon {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.6rem;
  .file-type-token {
    display: inline-block;
    .maha-icon {
      font-size: 2rem;
    }
  }
}
.maha-email-attachment-label {
  padding: 0.4rem 0.6rem 0.4rem 0;
  flex: 1;
  line-height: 1.2rem;
}
.maha-email-attachment-label-filename {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.9rem;
  width: 150px;
}
.maha-email-attachment-label-filesize {
  color: fadeout($black, 60);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.9rem;
  width: 150px;
}
.maha-email-body {
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  iframe {
    width: 100%;
    border: none;
    display: block;
    min-height: 350px;
  }
}
.maha-email-body-actions {
  text-align: right;
  .maha-button {
    margin: 0.8rem 0 0.8rem 0.8rem;
  }
}

.maha-control-panel {
  flex: 1;
  flex-direction: column;
  display: flex;
}
.maha-control-panel-header {
  background-color: $darkblue;
  text-align: center;
  padding: 1.6rem 0.8rem 2.4rem 0.8rem;
  .maha-icon {
    color: fadeout($white, 20);
    font-size: 5rem;
  }
}
.maha-control-panel-body {
  flex: 1;
  @include vscroll;
}
.maha-control-panel-section {
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.maha-control-panel-item {
  display: flex;
  border-bottom: 1px solid fadeout($black, 95);
  @include hover {
    background-color: fadeout($black, 97);
  };
  cursor: pointer;
}
.maha-control-panel-item-label {
  flex: 1;
  padding: 0.8rem;
  color: fadeout($black, 60);
  strong {
    color: fadeout($black, 40);
  }
}
.maha-control-panel-item-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 80);
  }
}

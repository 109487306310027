.maha-dropdown {
  .disabled {
    background-color: lighten($black, 97) !important;
  }
  .ui.selection.dropdown {
    border: none;
    padding: 0 2.1rem 0 0;
    .menu {
      border-top: 1px solid lighten($black, 90) !important;
    }
    & > div:first-child {
      @include flex-justify-middle-center;
      height: 100%;
      & > div:first-child {
        width: 100%;
      }
    }
    .menu > .item {
      padding: 0 !important;
    }
  }
  .text {
    padding: 0.8rem 0 0.8rem 0.8rem;
  }
  .item {
    border: none;
  }
  .placeholder {
    color: fadeout($black, 60);
    padding: 0.8rem;
    line-height: 1.6rem;
    display: inline-block;
    word-break: break-word;
  }
}

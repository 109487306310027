.maha-text {
  display: flex;
  background: $white;
  padding: 0 0 0.8rem;
  color: fadeout($black, 40);
  overflow-wrap: anywhere;
  p:last-child {
    margin: 0;
  }
  &.disabled {
    background-color: fadeout($black, 97);
    color: fadeout($black, 70);
  }
}

.maha-richtext a,
.maha-richtext-link {
  color: $blue;
  text-decoration: underline;
  cursor: pointer;
}
.maha-richtext-code {
  margin: 0.4rem 0;
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  background-color: fadeout($black, 97);
  color: fadeout($black, 40);
  padding: 0.4rem;
  font-size: 0.9rem;
  line-height: 1.6rem;
  overflow:auto;
  font-family: monospace;
  white-space: pre;
}
.maha-richtext-snippet {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.2rem;
  background-color: fadeout($black, 97);
  padding: 0 0.4rem;
  color: $red;
}
.maha-richtext blockquote {
  border-left: 4px solid #2185d0;
  background-color: fadeout($black, 95);
  padding: .8rem;
  margin: .4rem 0;
}

.maha-handset-voicemail-result-token {
  display: flex;
  flex: 1;
}
.maha-handset-voicemail-result-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 60);
  }
}

.maha-handset-voicemail-token {
  display: flex;
  flex: 1;
}
.maha-handset-voicemail-token-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.6rem;
  flex: 0 0 1.2rem;
  .maha-icon {
    color: $blue;
    font-size: 0.8rem;
  }
}
.maha-handset-voicemail-token-avatar {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.6rem;
  position: relative;
  .contact-avatar-token {
    width: 2.2rem;
    .maha-icon {
      font-size: 1.4rem;
    }
  }
  .maha-logo-text {
    font-size: 0.8rem;
  }
  .fa-check-circle {
    background-color: $white;
    color: $blue;
    border: 1px solid $white;
    border-radius: 50%;
    position: absolute;
    bottom: 1rem;
    right: -0.3rem;
    font-size: 0.9rem;
  }
}
.maha-handset-voicemail-token-label {
  position: relative;
  flex: 1;
  padding: 0.8rem;
  span {
    color: fadeout($black, 60);
  }
}
.maha-handset-voicemail-token-timestamp {
  position: absolute;
  top: 50%;
  right: 0.8rem;
  transform: translateY(-50%);
  color: $blue;
  font-size: 0.8rem;
}

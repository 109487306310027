.campaigns-variant-designer {
  border-right: 1px solid lighten($black, 90);
  background-color: $white;
  flex-direction: column;
  display: flex;
  flex: 1;
}
.campaigns-variant-designer-header {
  border-bottom: 1px solid lighten($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.4rem;
}
.campaigns-variant-designer-body {
  display: flex;
  flex: 1;
}
.email-reader {
  background: $white;
  display: flex;
  flex: 1;
}
.email-reader-list {
  display: flex;
  flex-direction: column;
  .maha-searchbox {
    background-color: $darkblue;
    padding: 0.8rem;
    flex: initial;
    .maha-searchbox-container {
      background-color: fadeout($white, 50);
    }
    .maha-icon {
      color: fadeout($white, 20);
    }
    input {
      color: $white !important;
      &::-webkit-input-placeholder {
        color: fadeout($white, 20);
      }
      &:-moz-placeholder {
        color: fadeout($white, 20);
      }
      &::-moz-placeholder {
        color: fadeout($white, 20);
      }
      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: fadeout($white, 20);
      }
    }
  }
  .maha-infinite {
    flex: 1;
  }
}
.email-reader-list-result {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
}
.email-reader-show {
  background-color: fadeout($black, 80);
  display: flex;
  flex: 1;
}
@media only screen and (max-width: 1024px) {
  .email-reader-list {
    flex: 1;
  }
  .email-reader-show {
    display: none;
  }
}
@media only screen and (min-width: 1025px) {
  .email-reader-list {
    flex: 0 0 350px;
    max-width: 40%;
  }
  .email-reader-list-result {
    &.selected {
      background-color: fadeout($yellow, 90);
    }
  }
}

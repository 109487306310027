.maha-videofield {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border: 1px solid fadeout($black, 95);
  border-radius: 0.2rem;
  background-color: fadeout($black, 97);
  overflow: hidden;
}
.maha-videofield-body {
  border-bottom: 1px solid fadeout($black, 95);
}
.maha-videofield-footer {
  padding: 0.8rem 0.4rem;
}
.maha-videofield-player {
  position: relative;
	padding-bottom: 56.25%;
	height: 0;
  width: 100%;
  iframe {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
}

.maha-videofield-picker {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-videofield-picker-header {
  background-color: fadeout($black, 97);
  border-bottom: 1px solid fadeout($black, 95);
  input {
    box-shadow: none !important;
    border: none !important;
    background: transparent !important;
    padding: 0.8rem;
    width: 100%;
    outline: none!important;
    font-size: 1rem!important;
    line-height: 1.6rem !important;
    color: fadeout($black, 60) !important;
  }

}
.maha-videofield-picker-body {
  flex: 1;
  display: flex;
  .maha-videofield-player {
    margin: auto 0.8rem;
  }
}

.maha-device-offer {
  flex: 1;
  background-color: $darkblue;
  display: flex;
}
.maha-device-offer-panel {
  margin: auto;
  width: calc(100% - 1.6rem);
  max-width: 350px;
  h2, p {
    line-height: 1.6rem;
    color: $white;
    margin: 0;
  }
  text-align: center;
  padding: 0.8rem;
  .link {
    color: $white;
    @include hover {
      text-decoration: underline !important;
    };
  }
}
.maha-device-offer-device {
  margin: 2.4rem 0;
  &.mobile {
    width: 200px;
  }
  &.desktop {
    width: 350px;
  }
}
.maha-device-offer-button {
  cursor: pointer;
  margin-bottom: 0.8rem;
}

@import './emojis';

.maha-emojis {
  position: relative;
  display: flex;
}
.maha-emojis-icon {
  padding: 0.8rem 0.8rem 0.8rem 0;
  cursor: pointer;
  display: flex;
  flex: 1;
  @include hover {
    .maha-icon {
      background-color: fadeout($yellow, 40);
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin: 0 auto;
    border-radius: 50%;
    color: fadeout($black, 80);
  }
}
.maha-emojis-chooser {
  box-shadow: 0 0 0 1px fadeout($black, 90), 0 1px 5px fadeout($black, 85);
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  position: absolute;
  right: -2rem;
  width: 23.6rem;
  height: 25rem;
  display: flex;
  z-index: 10;
  &.below {
    top: 2.4rem;
    .maha-emojis-chooser-inner {
      &:before {
        border-bottom-color: fadeout($black, 90);
        top: -12px;
      }
      &:after {
        border-bottom-color: $white;
        top: -10px;
      }
    }
  }
  &.above {
    bottom: 2.4rem;
    .maha-emojis-chooser-inner {
      &:before {
        border-top-color: fadeout($black, 90);
        bottom: -12px;
      }
      &:after {
        border-top-color: $white;
        bottom: -10px;
      }
    }
  }
}
.maha-emojis-chooser-inner {
  display: flex;
  flex: 1;
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
  }
  &:before {
    right: calc(0.4rem + 31px);
    border: 6px solid transparent;
  }
  &:after {
    border: 5px solid transparent;
    right: calc(0.4rem + 32px);
  }
}
.maha-emojis-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-emojis-footer {
  border-top: 1px solid fadeout($black, 90);
  padding: 0.2rem;
}
.maha-emojis-body {
  flex: 1;
  @include vscroll;
}
.maha-emojis-category-title {
  padding: 0.4rem 0.8rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: fadeout($black, 40);
  font-weight: bold;
}
.maha-emojis-category-emojis {
  overflow: hidden;
  padding: 0.1rem;
}
.maha-emojis-footer-label {
  float: left;
  padding: 0.4rem 0.6rem;
  font-weight: bold;
}
.maha-emojis-footer-emoji,
.maha-emojis-category-emoji {
  float: left;
  width: 3.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.4rem;
  @include hover {
    background-color: fadeout($black, 80);
  };
}
.maha-emojis-category-emoji {
  font-size: 1.6rem;
}
.maha-emojis-footer-emoji {
  font-size: 1.2rem;
}

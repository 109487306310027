.stagesfield-stages {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  .stagesfield-stage {
    border-bottom: 1px solid fadeout($black, 90);
    &:first-child {
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
    }
    &:last-child {
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
  }
  .stagesfield-stage:last-child {
    border-bottom: none;
  }
}
.stagesfield-stage {
  background-color: $white;
  display: flex;
}
.stagesfield-stage-text {
  border: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  border-radius: 0.4rem;
  padding: 0.8rem;
}
.stagesfield-stage-handle {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.4rem;
  flex: 0 0 2.4rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }
}
.stagesfield-stage-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem;
}
.stagesfield-stage-label {
  padding: 0.4rem 0;
  flex: 1;
}
.stagesfield-stage-probability {
  padding: 0.4rem 0 0.4rem 0.4rem;
  flex: 0 0 130px;
}
.stagesfield-stage-action {
  @include flex-justify-middle-center;
  flex: 0 0 2.4rem;
  padding: 0.8rem;
  .maha-icon {
    cursor: pointer;
    color: fadeout($black, 80);
  }
  &:hover .maha-icon {
    color: fadeout($black, 60);
  }
}
.stagesfield-stage-add {
  padding: 0.8rem;
}

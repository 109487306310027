.truevail-timeframe-token {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  display: inline-block;
  table {
    width: 100%;
  }
  tr {
    border-bottom: 1px solid fadeout($black, 90);
  }
  td {
    border-right: 1px solid fadeout($black, 90);
    padding: 0.4rem 0.8rem;
    &:last-child {
      border-right: none;
    }
    &[colspan="2"] {
      color: fadeout($black, 60);
      text-align: center;
    }
  }
  thead td {
    background-color: fadeout($black, 97);
    font-weight: bold;
  }
  tbody tr:last-child {
    border-bottom: none;
  }
  .maha-input {
    border: none;
    border-radius: 0;
  }
}
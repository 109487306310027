.maha-details-item.receipts {
  .maha-details-item-content,
  .maha-details-item-content-value {
   padding: 0;
  }
  .receipt-plain-file {
    border-bottom: 1px solid fadeout($black, 90);
    &:last-child {
      border: none;
    }
  }
}
.receipt-thumbnail {
  background-color: fadeout($black, 25);
  width: 100%;
  position: relative;
  margin: auto;
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  padding: 0.8rem;
  height: calc(360px + 1.6rem);
  .maha-image {
    background: none;
    height: 100%;
  }
  .receipt-plain-file {
    margin: auto;
  }
  img {
    position: absolute;
    transform: translateX(-50%);
    display: block;
    margin: auto;
    left: 50%;
    width: auto !important;
    height: 100%;
  }
}
.receipt {
  background-color: $black;
  @include vscroll;
  display: fixed;
  .maha-image {
    margin: auto;
  }
  img {
    width: 100%;
  }
}

.email-client {
  background-color: $white;
  display: flex;
  flex: 1;
}
.email-client-body {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.email-client-header {
  border-bottom: 1px solid fadeout($black, 90);
  background: fadeout($black, 97);
  display: flex;
  .email-client-header-section:first-child {
    padding: 0.4rem 0.8rem;
  }
  .email-client-header-section:last-child {
    padding: 0.8rem;
    text-align: right;
  }
}
.email-client-header-section {
  padding: 0.8rem;
  flex: 1;
  .maha-button {
    &:last-child:after {
      content: none;
      margin: 0;
    }
    &:after {
      color: fadeout($black, 80);
      content: '|';
      margin: 0 0.8rem;
    }
  }
}
.email-client-list {
  border-right: 1px solid fadeout($black, 90);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .maha-menus {
    flex: initial;
  }
  .maha-infinite {
    flex: 1;
  }
  * {
    @include unselectable;
  }
}
.email-client-list-result {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  &.selected {
    background-color: fadeout($yellow, 90);
  }
}
.email-client-list-result-header {
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.email-client-reader {
  display: flex;
  flex: 1;
}
.email-client-show {
  background-color: $white;
  flex: 1;
  @include hscroll;
  @include vscroll;
}
.email-client-task-header {
  border-bottom: 1px solid fadeout($black, 90);
  background: fadeout($black, 97);
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  padding: 0.8rem;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .email-client-list {
    flex: 1;
  }
  .full .email-client-body {
    display: none;
  }
}
@media only screen and (min-width: 1025px) {
  .email-client-list {
    flex: 0 0 350px;
  }
}

.maha-criteria {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-criteria-overview {
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.maha-criteria-alert {
  background-color: fadeout($yellow, 90);
  color: fadeout($black, 50);
  padding: 0.8rem;
  border-top: 1px solid fadeout($black, 90);
  text-align: center;
  .maha-icon {
    margin-right: 0.8rem;
  }
}
.maha-criteria-overview-body {
  flex: 1;
  display: flex;
}
.maha-criteria-items {
  flex: 1;
  @include vscroll;
  & > .maha-criteria-item > .maha-criteria-item-box {
    padding: 0.8rem !important;
    background: none !important;
    border: none !important;
  }
}
.maha-criteria-item.and,
.maha-criteria-item.or {
  & > .maha-criteria-item-box {
    background-color: $white;
  }
}
.maha-criteria-item.and {
  & > .maha-criteria-item-box > .maha-criteria-item:after {
    content: 'and';
  }
}
.maha-criteria-item.or {
  & > .maha-criteria-item-box > .maha-criteria-item:after {
    content: 'or';
  }
}
.maha-criteria-item {
  .ui.button {
    text-transform: uppercase;
  }
  &:after {
    display: block;
    margin: 0 0 0.4rem;
    color: fadeout($black, 60);
    text-transform: uppercase;
  }
}
.maha-criteria-item-box {
  border: 1px solid fadeout($blue, 80);
  background-color: fadeout($blue, 97);
  padding: 0.6rem 2rem 0.6rem 0.6rem;
  border-radius: 0.4rem;
  position: relative;
  margin-bottom: 0.4rem;
  cursor: pointer;
  color: $blue;
}
.maha-criteria-item-remove {
  position: absolute;
  right: 0.6rem;
  top: 0.6rem;
  color: fadeout($black, 60);
  cursor: pointer;
  @include hover {
    color: fadeout($black, 40);
  };
}
.maha-criteria-property {
  color: $blue;
}
.maha-criteria-result {
  border-bottom: 1px solid fadeout($black, 90);
}
.maha-criterion {
  flex: 1;
  display: flex;
}
.maha-criterion-items {
  @include vscroll;
  flex: 1;
}
.maha-criterion-footer {
  background-color: fadeout($black, 97);
  border-top: 1px solid fadeout($black, 90);
  padding: 0.8rem;
}
.maha-criterion-type {
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.maha-criterion-item {
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-criterion-form {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-criterion-form-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($blue, 30);
  .maha-select {
    border: none !important;
    background: none !important;
  }
  .maha-select-option {
    border-bottom: none;
  }
  .maha-select-option-icon {
    padding: 0.4rem 0 0.4rem 0.8rem;
    border-bottom: none;
  }
  .maha-select-option-label {
    padding: 0.4rem 0.8rem;
    color: fadeout($white, 10);
    .token {
      padding: 0;
    }
  }
  .fa-check-circle {
    color: $white!important;
  }
  .fa-circle-o {
    color: $white !important;
  }
}
.maha-criterion-form-body {
  flex-direction: column;
  display: flex;
  flex: 1;
  .maha-search-header {
    border-bottom: 1px solid fadeout($black, 90)
  }
}
.maha-criterion-form-panel {
  flex: 1;
  padding: 0.8rem;
}
.maha-criterion-field {
  margin-bottom: 0.8rem;
}
.maha-criterion-form-chooser {
  background-color: fadeout($black, 97);
  border-bottom: 1px solid fadeout($black, 90);
  padding: 0.8rem;
}
.maha-criterion-form-footer {
  background-color: fadeout($black, 97);
  border-top: 1px solid fadeout($black, 90);
  padding: 0.8rem;
}

.maha-search-result-icon,
.maha-asset-icon {
  position: relative;
  .fa-file-pdf-o { color: $red; }
  .fa-file-word-o { color: $blue; }
  .fa-file-excel-o { color: $green; }
  .fa-file-powerpoint-o { color: $orange; }
  .fa-file-image-o { color: $purple; }
  .fa-file-audio-o { color: $teal; }
  .fa-play-circle { color: $red; }
  .fa-file-text-o { color: $black; }
  .fa-file-text { color: $blue; }
  .fa-map-o { color: $red; }
  .fa-file-video-o { color: $red; }
}

.maha-asset-icon-badge {
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  bottom: -0.3rem;
  right: -0.3rem;
  img {
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
}

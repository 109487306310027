.collectionfield-item {
  display: flex;
}
.collectionfield-item-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    font-size: 1.4rem;
    color: $red
  }
}
.collectionfield-item-token {
  display: flex;
  flex: 1;
  .token {
    max-width: calc(90%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
}

.finance-allocationfield {
  .fa-circle-o {
    color: fadeout($black, 80);
  }
  .fa-check-circle {
    color: $blue;
  }
  th, td {
    padding: 0.6rem 0.8rem !important;
  }
  tbody tr {
    cursor: pointer;
    @include hover {
      background-color: fadeout($black, 97);
    };
  }
  tfoot tr td {
    border-top: 1px solid fadeout($black, 90) !important;
  }
}

.maha-phonefield {
  .maha-input {
    border: none;
    flex: 1;
    input {
      border: none !important;
    }
  }
  &.opened {
    .maha-input {
      border-radius: 0.4rem 0.4rem 0 0;
    }
  }
  .maha-input-field input {
    padding-left: 0 !important;
  }
}
.maha-phonefield-flag {
  @include flex-justify-middle-center;
  padding: 0.4rem;
}
.maha-phonefield-flag-inner {
  background-color: fadeout($black, 95);
  position: relative;
  cursor: pointer;
  padding: 0.2rem 1.2rem 0.2rem 0.2rem;
  border-radius: 0.2rem;
  .emoji {
    font-size: 1.2rem;
    line-height: 1rem;
  }
  .maha-icon {
    color: fadeout($black, 60);
    position: absolute;
    top: 25%;
    right: 0.6rem;
    font-size: 0.8rem;
  }
}
.maha-phonefield-prefix {
  line-height: 2.8rem;
  padding: 0 0.4rem;
}
.maha-phonefield-number {
  flex: 1;
}
.maha-phonefield-countries {
  border: 1px solid fadeout($black, 90);
  border-top: none;
  border-radius: 0 0 0.4rem 0.4rem;
  top: 100%;
  background-color: $white;
  position: absolute;
  width: 100%;
  height: 175px;
  z-index: 10;
  @include vscroll;
}
.maha-phonefield-countries-divider {
  border-top: 1px solid fadeout($black, 90);
  height: 1px;
}
.maha-phonefield-country {
  display: flex;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-phonefield-country-flag {
  padding: 0.4rem 0 0 0.2rem;
  .emoji {
    font-size: 1.2rem;
    line-height: 1rem;
  }
}
.maha-phonefield-country-label {
  padding: 0.4rem;
  flex: 1;
}
.maha-phonefield-country-code {
  color: fadeout($black, 60);
}

.organizersfield {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
}
.organizersfield-organizer {
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
}
.organizersfield-organizer-token {
  flex: 1;
}
.organizersfield-organizer-action {
  padding: 0.8rem 0.8rem 0.8rem 0;
  cursor: pointer;
  color: fadeout($black, 80);
  @include hover {
    .maha-icon {
      color: fadeout($black, 60);
    }
  };
}
.organizersfield-add {
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}

.site-role-token {
  display: block;
  color: $white;
  padding: .4rem .8rem;
  font-size: 11px;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
  width: 10rem;
  &.administrator {
    background-color: $red;
  }
  &.contributor {
    background-color: $yellow;
  }
}

.truevail-configure-overview {
  padding: 0.8rem;
  flex: 1;
  @include vscroll;
}
.truevail-configure-overview-header {
  h1 {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 18px;
    line-height: 2rem;
    color: #02585F;
    margin: 0;
  }
  p {
    line-height: 1.8rem;
    margin: 0 0 0.8rem;
  }
}
.truevail-configure-overview-header-image {
  height: 250px;
  background-size: cover;
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 0.8rem;
    overflow: hidden;
  }
}
.truevail-configure-configuring {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.truevail-configure-configuring-header {
  padding: 0.8rem;
}
.truevail-configure-configuring-body {
  display: flex;
  flex: 1;
}
.truevail-configure-configuring-text {
  flex: 1;
  padding: 0.8rem;
}
.truevail-configure-configuring-button {
  padding: 3.2rem 0.8rem 0;
  text-align: center
}
@media (min-width: 769px) {
  .truevail-configure-task-token-details {
    display: flex;
    flex: 1;
  }
  .truevail-configure-task-token-description {
    padding: 0.8rem;
    flex: 1;
  }
  .truevail-configure-task-token-status {
    padding: 0.8rem 0.8rem 0.8rem 0;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .truevail-configure-task-token-details {
    padding: 0.8rem;
  }
  .truevail-configure-task-token-description {
    margin-bottom: 0.8rem;
  }
}

.truevail-configure-task-token {
  display: flex;
  flex: 1;
}
.truevail-configure-task-token-details {
  flex: 1;
}
.truevail-configure-task-token-description {
  color: fadeout($black, 40);
  strong {
    color: $black;
  }
}
.truevail-configure-task-token-status {
  text-transform: uppercase;
  &.incomplete {
    color: fadeout($black, 80);    
  }
  &.completed {
    color: $green;
  }
  &.opted.out {
    color: $red;
  }
}
.truevail-configure-task-token-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 80);
  }
}

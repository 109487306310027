.selected {
  .block-type-token-icon .fa,
  .block-type-token-label {
    color: $blue
  }
}
.block-type-token {
  flex: 1;
  display: flex;
  margin: 0.2rem;
  border-radius: 0.4rem;
}
.block-type-token-icon {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.8rem;
  flex: 0 0 2.8rem;
  img {
    display: block;
    width: 16px;
    height: 16px;
  }
}
.block-type-token-label {
  padding: 0.4rem 0;
  flex: 1;
}

.maha-attachment-asset,
.maha-attachment-image {
  margin-top: 0.4rem;
  width: 100%;
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  display: block;
  img {
    width: 100%;
    display: block;
  }
}
.maha-attachment-local {
  color: $blue;
  cursor: pointer;
  @include hover {
    text-decoration: underline;
    color: darken($blue, 20);
  };
}

.websites-media {
  padding: 0.4rem;
  flex: 1;
}
.websites-media-item {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  position: relative;
  float: left;
  cursor: pointer;
  overflow: hidden;
  margin: 0.4rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 1241px) {
    width: calc(100% / 8 - 0.8rem);
  }
  @media (max-width: 1440px) {
    width: calc(100% / 7 - 0.8rem);
  }
  @media (max-width: 1280px) {
    width: calc(100% / 6 - 0.8rem);
  }
  @media (max-width: 1024px) {
    width: calc(100% / 5 - 0.8rem);
  }
  @media (max-width: 960px) {
    width: calc(100% / 4 - 0.8rem);
  }
  @media (max-width: 480px) {
    width: calc(100% / 2 - 0.8rem);
  }
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.websites-media-preview {
  .maha-image {
    background-color: fadeout($black, 97);
  }
}
.websites-media-label {
  border-top: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  font-size: 0.9rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  span {
    color: fadeout($black, 60);
  }
}

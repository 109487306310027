.datasets-editor {
  background-color: $white;
  display: flex;
  flex: 1
}
.datasets-editor-main {
  display: flex;
  flex: 1;
}
.datasets-editor-sidebar {
  border-left: 1px solid fadeout($black, 90);
  flex: 0 0 calc(100% / 3);
  position: relative;
  max-width: 300px;
  display: flex;
  .maha-modal-panel-header {
    background-color: $grey !important;
  }
}

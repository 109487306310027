.maha-details {
  background-color: $white;
  border-radius: 0.4rem;
  margin-bottom: 1rem;
  & > .maha-details-section:first-child > .maha-details-title {
    border-radius: 0.4rem 0.4rem 0 0;
  }
}
.maha-details-header {
  border-radius: 0.4rem 0.4rem 0 0;
  background-color: $white;
  overflow: hidden;
}
.maha-details-message {
  padding: 0.8rem;
  color: $white;
  border-radius: 0.4rem;
  margin-bottom: 0.8rem;
  .link {
    color: $white !important;
    text-decoration: underline;
  }
  &.error {
    background: $red;
  }
  &.notice {
    background: $blue;
  }
  &.warning {
    background: $yellow;
  }
}
.maha-details-alert {
  @include colorize(background-color);
  .link {
    color: $white !important;
    text-decoration: underline;
  }
  line-height: 1.6rem;
  padding: 0.8rem;
  color: $white;
  text-align: center;
  border-radius: 0.4rem;
  margin-bottom: 0.8rem;
}
.maha-details-steps {
  border-radius: 0.4rem;
  margin-bottom: 0.8rem;
  overflow: hidden;
}
.maha-details-title {
  background-color: $mediumblue;
  font-weight: bold;
  margin-bottom: 1px;
  color: $white;
}
.maha-details-subtitle {
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.maha-details-instructions {
  color: fadeout($black, 40);
  line-height: 1.8rem;
  padding: 0.8rem;
}
.maha-details-section {
  .error {
    color: $red;
  }
  &.padded {
    .maha-details-title {
      padding: 0.8rem;
    }
    .maha-details-subtitle {
      padding: 0.8rem;
    }
  }
}
.maha-details-item {
  display: flex;
  color: fadeout($black, 40);
  position: relative;
  &.yellow {
    background-color: fadeout($yellow, 90);
  }
  strong {
    color: $black;
  }
  &.padded {
    .maha-details-item-component {
      padding: 0.8rem !important;;
    }
  }
  &.unpadded {
    .maha-details-item-content-value {
      padding: 0 !important;
    }
  }
}
.maha-details-item-link {
  @include hover {
    background-color: fadeout($black, 97);
  };
  cursor: pointer;
  .fa-chevron-right {
    color: fadeout($black, 40);
  }
}
.maha-details-item-unit {
  margin-left: 3px;
}
.maha-details-item-padded {
  padding: 0.8rem;
  flex: 1;
}
.maha-details-item-label {
  padding: 0.8rem;
  flex: 1;
}
.maha-details-item-data {
  padding: 0.8rem;
}
.maha-details-item-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.maha-details-item-component {
  width: 100%;
  flex: 1;
}
.maha-details-item-highlight {
  padding: 0.8rem;
  line-height: 1.6rem;
  background-color: fadeout($yellow, 90);
}
.maha-details-item-content {
  flex: 1;
  overflow: hidden;
  .fa-circle-o-notch {
    color: $blue;
  }
  .fa-check-circle-o {
    color: $green;
  }
}
.maha-details-item-link {
  display: block;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
  display: flex;
}
.maha-details-item-content-label {
  span {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    color: $darkblue;
  }
}
.maha-details-item-content-value {
  line-height: 1.6rem;
  word-break: break-word;
  .token {
    padding: 0;
  }
}
.maha-details-item-extra {
  display: flex;
  padding: 0.8rem 0.8rem 0.8rem 0;
  & > * {
    margin: auto !important;
  }
  .button {
    padding: 0.75rem !important;
    .maha-icon {
      margin: 0 !important;
    }
  }
}
.maha-details-item-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  font-size: 0.8rem;
  .maha-icon {
    cursor: pointer;
    color: fadeout($black, 80);
    line-height: 1rem;
    font-size: 1.2rem;
  }
}
.maha-details-buttons {
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}
.maha-page-sidebar {
  .maha-details-item-content {
    display: block !important;
    padding: 0.8rem;
  }
  .maha-details-item-content-label,
  .maha-details-item-content-value {
    flex: none !important;
    padding: 0;
  }
}

.maha-details-status {
  border-radius: 0.4rem;
  margin-bottom: 0.8rem;
  padding: 0.8rem;
  line-height: 1.6rem;
  background-color: $white;
  &.warning {
    background-color: lighten($yellow, 40);
  }
  &.success {
    background-color: fadeout($green, 80);
  }
  .ui.button {
    margin: 0 0.4rem 0.4rem 0;
  }
}

@media (max-width: 768px) {
  .maha-details-item-content {
    padding: 0.8rem;
  }
}
@media (min-width: 769px) {
  .maha-details.expanded {
    .maha-details-item-content {
      padding: 0.8rem;
    }
  }
  .maha-details-item.compact {
    padding: 0.8rem;
    .maha-details-item-content-value {
      padding: 0 !important;
    }
  }
  .maha-details.compact {
    .maha-details-item-content {
      padding: 0.8rem;
    }
  }
  .maha-details:not(.compact) {
    .maha-details-item-content {
      display: flex;
    }
    .maha-details-item-content-label {
      flex: 0 0 14rem;
      padding: 0.8rem;
    }
    .maha-details-item-content-value {
      flex: 1;
      padding: 0.8rem;
    }
  }
}

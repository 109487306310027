.profilefield {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
}
.profilefield-placeholder {
  color: fadeout($black, 50);
  padding: 0.8rem;
}
.profilefield-profile {
  display: flex;
  flex: 1;
}
.profilefield-profile-token {
  flex: 1;
  padding: 0.4rem;
  .profile-token {
    border-radius: 0.4rem;
    background-color: fadeout($blue, 90);
  }
}
.profilefield-profile-remove {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.8rem;
  display: flex;
  .maha-icon {
    cursor: pointer;
    color: fadeout($black, 70);
    @include hover{
      color: fadeout($black, 50);
    };
  }
}
.profilefield-picker-profiles {
  flex: 1;
  @include vscroll;
}
.profilefield-picker-profile {
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid fadeout($black, 90);
  @include hover{
    background-color: fadeout($black, 97);
  };
}
.profilefield-picker-profile-add {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  display: flex;
  @include hover{
    background-color: fadeout($black, 97);
  };
}
.profilefield-picker-profile-add-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    color: $red;
    font-size: 1.8rem;
  }
}
.profilefield-picker-profile-add-label {
  line-height: 1.7rem;
  padding: 0.8rem;
  flex: 1;
}

.events-ticket-qrcode {
  margin: 0 auto;
  width: 9rem;
  height: 9rem;
}
.maha-table.attendance {
  .maha-icon {
    font-size: 1.6rem;
  }
  .fa-check-circle {
    color: $green;
  }
}

.maha-attachments-dalle-prompt {
  flex: 1;
  display: flex;
  .maha-textarea {
    border: none;
    border-radius: 0;
    flex: 1;
  }
}
.maha-attachments-dalle-result {
  display: flex;
  flex: 1;
}
.maha-attachments-dalle-preview {
  display: flex;
  flex: 1;
}
.maha-attachments-dalle-preview-image {
  padding: 0.8rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  div {
    max-width: 500px;
    width: 100%;
  }
  img {
    width: 100%;
  }
}

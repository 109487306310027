.crm-agreement-token {
  display: flex;
}
.crm-agreement-token-asset {
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-asset-thumbnail {
    width: 1.4rem;
    height: 1.4rem;
    .maha-icon {
      font-size: 1.4rem;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}
.crm-agreement-token-details {
  padding: 0.8rem;
  flex: 1;
  span {
    color: fadeout($black, 60);
  }
}

.metadatafield {
  margin: -0.8rem;
  flex: 1;
}
.metadatafield-item {
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
}
.metadatafield-item-metadata {
  flex: 1;
}
@media only screen and (min-width: 769px) {
  .metadatafield-item-preview {
    padding: 0.8rem 0 0.8rem 0.8rem;
    .maha-image {
      width: 150px;
      height: 150px;
    }
  }
  .metadatafield-item-metadata {
    padding: 0 0.8rem;
    flex: 1;
  }
}
@media only screen and (max-width: 768px) {
  .metadatafield-item-preview {
    padding: 0.8rem 0.8rem 0 0.8rem;
    .maha-image {
      width: 100%;
    }
  }
  .metadatafield-item {
    flex-direction: column;
  }
}

.maha-chartfield {
  overflow: hidden;
}
.maha-chartfield-chart {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  margin-right: 0.4rem;
  padding: 0.4rem;
  display: flex;
  float: left;
  flex-direction: column;
  cursor: pointer;
  width: 70px;
  &.selected {
    border-color: $blue;
    .maha-chartfield-chart-label {
      color: $blue;
    }
  }
}
.maha-chartfield-chart-label {
  color: fadeout($black, 60);
  text-transform: uppercase;
  line-height: 1.4rem;
  text-align: center;
  font-size: 0.7rem;
}
.maha-chartfield-chart-image {
  display: flex;
  flex: 1;
  img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

}
.maha-chartfield-chart-radio {
  @include flex-justify-middle-center;
  .fa-check-circle {
    color: $blue;
  }
  .fa-circle-o {
    color: fadeout($black, 80);
  }
}

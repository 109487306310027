.contact-token {
  display: flex;
}
.contact-token-avatar {
  padding: 0.4rem 0 0.4rem 0.8rem;
  .contact-avatar-token {
    width: 2rem;
  }
}
.contact-token-label {
  flex: 1;
  padding: 0.8rem;
  width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;  
}

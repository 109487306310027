.subscriptions-allowances-token {
  table tbody tr {
    td:nth-child(2),
    td:nth-child(3) {
      width: 80px;
    }
  }
}
.subscriptions-price-token {
  table tbody tr {
    td:nth-child(2) {
      width: 80px;
    }
  }
}
.subscriptions-usages-token {
  table tbody tr {
    td:nth-child(2) {
      width: 100px;
    }
  }
}

.durationfield {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: inline-flex;
  width: 200px;
  .maha-input {
    border: none;
    outline: none;
  }
  .maha-input:first-child {
    flex: 0 0 80px;
    border-right: 1px solid fadeout($black, 90);
    border-radius: 0.4rem 0 0 0.4rem;
  }
  .maha-input:last-child {
    border-radius: 0 0.4rem 0.4rem 0;
    flex: 1
  }
}

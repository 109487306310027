.maha-shortlink-token {
  background-color: $white;
  overflow: hidden;
  border-radius: 0.4rem;
  margin-bottom: 1rem;
  display: flex;
  position: relative;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.maha-shortlink-token-share {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.8rem;
  cursor: pointer;
  color: fadeout($black, 80);
  z-index: 1;
  .maha-icon {
    font-size: 1.4rem;
  }
  @include hover {
    color: fadeout($black, 60);
  };
}
.maha-shortlink-token-qr {
  @include flex-justify-middle-center;
  background-color: fadeout($black, 95);
  cursor: pointer;
  opacity: 0.8;
  padding: 0.8rem;
  @include hover {
    opacity: 1;
  };
  img {
    display: block;
    width: 110px;
    height: 110px;
  }
}
.maha-shortlink-token-details {
  flex: 1;
  .maha-shortlink-token-link:last-child {
    border-bottom: none;
  }
}
.maha-shortlink-token-link {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0.8rem;
  @media only screen and (min-width: 821px) {
    max-width: calc(100vw - 3.2rem - 420px);
  }
  @media only screen and (max-width: 820px) {
    max-width: calc(100vw - 1.6rem);
  }
}
.maha-shortlink-token-modal {
  display: flex;
  flex: 1
}
.maha-shortlink-token-modal-preview {
  border-right: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  display: flex;
  flex: 1
}
.maha-shortlink-token-modal-form {
  display: flex;
  flex: 0 0 300px;
}

.maha-shortlink-token-modal-qr {
  @include flex-justify-middle-center;
  flex: 1;
  img {
    width: 100%;
    max-width: 400px;
  }
}
.maha-shortlink-token-modal-link {
  border-top: 1px solid fadeout($black, 90);
  background: fadeout($black, 97);
  text-align: center;
  padding: 0.8rem;
}

.maha-handset-receiver {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-handset-receiver-inactive {
  background-color: fadeout($black, 97);
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 95);
  };
}
.maha-handset-receiver-inactive-avatar {
  padding: 0.8rem 0 0.8rem 0.8rem;
  display: flex;
  .contact-avatar-token {
    width: 2.6rem;
  }
}
.maha-handset-receiver-inactive-label {
  max-width: 240px;
  padding: 0.8rem;
  flex: 1;
}
.maha-handset-receiver-inactive-program {
  color: fadeout($black, 60);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.maha-handset-receiver-inactive-timer {
  padding: 0.8rem 0.8rem 0.8rem 0;
  color: $blue;
  line-height: 2.6rem;
}
.maha-handset-receiver-inactive {
  .maha-handset-action {
    padding: 0.4rem;
    display: flex;
  }
  .maha-handset-button-container {
    margin: auto;
    font-size: 0.6rem;
  }
}
.maha-handset-receiver-active {
  flex: 1;
  display: flex;
}
.maha-handset-call {
  width: 100%;
  max-width: 375px;
  height: 560px;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: $white;
}
.maha-handset-call-extra {
  padding: 0.8rem;
  text-align: center;
  color: $red;
}
.maha-handset-call-timer {
  padding: 2rem 0.8rem 0;
  text-align: center;
  .crm-timer {
    color: fadeout($black, 40);
    font-size: 2rem;
  }
}
.maha-handset-call-info {
  text-align: center;
  padding: 0.8rem;
}
.maha-handset-call-body {
  flex-direction: column;
  flex: 1;
  display: flex;
}
.maha-handset-call-functions {
  margin: auto 0;
}

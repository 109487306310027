.maha-verify-overlay {
  @include opacity-transition(0, 0.5, .25s);
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
}
.maha-verify {
  @include opacity-transition(0, 1, .25s);
  position: absolute;
  border-radius: 5px;
  background-color: $white;
  width: 375px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}
.maha-verify-body {
  padding: 0.8rem;
  line-height: 1.6rem;
  color: fadeout($black, 40);
}
.maha-verify-text {
  margin-bottom: 0.8rem;
}
.maha-verify-footer {
  border-top: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem 0.4rem;
}

.crm-workflow-branchesfield {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.3rem;
  .crm-workflow-branchesfield-branch:last-child {
    border-bottom: none;
  }
}
.crm-workflow-branchesfield-branch {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.crm-workflow-branchesfield-branch-label {
  display: flex;
  flex: 1;
  input {
    flex: 1;
    padding: 0.8rem;
    border: none;
    &:focus {
      outline: none;
    }
  }
}
.crm-workflow-branchesfield-branch-add {
  flex: 1;
  padding: 0.4rem 0.8rem;
  background-color: fadeout($black, 98);
  .link {
    font-size: 0.8rem;
  }
}
.crm-workflow-branchesfield-branch-action {
  @include flex-justify-middle-center;
  cursor: pointer;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 80);
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}

.crm-workflow-branchesfield-form {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.crm-workflow-branchesfield-form-header {
  border-bottom: 1px solid fadeout($black, 90);
  padding: 0.8rem;
}
.crm-workflow-branchesfield-form-body {
  display: flex;
  flex: 1;
}

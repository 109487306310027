.maha-profile-manager-terms {
  padding: 0.8rem;
  @include vscroll;
  flex: 1;
  h2 {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: $darkblue;
    margin: 0;
  }
  p {
    line-height: 1.8rem;
    margin: 0 0 0.8rem;
    font-size: 1rem;
  }
  ol {
    padding: 0 0 0 1.2rem;
    margin: 0 0 0.8rem;
  }
  li {
    line-height: 1.8rem;
    margin: 0 0 0.8rem;
    font-size: 1rem;
  }
}
.finance-invoice {
  background-color: $white;
}
@media (max-width: 768px) {
  .finance-invoice-col {
    padding: 0.4rem 0.8rem;
  }
}
@media (min-width: 769px) {
  .finance-invoice-row {
    display: flex;
    & > div {
      flex: 0 0 50%;
    }
  }
  .finance-invoice-col {
    padding: 0.8rem;
  }
}
.finance-invoice-box {
  border: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  flex: 1;
}
.finance-invoice-status {
  display: block !important;
  padding: 0 !important;
}
.finance-invoice-col {
  display: flex;
}
.finance-invoice-details {
  border: 1px solid fadeout($black, 90);
  flex: 1;
  display: flex;
}
.finance-invoice-details-code {
  border-right: 1px solid fadeout($black, 90);
  img {
    width: 87px;
    height: 87px;
    display: block;
  }
}
.finance-invoice-details-data {
  flex: 1;
  table {
    width: 100%;
  }
  tr {
    td {
      padding: 0.4rem;
      &:first-child {
        font-weight: bold;
      }
    }
  }
}
.finance-invoice-vendor {
  flex: 1;
  display: flex;
}
.finance-invoice-vendor-logo {
  flex: 0 0 4.6rem;
}
.finance-invoice-vendor-details {
  padding-left: 0.8rem;
  line-height: 1.6rem;
  flex: 1;
}
.finance-invoice-lineitems {
  padding: 0.8rem;
  .ui.table {
    border-radius: 0;
  }
  th, td {
    padding: 0.6rem !important;
  }
  tr.voided {
    span {
      color: $red;
      text-decoration: line-through;
    }
  }
  tr {
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4) {
      width: 80px !important;
      text-align: right;
    }
  }
  tr.total td {
    &:first-child {
      background-color: fadeout($black, 97);
    }
    color: $black;
    font-weight: bold;
  }
  img {
    border-radius: 4px;
    box-shadow: 0px 1px 2px fadeout($black, 75);
    margin-right: 0.8rem;
    height: 20px;
    width: 32px;
    vertical-align: middle;
    display: inline-block;
  }
}
.finance-invoice-footnote {
  padding: 0 0.8rem;
}
.finance-invoice-notes {
  border: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  margin: 0.8rem;
  padding: 0.8rem;
}
.finance-invoice-tax-deductible {
  border: 1px solid fadeout($yellow, 80);
  background-color: fadeout($yellow, 90);
  margin: 0.8rem;
  padding: 0.8rem;
}
.finance-invoice-asterix {
  color: $red;
}

.maha-push-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: fadeout($black, 60);
}
.maha-push-modal {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 1.6rem);
  max-width: 350px;
  height: calc(100% - 1.6rem);
  max-height: 640px;
  display: flex;
  flex-direction: column;
  background-color: $white;
  transition: opacity 0.5s ease-in-out 0s;
}
.maha-push-modal-image {
  img {
    width: 100%;
  }
}
.maha-push-modal-body {
  flex: 1;
  padding: 1.6rem;
  display: flex;
}
.maha-push-modal-message {
  margin: auto;
  text-align: center;
  h3 {
    margin: 0;
  }
  p {
    color: fadeout($black, 40);
  }
}
.maha-push-modal-footer {
  padding: 0.8rem;
}

.maha-changelog {
  flex: 1;
  @include vscroll;
}
.maha-changelog-release {
  border-bottom: 1px solid fadeout($black, 90);
  padding: 0.8rem;
}
.maha-changelog-release-new {
  transform: translate(5px, -50%);
  display: inline-block;
  background-color: $red;
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
  font-size: 0.6rem;
  line-height: 1em;
  color: $white;
}
.maha-changelog-release-timestamp {
  color: fadeout($black, 40);
  font-style: italic;
  font-size: 0.8rem;
}
.maha-changelog-release-body {
  font-size: 0.8rem;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .label {
    border: 1px solid $black;
    padding: 0.1rem 0.4rem;
    border-radius: 0.4rem;
    margin-right: 0.4rem;
    font-size: 0.8rem;
  }
  .bug {
    border-color: $red;
    color: $red;
  }
  .feature {
    border-color: $green;
    color: $green;
  }
}
.maha-changelog-change {
  margin: 0.4rem 0;
}

.tofield {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
}
.tofield-body {
  padding: 0.8rem;
}
.tofield-footer {
  border-top: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  color: fadeout($black, 40);
  strong {
    color: fadeout($black, 20);
  }
}
.tofield-input {
  margin-bottom: 0.8rem;
}
.tofield-field {
  border: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  border-radius: 0.4rem;
  padding: 0.8rem;
  .maha-input {
    margin-bottom: 0.8rem;
    &:last-child {
      cursor: pointer;
      margin-bottom: 0;
    }
  }
  .maha-button {
    display: inline-block;
  }
}
.tofield-total {
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: fadeout($black, 60);
  strong {
    color: $darkblue;
  }
}
.tofield-picker {
  border-top: 1px solid fadeout($black, 90);
  display: flex;
  flex: 1;
}
.tofield-picker-filter {
  border-right: 1px solid fadeout($black, 90);
  flex: 0 0 350px;
  display: flex;
  position: relative;
}
.tofield-picker-main {
  display: flex;
  flex: 1;
}
.tofield-picker-result {
  display: flex;
  border-bottom: 1px solid fadeout($black, 97);
}
.tofield-picker-result-token {
  display: flex;
  flex: 1;
}
.tofield-picker-result-remove {
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}

.maha-asset-preview {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-asset-preview-main {
  display: flex;
  flex: 1;
}
.maha-asset-preview-sidebar {
  border-left: 1px solid fadeout($black, 90);
  flex: 0 0 350px;
  display: flex;
}
.maha-asset-preview-body {
  flex: 1;
  display: flex;
}

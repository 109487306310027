.team-token {
  display: flex;
}
.team-token-logo {
  @include flex-justify-middle-center;
  padding: 0.4rem 0 0.4rem 0.8rem;
  .maha-logo {
    width: 2rem;
  }
}
.team-token-label {
  padding: 0.8rem;
  flex: 1;
  span {
    color: fadeout($black, 60);
  }
}
.team-token-activity {
  color: $red;
  margin-left: 5px;
  font-size: 0.8rem;
  line-height: 2rem;
}
span.red { color: $red; }

.maha-criterion-form {
  .crm-checkboxesfield {
    flex: 1;
    margin: 0 !important;
    @include vscroll;
  }
}
.crm-checkboxesfield {
  margin: -0.8rem;
}
.crm-checkboxesfield-header {
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.crm-checkboxesfield-item {
  cursor: pointer;
  display: flex;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.crm-checkboxesfield-item-icon {
  @include flex-justify-middle-center;
  padding: 0.4rem 0 0.4rem 0.8rem;
  .maha-icon {
    font-size: 1.2rem;
  }
  .fa-check-circle,
  .fa-check-square {
    color: $blue;
  }
  .fa-circle-o,
  .fa-square-o {
    color: fadeout($black, 80);
  }
}
.crm-checkboxesfield-item-label {
  padding: 0.4rem 0.8rem;
}

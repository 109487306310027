.drive-versions {
  flex: 1;
  @include vscroll;
}
.drive-versions {
  .drive-version:last-child {
    border-bottom: none;
  }
}
.drive-upload {
  padding: 0.8rem;
  border-bottom: 1px solid fadeout($black, 90);
}
.drive-version {
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
}
.drive-version-active {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.8rem;
  flex: 0 0 1.2rem;
  .fa-check-circle {
    color: $green;
  }
  .fa-circle-o {
    color: fadeout($black, 80);
    cursor: pointer;
    @include hover {
      color: fadeout($black, 40);
    };
  }
}
.drive-version-preview {
  flex: 0 0 4.8rem;
  display: flex;
  padding: 0.4rem 0.8rem;
  align-items: center;
  justify-content: center;
  .maha-asset-icon {
    font-size: 2.1rem;
  }
  .maha-image {
    width: 2.8rem;
    height: 2.8rem;
    display: inline-block;
    img {
      width: 100%;
    }
  }
}
.drive-version-details {
  flex: 1;
  padding: 0.4rem 0;
}
.drive-version-text-version,
.drive-version-meta-timestamp {
  display: inline;
  margin-right: 1rem;
  font-weight: bold;
  color: fadeout($black, 50);
}
.drive-version-text-filename,
.drive-version-meta-user {
  display: inline;
  color: fadeout($black, 70);
}

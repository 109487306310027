.flowchart-designer {
  background-color: $white;
  flex-direction: column;
  display: flex;
  flex: 1;
  .crm-checkboxesfield {
    flex: 1;
    margin: 0;
  }
}
.flowchart-designer-main {
  display: flex;
  flex: 1;
}
.flowchart-designer-status {
  color: fadeout($black, 40);
  text-align: center;
  padding: 0.8rem;
  line-height: 1.8rem;
  flex: 0 0 3.5rem;
  &.draft {
    background-color: fadeout($yellow, 60);
  }
  &.scheduled {
    background-color: fadeout($teal, 70);
  }
  &.active, &.sent {
    background-color: fadeout($green, 60);
  }
  &.inactive {
    background-color: fadeout($red, 80);
  }
}
.flowchart-designer-footer {
  padding: 0.8rem;
}
.flowchart-designer-sidebar {
  border-left: 1px solid fadeout($black, 90);
  flex: 0 0 calc(100% / 3);
  max-width: 350px;
  min-width: 350px;
  display: flex;
  .maha-attachments-drive-search {
    border-right-color: $grey !important;
  }
  .maha-form-field {
    flex-direction: column;
  }
  .maha-form-field-label {
    flex: 1;
    padding: 0;
  }
  & > * {
    flex: 1;
  }
}
.flowchart-designer-icon {
  flex: 0 0 3rem;
  .fa-phone {
    font-size: 1.4rem;
  }
}
.flowchart-designer-block-draglayer,
.flowchart-designer-block-icon,
.flowchart-box-icon {
  &.trigger {
    background-color: $green;
  }
  &.voice,
  &.sms {
    background-color: $violet;
  }
  &.administrative {
    background-color: $pink;
  }
  &.control {
    background-color: $blue;
  }
  &.communication {
    background-color: $purple;
  }
  &.contact {
    background-color: $teal;
  }
  &.ending {
    background-color: $red;
  }
  &.action {
    background-color: $blue;
  }
}
.flowchart-designer-form {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.flowchart-designer-form-header {
  border-bottom: 1px solid fadeout($black, 95);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}
.flowchart-designer-form-body {
  flex: 1;
  display: flex;
}
.flowchart-designer-form-footer {
  border-top: 1px solid fadeout($black, 85);
  padding: 0.8rem;
}

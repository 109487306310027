.maha-select.maha-input {
  &.unlabeled {
    margin-top: 1.6rem;
  }
  &.disabled * {
    color: fadeout($black, 80) !important;
  }
  &:not(.disabled) {
    .maha-select-option {
      cursor: pointer;
      @include hover {
        background-color: fadeout($black, 97);
      };
    }
  }
}
.maha-select-deselect {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  text-transform: uppercase;
  font-size: 0.8rem;
}
.maha-select-options {
  margin: 0 0 0.4rem;
  flex: 1;
}
.maha-select-option {
  display: flex;
  &.selected {
    .maha-icon {
      color: $blue;
    }
  }
  strong {
    color: $black;
  }
}
.maha-select-option-icon {
  @include flex-justify-middle-center;
  padding: 0.4rem 0 0.4rem 0.4rem;
  .maha-icon {
    color: fadeout($black, 80);
    font-size: 1.2rem;
  }
}
.maha-select-option-label {
  display: flex;
  flex: 1;
  line-height: 1.6rem;
  color: fadeout($black, 40);
  .token {
    padding: 0.4rem 0.8rem;
    flex: 1;
  }
}
.maha-select-option-extra {
  color: fadeout($black, 80);
  padding: 0.4rem 0.4rem 0.4rem 0;
  text-transform: uppercase;
}
.maha-select-option-empty {
  flex: 1;
  line-height: 1.6rem;
  color: fadeout($black, 40);
  padding: 0.6rem 0.8rem;
}
@media (min-width: 769px) {
  .maha-select.maha-input {
    &.two-column .maha-select-options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &.three-column .maha-select-options {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    &.four-column .maha-select-options {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
    &.five-column .maha-select-options {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
    &.six-column .maha-select-options {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

.email-envelope {
  display: flex;
  &.unread {
    .email-envelope-timestamp,
    .email-envelope-subject {
      font-weight: bold;
      color: $blue;
    }
  }
}
.email-envelope-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  flex: 0 0 3rem;
  .maha-icon {
    font-size: 0.8rem;
    color: $blue;
  }
}
.email-envelope-details {
  padding: 0.8rem 0.8rem 0.8rem 0;
  position: relative;
  flex: 1;
}
.email-envelope-flags {
  padding: 0.8rem 0.8rem 0 0;
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
}
.email-envelope-flag {
  width: 1.4rem;
  padding: 0.4rem;
  color: fadeout($black, 60);
  .maha-icon {
    font-size: 1.2rem;
  }
  .fa-flag {
    color: $red;
  }
  .fa-check {
    color: $green;
  }
  .fa-exclamation {
    color: $red;
  }
  .fa-arrow-down {
    color: $blue;
  }
}
.email-envelope-row {
  display: flex;
}
.email-envelope-timestamp {
  color: fadeout($black, 60);
  font-size: 0.9rem;
}
.email-envelope-contact {
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: $black;
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-envelope-subject {
  color: $black;
  flex: 1;
  line-height: 1.4rem;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.8rem;
}
.email-envelope-snippet {
  color: fadeout($black, 60);
  flex: 1;
  line-height: 1.4rem;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-envelope-folder {
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  padding: 0.4rem;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
  border-color: fadeout($black, 60);
  background-color: fadeout($black, 97);
  color: fadeout($black, 60);
  &.imported {
    border-color: fadeout($green, 60);
    background-color: fadeout($green, 97);
    color: fadeout($green, 60);
  }
}

.maha-shortlink {
  padding: 0.6rem 0.8rem 0.6rem 0;
  cursor: pointer;
  display: flex;
  flex: 1;
  @include hover {
    .maha-icon {
      color: $red;
    }
  };
  .maha-icon {
    font-size: 1.2rem;
    line-height: 1rem;
    margin: 0 auto;
    color: fadeout($black, 80);
  }
}

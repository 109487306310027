.maha-icon {
  display: inline-block;
  text-align: center;
  line-height: 1em;
  width: 1em;
  height: 1em;
  svg {
    width: 1em;
    height: 1em;
  }
  .fa {
    display: block;
    line-height: 1em;
    font-size: 1em;
  }
  .fa-calendar {
    transform: scale(0.9);
  }
  .fa-mobile {
    transform: scale(1.5);
  }
  .fa-phone {
    transform: scale(1.2);
  }
}
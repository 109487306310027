.tickettypesfield {
  border: 1px solid fadeout($black, 90);
  overflow: hidden;
  &.dragging {
    .tickettypesfield-tickettype-handle {
      cursor: grabbing;
    }
  }
}
.tickettypesfield-tickettype {
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
  background-color: $white;
}
.tickettypesfield-tickettype-handle {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }
}
.tickettypesfield-tickettype-token {
  flex: 1;
}
.tickettypesfield-tickettype-action {
  flex: 0 0 1.8rem;
  padding: 0.4rem 0.8rem 0.4rem 0;
  cursor: pointer;
  color: fadeout($black, 80);
  @include hover {
    .maha-icon {
      color: fadeout($black, 60);
    }
  };
}
.tickettypesfield-add {
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}

.drive-tasks {
  @media (min-width: 769px) {
    .maha-tasks-title .maha-tasks-title-icon:last-child {
      display: none;
    }
  }
  .maha-details {
    margin: 0;
  }
  .maha-task {
    padding: 0.8rem;
  }
}

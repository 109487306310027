.finance-payment-type-token {
  img {
    border-radius: 4px;
    box-shadow: 0px 1px 2px fadeout($black, 75);
    margin: 5px 5px -5px;
    height: 20px;
    width: 32px;
    display: inline-block;

  }
}

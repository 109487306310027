.maha-app-token {
  position: relative;
  padding: 0.8rem;
}
.maha-app-token-icon {
  background-clip: padding-box;
  color: $blue;
  margin-right: 10px;
  display: inline-flex;
  width: 1.6rem;
  height: 1.6rem;
  text-align: center;
  .maha-icon {
    margin: auto !important;
    font-size: 1.1rem !important;
  }
}

.maha-devices {
  flex: 1;
  @include vscroll;
}
.maha-devices-header {
  padding: 0.8rem;
  background: fadeout($black, 97);
  border-bottom: 1px solid fadeout($black, 95);
  color: fadeout($black, 40);
}
.maha-devices-item {
  display: flex;
  border-bottom: 1px solid fadeout($black, 95);
  @include hover {
    background-color: fadeout($black, 97);
  };
  cursor: pointer;
}
.maha-devices-item-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    font-size: 2rem;
    color: fadeout($black, 80);
  }
}
.maha-devices-item-label {
  flex: 1;
  padding: 0.8rem;
  color: fadeout($black, 40);
  strong {
    color: $black;
  }
  em {
    color: $green;
    font-style: normal;
  }
  .maha-devices-item-enabled {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-style: italic;
    color: $green;
  }
  .maha-devices-item-disabled {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-style: italic;
    color: $red;
  }
}
.maha-devices-item-presence {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    font-size: 0.8rem;
    &.active {
      color: $green;
    }
    &.absent {
      color: $orange;
    }
    &.offline {
      color: fadeout($black, 80);
    }
    color: fadeout($black, 80);
  }
}
.maha-devices-item-remove {
  color: $blue;
  cursor: pointer;
  text-decoration: underline;
}
.maha-devices-footer {
  padding: 0.8rem;
  border-top: 1px solid fadeout($black, 95);
  background-color: fadeout($black, 97);
  .button {
    display: flex !important;
  }
  .button-icon {
    @include flex-justify-middle-center;
    padding: 0 0.8rem 0 0;
    .maha-icon {
      font-size: 3.2rem;
    }
  }
  .button-label {
    flex: 1;
    h3, p {
      text-align: left;
      margin: 0;
    }
  }

}
.maha-devices-native {
  background-color: $pink;
  @include hover {
    background-color: lighten($pink, 5);
  };
  color: $white;
  padding: 0.8rem;
  cursor: pointer;
  h3 {
    margin: 0;
  }
  span {
    text-decoration: underline;
  }
}

.maha-device {
  flex: 1;
  flex-direction: column;
  display: flex;
}
.maha-device-header {
  background-color: $darkblue;
  text-align: center;
  padding: 2.4rem 0.8rem;
  .maha-icon {
    color: $white;
    font-size: 4rem;
  }
  p {
    margin: 0.8rem 0 0;
  }
}
.maha-device-body {
  flex: 1;
}
.maha-device-footer {
  background-color: $black;
  display: flex;
  padding: 0.4rem;
}
.maha-device-footer-item {
  flex: 1;
  padding: 0.4rem;
}

.multifield-items {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  overflow: hidden;
  .multifield-item:last-child {
    border-bottom: none;
  }
}
.multifield-item {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: $white;
  display: flex;
}
.multifield-item-handle {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }
}
.multifield-item-token {
  flex: 1;
}
.multifield-item-label {
  input {
    border: none;
    box-shadow: none;
    outline: none;
    padding: 0.8rem;
    width: 100%;
  }
  flex: 1;
}
.multifield-item-action {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  cursor: pointer;
  .maha-icon {
    color: fadeout($black, 80);
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 60);
    }
  }
}
.multifield-items-add {
  padding: 0.8rem;
}

.email-token {
  display: flex;
  flex: 1;
}
.email-token-avatar {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .contact-avatar-token,
  .maha-logo,
  .maha-avatar {
    width: 2.4rem;
  }
}
.email-token-details {
  padding: 0.4rem 0.8rem;
  position: relative;
  flex: 1;
  color: fadeout($black, 60);
  small {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  strong {
    font-weight: normal;
    color: $black;
  }
  .maha-icon {
    font-size: 0.8rem;
  }
}
.email-token-row {
  display: flex;
  & > div:first-child {
    flex: 1;
  }
}
.email-token-timestamp {
  color: fadeout($black, 60);
  font-size: 0.8rem;
}
.email-token-recipient {
  line-height: 1.4rem;
  color: $black;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-token-subject {
  color: fadeout($black, 60);
  flex: 1;
  line-height: 1.4rem;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.8rem;
}
.email-token-count {
  color: fadeout($blue, 40);
  font-size: 0.8rem;
}

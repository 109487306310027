.fontset-preview {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  padding: 0.8rem;
  min-height: 100px;
}
.fontset-preview-heading {
  font-size: 20px;
  line-height: 130%;
}
.fontset-preview-text {
  font-size: 14px;
  line-height: 160%;
  color: fadeout($black, 40);
}

.responsivefield {
  position: relative;
  &:hover {
    .responsivefield-header {
      opacity: 1;
    }
  }
}
.responsivefield-header {
  transition: opacity .2s ease;
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  transform: translateY(-22px);
  text-align: right;
}
.responsivefield-header-icon {
  background-color: fadeout($black, 90);
  margin-left: 5px;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  line-height: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  &:not(.disabled) {
    @include hover {
      background-color: fadeout($blue, 65);
    };
  }
  &.disabled {
    background-color: fadeout($blue, 90);
    cursor: auto;
    .maha-icon {
      color: fadeout($blue, 80);
    }
  }
  &.selected {
    background-color: $blue !important;
    .maha-icon {
      color: $white !important;
    }
  }
  .fa-mouse-pointer {
    font-size: 10px;
    transform: translate(2px, -1px);
  }
  .fa-refresh {
    font-size: 10px;
    transform: translate(0, -1px);
  }
  .maha-icon {
    color: fadeout($black, 60);
  }
}
.responsivefield-devices {
  border: 1px solid fadeout($black, 90);
  border-bottom: none;
  border-radius: 0.4rem 0.4rem 0 0;
  overflow: hidden;
  display: flex;
  font-size: 0.8rem;
  .responsivefield-device:last-child {
    border-right: none;
  }
}
.responsivefield-device {
  border-right: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0 0.4rem;
  flex: 1;
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid fadeout($black, 90);
  color: fadeout($black, 60);
  @include hover {
    background-color: fadeout($black, 95);
  };
  &.selected {
    border-bottom: none;
    background-color: $white;
    color: $blue;
  }
}
.responsivefield-body {
  border: 1px solid fadeout($black, 90);
  border-radius: 0 0 0.4rem 0.4rem;
  background-color: $white;
  border-top: none;
  padding: 0.8rem;
}

.maha-password-icon {
  @include flex-justify-middle-center;
  padding: 0.4rem;
  &.weak .maha-icon {
    color: fadeout($black, 80);
  }
  &.strong .maha-icon {
    color: $green;
  }
  .maha-icon {
    font-size: 1.2rem;
  }
}

.crm-verification-token {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: inline-block;
}
.crm-verification-token-body {
  padding: 0.4rem 0.8rem;
  line-height: 1.6rem;
}
.crm-verification-token-staus {
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 1.6rem;
  &.pending {
    .maha-icon { color: $teal; }
    color: $teal;
  }
  &.verified {
    .maha-icon { color: $green; }
    color: $green;
  }
  &.expired {
    .maha-icon { color: $red; }
    color: $red;
  }
}
.crm-verification-expires {
  color: fadeout($black, 60);
}
.crm-verification-token-footer {
  border-top: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.4rem;
}
.social-preview {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
}
.social-preview-body {
  padding: 0.8rem;
}
.social-preview-title {
  font-size: 1.4rem;
}
.social-preview-link {
  font-size: 0.8rem;
  line-height: 1.2rem;
}

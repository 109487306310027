.maha-star {
  flex: 1;
  display: flex;
  cursor: pointer;
  &.labeled {
    @include hover {
      background-color: fadeout($black, 97);
    };
    .maha-icon {
      color: lighten($black, 50);
    }
    .maha-star-icon {
      padding: 0.8rem;
    }
    .maha-star-text {
      padding: 0.8rem 0.8rem 0.8rem 0;
      color: $blue;
    }
  }
}
.maha-star-icon {
  @include flex-justify-middle-center;
  & > .maha-icon {
    color: lighten($black, 80);
    &.fa-star {
      color: $yellow;
    }
    &.fa-star-o {
      @include hover {
        color: $yellow;
      };
    }
  }
}

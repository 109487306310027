.palettefield {
  max-width: 300px;
}
.colorset-token {
  padding: 0.8rem;
}
.colorset-swatches-container {
  border: 1px solid fadeout($black, 90);
  background: $white;
  border-radius: 0.4rem;
  padding: 0.8rem 2.9rem 0.8rem 0.8rem;
}
.colorset-swatches {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  .colorset-swatch:last-child {
    border-right: none;
  }
}
.colorset-swatch {
  flex: 1;
  height: 30px;
  border-right: 1px solid fadeout($black, 90);
}

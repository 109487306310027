.maha-addressfield {
  .maha-input {
    border: none;
    flex: 1;
    input {
      border: none !important;
    }
  }
  &.compact {
    flex-direction: column;
    & > .maha-input:nth-child(2) {
      border: none;
      border-radius: 0 0 0.4rem 0.4rem;
      border-top: 1px solid fadeout($black, 90);
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & > .maha-input:nth-child(2) {
      border: none;
      border-radius: 0 0 0.4rem 0.4rem;
      border-top: 1px solid fadeout($black, 90);
    }
  }
  @media (min-width: 769px) {
    & > .maha-input:nth-child(2) {
      border-left: 1px solid fadeout($black, 90);
    }
  }
}
.maha-addressfield-results {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  position: absolute;
  left: -1px;
  right: -1px;
  z-index: 10;
  .maha-addressfield-result:last-child {
    border: none;
  }
  &.down {
    border-radius: 0 0 0.4rem 0.4rem;
    top: calc(100% - 2px);
  }
  &.up {
    border-radius: 0.4rem 0.4rem 0 0;
    bottom: calc(100% - 2px);
  }
}
.maha-addressfield-result {
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  &.selected {
    background-color: fadeout($black, 95);
  }
  @include hover {
    background-color: fadeout($black, 95);
  };
}
.maha-addressfield-result-add {
  padding: 0.8rem;
  color: $blue;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 95);
  };
}
.maha-addressfield-result-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  .maha-icon {
    color: $red;
    font-size: 1.2rem;
  }
}
.maha-addressfield-result-details {
  padding: 0.8rem 0.8rem 0.8rem 0;
  color: fadeout($black, 60);
  line-height: 1.4rem;
  strong {
    color: $black;
  }
}
.maha-addressfield-street2 {
  input {
    padding: 0.6rem !important;
    border: none !important;
  }
}
.maha-addressfield-link {
  color: $blue;
  font-size: 0.8rem;
  cursor: pointer;
}

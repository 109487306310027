.event-scanner {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: $black;
}
.event-scanner-header {
  padding: 0.8rem;
  text-align: center;
  color: $white;
}
.event-scanner-canvas {
  @include flex-justify-middle-center;
  padding: 0 0.8rem;
  canvas {
    width: 100%;
    max-width: 350px;
  }
}
.event-scanner-body {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  flex: 1;
}
.event-scanner-message {
  text-align: center;
  .maha-icon {
    background-color: $white;
    font-size: 5rem;
    border-radius: 50%;
    padding: 0 5px;
  }
  .fa-check-circle {
    color: $green;
  }
  .fa-times-circle {
    color: $red;
  }
}
.event-scanner-message-text {
  text-align: center;
  color: $white;
  padding: 0.8rem 0;
}
.event-scanner-footer {
  padding: 0.8rem;
}

@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,600';
@import 'react-grid-layout/css/styles.css';
@import 'react-resizable/css/styles.css';
@import './variables.scss';
@import './functions.scss';
@import './root.scss';

@import '../../../core/lib/admin/components/account/devices/style.scss';
@import '../../../core/lib/admin/components/account/notification_types/style.scss';
@import '../../../core/lib/admin/components/account/style.scss';
@import '../../../core/lib/admin/components/asset_icon/style.scss';
@import '../../../core/lib/admin/components/asset_info/style.scss';
@import '../../../core/lib/admin/components/asset_preview/style.scss';
@import '../../../core/lib/admin/components/asset_thumbnail/style.scss';
@import '../../../core/lib/admin/components/asset_viewer/style.scss';
@import '../../../core/lib/admin/components/assignment/style.scss';
@import '../../../core/lib/admin/components/attachment/style.scss';
@import '../../../core/lib/admin/components/attachments/dalle/style.scss';
@import '../../../core/lib/admin/components/attachments/device/style.scss';
@import '../../../core/lib/admin/components/attachments/drive/style.scss';
@import '../../../core/lib/admin/components/attachments/explorer/style.scss';
@import '../../../core/lib/admin/components/attachments/importing/style.scss';
@import '../../../core/lib/admin/components/attachments/photos/style.scss';
@import '../../../core/lib/admin/components/attachments/review/style.scss';
@import '../../../core/lib/admin/components/attachments/sources/style.scss';
@import '../../../core/lib/admin/components/attachments/style.scss';
@import '../../../core/lib/admin/components/attachments/truevail/style.scss';
@import '../../../core/lib/admin/components/attachments/unsplash/style.scss';
@import '../../../core/lib/admin/components/attachments/web/style.scss';
@import '../../../core/lib/admin/components/audit/style.scss';
@import '../../../core/lib/admin/components/avatar/style.scss';
@import '../../../core/lib/admin/components/badge/style.scss';
@import '../../../core/lib/admin/components/button/style.scss';
@import '../../../core/lib/admin/components/buttons/style.scss';
@import '../../../core/lib/admin/components/camera/style.scss';
@import '../../../core/lib/admin/components/carousel/style.scss';
@import '../../../core/lib/admin/components/changelog/style.scss';
@import '../../../core/lib/admin/components/chart/style.scss';
@import '../../../core/lib/admin/components/code/style.scss';
@import '../../../core/lib/admin/components/collection/filter/style.scss';
@import '../../../core/lib/admin/components/collection/style.scss';
@import '../../../core/lib/admin/components/comment/style.scss';
@import '../../../core/lib/admin/components/comments/style.scss';
@import '../../../core/lib/admin/components/composer/attachments/style.scss';
@import '../../../core/lib/admin/components/composer/style.scss';
@import '../../../core/lib/admin/components/control_panel/style.scss';
@import '../../../core/lib/admin/components/copytext/style.scss';
@import '../../../core/lib/admin/components/criteria_builder/style.scss';
@import '../../../core/lib/admin/components/criteria_designer/style.scss';
@import '../../../core/lib/admin/components/details/style.scss';
@import '../../../core/lib/admin/components/dnstable/style.scss';
@import '../../../core/lib/admin/components/dropzone/style.scss';
@import '../../../core/lib/admin/components/email_activities/style.scss';
@import '../../../core/lib/admin/components/email_reader/style.scss';
@import '../../../core/lib/admin/components/email_viewer/style.scss';
@import '../../../core/lib/admin/components/email/style.scss';
@import '../../../core/lib/admin/components/embed/style.scss';
@import '../../../core/lib/admin/components/emojis/style.scss';
@import '../../../core/lib/admin/components/entity_chooser/style.scss';
@import '../../../core/lib/admin/components/feed/style.scss';
@import '../../../core/lib/admin/components/fields/style.scss';
@import '../../../core/lib/admin/components/finder/style.scss';
@import '../../../core/lib/admin/components/flash/style.scss';
@import '../../../core/lib/admin/components/flowchart_designer/blocks/email/style.scss';
@import '../../../core/lib/admin/components/flowchart_designer/blocks/ifthen/style.scss';
@import '../../../core/lib/admin/components/flowchart_designer/blocks/message/style.scss';
@import '../../../core/lib/admin/components/flowchart_designer/blocks/play/style.scss';
@import '../../../core/lib/admin/components/flowchart_designer/blocks/say/style.scss';
@import '../../../core/lib/admin/components/flowchart_designer/branchesfield/style.scss';
@import '../../../core/lib/admin/components/flowchart_designer/canvas/style.scss';
@import '../../../core/lib/admin/components/flowchart_designer/sidebar/style.scss';
@import '../../../core/lib/admin/components/flowchart_designer/style.scss';
@import '../../../core/lib/admin/components/form/addressfield/style.scss';
@import '../../../core/lib/admin/components/form/alignmentfield/style.scss';
@import '../../../core/lib/admin/components/form/assignmentfield/style.scss';
@import '../../../core/lib/admin/components/form/attachmentfield/style.scss';
@import '../../../core/lib/admin/components/form/checkbox/style.scss';
@import '../../../core/lib/admin/components/form/codefield/style.scss';
@import '../../../core/lib/admin/components/form/collectionfield/style.scss';
@import '../../../core/lib/admin/components/form/colorfield/style.scss';
@import '../../../core/lib/admin/components/form/criteriafield/style.scss';
@import '../../../core/lib/admin/components/form/datefield/style.scss';
@import '../../../core/lib/admin/components/form/datetimefield/style.scss';
@import '../../../core/lib/admin/components/form/domainfield/style.scss';
@import '../../../core/lib/admin/components/form/dropdown/style.scss';
@import '../../../core/lib/admin/components/form/durationfield/style.scss';
@import '../../../core/lib/admin/components/form/filefield/style.scss';
@import '../../../core/lib/admin/components/form/formatfield/style.scss';
@import '../../../core/lib/admin/components/form/generate_field/style.scss';
@import '../../../core/lib/admin/components/form/htmlfield/style.scss';
@import '../../../core/lib/admin/components/form/imagefield/style.scss';
@import '../../../core/lib/admin/components/form/linkfield/style.scss';
@import '../../../core/lib/admin/components/form/lookup/style.scss';
@import '../../../core/lib/admin/components/form/mediafield/style.scss';
@import '../../../core/lib/admin/components/form/multifield/style.scss';
@import '../../../core/lib/admin/components/form/numberfield/style.scss';
@import '../../../core/lib/admin/components/form/numericfield/style.scss';
@import '../../../core/lib/admin/components/form/optionsfield/style.scss';
@import '../../../core/lib/admin/components/form/password/style.scss';
@import '../../../core/lib/admin/components/form/permalinkfield/style.scss';
@import '../../../core/lib/admin/components/form/phonefield/style.scss';
@import '../../../core/lib/admin/components/form/profilefield/style.scss';
@import '../../../core/lib/admin/components/form/range/style.scss';
@import '../../../core/lib/admin/components/form/ratingfield/style.scss';
@import '../../../core/lib/admin/components/form/recipientfield/style.scss';
@import '../../../core/lib/admin/components/form/select/style.scss';
@import '../../../core/lib/admin/components/form/smsfield/shortlink/style.scss';
@import '../../../core/lib/admin/components/form/smsfield/style.scss';
@import '../../../core/lib/admin/components/form/style.scss';
@import '../../../core/lib/admin/components/form/tablefield/style.scss';
@import '../../../core/lib/admin/components/form/tagfield/style.scss';
@import '../../../core/lib/admin/components/form/text/style.scss';
@import '../../../core/lib/admin/components/form/textarea/style.scss';
@import '../../../core/lib/admin/components/form/textfield/style.scss';
@import '../../../core/lib/admin/components/form/timefield/style.scss';
@import '../../../core/lib/admin/components/form/tokenfield/style.scss';
@import '../../../core/lib/admin/components/form/urlfield/style.scss';
@import '../../../core/lib/admin/components/form/videofield/style.scss';
@import '../../../core/lib/admin/components/format/style.scss';
@import '../../../core/lib/admin/components/gallery_list/style.scss';
@import '../../../core/lib/admin/components/gallery/style.scss';
@import '../../../core/lib/admin/components/greeting/style.scss';
@import '../../../core/lib/admin/components/grid/style.scss';
@import '../../../core/lib/admin/components/help/articles/style.scss';
@import '../../../core/lib/admin/components/help/style.scss';
@import '../../../core/lib/admin/components/host/badge/style.scss';
@import '../../../core/lib/admin/components/host/offer/style.scss';
@import '../../../core/lib/admin/components/host/style.scss';
@import '../../../core/lib/admin/components/icon/style.scss';
@import '../../../core/lib/admin/components/image_editor/style.scss';
@import '../../../core/lib/admin/components/image_preview/style.scss';
@import '../../../core/lib/admin/components/image_tools/style.scss';
@import '../../../core/lib/admin/components/image/style.scss';
@import '../../../core/lib/admin/components/import/style.scss';
@import '../../../core/lib/admin/components/infinite/style.scss';
@import '../../../core/lib/admin/components/input_tokens/style.scss';
@import '../../../core/lib/admin/components/link/style.scss';
@import '../../../core/lib/admin/components/list/style.scss';
@import '../../../core/lib/admin/components/loader/style.scss';
@import '../../../core/lib/admin/components/logo/style.scss';
@import '../../../core/lib/admin/components/manager/style.scss';
@import '../../../core/lib/admin/components/mblocks_designer/followsfield/style.scss';
@import '../../../core/lib/admin/components/mblocks_designer/imagefield/style.scss';
@import '../../../core/lib/admin/components/mblocks_designer/sharesfield/style.scss';
@import '../../../core/lib/admin/components/mblocks_designer/sidebar/content/style.scss';
@import '../../../core/lib/admin/components/mblocks_designer/sidebar/design/style.scss';
@import '../../../core/lib/admin/components/mblocks_designer/style.scss';
@import '../../../core/lib/admin/components/menu/style.scss';
@import '../../../core/lib/admin/components/message/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/buttonstylefield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/colorfield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/colorsfield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/followfield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/fontsfield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/iconfield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/layoutfield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/linkedfield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/media/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/mediapreview/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/palettefield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/pinsfield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/responsivefield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/segmentsfield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/seo_preview/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/sharefield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/slidesfield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/social_preview/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/components/textstylefield/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/navigator/content/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/navigator/outline/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/navigator/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/panels/page/assets/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/tokens/block/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/tokens/element/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/tokens/icon/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/tokens/social_follow/style.scss';
@import '../../../core/lib/admin/components/mjson_designer/tokens/social_share/style.scss';
@import '../../../core/lib/admin/components/mjson_preview/style.scss';
@import '../../../core/lib/admin/components/mjson_screenshot/style.scss';
@import '../../../core/lib/admin/components/modal_panel/style.scss';
@import '../../../core/lib/admin/components/modal/style.scss';
@import '../../../core/lib/admin/components/multiform/style.scss';
@import '../../../core/lib/admin/components/navigation/style.scss';
@import '../../../core/lib/admin/components/network/style.scss';
@import '../../../core/lib/admin/components/notifications/style.scss';
@import '../../../core/lib/admin/components/omnisearch/style.scss';
@import '../../../core/lib/admin/components/page/style.scss';
@import '../../../core/lib/admin/components/panel/style.scss';
@import '../../../core/lib/admin/components/picklist/style.scss';
@import '../../../core/lib/admin/components/platform/style.scss';
@import '../../../core/lib/admin/components/portal/style.scss';
@import '../../../core/lib/admin/components/preview/style.scss';
@import '../../../core/lib/admin/components/profile_connect/style.scss';
@import '../../../core/lib/admin/components/profile_manager/style.scss';
@import '../../../core/lib/admin/components/profile_reconnect/style.scss';
@import '../../../core/lib/admin/components/progress/bar/style.scss';
@import '../../../core/lib/admin/components/progress/pie/style.scss';
@import '../../../core/lib/admin/components/prompt/style.scss';
@import '../../../core/lib/admin/components/push/style.scss';
@import '../../../core/lib/admin/components/reaction/style.scss';
@import '../../../core/lib/admin/components/reactions/style.scss';
@import '../../../core/lib/admin/components/report/style.scss';
@import '../../../core/lib/admin/components/ribbon/style.scss';
@import '../../../core/lib/admin/components/richtext/style.scss';
@import '../../../core/lib/admin/components/scrollpane/style.scss';
@import '../../../core/lib/admin/components/search/style.scss';
@import '../../../core/lib/admin/components/searchbox/style.scss';
@import '../../../core/lib/admin/components/sensitive_text/style.scss';
@import '../../../core/lib/admin/components/sessions/style.scss';
@import '../../../core/lib/admin/components/shortlink/style.scss';
@import '../../../core/lib/admin/components/signin/style.scss';
@import '../../../core/lib/admin/components/social_conversations/posts/style.scss';
@import '../../../core/lib/admin/components/social_conversations/style.scss';
@import '../../../core/lib/admin/components/social_designer/placesfield/style.scss';
@import '../../../core/lib/admin/components/social_designer/style.scss';
@import '../../../core/lib/admin/components/social_preview/style.scss';
@import '../../../core/lib/admin/components/social_schedule/scheduled/list/style.scss';
@import '../../../core/lib/admin/components/social_schedule/scheduled/month/style.scss';
@import '../../../core/lib/admin/components/social_schedule/scheduled/style.scss';
@import '../../../core/lib/admin/components/social_schedule/scheduled/week/style.scss';
@import '../../../core/lib/admin/components/social_schedule/style.scss';
@import '../../../core/lib/admin/components/stack/style.scss';
@import '../../../core/lib/admin/components/star/style.scss';
@import '../../../core/lib/admin/components/steps/style.scss';
@import '../../../core/lib/admin/components/support_assignment/style.scss';
@import '../../../core/lib/admin/components/table/style.scss';
@import '../../../core/lib/admin/components/tabs/style.scss';
@import '../../../core/lib/admin/components/tasks/style.scss';
@import '../../../core/lib/admin/components/timer/style.scss';
@import '../../../core/lib/admin/components/toggle_list/style.scss';
@import '../../../core/lib/admin/components/token/style.scss';
@import '../../../core/lib/admin/components/tokens/style.scss';
@import '../../../core/lib/admin/components/toolbar/style.scss';
@import '../../../core/lib/admin/components/tooltip/style.scss';
@import '../../../core/lib/admin/components/touch/style.scss';
@import '../../../core/lib/admin/components/uploader/style.scss';
@import '../../../core/lib/admin/components/verify/style.scss';
@import '../../../core/lib/admin/components/version_history/style.scss';
@import '../../../core/lib/admin/components/versions/style.scss';
@import '../../../core/lib/admin/components/virtualized/style.scss';
@import '../../../core/lib/admin/tokens/access_type/style.scss';
@import '../../../core/lib/admin/tokens/access/style.scss';
@import '../../../core/lib/admin/tokens/account/style.scss';
@import '../../../core/lib/admin/tokens/app/style.scss';
@import '../../../core/lib/admin/tokens/asset/style.scss';
@import '../../../core/lib/admin/tokens/assignee/style.scss';
@import '../../../core/lib/admin/tokens/authentication_strategy/style.scss';
@import '../../../core/lib/admin/tokens/dmarc_report/style.scss';
@import '../../../core/lib/admin/tokens/dmarc_status/style.scss';
@import '../../../core/lib/admin/tokens/email_bounce/style.scss';
@import '../../../core/lib/admin/tokens/email_interactions/style.scss';
@import '../../../core/lib/admin/tokens/email_type/style.scss';
@import '../../../core/lib/admin/tokens/email/style.scss';
@import '../../../core/lib/admin/tokens/file_type/style.scss';
@import '../../../core/lib/admin/tokens/help_article/style.scss';
@import '../../../core/lib/admin/tokens/icon/style.scss';
@import '../../../core/lib/admin/tokens/image/style.scss';
@import '../../../core/lib/admin/tokens/mode/style.scss';
@import '../../../core/lib/admin/tokens/phone_number/style.scss';
@import '../../../core/lib/admin/tokens/presence/style.scss';
@import '../../../core/lib/admin/tokens/profile/style.scss';
@import '../../../core/lib/admin/tokens/progress/style.scss';
@import '../../../core/lib/admin/tokens/rfc822/style.scss';
@import '../../../core/lib/admin/tokens/service/style.scss';
@import '../../../core/lib/admin/tokens/source/style.scss';
@import '../../../core/lib/admin/tokens/status/style.scss';
@import '../../../core/lib/admin/tokens/team/style.scss';
@import '../../../core/lib/admin/tokens/token/style.scss';
@import '../../../core/lib/admin/tokens/twilio_status/style.scss';
@import '../../../core/lib/admin/tokens/unsplash/style.scss';
@import '../../../core/lib/admin/tokens/user/style.scss';
@import '../../../apps/automation/admin/components/actions/style.scss';
@import '../../../apps/automation/admin/tokens/bounce_type/style.scss';
@import '../../../apps/automation/admin/tokens/email/style.scss';
@import '../../../apps/automation/admin/tokens/trigger/style.scss';
@import '../../../apps/automation/admin/tokens/workflow/style.scss';
@import '../../../apps/automation/admin/views/workflows/show/style.scss';
@import '../../../apps/calendar/admin/components/calendar/style.scss';
@import '../../../apps/campaigns/admin/components/purposefield/style.scss';
@import '../../../apps/campaigns/admin/components/social_preview/style.scss';
@import '../../../apps/campaigns/admin/components/tofield/style.scss';
@import '../../../apps/campaigns/admin/tokens/campaign/style.scss';
@import '../../../apps/campaigns/admin/tokens/social_campaign_variant/style.scss';
@import '../../../apps/campaigns/admin/views/email/style.scss';
@import '../../../apps/campaigns/admin/views/social/show/style.scss';
@import '../../../apps/crm/admin/components/addresscriteria/style.scss';
@import '../../../apps/crm/admin/components/channelfield/style.scss';
@import '../../../apps/crm/admin/components/checkboxesfield/style.scss';
@import '../../../apps/crm/admin/components/contact_chooser/style.scss';
@import '../../../apps/crm/admin/components/contactcard/style.scss';
@import '../../../apps/crm/admin/components/contactfield/style.scss';
@import '../../../apps/crm/admin/components/contactfields/style.scss';
@import '../../../apps/crm/admin/components/contactfieldsfield/style.scss';
@import '../../../apps/crm/admin/components/list/style.scss';
@import '../../../apps/crm/admin/components/positionfield/style.scss';
@import '../../../apps/crm/admin/components/program_chooser/style.scss';
@import '../../../apps/crm/admin/components/programform/style.scss';
@import '../../../apps/crm/admin/tokens/access_type/style.scss';
@import '../../../apps/crm/admin/tokens/agreement/style.scss';
@import '../../../apps/crm/admin/tokens/contact_avatar/style.scss';
@import '../../../apps/crm/admin/tokens/contact/style.scss';
@import '../../../apps/crm/admin/tokens/file/style.scss';
@import '../../../apps/crm/admin/tokens/note/style.scss';
@import '../../../apps/crm/admin/tokens/program_access/style.scss';
@import '../../../apps/crm/admin/tokens/program/style.scss';
@import '../../../apps/crm/admin/tokens/sender/style.scss';
@import '../../../apps/crm/admin/tokens/tag/style.scss';
@import '../../../apps/crm/admin/tokens/verification/style.scss';
@import '../../../apps/crm/admin/views/contacts/show/style.scss';
@import '../../../apps/crm/admin/views/contacts/show/timeline/style.scss';
@import '../../../apps/dashboards/admin/components/chartfield/style.scss';
@import '../../../apps/dashboards/admin/components/dashboard/cards/style.scss';
@import '../../../apps/dashboards/admin/components/dashboard/panels/grid/style.scss';
@import '../../../apps/dashboards/admin/components/dashboard/style.scss';
@import '../../../apps/dashboards/admin/components/daterangefield/style.scss';
@import '../../../apps/datasets/admin/components/apiaccessfield/style.scss';
@import '../../../apps/datasets/admin/components/apifield/style.scss';
@import '../../../apps/datasets/admin/components/editor/style.scss';
@import '../../../apps/domains/admin/components/lookup/style.scss';
@import '../../../apps/domains/admin/components/valuefield/style.scss';
@import '../../../apps/domains/admin/tokens/domain_status/style.scss';
@import '../../../apps/domains/admin/tokens/record/style.scss';
@import '../../../apps/domains/admin/views/domains/show/style.scss';
@import '../../../apps/drive/admin/components/explorer/style.scss';
@import '../../../apps/drive/admin/components/move/style.scss';
@import '../../../apps/drive/admin/components/share/style.scss';
@import '../../../apps/drive/admin/components/sidebar/style.scss';
@import '../../../apps/drive/admin/components/tasks/style.scss';
@import '../../../apps/drive/admin/components/versions/style.scss';
@import '../../../apps/email/admin/components/composer/style.scss';
@import '../../../apps/email/admin/components/email_client/style.scss';
@import '../../../apps/email/admin/tokens/envelope/style.scss';
@import '../../../apps/email/admin/views/emails/new/style.scss';
@import '../../../apps/events/admin/components/organizersfield/style.scss';
@import '../../../apps/events/admin/components/scanner/style.scss';
@import '../../../apps/events/admin/components/sessionsfield/style.scss';
@import '../../../apps/events/admin/components/ticketfieldsfield/style.scss';
@import '../../../apps/events/admin/components/tickettypesfield/style.scss';
@import '../../../apps/events/admin/tokens/event/style.scss';
@import '../../../apps/events/admin/tokens/organizer_avatar/style.scss';
@import '../../../apps/events/admin/tokens/organizer/style.scss';
@import '../../../apps/events/admin/tokens/payment/style.scss';
@import '../../../apps/events/admin/tokens/session/style.scss';
@import '../../../apps/events/admin/tokens/status/style.scss';
@import '../../../apps/events/admin/tokens/ticket_type/style.scss';
@import '../../../apps/events/admin/views/events/tickets/style.scss';
@import '../../../apps/finance/admin/components/allocationfield/style.scss';
@import '../../../apps/finance/admin/components/allocations/style.scss';
@import '../../../apps/finance/admin/components/amountfield/style.scss';
@import '../../../apps/finance/admin/components/invoice/style.scss';
@import '../../../apps/finance/admin/components/line_items/style.scss';
@import '../../../apps/finance/admin/components/paymentfield/braintree/achfield/style.scss';
@import '../../../apps/finance/admin/components/paymentfield/braintree/cardfield/style.scss';
@import '../../../apps/finance/admin/components/paymentfield/stripe/cardfield/style.scss';
@import '../../../apps/finance/admin/components/paymentfield/style.scss';
@import '../../../apps/finance/admin/components/paymentsfield/style.scss';
@import '../../../apps/finance/admin/components/receipt/style.scss';
@import '../../../apps/finance/admin/components/routingnumberfield/style.scss';
@import '../../../apps/finance/admin/style.scss';
@import '../../../apps/finance/admin/tokens/allocations/style.scss';
@import '../../../apps/finance/admin/tokens/card/style.scss';
@import '../../../apps/finance/admin/tokens/integration/style.scss';
@import '../../../apps/finance/admin/tokens/membership/style.scss';
@import '../../../apps/finance/admin/tokens/payment_type/style.scss';
@import '../../../apps/finance/admin/tokens/project/style.scss';
@import '../../../apps/finance/admin/tokens/type/style.scss';
@import '../../../apps/finance/admin/views/projects/style.scss';
@import '../../../apps/forms/admin/components/form_designer/components/imagefield/style.scss';
@import '../../../apps/forms/admin/components/form_designer/components/productfield/style.scss';
@import '../../../apps/forms/admin/components/form_designer/components/rulesfield/style.scss';
@import '../../../apps/forms/admin/components/form_designer/page/design/style.scss';
@import '../../../apps/forms/admin/components/form_designer/page/fields/style.scss';
@import '../../../apps/forms/admin/tokens/content/style.scss';
@import '../../../apps/forms/admin/tokens/embed/style.scss';
@import '../../../apps/forms/admin/tokens/form/style.scss';
@import '../../../apps/forms/admin/tokens/landing_page/style.scss';
@import '../../../apps/maha/admin/roots/help/style.scss';
@import '../../../apps/maha/admin/style.scss';
@import '../../../apps/maha/admin/tokens/email_activity/style.scss';
@import '../../../apps/maha/admin/tokens/engagement/style.scss';
@import '../../../apps/maha/admin/tokens/icon/style.scss';
@import '../../../apps/maha/admin/tokens/import/style.scss';
@import '../../../apps/phone/admin/components/button/style.scss';
@import '../../../apps/phone/admin/components/call_activities/style.scss';
@import '../../../apps/phone/admin/components/handset/connections/header/style.scss';
@import '../../../apps/phone/admin/components/handset/connections/style.scss';
@import '../../../apps/phone/admin/components/handset/contacts/style.scss';
@import '../../../apps/phone/admin/components/handset/dialer/style.scss';
@import '../../../apps/phone/admin/components/handset/style.scss';
@import '../../../apps/phone/admin/components/handset/tabs/phone_numbers/style.scss';
@import '../../../apps/phone/admin/components/handset/voicemail/style.scss';
@import '../../../apps/phone/admin/components/keypad/style.scss';
@import '../../../apps/phone/admin/components/phoneform/style.scss';
@import '../../../apps/phone/admin/components/recipientsfield/style.scss';
@import '../../../apps/phone/admin/components/recordingfield/style.scss';
@import '../../../apps/phone/admin/components/sms_client/style.scss';
@import '../../../apps/phone/admin/components/sms_reader/style.scss';
@import '../../../apps/phone/admin/components/speakfield/style.scss';
@import '../../../apps/phone/admin/roots/twilio/style.scss';
@import '../../../apps/phone/admin/tokens/call/style.scss';
@import '../../../apps/phone/admin/tokens/caller/style.scss';
@import '../../../apps/phone/admin/tokens/conversation/style.scss';
@import '../../../apps/phone/admin/tokens/hold_music/style.scss';
@import '../../../apps/phone/admin/tokens/phone_number/style.scss';
@import '../../../apps/phone/admin/tokens/twilio_number/style.scss';
@import '../../../apps/platform/admin/components/appsfield/style.scss';
@import '../../../apps/platform/admin/components/secretsfield/style.scss';
@import '../../../apps/platform/admin/tokens/filename/style.scss';
@import '../../../apps/sales/admin/components/dealboard/style.scss';
@import '../../../apps/sales/admin/components/stagesfield/style.scss';
@import '../../../apps/sales/admin/tokens/progress/style.scss';
@import '../../../apps/sales/admin/views/pipelines/show/stages/style.scss';
@import '../../../apps/sites/admin/tokens/manager/style.scss';
@import '../../../apps/sites/admin/tokens/role/style.scss';
@import '../../../apps/sites/admin/views/types/style.scss';
@import '../../../apps/stores/admin/components/fulfillmentfield/style.scss';
@import '../../../apps/stores/admin/components/inventoryfield/style.scss';
@import '../../../apps/stores/admin/components/mediafield/style.scss';
@import '../../../apps/stores/admin/components/optionsfield/style.scss';
@import '../../../apps/stores/admin/components/order/style.scss';
@import '../../../apps/stores/admin/components/refundfield/style.scss';
@import '../../../apps/stores/admin/tokens/product/style.scss';
@import '../../../apps/stores/admin/tokens/store/style.scss';
@import '../../../apps/stores/admin/views/stores/products/new/inventory/style.scss';
@import '../../../apps/stores/admin/views/stores/products/new/style.scss';
@import '../../../apps/subscriptions/admin/components/addonsfield/style.scss';
@import '../../../apps/subscriptions/admin/components/allowancesfield/style.scss';
@import '../../../apps/subscriptions/admin/components/frequencyfield/style.scss';
@import '../../../apps/subscriptions/admin/components/resourcesfield/style.scss';
@import '../../../apps/subscriptions/admin/components/usagesfield/style.scss';
@import '../../../apps/subscriptions/admin/tokens/service/style.scss';
@import '../../../apps/subscriptions/admin/tokens/style.scss';
@import '../../../apps/tasks/admin/components/reminderfield/style.scss';
@import '../../../apps/tasks/admin/tokens/due/style.scss';
@import '../../../apps/tasks/admin/tokens/task/style.scss';
@import '../../../apps/team/admin/components/access/style.scss';
@import '../../../apps/team/admin/components/accessfield/style.scss';
@import '../../../apps/team/admin/style.scss';
@import '../../../apps/team/admin/views/users/style.scss';
@import '../../../apps/truevail/admin/components/assign/style.scss';
@import '../../../apps/truevail/admin/components/channelmapfield/style.scss';
@import '../../../apps/truevail/admin/components/configuration/brand/style.scss';
@import '../../../apps/truevail/admin/components/configuration/logo/style.scss';
@import '../../../apps/truevail/admin/components/configuration/style.scss';
@import '../../../apps/truevail/admin/components/datesfield/style.scss';
@import '../../../apps/truevail/admin/components/profilesetupfield/style.scss';
@import '../../../apps/truevail/admin/components/timeframefield/style.scss';
@import '../../../apps/truevail/admin/tokens/library/style.scss';
@import '../../../apps/truevail/admin/tokens/profiles/style.scss';
@import '../../../apps/truevail/admin/tokens/scheduling/style.scss';
@import '../../../apps/truevail/admin/tokens/social_type/style.scss';
@import '../../../apps/truevail/admin/tokens/timeframe/style.scss';
@import '../../../apps/truevail/admin/views/admin/aesthetic_profiles/show/style.scss';
@import '../../../apps/truevail/admin/views/agency/social_performance/show/style.scss';
@import '../../../apps/truevail/admin/views/agency/style.scss';
@import '../../../apps/websites/admin/components/destinationfield/style.scss';
@import '../../../apps/websites/admin/components/media/style.scss';
@import '../../../apps/websites/admin/components/mediapreview/style.scss';
@import '../../../apps/websites/admin/components/menu_designer/style.scss';
@import '../../../apps/websites/admin/components/metadatafield/style.scss';
@import '../../../apps/websites/admin/components/preview/style.scss';
@import '../../../apps/websites/admin/tokens/certificate/style.scss';
@import '../../../apps/websites/admin/tokens/website/style.scss';

.maha-finder {
  @include vscroll;
  flex: 1;
}
.maha-finder-item {
  display: flex;
  flex: 1;
  cursor: pointer;
  &.active {
    & > .maha-finder-item-toggle .maha-icon {
      transform: rotate(90deg);
    }
  }
  &.selected {
    background-color: fadeout($blue, 90) !important;
  }
  @include hover {
    background-color: fadeout($black, 97);
    .maha-finder-item-action {
      visibility: visible;
    }
  };
}
.maha-finder-item-padding {
  flex: 0 0 1.6rem;
}
.maha-finder-item-toggle {
  @include flex-justify-middle-center;
  flex: 0 0 1.6rem;
  .maha-icon {
    transition: transform .15s ease-in-out;
    color: fadeout($black, 60);
    font-size: 0.6rem;
  }
}
.maha-finder-item-icon {
  @include flex-justify-middle-center;
  flex: 0 0 1.6rem;
  position: relative;
  padding: 0.8rem 0;
  .maha-icon {
    color: $blue;
    font-size: 0.8rem;
  }
}
.maha-finder-item-action {
  @include flex-justify-middle-center;
  visibility: hidden;
  padding: 0.8rem;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 60);
  }
}
.maha-finder-item-details {
  padding: 0.8rem;
  color: fadeout($black, 40);
  flex: 1;
  width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.maha-finder-item-count {
  padding: 0.8rem 0.8rem 0.8rem 0;
  color: $blue;
  span {
    font-weight: bold;
    font-size: 0.8rem;
  }
}
.maha-finder-empty {
  padding: 0.8rem 0.2rem 0.8rem 4.2rem;
  color: fadeout($black, 60);
  font-style: italic;
}

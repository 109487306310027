.paymentsfield {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  flex-direction: column;
  display: flex;
  position: relative;
}
.paymentsfield-selectall {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
}
.paymentsfield-body {
  display: flex;
  flex: 1;
}
.paymentsfield-footer {
  border-top: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}
.paymentsfield-payments {
  flex: 1;
  .paymentsfield-payment:last-child {
    border-bottom: none;
  }
}
.paymentsfield-payment {
  border-bottom: 1px solid fadeout($black, 90);
  color: fadeout($black, 40);
  cursor: pointer;
  display: flex;
  &.selected {
    background-color: fadeout($yellow, 97);
  }
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.paymentsfield-payment-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .fa-circle-o {
    color: fadeout($black, 80);
  }
  .fa-check-circle {
    color: $blue;
  }
}
.paymentsfield-payment-method {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.paymentsfield-payment-label {
  flex: 1;
  padding: 0.8rem;
  line-height: 1.2rem;
  span {
    font-size: 0.8rem;
    color: fadeout($black, 40);
  }
}
.paymentsfield-payment-type {
  padding: 0.8rem;
  line-height: 2rem;
  vertical-align: middle;
}
.paymentsfield-payment-amount {
  padding: 0.8rem;
  width: 80px;
  text-align: right;
  line-height: 2rem;
  vertical-align: middle;
}
@media (max-width: 768px) {
  .paymentsfield-body {
    max-height: calc(100vh - 21rem);
  }
}
@media (min-width: 769px) {
  .paymentsfield-body {
    max-height: 450px;
  }
  .paymentsfield-payments {
    @include vscroll;
  }
}

.maha-flash-popup {
  position: absolute;
  padding: 1rem;
  &.info {
    .maha-flash-popup-icon {
      border-color: $blue;
      .maha-icon {
        color: $blue;
      }
    }
  }
  &.warning {
    .maha-flash-popup-icon {
      border-color: $orange;
      .maha-icon {
        color: $orange;
      }
    }
  }
  &.success {
    .maha-flash-popup-icon {
      border-color: $green;
      .maha-icon {
        color: $green;
      }
    }
  }
  &.error {
    .maha-flash-popup-icon {
      border-color: $red;
      .maha-icon {
        color: $red;
      }
    }
  }
}
.maha-flash-popup-panel {
  display: flex;
  color: $white;
  background-color: lighten($black, 40);
  border-radius: 0.2rem;
  overflow: hidden;
  width: 100%;
  max-width: calc(800px - 2rem);
  margin: 0 auto;
  box-shadow: 0 0 10px fadeout($black, 75);
}
.maha-flash-popup-icon {
  @include flex-justify-middle-center;
  border-left: 5px solid $black;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    font-size: 1.6rem;
  }
}
.maha-flash-popup-message {
  padding: 0.8rem;
  flex: 1;
  display: flex;
  p {
    margin: auto 0;
  }
}
.maha-flash-popup-clear {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: $white;
    }
  };
  .maha-icon {
    color: fadeout($white, 20);
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .maha-flash-popup {
    @include translate-y-transition(-100%, 0, .25s);
    top: 0;
    left: 0;
    right: 0;
  }
}
@media (min-width: 769px) {
  .maha-flash-popup {
    @include translate-x-transition(100%, 0, .25s);
    bottom: 0;
    right: 0;
  }
}

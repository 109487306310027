.picklist {
  flex: 1;
  display: flex;
}
.picklist-options {
  flex: 1;
  @include vscroll;
}
.picklist-option {
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
  border-bottom: 1px solid fadeout($black, 90);
}

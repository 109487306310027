.channelfield-items {
  width: 100%;
  .channelfield-item {
    &:first-child {
      .channelfield-item-handle {
        border-top-left-radius: 0.4rem;
        overflow: hidden;
      }
      .channelfield-item-action {
        border-top-right-radius: 0.4rem;
        overflow: hidden;
      }
    }
    &:last-child {
      border-bottom: none;
      .channelfield-item-handle {
        border-bottom-left-radius: 0.4rem;
        overflow: hidden;
      }
      .channelfield-item-action {
        border-bottom-right-radius: 0.4rem;
        overflow: hidden;
      }
    }
  }
}
.channelfield-empty {
  border-bottom: 1px solid fadeout($black, 90);
  color: fadeout($black, 70);
  padding: 0.8rem;
}
.channelfield-item {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  display: flex;
}
.channelfield-item-handle {
  @include flex-justify-middle-center;
  border-right: 1px solid fadeout($black, 90);
  background-color: $white;
  flex: 0 0 2rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }
}
.channelfield-item-field {
  background-color: $white;
  padding: 0;
  border-right: 1px solid fadeout($black, 90);
  flex: 1;
  & > div,
  .maha-input {
    border: none;
    border-radius: 0;
  }
}
.channelfield-item-action {
  @include flex-justify-middle-center;
  background-color: $white;
  flex: 0 0 3rem;
  cursor: pointer;
  .maha-icon {
    color: fadeout($black, 80);
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}
.channelfield-add {
  display: flex;
}
.channelfield-add-spacer {
  @include flex-justify-middle-center;
  flex: 0 0 2rem;
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.channelfield-add-field {
  flex: 1;
  padding: 0;
  .maha-addressfield,
  .maha-input {
    border-radius: 0;
    border-bottom: none;
    border-left: none;
    border-top: none;
  }
  .error {
    font-size: 0.8rem;
    margin: 0.4rem 0 0 0.4rem;
    color: $red;
  }
  input {
    border-radius: 0;
  }
}
.channelfield-add-button {
  @include flex-justify-middle-center;
  flex: 0 0 3rem;
  display: flex;
  .maha-button {
    font-weight: bold !important;
  }
}

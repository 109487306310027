.list {
  background-color: $white;
}
.list-section-title {
  padding: 0.4rem 0.8rem;
  background-color: fadeout($black, 80);
}
.list-item {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  display: flex;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.list-item-details {
  display: flex;
  flex: 1;
}
.list-item-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 80);
  }
}

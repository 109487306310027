.maha-stack {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
}
.maha-stack-card {
  transition: transform .25s ease-in-out 0s;
  background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.maha-stack-card-cover {
  transition: opacity .25s ease-in-out 0s;
  background-color: $black;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

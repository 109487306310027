.maha-tablefield {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  flex-direction: column;
  border-radius: 0.4rem;
  display: flex;
  input {
    border: none !important;
    background: none !important;
    padding: 0.8rem !important;
    line-height: 1.8rem !important;
  }
}
.maha-tablefield-header {
  .maha-tablefield-row {
    background-color: fadeout($black, 97);
  }
  .maha-tablefield-column {
    padding: 0.8rem;
    font-weight: bold;
  }
}
.maha-tablefield-body {
  .maha-tablefield-row {
    background-color: $white;
  }
}
.maha-tablefield-row {
  flex: 1;
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
  &.dragging .maha-tablefield-handle {
    cursor: grabbing;
  }
}
.maha-tablefield-handle {
  @include flex-justify-middle-center;
  border-right: 1px solid fadeout($black, 90);
  flex: 0 0 2.4rem;
  padding: 0.6rem;
  cursor: grab;
  color: fadeout($black, 70);
  @include hover {
    color: fadeout($black, 40);
  };
}
.maha-tablefield-actions {
  @include flex-justify-middle-center;
  flex: 0 0 3rem;
  padding: 0.6rem;
  cursor: pointer;
  color: fadeout($black, 70);
  @include hover {
    color: fadeout($black, 40);
  };
}
.maha-tablefield-column {
  flex: 1;
  border-right: 1px solid fadeout($black, 90);
}
.maha-tablefield-footer {
  .maha-tablefield-row {
    border: none;
  }
}

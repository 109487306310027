.authentication-strategy-token {
  display: flex;
}
.authentication-strategy-token-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  img {
    width: 2.6rem;
  }
}
.authentication-strategy-token-label {
  padding: 0.8rem 0.8rem 0.8rem 0;
  color: fadeout($black, 60);
  strong {
    color: $black;
  }
}

.maha-email-new {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.maha-email-new-header-row {
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
  .maha-email-new-header-row-tool:last-child {
    border-right: none;
  }
  .maha-recipientfield,
  .maha-dropdown .ui.selection.dropdown {
    border: none;
    padding: 0;
  }
  .dropdown.icon {
    display: none;
  }
  .maha-input {
    border: none;
  }
}
.maha-email-new-header-row-label {
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.maha-email-new-header-row-input {
  flex: 1;
}
.maha-email-new-header-row-tool {
  border-right: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
  flex: 1;
  .maha-button {
    line-height: 1.8rem;
  }
}
.maha-email-new-header-row-extra {
  flex: 0 0 50px;
  display: flex;
  .maha-dropdown {
    width: 100%;
  }
  .token {
    text-align: center;
  }
}
.maha-email-new-header-row-button {
  @include flex-justify-middle-center;
  flex: 0 0 50px;
  cursor: pointer;
  padding: 0.8rem;
  .maha-icon {
    color: fadeout($black, 80);
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 60);
    }
  };
}
.maha-email-new-body {
  border-bottom: 1px solid fadeout($black, 80);
  overflow: hidden;
  flex: 1;
  padding: 0;
  display: flex;
  .email-composer {
    display: flex;
    flex: 1;
  }
  .quill {
    display: flex;
    flex: 1;
  }
  .ql-editor {
    padding: 0.8rem;
  }
}
.maha-email-account {
  display: flex;
}
.maha-email-account-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    font-size: 1.4rem;
    color: $red;
  }
}
.maha-email-account-label {
  padding: 0.8rem;
  flex: 1
}

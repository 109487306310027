.crm-timeline-date {
  text-align: center;
  margin-bottom: 0.8rem;
}
.crm-timeline-card {
  border: 1px solid fadeout($black, 90);
  border: 1px solid fadeout($black, 90);
  border-radius: .5rem;
  background-color: $white;
  overflow: hidden;
  margin-bottom: 0.8rem;
  &.expanded {
    .crm-timeline-card-header-toggle {
      .maha-icon {
        transform: rotate(90deg);
      }
    }
  }
}
.crm-timeline-card-header {
  background-color: fadeout($black, 98);
  display: flex;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 95);
  };
}
.crm-timeline-card-header-toggle {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    color: fadeout($black, 80);
    transition: transform .15s ease-in-out;
  }
}
.crm-timeline-card-header-avatar {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .contact-avatar-token,
  .maha-avatar {
    width: 2.4rem;
    height: 2.4rem;
  }
}
.crm-timeline-card-header-details {
  flex: 1;
  padding: 0.8rem;
}
.crm-timeline-card-header-program {
  color: $blue;
  .maha-logo {
    display: inline-block;
    margin: 0 5px -1px 0;
    overflow: hidden;
    width: 13px;
  }
  .maha-logo-wrapper {
    border-radius: 2px;
  }
  .maha-logo-text {
    font-size: 0.6rem;
    line-height: 1.6rem;
  }
}
.crm-timeline-card-header-timestamp {
  color: fadeout($black, 60);
  font-size: 0.8rem;
  margin-right: 0.4rem;
}
.crm-timeline-card-header-tasks {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  flex: 0 0 2rem;
  .maha-icon {
    color: fadeout($black, 60);
  }
}
.crm-timeline-card-section-body {
  border-top: 1px solid fadeout($black, 90);
  color: fadeout($black, 50);
  line-height: 1.6rem;
  padding: 0.8rem;
  & > div {
    flex: 1;
  }
  ul {
    margin: 0 0 0 1.3rem;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0;
  }
  table {
    tr td:first-child {
      width: 180px;
    }
  }
}
.crm-timeline-card-section-footer {
  .maha-comments {
    border-top: 1px solid fadeout($black, 90);
  }
}

.campaigns-social-schedule-month {
  grid-auto-rows: 1fr;
  display: grid;
  flex: 1;
}
.campaigns-social-schedule-month-week {
  grid-template-columns: repeat(7, minmax(0, 1fr));
  display: grid;
  border-bottom: 1px solid fadeout($black, 90);
  &:last-child {
    border-bottom: none;
  }
}
.campaigns-social-schedule-month-day {
  border-right: 1px solid fadeout($black, 90);
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  &:last-child {
    border-right: none;
  }
  &.hover {
    background-color: fadeout($blue, 95);
    * {
      pointer-events: none;
    }    
  }
}
.campaigns-social-schedule-month-weekday {
  text-align: center;
  text-transform: uppercase;
  color: fadeout($black, 40);
  font-size: 0.8rem;
}
.campaigns-social-schedule-month-number {
  text-align: center;
  margin-bottom: 0.4rem;
  span {
    white-space: nowrap;
    font-size: 0.8rem;
    line-height: 1.8rem;
    display: inline-block;
    border-radius: 1rem;
    width: 1.8rem;
    height: 1.8rem;
    text-align: center;
    &.today {
      background-color: $blue;
      color: $white;      
    }
  }
}
.campaigns-social-schedule-month-content {
  overflow: hidden;
  flex: 1;
}
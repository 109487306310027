.finance-cardfield {
  flex: 1;
  display: flex;
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  display: flex;
  &:focus {
    outline: none !important;
    border-color: $lightblue;
    box-shadow: none;
  }
  &.disabled {
    background-color: fadeout($black, 97);
    color: fadeout($black, 90);
    * {
      color: fadeout($black, 90) !important;
    }
  }
  input {
    flex: 1;
    border: none !important;
    border-radius: 0 !important;
    background: none !important;
    padding: 0.8rem 0.6rem !important;
  }
}
.finance-cardfield-input {
  flex: 1;
  display: flex;
}
.finance-cardfield-icon {
  @include flex-justify-middle-center;
  padding: 0.6rem 0 0.6rem 0.6rem;
  flex: 0 0 2rem;
  .maha-icon {
    font-size: 1.6rem;
    color: fadeout($black, 70);
  }
  img {
    width: 32px;
  }
}

.finance-cardfield-number {
  flex: 1;
}
.finance-cardfield-expiration {
  flex: 0 0 5rem;
}
.finance-cardfield-cvv {
  flex: 0 0 3.5rem;
}
.finance-cardfield-clear {
  @include flex-justify-middle-center;
  padding: 0.6rem;
  flex: 0 0 2rem;
  cursor: pointer;
  .maha-icon {
    color: fadeout($black, 80);
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 70);
    }
  };
}

.maha-payment-ach {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  padding: 0.8rem;
  border-radius: 0.4rem;
  .maha-form {
    margin: -0.8rem;
  }
}
.maha-payment-mandate {
  margin: 0.8rem 0 1.6rem !important;
}

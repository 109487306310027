.maha-navigation {
  background-color: $white;
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;
}
.maha-navigation-panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-navigation-header {
  flex: 0 0 3.5rem;
  background-color: $darkblue;
  cursor: pointer;
  display: flex;
  .maha-logo {
    width: 1.6rem;
    height: 1.6rem;
  }
  .maha-logo-wrapper {
    border-radius: 0 !important;
  }
}
.maha-navigation-header-back,
.maha-navigation-header-next {
  @include flex-justify-middle-center;
  color: $white;
  line-height: 1rem;
}
.maha-navigation-header-back {
  display: flex;
  text-align: left;
  padding: 0.8rem;
}
.maha-navigation-header-title,
.maha-navigation-header-team {
  color: $white;
  flex: 1;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
}
.maha-navigation-header-team-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
.maha-navigation-header-title {
  padding: 0.8rem 0;
}
.maha-navigation-header-team {
  padding: 0.8rem 0;
  cursor: pointer;
}
.maha-navigation-header-next {
  flex: 0 0 3.5rem;
  display: flex;
  text-align: right;
  cursor: pointer;
}
.maha-navigation-body {
  display: flex;
  flex: 1;
  .maha-finder {
    .maha-finder-item-icon {
      .maha-icon {
        font-size: 1.1rem;
      }
    }
  }
}

.maha-navigation-account {
  flex: 1;
  display: flex;
  line-height: 1.4rem;
  overflow: hidden;
  cursor: pointer;
  color: fadeout($black, 40);
}
.maha-navigation-account-logo {
  position: relative;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    height: 1.6rem;
    width: 1.6rem;
  }
  .maha-logo-wrapper {
    border-radius: 0.4rem;
  }
}
.maha-navigation-account-title {
  padding: 0.8rem;
  line-height: 1.6rem;
  flex: 1;
}

.maha-account {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $white;
}
.maha-account-footer  {
  padding: 0.8rem;
}
.maha-account-identity  {
  background-color: $darkblue;
  padding: 2.5rem 0;
  .maha-avatar {
    width: 125px;
    margin: 0 auto 20px;
    .maha-avatar-text {
      font-size: 60px;
      line-height: 60px;
    }
  }
  h2 {
    text-align: center;
    margin: 0;
    color: $white;
  }
  p {
    text-align: center;
    margin: 0;
    color: fadeout($white, 30);
  }
}
.maha-account-tasks {
  flex: 1;
  @include vscroll;
}
.maha-account-task {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  @include hover {
    background-color: fadeout($black, 95)
  };
  display: block;
  padding: 0.8rem;
  cursor: pointer;
  user-select: none;
  .maha-icon {
    color: fadeout($black, 40);
    font-size: 18px;
    margin-right: 10px;
  }
}


.maha-pause {
  color: fadeout($black, 40);
  .maha-icon {
    margin-right: 0.3rem;
  }
  .button {
    margin-top: 1.6rem;
  }
}
.maha-desktop {
  span {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
.maha-desktop-panel {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  color: fadeout($black, 40);
  display: flex;
  margin-bottom: 0.8rem;
  .maha-icon {
    margin-right: 0.3rem;
  }
  &.unknown {
    border-left: 5px solid $blue;
    strong {
      color: $blue;
    }
  }
  &.denied {
    border-left: 5px solid $red;
    strong {
      color: $red;
    }
  }
  &.granted {
    border-left: 5px solid $green;
    strong {
      color: $green;
    }
  }
}
.maha-desktop-panel-icon {
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    font-size: 3rem;
  }
}
.maha-desktop-panel-label {
  flex: 1;
  padding: 0.8rem;
}
.maha-preferences {
  background-color: $white;
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  overflow: hidden;
  &:focus {
    outline: none !important;
    border-color: $lightblue;
    border-radius: .28571429rem;
    background: $white;
    box-shadow: 0 0 0 0 fadeout($black, 75) inset;
  }
  .maha-preference:last-child {
    border-bottom: none;
  }
}
.maha-preference {
  border-bottom: 1px solid fadeout($black, 90);
  @include hover {
    background-color: fadeout($black, 97);
  };
  cursor: pointer;
  display: flex;
}
.maha-preference-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  flex: 0 0 2rem;
  .maha-icon {
    font-size: 1.2rem;
  }
  .fa-square-o,
  .fa-circle-o {
    color: fadeout($black, 90);
  }
  .fa-check-square,
  .fa-check-circle {
    color: $blue;
  }
  .fa-bell {
    color: $red;
  }
}
.maha-preference-label {
  flex: 1;
  padding: 0.8rem;
  color: fadeout($black, 40);
  strong {
    color: $black;
  }
}
.maha-sound-token {
  display: inline-block;
  background-color: fadeout($black, 90);
  padding: 0.2rem 0.6rem;
  border-radius: 0.3rem;
  color: $blue;
  span {
    text-decoration: none;
  }
  @include hover {
    background-color: fadeout($black, 85);
  };
  .maha-icon {
    color: fadeout($black, 50);
  }
  @media (max-width: 768px) {
    margin-top: 0.3rem;
  }
  @media (min-width: 769px) {
    margin-left: 0.3rem;
  }

}
.maha-options {
  @include vscroll;
  .maha-option:last-child {
    border-bottom: none;
  }
  &:focus {
    outline: none !important;
    border-color: $lightblue;
    border-radius: .28571429rem;
    background: $white;
    box-shadow: 0 0 0 0 fadeout($black, 75) inset;
  }

}
.maha-option {
  border-bottom: 1px solid fadeout($black, 97);
  @include hover {
    background-color: fadeout($black, 95);
  };
  display: flex;
  cursor: pointer;
}
.maha-option-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    color: fadeout($black, 50)
  }
}
.maha-option-label {
  padding: 0.8rem;
  position: relative;
  flex: 1;
  .fa-check {
    color: $green;
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
  }
}
.maha-times {
  @include vscroll;
}
.maha-time {
  border-bottom: 1px solid fadeout($black, 97);
  @include hover {
    background-color: fadeout($black, 95);
  };
  padding: 0.8rem;
  position: relative;
  cursor: pointer;
  .fa-check {
    color: $green;
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
  }
}

.maha-attachments {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-attachments-main {
  flex: 1;
  display: flex;
}
.maha-attachments-new {
  display: flex;
  flex-wrap: wrap;
}
.maha-attachments-new-source {
  flex: 1 0 50%;
  padding: 0.8rem;
  text-align: center;
  img {
    width: 5rem;
    display: inline-block;
  }
}
.maha-attachments-review-panel {
  background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
}
.maha-attachments-list-items {
  flex: 1;
}
.maha-attachments-list-item {
  border-bottom: 1px solid fadeout($black, 95);
  padding: 1;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  @include hover {
    background-color: fadeout($black, 97);
  };
  &.selected {
    background-color: fadeout($blue, 90);
    color: darken($blue, 10);
  }
  &.disabled {
    color: fadeout($black, 80);
    cursor: auto;
    .maha-icon {
      color: fadeout($black, 80);
    }
  }
  &.over {
    box-shadow: inset 0 0 0 2px $blue;
  }
  &.dragging {
    opacity: 0.4;
  }
}
.maha-attachments-list-item-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  text-align: center;
  position: relative;
  .fa-hdd-o { color: $blue; }
  .fa-folder { color: $yellow; }
  .fa-handshake-o { color: $teal; }
  .fa-star { color: $yellow; }
  .fa-trash { color: $purple; }
  .maha-asset-thumbnail {
    position: relative;
    flex: 1;
    width: 1.3rem;
    height: 1.3rem;
    .maha-image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}
.maha-attachments-list-item-service {
   border-radius: 0.4rem;
   position: absolute;
   display:flex;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-size: cover;
   background-color: fadeout($white, 90);
   img {
     width: .8rem;
     height: .8rem;
     border-radius: .2rem;
     position: absolute;
     bottom: .8rem;
     right: -.4rem;
   }
}
.maha-attachments-list-item-name {
  padding: 0.8rem;
  flex: 1;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .ui.progress {
    margin: 0;
  }
  span {
    font-size: 0.9rem;
    color: fadeout($black, 60);
    &.failed {
      color: $red;
    }
    &.complete {
      color: $green;
    }
  }
}
.maha-attachments-list-item-action {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  flex: 0 0 2rem;
  .fa-ellipsis-v {
    color: fadeout($black, 80);
    @include hover {
      color: fadeout($black, 50);
    };
    font-size: 1.2rem;
  }
  .fa-check {
    color: $green;
  }
  .fa-refresh {
    color: fadeout($black, 80);
  }
  .fa-chevron-right {
    color: fadeout($black, 80);
  }
}
.maha-attachments-list-item-remove {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    cursor: pointer;
    height: auto;
    color: fadeout($black, 60);
    @include hover {
      color: fadeout($black, 40);
    };
  }
}


.maha-attachments-grid-items {
  padding: 0.2rem;
  overflow: hidden;
}
.maha-attachments-grid-item {
  position: relative;
  float: left;
  cursor: pointer;
  overflow: hidden;
  margin: 0.2rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: calc(100% / 3 - 0.4rem);
  }
  @media (min-width: 769px) {
    width: calc(100% / 5 - 0.4rem);
  }
}
.maha-attachments-grid-item-preview {
  background-color: fadeout($black, 97);
  flex: 1;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.maha-attachments-grid-item-action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 2rem;
  color: $white;
  text-shadow: 0 0 2px fadeout($black, 90);
}
.maha-attachments-grid-item-icon {
  @include flex-justify-middle-center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .maha-asset-thumbnail {
    position: relative;
    display: flex;
    flex: 1;
    .maha-image {
      position: absolute;
      border-radius: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  .maha-asset-icon {
    .maha-icon {
      font-size: 4rem;
    }
    .fa-folder {
      color: $yellow;
    }
  }
}
.maha-attachments-grid-item-name {
  background-color: fadeout($black, 90);
  text-align: center;
  padding: 0.4rem 0.8rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

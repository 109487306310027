.campaigns-social-schedule-scheduled-token {
  display: inline-block;
  margin: 0.1rem;
  width: 3.0rem;
  height: 3.0rem;
  border-radius: 0.2rem;
  position: relative;
  overflow: hidden;
  cursor: grab;
  transition: box-shadow 200ms ease-in-out;
  @include hover {
    box-shadow: 0 0 6px fadeout($black, 70);
  };
  &.posted {
    cursor: pointer
  }
  .maha-mjson-screenshot {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4.0rem;
    height: 3.0rem;
    transform: translate(-50%, -50%);
  }
}
.campaigns-social-schedule-scheduled-token-time {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: $black;
  color: $white;
  text-align: center;
  font-size: 0.6rem;
  line-height: 1.1rem;
}
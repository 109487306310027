.mjson-designer-colorfield {
  border: 1px solid lighten($black, 90);
  background-color: $white;
  flex-direction: column;
  border-radius: 0.4rem;
  position: relative;
  display: flex;
  &.expanded {
    .mjson-designer-colorfield-input-action .maha-icon {
      transform: rotate(180deg);
    }
  }
  input[type=range] {
    -webkit-appearance: none;
    background: transparent;
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  .link {
    display: block;
    font-size: 0.9rem;
    margin-top: 0.4rem;
  }
  .brightness input[type=range]::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, var(--colorfield-dark) 0%, var(--colorfield-normal) 50%, var(--colorfield-light) 100%);
  }
  .brightness input[type=range]::-moz-range-track {
    background: linear-gradient(90deg, var(--colorfield-dark) 0%, var(--colorfield-normal) 50%, var(--colorfield-light) 100%);
  }
  .brightness input[type=range]::-ms-track {
    background: linear-gradient(90deg, var(--colorfield-dark) 0%, var(--colorfield-normal) 50%, var(--colorfield-light) 100%);
  }
  .opacity input[type=range]::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, var(--colorfield-transparent) 0%, var(--colorfield-opaque) 100%), repeating-conic-gradient(#DDD 0% 25%, transparent 0% 50%) 50% / 5px 5px;
  }
  .opacity input[type=range]::-moz-range-track {
    background: linear-gradient(90deg, var(--colorfield-transparent) 0%, var(--colorfield-opaque) 100%), repeating-conic-gradient(#DDD 0% 25%, transparent 0% 50%) 50% / 5px 5px;
  }
  .opacity input[type=range]::-ms-track {
    background: linear-gradient(90deg, var(--colorfield-transparent) 0%, var(--colorfield-opaque) 100%), repeating-conic-gradient(#DDD 0% 25%, transparent 0% 50%) 50% / 5px 5px;
  }
}
.mjson-designer-colorfield-controls {
  border-top: 1px solid fadeout($black, 90);
  padding: 0 0.8rem 0.8rem;
}
.mjson-designer-colorfield-input-field {
  .maha-input {
    border: none;
  }
}
.mjson-designer-colorfield-control {
  margin-top: 0.4rem;
}
.mjson-designer-colorfield-label {
  text-transform: uppercase;
  color: fadeout($black, 60);
  font-size: 0.8rem;
}
.mjson-designer-colorfield-input {
  display: flex;
}
.mjson-designer-colorfield-input-field {
  flex: 1;
}
.mjson-designer-colorfield-input-action {
  @include flex-justify-middle-center;
  padding: 0 0.8rem;
  .maha-icon {
    transition: transform .15s ease-in-out;
    color: fadeout($black, 60);
    font-size: 0.8rem;
  }
}
.mjson-designer-colorfield-summary {
  border-top: 1px solid lighten($black, 90);
  padding: 1rem;
  font-size: 0.8rem;
  line-height: 1rem;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
  span {
    text-transform: uppercase;
    color: fadeout($black, 60);
  }
}

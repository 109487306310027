.maha-modal-panel-body {
  & > .maha-menus .ui.secondary.pointing.menu .item {
    padding-top: 0;
  }
}
.maha-menus {
  background-color: $white;
  &.maha-menus-pointing {
    @include colorize(background-color, '.maha-menus-header');
  }
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-menus-item > .maha-menus-header {
  padding: 0.8rem;
}
.maha-menus-header {
  background-color: $black;
}
.maha-menus-body {
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.maha-menus-menu {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  &.transitioning {
    transition: transform .3s ease-in-out 0s;
  }
  &.right {
    transform: translateX(100%);
  }
  &.active {
    transform: translateX(0);
  }
  &.left {
    transform: translateX(-100%);
  }
}
.maha-menus-menu-content {
  width: 100%;
  flex: 1;
  display: flex;
}
.maha-menus-pointing {
  .ui.menu {
    box-shadow: none;
  }
  .ui.pointing.menu {
    .item {
      color: fadeout($white, 30) !important;
      &:hover {
        color: $white !important;
      }
      &.active {
        font-weight: normal;
        border-color: fadeout($white, 10) !important;
        color: $white !important;
      }
    }
  }
}

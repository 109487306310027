.maha-asset-thumbnail {
  & > .maha-image {
    background-size: cover;
    background-position: center;
  }
  .maha-image {
    overflow: hidden;
    border-radius: 4px;
  }
  img {
    display: block;
    width: 100%;
  }
}

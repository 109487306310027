.maha-domainfield {
  display: inline-flex !important;
  width: 450px;
  .maha-input {
    border: none;
    flex: 1;
  }
}
.maha-domainfield-subdomain {
  border-right: 1px solid fadeout($black, 90);
  flex: 1;
  display: flex;
}
.maha-domainfield-domain {
  flex: 0 0 200px;
  display: flex;
  .maha-input, .ui.selection.dropdown, .ui.dropdown .menu {
    background-color: lighten($black, 97);
  }
}
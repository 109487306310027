.mjson-designer-slidesfield-slides {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  overflow: hidden;
}
.mjson-designer-slidesfield-slide {
  position: relative;
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
  background: $white;
}
.mjson-designer-slidesfield-slide-handle {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.4rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }
}
.mjson-designer-slidesfield-slide-image {
  padding: 0.6rem 0;
  flex: 1;
  img {
    height: 125px;
    display: block;
  }
}
.mjson-designer-slidesfield-slide-action {
  cursor: pointer;
  padding: 0.8rem 0.8rem 0.8rem 0;
  display: flex;
  .maha-icon {
    margin: 0 auto;
    color: fadeout($black, 80);
  }
  &:hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  }
}
.mjson-designer-slidesfield-add {
  padding: 0.8rem;
}

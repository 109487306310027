.payment-token {
  display: inline;
  text-transform: uppercase;
  &.paid {
    color: $green;
  }
  &.canceled {
    color: $red;
  }
  &.unpaid {
    color: $red;
  }
}

.maha-tabs {
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-tabs-header {
  background-color: $darkblue;
  margin: 0;
}
.maha-tabs-header-content {
  margin: 0.8rem auto 0;
  width: 100%;
  max-width: 800px;
  img {
    display: block;
  }
}
.maha-tabs-items {
  margin: 0 auto;
  width: 100%;
  max-width: calc(800px - 2rem);
  display: flex;
  cursor: pointer;
}
.maha-tabs-item {
  flex: 1;
  padding: 0.66rem 0;
  font-size: 1rem;
  color: fadeout($white, 30) !important;
  text-align: center;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  @include hover {
    color: $white !important;
  };
  &.active {
    border-color: fadeout($white, 30);
    color: $white !important;
  }
}
.maha-tabs-body {
  flex: 1;
  position: relative;
  width: 100%;
}
.maha-tab {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  @include vscroll;
  p {
    color: fadeout($black, 50);
  }
  &.transitioning {
    transition: transform .3s ease-in-out 0s;
    display: block;
  }
  &.right {
    transform: translateX(100%);
  }
  &.active {
    transform: translateX(0);
    display: block;
  }
  &.left {
    transform: translateX(-100%);
  }
}
.maha-tab-content {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  padding: 0.8rem;
}

.maha-mjson-preview {
  background-color: #f3f3f3;
  padding: 0.8rem;
  cursor: pointer;
}
.maha-mjson-preview-inner {
  margin: 0 auto;
  max-width: 512px;
  width: 100%;
}

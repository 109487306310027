.email-interactions-token {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    list-style: none;
    margin: 0 0.4rem 0 0;
    font-size: 0.8rem;
    line-height: 1rem;
    color: fadeout($black, 60);
    &.bounced {
      color: $red;
      .fa-times-check-circle {
        color: $red;
      }
    }
    .maha-icon {
      .fa-check-circle-o {
        color: $green;
      }
    }
  }
}

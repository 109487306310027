.compact-type-token {
  text-align: center;
  padding: 0.4rem 0;
  @media (max-width: 768px) {
    padding-right: 0.4rem 0 0.4rem 0.4rem !important;
  }
}
.type-token {
  padding: 0.6rem 0.8rem;
  line-height: 1.4rem;
  .type-token-badge {
    margin-right: 0.4rem;
  }
}
.type-token-badge {
  padding: 4px;
  background-color: $black;
  border-radius: 4px;
  color: $white;
  display: inline-block;
  text-align: center;
}

.maha-notifications-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.maha-notifications-frame {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  min-height: 80px;
}
.maha-notifications-panel {
  margin: 0 auto;
  width: calc(100% - 2rem);
  @media only screen and (min-width: 769px) {
    max-width: calc(800px - 2rem)s;
  }
  position: relative;
  .maha-notifications-item {
    &:nth-child(2) { margin-top: 5px; }
    &:nth-child(3) { margin-top: 10px; }
    &:nth-child(4) { margin-top: 15px; }
    &:nth-child(5) { margin-top: 20px; }
    &:nth-child(6) { margin-top: 25px; }
    &:nth-child(7) { margin-top: 30px; }
  }
}
.maha-notifications-item {
  transform-origin: top;
  box-shadow: 0 0 10px fadeout($black, 75);
  background-color: $white;
  position: absolute;
  top: 0.8rem;
  left: 0;
  right: 0;
  display: flex;
  border-bottom: 1px solid fadeout($black, 95);
  cursor: pointer;
  @include hover {
    background-color: darken($white, 5);
  };
}
.maha-notifications-item-avatar {
  flex: 0 0 3.5rem;
  padding: 0.8rem 0 0.8rem 0.8rem;
  img {
    width: 100%;
  }
}
.maha-notifications-item-label {
  padding: 0.8rem 2.4rem 0.8rem 0.8rem;
  color: fadeout($black, 50);
  strong {
    color: $black;
  }
}
.maha-notifications-item-remove {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  cursor: pointer;
  color: fadeout($black, 40);
  @include hover {
    color: fadeout($black, 40);
  };
}

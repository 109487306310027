.phone-hold-token {
  display: inline-flex;
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  &.active .phone-hold-token-icon {
    background-color: $green;
    .maha-icon {
      color: $white;
    }
  }
}
.phone-hold-token-icon {
  @include flex-justify-middle-center;
  border-right: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  flex: 0 0 2.8rem;
  cursor: pointer;
  &:hover {
    background-color: fadeout($black, 90);
  }
}
.phone-hold-token-label {
  padding: 0.4rem 0.8rem;
  flex: 1;
  min-width: 200px;
}


.flowchart-designer-canvas {
  background-color: lighten($black, 97);
  position: relative;;
  flex: 1;
  display: flex;
  flex-direction: column;
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    box-shadow: inset 0px 5px 5px -5px fadeout($black, 80), inset -1px 0px 5px fadeout($black, 80);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
  svg.flowchart-connector {
    width: 160px;
    height: 30px;
    margin: 0 auto;
    overflow: visible;
    path {
      stroke: lighten($black, 80);
      stroke-width: 2;
      fill: transparent;
    }
    circle {
      fill: lighten($black, 80);
    }
  }
}
.flowchart-canvas-body {
  position: relative;
  overflow: hidden;
  cursor: grab;
  flex: 1;
  &.dragging {
    cursor: grabbing;
  }
}
.flowchart {
  position: absolute;
  top: 0.8rem;
  left: calc(50% + 3.2rem);
}
.flowchart-inner {
  overflow: hidden;
  padding: 1rem 0 0;
}
.flowchart-box-padding {
  position: relative;
}
.flowchart-box-highlight {
  display: none;
  border: 2px solid $teal;
  border-radius: 0.3rem;
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
}
.flowchart-box-actions {
  display: none;
  border-radius: 0.3rem;
  position: absolute;
  top: -2px;
  right: -2px;
  background-color: $teal;
  overflow: hidden;
}
.flowchart-box-action {
  padding: 0.2rem .4rem;
  cursor: pointer;
  .maha-icon {
    font-size: 0.8rem;
    color: fadeout($white, 30);
  }
  @include hover {
    .maha-icon {
      color: $white;
    }
  };
}
.flowchart-box-item {
  position: relative;
  border-radius: 0.4rem;
  border: 2px solid lighten($black, 80);
  margin: 0 auto;
  cursor: pointer;
  background-color: $white;
  transition: all .25s ease-in-out;
  display: flex;
  @include hover {
    border-color: $teal;
    .flowchart-box-actions {
      display: flex;
    }
  };
  &.active {
    border-color: $teal;
  }
  @include unselectable;
  &.expanded {
    width: 300px;
    min-height: 60px;
    .flowchart-box-more {
      display: none;
    }
  }
  &:not(.expanded) {
    width: 150px;
    min-height: 55px;
    .flowchart-box-details:not(.trigger,.ending) {
      display: none;
    }
  }
}
.flowchart-box-body {
  flex: 1;
  overflow: hidden;
}
.flowchart-box-label {
  padding: 1.2rem 0.8rem 0;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}
.flowchart-box-details {
  color: fadeout($black, 60);
  line-height: 1.2rem;
  text-align: center;
  font-size: 0.8rem;
  padding: 0 0.8rem 0.8rem;
}
.flowchart-box-more {
  color: $blue;
  line-height: 1.2rem;
  text-align: center;
  font-size: 0.8rem;
  padding: 0 0.8rem 0.8rem;
}
.flowchart-box-trigger,
.flowchart-box-ending {
  cursor: auto !important;
  @include hover {
    background-color: $white !important;
  };
}
.flowchart-box-icon {
  @include flex-justify-middle-center;
  border: 1px solid lighten($black, 80);
  border-radius: 50%;
  color: $white;
  width: 2rem;
  height: 2rem;
  line-height: 1.7rem;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  text-align: center;
  .fa-phone {
    transform: rotate(135deg);
  }
  .fa-volume-control-phone {
    transform: rotate(-45deg);
  }
  .fa-comment {
    font-size: 0.8rem;
  }
  .fa-clock-o {
    font-size: 1.2rem;
  }
}
.flowchart-dropzone {
  border-radius: 0.3rem;
  border: 2px solid lighten($black, 80);
  font-weight: bold;
  background-color: lighten($black, 95);
  color: fadeout($black, 40);
  padding: calc(1.6rem - 4px) 0.4rem;
  text-align: center;
  margin: 0 auto;
  width: 175px;
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 3.2rem;
}
.flowchart-branches-container {
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.flowchart-branches-expander {
  @include flex-justify-middle-center;
  margin: 0 auto;
  border: 2px solid lighten($black, 80);
  background-color: $white;
  border-radius: 0.3rem;
  color: #bfbfbf;
  cursor: pointer;
  width: 2.4rem;
  height: 1.2rem;
  @include hover {
    border-color: $teal;
    color: $teal;
  };
}
.flowchart-branches {
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
}
.flowchart-branch {
  flex-direction: column;
  display: flex;
  overflow: hidden;
  .flowchart-segments {
    flex-direction: column;
    overflow: hidden;
    display: flex;
    flex: 1;
  }
}
.flowchart-branch-label {
  display: flex;
}
.flowchart-branch-label-box {
  display: block;
  border: 2px solid lighten($black, 80);
  background-color: lighten($black, 90);
  color: fadeout($black, 40);
  text-align: center;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  padding: 0 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  min-height: 2.2rem;
  margin: 0 auto;
}
.flowchart-segment {
  display: flex;
  flex-direction: column;
  &.trigger {
    padding: 0;
  }
}
.flowchart-placeholder {
  display: flex;
}
.flowchart-drag-layer {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
}
.flowchart-box-add {
  position: relative;
  margin: auto;
  height: 60px;
  display: flex;
}
.flowchart-box-add-target {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.flowchart-box-add-label {
  position: absolute;
  cursor: pointer;
  border-radius: 0.3rem;
  border: 2px solid transparent;
  font-weight: bold;
  color: fadeout($black, 40);
  padding: 0 0.2rem;
  text-align: center;
  width: 150px;
  text-transform: uppercase;
  font-size: 0.8rem;
  background-color: lighten($black, 95);
  border-color: lighten($black, 80);
  top: 15px;
  margin: 0 5px;
  &:after {
    content: 'DROP HERE';
  }
}

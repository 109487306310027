.maha-input-tokens {
  flex: 1;
  padding: 0.2rem;
}
.maha-input-token {
  display: inline-flex;
  margin: 0.2rem;
  padding: 0 0.4rem;
  background-color: fadeout($blue, 90);
  border-radius: 0.4rem;
  & * {
    fill: fadeout($blue, 30);
  }
}
.maha-input-token-label {
  padding: 0.4rem;
  color: fadeout($blue, 40);
  flex: 1;
}
.maha-input-token-action {
  padding: 0.4rem 0;
  color: fadeout($blue, 80);
  cursor: pointer;
  @include hover {
    color: fadeout($blue, 60);
  };
}
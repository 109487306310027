.app-access {
  .maha-details-title {
    padding: 0;
  }
  .maha-details-item {
    .fa-check {
      color: $green;
    }
    .fa-times {
      color: $red;
    }
  }
}
.user-import-finalize {
  flex: 1;
  display: flex;
  .instructions {
    padding: .8rem .8rem 0;
    margin-bottom: 0 !important;
  }
}

.maha-handset-call-header-title {
  padding: 0.8rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: $blue;
}
.maha-handset-call-header-call {
  display: flex;
}
.maha-handset-call-header-link {
  @include flex-justify-middle-center;
  .maha-icon {
    font-size: 1.6rem;
    color: fadeout($black, 80);
  }
}
.maha-handset-call-header-program,
.maha-handset-call-header-contact {
  flex: 1;
  text-align: center;
  .maha-avatar,
  .maha-logo,
  .contact-avatar-token {
    width: 4rem;
    margin: 1rem auto;
  }
  .maha-avatar-text {
    font-size: 2rem;
  }
  h4, p {
    text-align: center;
    margin: 0;
  }
  p {
    color: fadeout($black, 60);
  }
}

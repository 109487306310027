.maha-versions {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.maha-versions-body {
  display: flex;
  flex: 1;
}
.maha-versions-footer {
  background-color: fadeout($black, 10);
  font-size: 0.8rem;
  padding: 0.4rem 0.8rem;
  color: $white;
  .fa-check {
    color: $green;
  }
  .fa-times {
    color: $red;
  }
}
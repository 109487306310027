.maha-device-badge {
  background-color: $darkblue;
  border-radius: 0.4rem;
  text-align: center;
  position: relative;
  padding: 0.8rem;
  color: $white;
  .maha-button.red {
    margin-top: 0.8rem;
  }
}
.maha-device-badge-dismiss {
  position: absolute;
  top: 0.2rem;
  right: 0.6rem;
  font-size: 0.8rem;
  color: fadeout($white, 20);
  @include hover {
    color: $white;
  };
}

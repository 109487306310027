.project-expense-type-token {
  display: flex;
  flex: 1;
  .project-expense-type-token-details {
    flex:1;
    padding: 0.8rem;;
  }
  .project-expense-type-token-control {
    @include flex-justify-middle-center;
    flex: 0 0 5rem;
    padding: 0.8rem;
  }
}

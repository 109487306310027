.maha-modal-panel-body > .maha-code {
  background: #282c34;
  margin: 0.8rem;
  flex: 1;
}
.maha-code {
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  font-size: 0.9rem;
}
.maha-code-header {
  border-radius: 0.4rem 0.4rem 0 0;
  padding: 0.8rem 0.8rem 0 0.8rem;
  background-color: #282c34;
  text-align: right;
  .maha-copytext {
    @include hover {
      .fa-clone,
      .maha-copytext-text {
        color: $white;
      }
    };
    .fa-clone,
    .maha-copytext-text {
      color: darken($white, 20);
    }
  }
}
.maha-code-body {
  border-radius: 0 0 0.4rem 0.4rem;
  overflow: hidden;
  width: 100%;
  flex: 1;
  pre {
    margin: 0;
  }
  code.hljs {
    padding: 0 0.8rem 2rem;
  }
}

.purposefield {
  overflow: hidden;
  .maha-select {
    margin: 0 !important;
  }
}
.purposefield-explanation {
  padding: 0.6rem 0;
  color: fadeout($black, 60);
  strong {
    color: $red;
  }
}

.allocations-instructions {
  color: fadeout($black, 40);
  margin-bottom: 0.8rem;
}
.allocations-inline {
  .link {
    margin-top: 0.8rem;
  }
}
.allocations-table {
  table.ui.unstackable.table {
    border-radius: 0.4rem;
    margin: 0 0 0.8rem;
  }
  thead, tfoot {
    th {
      background-color: fadeout($black, 90) !important;
    }
    td, th {
      padding: .5rem .7rem !important;
    }
  }
  tbody {
    td:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    td:nth-child(3),
    td:nth-child(4) {
      width: 2rem;
    }
  }
  tfoot {
    th {
      border-top: 1px solid fadeout($black, 90) !important;
    }
    td {
      border-top: 1px solid fadeout($black, 90) !important;
    }
  }
  .alert {
    margin-top: 0.8rem;
  }
}
.allocations-action {
  &.disabled .maha-icon {
    color: fadeout($black, 80);
  }
  text-align: center;
  cursor: pointer;
  .maha-icon {
    color: fadeout($black, 50);
  }
  @include hover {
    .maha-icon {
      color: $black;
    }
  };
}
@media (max-width: 769px) {
  .allocations tbody td {
    max-width: calc(100vw - 20rem);
  }
}

.truevail-datesfield {
  table {
    border-top: 1px solid fadeout($black, 90);
    border-left: 1px solid fadeout($black, 90);
    background-color: $white;
    font-size: 0.8rem;
  }
  td {
    border-bottom: 1px solid fadeout($black, 90);
    border-right: 1px solid fadeout($black, 90);
    padding: 0.4rem;
  }
  span {
    margin: auto;
    text-align: center;
    display: block;
    line-height: 2rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
  thead td {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }
  td.selected span {
    background-color: fadeout($blue, 85) !important;
    transition: all 250ms ease-in-out;
  }
  td.today span {
    font-weight: bold;
  }
  td.invalid {
    color: fadeout($black, 80);
  }
  td.valid {
    cursor: pointer;
    @include hover {
      span {
        background-color: fadeout($black, 97);
      }
    };
  }
}
.truevail-datesfield-action {
  cursor: pointer;
  color: fadeout($black, 60);
  @include hover {
    color: fadeout($black, 20);
  };
}
.truevail-datesfield-summary {
  margin-top: 0.8rem;
  color: $blue;
}
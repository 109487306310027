.truevail-social-batch-campaigns {
  display: flex;
  flex: 1;
}
.truevail-social-batch-campaigns-unassigned {
  flex: 0 0 400px;
  border-right: 1px solid fadeout($black, 90);
  flex-direction: column;
  display: flex;
}
.truevail-social-batch-campaigns-unassigned-header {
  border-bottom: 1px solid fadeout($black, 90);
  border-radius: 0;
  border: none;
}
.truevail-social-batch-campaigns-unassigned-body {
  display: flex;
  flex: 1;
  .maha-search {
    flex: 1;
  }
}
.truevail-social-batch-campaigns-unassigned-campaign {
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  }
}

.truevail-social-batch-campaigns-assigned {
  display: flex;
  flex: 1;
}
.truevail-social-batch-campaigns-assigned-campaigns {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.truevail-social-batch-campaigns-assigned-campaigns-body {
  @include vscroll;  
  flex: 1;
  .truevail-social-batch-campaigns-assigned-campaign {
    border-bottom: 1px solid fadeout($black, 90);
  }  
}
.truevail-social-batch-campaigns-assigned-campaigns-footer {
  border-top: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}

.truevail-social-batch-campaigns-assigned-campaign {
  background-color: $white;
  transition: opacity 250ms ease-in-out;
  &.expanded-exit-active {
    opacity: 0;
  }
  &.expanded-exit {
    opacity: 1;
  }
  display: flex;
}
.truevail-social-batch-campaigns-assigned-campaign-handle {
  @include flex-justify-middle-center;
  padding: 0.4rem 0 0.4rem 0.8rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }
}
.truevail-social-batch-campaigns-assigned-campaign-thumbnail {
  @include flex-justify-middle-center;
  padding: 0.4rem 0 0.4rem 0.8rem;
  flex: 0 0 80px;
  & > * {
    max-width: 100%;
    max-height: 100%;
  }
}
.truevail-social-batch-campaigns-assigned-campaign-details {
  padding: 0.4rem 0.8rem;
  align-items: center;
  display: flex;
  flex: 1;
}
.truevail-social-batch-campaigns-assigned-campaign-action {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.8rem 0.4rem 0;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.pinsfield {
  &.section .pinsfield-pin-icon .maha-icon {
    color: $teal;
  }
  &.row .pinsfield-pin-icon .maha-icon {
    color: $violet;
  }
  &.column .pinsfield-pin-icon .maha-icon {
    color: $purple;
  }
  &.block .pinsfield-pin-icon .maha-icon {
    color: $pink;
  }
}
.pinsfield-pins {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  overflow: hidden;
  .pinsfield-pin:last-child {
    border-bottom: none;
  }
}
.pinsfield-pin {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: $white;
  display: flex;
}
.pinsfield-pin-handle {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }
}
.pinsfield-pin-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    font-size: 1.4rem;
    color: $red
  }
}
.pinsfield-pins-empty {
  padding: 0.8rem;
  color: fadeout($black, 60);
}
.pinsfield-pin-label {
  line-height: 1.2rem;
  padding: 0.8rem;
  flex: 1;
  span {
    font-size: 0.9rem;
    color: fadeout($black, 60);
  }
}
.pinsfield-pin-action {
  padding: 0.8rem 0.8rem 0.8rem 0;
  display: flex;
  cursor: pointer;
  .maha-icon {
    color: fadeout($black, 80);
    margin: 0 auto auto;
  }
  &:hover .maha-icon {
    color: fadeout($black, 60);
  }
}
.pinsfield-pin-add {
  padding: 0.8rem 1.2rem;
}

.maha-codefield {
  height: 200px;
  position: relative;
  border: 1px solid fadeout($black, 90);
  border-radius: 5px;
  overflow: hidden;
  .ace_editor {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.maha-tokenfield {
  flex-direction: column;
  .maha-input {
    border: none;
  }
}
.maha-tokenfield-token {
  background-color: fadeout($blue, 95);
  border-top: 1px solid fadeout($black, 90);
  padding: 0.4rem 0.8rem;
  font-size: 0.8rem;
  color: $blue;
  max-width: calc(100%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.maha-tokenfield-token-text {
  color: $blue;
}

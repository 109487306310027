.maha-modal-panel {
  @include colorize(background-color, '> .maha-modal-panel-header');
  @include colorize(background-color, '.maha-tabs-header');
  @include colorize(background-color, '.maha-menus-pointing .maha-menus-header');
  flex: 1;
  display: flex;
  flex-direction: column;
  background: $white;
}
.maha-modal-panel-header {
  flex: 0 0 3.5rem;
  background-color: $darkblue;
  position: relative;
  .maha-modal-panel-header-navigation:first-child {
    left: 0;
  }
  .maha-modal-panel-header-navigation:last-child {
    right: 0;
  }
}
.maha-modal-panel-header-navigation {
  position: absolute;
  top: 0;
  display: flex;
}
.maha-modal-panel-header-navigation-item {
  @include flex-justify-middle-center;
  min-width: 2.5rem;
  color: fadeout($white, 10);
  padding: 0.8rem;
  height: 3.5rem;
  position: relative;
  line-height: 1.8rem;
  @include hover {
    color: $white;
  };
  .maha-icon {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
  &.disabled {
    color: fadeout($white, 40);
  }
}
.maha-modal-panel-header-navigation-button {
  @include flex-justify-middle-center;
}
.maha-modal-panel-header-title {
  flex: 1;
  color: $white;
  padding: 0.4rem 0;
  text-align: center;
  line-height: 2.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 6rem);
}
.maha-modal-panel-instructions {
  padding: 0.8rem;
  color: fadeout($black, 40);
  line-height: 1.8rem;
}
.maha-modal-panel-alert {
  padding: 0.8rem;
  background-color: fadeout($red, 90);
  border-bottom: 1px solid fadeout($black, 90);
  line-height: 1.8rem;
  color: fadeout($black, 40);
  text-align: center;
  strong {
    color: $red;
  }
}
.maha-modal-panel-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}
.maha-modal-panel-content {
  flex: 1;
  &.padded {
    padding: 0.8rem;
  }
  &.scrollable {
    @include vscroll;
  }
}
.maha-modal-panel-inner {
  @include vscroll;
  flex: 1;
}
.maha-modal-panel-footer {
  padding: 0.8rem 0.4rem;
  border-top: 1px solid fadeout($black, 90);
  display: flex;
}

.maha-generate-field {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  .link {
    font-size: 0.8rem;
    display: block;
  }
  .fa-square {
    font-size: 0.8rem;
  }
}
.maha-generate-button {
  padding: 0 0.8rem 0.8rem;
}
.maha-generate-output {
  padding: 0 0.8rem 0.8rem;
}
.maha-generate-content {
  border: 2px dashed fadeout($black, 90);
  textarea {
    color: fadeout($black, 40) !important;
    padding: 0.8rem !important;
    border: none !important;
  }
}
.maha-generate-output-status {
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
  color: fadeout($black, 60);
  font-style: italic;
}
.import {
  flex: 1;
  display: flex;
}
.import-intro {
  flex: 1;
  display: flex;
  flex-direction: column;
  h3 {
    margin: 0;
  }
  p {
    color: fadeout($black, 40);
  }
  .button {
    margin: 0;
    .maha-icon {
      margin-left: 5px;
    }
  }
  .maha-details {
    border: 1px solid fadeout($black, 90);
    border-radius: 0.4rem;
  }
  .fa-file-excel-o {
    color: $green;
    font-size: 3rem;
  }
}
.import-intro-template {
  padding: 0.8rem;
}
.import-intro-template,
.import-intro-incomplete {
  border-top: 1px solid fadeout($black, 95);
}

.import-intro-incomplete-header {
  position: relative;
  margin-bottom: 0.8rem;
  p {
    margin: 0;
    display: inline;
  }
}
.import-intro-deleteall {
  position: absolute;
  right: 0;
}
.import-intro-start {
  padding: 0.8rem;
  border-bottom: 1px solid fadeout($black, 95);
}
.import-intro-incomplete {
  padding: 0.8rem;
  flex: 1;
  @include vscroll;
  .maha-details-item.maha-details-item-link {
    display: flex;
  }
}

.import-mappings {
  padding: 0.8rem;
  flex: 1;
  @include vscroll;
  .maha-button {
    cursor: pointer;
    @include hover{
      .maha-icon {
        color: fadeout($black, 40);
      }
    };
    .maha-icon {
      color: fadeout($black, 80);
    }
  }
}

.import-parsing {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: .8rem;
  .success, .error {
    font-size:2rem;
    flex:1;
    margin:auto;
    text-align: center;
    font-weight: bold;
  }
  .maha-progress {
    margin: auto;
  }
}

.import-parsing .maha-progress h3:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px;
  padding-right: 4px;
  position:absolute;
}

.maha-import-preview {
  flex: 1;
  display: flex;
  flex-direction: column;
  label {
    display: block;
    text-transform: uppercase;
  }
}
.maha-import-preview-header {
  flex: 0 0 6rem;
  background-color: $darkblue;
  border-bottom: 1px solid fadeout($black, 90);
  padding: 0 0.4rem;
  @media (max-width: 375px) {
    flex-direction: column;
    flex: 0 0 6rem;
  }
  @media (min-width: 376px) {
    flex: 0 0 3rem;
  }
  display: flex;
  label {
    display: none;
  }
}
.maha-import-preview-header-item {
  padding: 0 0.4rem;
  flex: 1;
  text-align: center;
}
.maha-import-preview-result {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-import-preview-pager {
  margin: 0.8rem;
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: flex;
  .maha-import-preview-pager-item {
    &:nth-child(1),
    &:nth-child(3) {
      cursor: pointer;
      padding: 0.8rem;
      .maha-icon {
        color: fadeout($black, 80)
      }
      @include hover {
        .maha-icon {
          color: fadeout($black, 40)          
        }
      };
    }
    &:nth-child(2) {
      color: fadeout($black, 40);
      text-align: center;
      padding: 0.8rem;
      flex: 1;
    }
  }
}
.maha-import-preview-body {
  @include vscroll;
  flex: 1;
  padding: 0.8rem;
}
.maha-import-preview-errors {
  background-color: fadeout($red, 90);
  margin: 0 0 0.8rem;
  border-radius: 0.4rem;
  padding: 0.8rem;
  color: $red;
  p {
    margin: 0 0 0.8rem;
  }
}
.maha-import-preview-record {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  width: 100%;
  tr {
    border-bottom: 1px solid fadeout($black, 90);
  }
  th {
    border-right: 1px solid fadeout($black, 90);
    background-color: fadeout($black, 97);
    padding: 0.4rem;
    overflow: hidden;
    text-align: left;
    width: calc(100% / 3);
    word-break: break-all;
  }
  td {
    word-break: break-all;
    padding: 0.4rem;
    color: fadeout($black, 50);
  }
}
.maha-import-preview-chooser {
  border: 1px solid $white;
  border-radius: .25rem;
  .maha-import-preview-chooser-item:last-child {
    border-right: none;
  }
  overflow: hidden;
  display: flex;
}
.maha-import-preview-chooser-item {
  flex: 1;
  border-right: 1px solid $white;
  padding: 0.4rem;
  font-size: 0.8rem;
  text-align: center;
  cursor: pointer;
  color: $white;
  &.active {
    background-color: $white;
    color: $darkblue;
  }
}

.import-processing {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: .8rem;
  .success, .error {
    font-size:2rem;
    flex:1;
    margin:auto;
    text-align: center;
    font-weight: bold;
  }
  .maha-progress {
    margin: auto;
  }
}

.import-processing .maha-progress h3:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px;
  padding-right: 4px;
  position:absolute;
}

.import-summary {
  flex: 1;
}
.import-summary-item {
  display: flex;
  border-bottom: 1px solid fadeout($black, 95);
}
.import-summary-item-icon {
  display: flex;
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.import-summary-item-icon-circle {
  margin: 0 auto auto;
  display: flex;
  border-radius: 50%;
  padding: 0.3rem;
  &.green {
    background-color: $green;
  }
  &.red {
    background-color: $red;
  }
  &.teal {
    background-color: $teal;
  }
  .maha-icon {
    line-height: 1.4rem;
    font-size: 1rem;
    margin: auto;
    color: $white;
  }
}
.import-summary-item-label {
  line-height: 2rem;
  padding: 0.8rem;
  flex: 1;
  ul {
    margin: 0;
    padding: 0 0 0 1.4rem;
  }
  li {
    color: fadeout($black, 40);
    line-height: 1.4rem;
  }
}
.import-summary-item-action {
  display: flex;
  padding: 0.8rem;
  .ui.button {
    width: 6rem;
    margin: 0 0 auto 0.4rem;
  }
}.import-validating {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: .8rem;
  .import-validating-success-title {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 20px;
  }
  .totals-row {
    padding: .8rem;
    text-align: center;
    .value {
      font-weight:bold;
      font-size:24px;
    }
    .button-row {
      display:flex;
      a.button {
        flex:2;
        max-width: 48%;
        margin: 10px auto;
      }
    }
  }
  .ui.button.run-import {
    font-size: 1.5rem;
    max-width: 75%;
    margin: 30px auto 0;
    background: #21ba45;
    color: $white;
  }
}


.import-mapping {
  flex: 1;
  display: flex;
  flex-direction: column;
  .import-mapping-warning {
    padding: 0.8rem;
    text-align: center;
    font-weight: bold;
  }
  .maha-table {
    flex: 1;
    position: relative;
    @include vscroll;
  }
}


.import-item {
  line-height: 1.4rem;
  display: flex;
}
.import-item-icon {
  padding: 0.8rem 0 0.8rem 0.8rem;
  display: flex;
  img {
    border-radius: 0.3rem;
    overflow: hidden;
    margin: auto;
    width: 2.8rem;
  }
}
.import-item-label {
  flex: 1;
  font-size: 0.9rem;
  padding: 0.8rem;
}
.import-intro-deleteall, .import-item-remove {
  color: $blue;
  cursor: pointer;
  display: inline;
  @include hover {
    text-decoration: underline;
  };
}


.import-finalize {
  display: flex;
  flex: 1;
  padding: .8rem;

}
.import-complete {
  display: flex;
  flex: 1;
  padding: .8rem;
}


.import {
  display: flex;
  flex: 1;
  .list {
    @include vscroll;
  }
}
.import-intro .maha-details-section {
  .maha-details-item {
    border-bottom: 1px solid fadeout($black, 90);
    &:last-child {
      border: none;
    }
  }
}
.import-sources {
  display: flex;
  flex: 1;
}
.import-sources-body {
  @include vscroll;
  flex: 1;
}
.import-source {
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid fadeout($black, 95);
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.import-source-service {
  flex: 0 0 3rem;
  padding: 0.8rem;
  display: flex;
  img {
    margin: auto;
    border-radius: 0.3rem;
    overflow: hidden;
    width: 1.6rem;
    height: 1.6rem;
    display: block;
  }
  .fa-plus-circle {
    font-size: 1.85rem;
    color: $red;
  }
}
.import-source-label {
  flex: 1;
  padding: 0.8rem 0.8rem 0.8rem 0;
  line-height: 1.4rem;
  span {
    color: fadeout($black, 40);
  }
}
.import-source-proceed {
  padding: 0.8rem 0.8rem 0.8rem 0;
  display: flex;
  .maha-icon {
    color: fadeout($black, 80);
    margin: auto;
  }
}

.import-list {
  display: flex;
  border-bottom: 1px solid fadeout($black, 95);
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.import-list-label {
  flex: 1;
  padding: 0.8rem;
}
.import-list-proceed {
  padding: 0.8rem;
  display: flex;
  .maha-icon {
    margin: auto;
    color: fadeout($black, 80);
  }
}

.import-contact {
  display: flex;
  border-bottom: 1px solid fadeout($black, 95);
}
.import-contact-avatar {
  padding: 0.8rem 0 0.8rem 0.8rem;
  display: flex;
  img {
    background-color: fadeout($black, 90);
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    width: 2.6rem;
    height: 2.6rem;
    display: block;
  }
}
.import-contact-label {
  flex: 1;
  padding: 0.8rem;
}
.import-contact-proceed {
  padding: 0.8rem;
  display: flex;
  .maha-icon {
    margin: auto;
    color: fadeout($black, 80);
  }
}


@keyframes ellipsis {
  to {
    width: 1.15rem;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.15rem;
  }
}
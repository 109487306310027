.truevail-profiles-token {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
}
.truevail-profiles-token-profile {
  border-bottom: 1px solid fadeout($black, 90);
  &:last-child {
    border-bottom: none;
  }
}

.maha-composer {
  border: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  border-radius: 0.4rem;
  display: flex;
  padding: 0;
  flex: 1;
}
.maha-composer-attachments-icon {
  @include flex-justify-top-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  display: flex;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: $red;
    }
  };
  .maha-icon {
    color: fadeout($black, 60);
    font-size: 1.4rem;
  }
}
.maha-composer-input {
  flex: 1;
}
.maha-composer-camera {
  @include hover {
    .maha-icon {
      color: $red;
    }
  };
}
.maha-composer-editor {
  flex-direction: column;
  min-height: 100%;
  display: flex;
  flex: 1;
}
.maha-composer-emojis {
  .maha-icon {
    font-size: 1.4rem;
    @include hover {
      color: fadeout($black, 40);
    };
  }
}
.maha-composer-emojis {
  border-right: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  display: flex;
  text-align: center;
  cursor: pointer;
  .maha-icon {
    line-height: 1rem;
    margin: 0 auto;
    color: fadeout($black, 60);
  }
}
.maha-composer-link {
  width: 100%;
  max-width: 370px;
  position: relative;
  .fa-times {
    text-shadow: 0 0 1px fadeout($black, 25);
    position: absolute;
    color: darken($white, 5);
    right: 0.4rem;
    top: 0.4rem;
    cursor: pointer;
    @include hover {
      color: $white;
    };
  }
}

.maha-composer-textarea {
  display: flex;
  flex: 1;
}
.maha-composer-textarea-field {
  flex: 1;
  word-break: break-word;
  padding: 0.8rem;
  textarea {
    background-color: transparent;
    resize: none;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    display: block;
    overflow: hidden;
    &::-webkit-input-placeholder {
      opacity: 0.5;
    }
    &:-moz-placeholder {
      opacity: 0.5;
    }
    &::-moz-placeholder {
      opacity: 0.5;
    }
    &:-ms-input-placeholder {
      opacity: 0.5;
    }
  }
}


@media (max-width: 768px) {
  .maha-composer {
    .maha-emojis {
      display: none;
    }
  }
}
@media (min-width: 769px) {
  .maha-composer-camera {
    display: none;
  }
}

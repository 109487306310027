.certificate-token {
  display: flex;
}
.certificate-token-domains {
  padding: 0.8rem;
  .pending .maha-icon { color: fadeout($black, 80); }
  .success .maha-icon { color: $green; }
  .failed .maha-icon { color: $red; }
  .maha-icon {
    font-size: 0.6rem;
  }
}
.certificate-token-extra {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  span {
    text-transform: uppercase;
  }
}

.colortoken {
  background-color: $white;
  display: flex;
}
.colortoken-icon {
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.colortoken-swatch {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid fadeout($black, 90);
}
.colortoken-label {
  padding: 0.8rem;
  flex: 1;
}

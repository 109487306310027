.maha-asset-token {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: inline-block;
  min-width: 250px;
  overflow: hidden;
}
.maha-asset-token-click {
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-asset-token-details {
  display: flex;
  &.pdf {
    .maha-icon { color: $red; }
    .maha-asset-token-download { background-color: $red; }
  }
  &.xls {
    .maha-icon { color: $green; }
    .maha-asset-token-download { background-color: $green; }
  }
  &.doc {
    .maha-icon { color: $blue; }
    .maha-asset-token-download { background-color: $blue; }
  }
  &.ppt {
    .maha-icon { color: $orange; }
    .maha-asset-token-download { background-color: $orange; }
  }
  &.jpg, &.gif, &.png {
    .maha-icon { color: $purple; }
    .maha-asset-token-download { background-color: $purple; }
  }
}
.maha-asset-token-icon {
  position: relative;
  flex: 0 0 3rem;
  padding: 0.8rem 0.6rem;
  display: flex;
  .maha-asset-icon {
    margin: 0 auto;
  }
  .maha-icon {
    font-size: 2rem;
  }
}
.maha-asset-token-text {
  flex: 1;
  padding: 0.8rem 0.8rem 0.8rem 0;
  font-size: 0.8rem;
  line-height: 1.4rem;
}
.maha-asset-token-filename {
  color: $black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 3.2rem);
}
.maha-asset-token-filesize {
  color: fadeout($black, 60);
  float: left;
}
.maha-asset-token-links {
  clear: both;
  span {
    margin: 0 2px;
  }
}
.maha-asset-token-link {
  @include hover {
    text-decoration: underline;
  };
  cursor: pointer;
  display: inline-block;
  color: $blue;
}
.maha-asset-token-preview {
  border-top: 1px solid fadeout($black, 90);
}
.maha-asset-token-preview-frame {
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
}

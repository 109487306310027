.subscriptions-addonsfield {
  table tbody tr {
    &:not(.included) {
      input,
      td {
        color: fadeout($black, 80) !important;
      }
    }
    td:nth-child(1) {
      width: 40px;
      text-align: center;
      cursor: pointer;
      .fa-square-o {
        color: fadeout($black, 80);
      }
      .fa-check-square {
        color: $blue;
      }
    }
    td:nth-child(3) {
      width: 110px;
      padding: 1px !important;
      .maha-input {
        border: none !important;
        border-radius: 0 !important;
      }
    }
  }
}

.maha-form-segment {
  .maha-smsfield {
    margin-top: 1rem;
  }
}
.maha-smsfield {
  position: relative;
  textarea {
    box-sizing: border-box;
    border: none;
    width: 100%;
  }
}
.maha-smsfield-segments {
  position: absolute;
  right: 0;
  top: -2rem;
  color: fadeout($black, 50);
  font-size: 0.8rem;
  &.max {
    color: $red;
  }
}
.maha-smsfield-editor {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 5px;
  display: flex;
}
.maha-smsfield-editor-textarea {
  flex: 1;
  textarea {
    border: none !important;
    outline: none !important;
    resize: none !important;
  }
}
.maha-smsfield-editor-tools {
  .maha-emojis-icon {
    padding-bottom: 0;
  }
}

.subscription-service-token {
  display: flex;
  flex: 1;
}
.subscription-service-token-logo {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 1.8rem;
    font-size: 0.8rem;
  }
}
.subscription-service-token-label {
  padding: 0.8rem;
  color: fadeout($black, 40);
}

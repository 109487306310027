.maha-embed {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.maha-embed-header {
  padding: 0.8rem;
  .ui.button {
    width: 140px;
  }
}
.maha-embed-body {
  padding: 0 0.8rem 0.8rem;
  display: flex;
  flex: 1;
}
.maha-embed-code {
  background-color: #282c34;
  font-size: 0.9rem;
  width: 100%;
  flex: 1;
  pre {
    margin: 0;
  }
}

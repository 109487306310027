.access-type-token {
  display: block;
  color: $white;
  padding: 0.4rem 0.8rem;
  font-size: 11px;
  font-weight: bold;
  border-radius: 0.4rem;
  text-align: center;
  width: 8rem;
  text-transform: uppercase;
  &.manage {
    background-color: $red;
  }
  &.contribute,
  &.edit {
    background-color: $orange;
  }
  &.view {
    background-color: $green;
  }
}

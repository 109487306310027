.email-viewer-canvas {
  @include vscroll;
  flex: 1;
}
.email-viewer {
  background-color: $white;
  flex-direction: column;
  display: flex;
}
.email-viewer-header {
  position: relative;
  background-color: $blue;
  flex: 1;
  padding: 0.8rem;
  line-height: 1.8rem;
  color: $white;
  strong {
    color: $white;
  }
  .link {
    color: $white;
    &:hover {
      text-decoration: underline;
    }
  }
}
.email-viewer-header-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  .maha-icon {
    font-size: 1.2rem;
    color: $white;
  }
}
.email-viewer-header-action {
  flex: 1;
  padding: 0.8rem;
  color: fadeout($black, 60);
  cursor: pointer;
  &:hover {
    color: fadeout($black, 40);
  }
}
.email-viewer-header-contacts {
  display: inline;
  .maha-button {
    display: inline;
    &:after {
      content: ';';
      margin-right: 5px;
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}
.email-viewer-email {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: $white;
  &.expanded {
    .fa-chevron-right {
      transform: rotate(90deg);
    }
  }
}
.email-viewer-email-header {
  background-color: lighten($black, 97);
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  display: flex
}
.email-viewer-email-header-icon {
  padding: 0.8rem 0 0.8rem 0.8rem;
  flex: 0 0 2rem;
  display: flex;
  .maha-icon {
    transition: transform .15s ease-in-out;
    color: fadeout($black, 60);
    margin: auto;
    font-size: 0.8rem;
  }
}
.email-viewer-email-header-details {
  flex: 1;
}
.email-viewer-email-header-timestamp {
  font-size: 0.8rem;
  color: fadeout($black, 40);
}
.email-viewer-email-header-action {
  padding: 0.8rem;
  color: fadeout($black, 60);
  cursor: pointer;
  &:hover {
    color: fadeout($black, 40);
  }
}
.email-viewer-email-body {
  background: $white;
  iframe {
    display: block;
    border: none;
    height: 0;
  }
}
.email-viewer-email-body-buttons {
  padding: 0.4rem;
  .maha-button {
    margin: 0.4rem;
  }
}
.email-viewer-footer {
  border-top: 1px solid fadeout($black, 90);
  background: $white;
}
@media (max-width: 768px) {
  .email-viewer-email-header-details {
    padding: 0.8rem;
  }
}
@media (min-width: 769px) {
  .email-viewer-email-header-details {
    display: flex;
  }
  .email-viewer-email-header-label {
    padding: 0.8rem;
    flex: 1;
  }
  .email-viewer-email-header-timestamp {
    line-height: 2.6rem;
    padding: 0.8rem;
  }
}
@media only screen and (max-width: 1024px) {
  .email-reader-list {
    flex: 1;
  }
  .email-reader-show {
    display: none;
  }
}
@media only screen and (min-width: 1025px) {
  .email-reader-list {
    flex: 0 0 350px;
    max-width: 40%;
  }
  .email-reader-list-result {
    &.selected {
      background-color: fadeout($yellow, 90);
    }
  }
}

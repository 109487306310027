.maha-logo {
  overflow: hidden;
  border-radius: 0.4rem;
  img {
    width: 100%;
  }
}
.maha-logo-badge {
  width: 100%;
  padding-top: 100%;
  position: relative;
}
.maha-logo-wrapper {
  &.background {
    background-color: fadeout($black, 40);
  }
  border-radius: 0.4rem;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  color: $white !important;
  font-family: monospace;
  display: flex;
  overflow: hidden;
  .maha-image {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.maha-logo-text {
  margin: auto;
}

.platform-secretsfield-secrets {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  .platform-secretsfield-secret:last-child {
    border-bottom: none;
  }
}
.platform-secretsfield-secret {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.platform-secretsfield-secret-field {
  border-right: 1px solid fadeout($black, 90);
  flex: 1;
  .maha-textfield {
    border: none;
  }
}
.platform-secretsfield-secret-action {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 70);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}

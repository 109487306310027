.maha-attachments-web {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-attachments-web-header {
  background-color: fadeout($black, 97);
  border-bottom: 1px solid fadeout($black, 90);
}
.maha-attachments-web-body {
  flex: 1;
  display: flex;
}
.maha-attachments-web-preview {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-attachments-web-preview-body {
  @include flex-justify-middle-center;
  flex: 1;
  overflow: hidden;
  padding: 0.8rem;
  flex-direction: column;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
}
.maha-attachments-web-preview-footer {
  padding: 0 0.8rem 0.8rem;
}

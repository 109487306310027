.maha-range {
  overflow: hidden;
}
.maha-range-slider {
  flex: 1;
  position: relative;
  padding: 0 0.8rem;
  input {
    position: absolute;
    top: calc(50% - 2px);
    width: calc(100% - 1.6rem);
    display: block;
    &:focus {
      outline: none;
    }
    &::-webkit-slider-runnable-track {
      height: 5px;
      background: lighten($black, 90);
      border-radius: 3px;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background-color: $blue;
      width: 15px;
      height: 15px;
      border: 0;
      border-radius: 15px;
      margin-top: -5px;
    }
    &::-moz-range-track {
      background: lighten($black, 90);
    }
    &::-moz-range-thumb {
      background-color: $blue;
      width: 15px;
      height: 15px;
      border: 0;
      border-radius: 15px;
    }
    &::-ms-track {
      background: lighten($black, 90);
      border-color: $white;
      border-width: 6px 0;
      border-radius: 3px;
    }
  }
}
.maha-range-label {
  flex: 0 0 85px;
  &>div {
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-bottom: none;
  }
}

.phone-conversation-tokens-button {
  margin-bottom: 0.8rem;
}
.phone-conversation-tokens-feed {
  border: 1px solid fadeout($black, 95);
  border-radius: 0.4rem;
  background: $white;
  overflow: hidden;
}
.phone-conversation-token {
  display: flex;
  flex: 1;
}
.phone-conversation-token-avatar {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .contact-avatar-token,
  .maha-logo {
    width: 2.4rem
  }
}
.phone-conversation-token-row {
  display: flex;
  & > div:first-child {
    flex: 1;
  }
}
.phone-conversation-token-details {
  flex: 1;
  padding: 0.8rem;
  .maha-logo {
    width: 24px;
  }
  .maha-icon {
    color: fadeout($black, 60);
  }
  .maha-asset-token {
    border: 1px solid fadeout($black, 90);
    display: inline-block;
    border-radius: 0.4rem;
    width: auto;
    min-width: 150px;
    margin: 0.4rem 0.4rem 0 0;
  }
}
.phone-conversation-token-user {
  font-weight: bold;
  display: inline;
  margin-right: 0.4rem;
}
.phone-conversation-token-timestamp {
  display: inline;
  font-size: 0.8rem;
  color: fadeout($black, 60);
  display: inline;
  cursor: pointer;
  span {
    display: inline;
  }
}
.phone-conversation-token-program {
  color: $blue;
  .maha-logo {
    display: inline-block;
    margin: 0 0 -1px 0;
    overflow: hidden;
    width: 13px;
  }
  .maha-logo-wrapper {
    border-radius: 2px;
  }
  .maha-logo-text {
    font-size: 0.6rem;
    line-height: 1.6rem;
  }
}
.phone-conversation-token-recipient {
  line-height: 1.4rem;
  color: $black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phone-conversation-token-body {
  color: fadeout($black, 60);
  flex: 1;
  line-height: 1.4rem;
  height: 2.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.8rem;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.phone-conversation-token-extra {
  color: $blue;
  padding: 0.8rem 0 0.8rem 0.8rem;
  text-align: right;
}

.crm-program-chooser {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.crm-program-chooser-search {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
}
.crm-program-chooser-results {
  display: flex;
  flex: 1;
}
.crm-program-chooser-list-item {
  &:not(.disabled) {
    @include hover {
      background-color: fadeout($black, 97);
    };
    cursor: pointer;
  }
  &.disabled {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: fadeout($white, 30);
    }
  }
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.crm-program-chooser-list-item-token {
  display: flex;
  flex: 1;
}
.crm-program-chooser-list-item-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 80);
  }
}

.maha-datetimefield {
  display: inline-flex !important;
}
.maha-datetimefield-date {
  display: flex;
  align-items: middle;
  .maha-input {
    width: 120px;
  }
  &:after {
    padding: 0.6rem 0 0;
    content: '@';
    color: fadeout($black, 70);
  }
  & > * {
    border: none !important;
  }
}
.maha-datetimefield-time {
  display: flex;
  align-items: center;
  & > * {
    width: 90px;
    border: none !important;
    border-radius: 0 !important;
  }
}
.maha-datetimefield-tz {
  display: flex;
  align-items: center;
  border-left: 1px solid fadeout($black, 90);
  & > * {
    width: 110px;
    border: none !important;
    border-radius: 0 !important;
  }
}

.campaigns-social-schedule-list {
  display: flex;
  flex: 1;
}
.campaigns-social-schedule-list-container {
  @include vscroll;
  flex: 1;
}
.campaigns-social-schedule-list-item {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.campaigns-social-schedule-list-item-token {
  display: flex;
  flex: 1;
}
.campaigns-social-schedule-list-item-action {
  padding: 0.8rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
.campaigns-social-schedule-list-item-proceed {
  padding: 0.8rem;
  justify-content: center;
  align-items: center;
  display: flex;
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.drive-share {
  display: flex;
  flex: 1;
}
.drive-share-header {
  background-color: fadeout($black, 97);
  border-bottom: 1px solid fadeout($black, 95);
  .maha-asset-token {
    width: 100%;
    border: none;
    background: none;
  }
}
.drive-share-body {
  padding: 0.8rem;
  h3 {
    margin: 0;
  }
  p {
    color: fadeout($black, 40);
  }
}
.drive-share-link {
  border: 1px solid fadeout($black, 90);
  display: inline-flex;
  border-radius: 0.4rem;
  max-width: 100%;
}
.drive-share-link-text {
  flex: 1;
  padding: 0.6rem 0.8rem;
  word-break: break-all;
  overflow: hidden;
  height: 2.3rem;
}
.drive-share-link-button {
  border-left: 1px solid fadeout($black, 90);
  .ui.button {
    border-radius: 0 0.4rem 0.4rem 0;
  }
}
.drive-share-methods-item {
  display: flex;
}
.drive-share-methods-item-icon {
  padding: 0.8rem;
  .fa-envelope { background-color: $green; }
  .fa-link { background-color: $blue; }
  .maha-icon {
    color: $white;
    padding: 0.4rem;
    border-radius: 50%;
  }
}
.drive-share-methods-item-text {
  flex: 1;
  padding: 0.8rem 0;
}

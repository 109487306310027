.maha-tasks {
  flex: 1;
  display: flex;
}
.maha-tasks-overlay {
  @include opacity-transition(0, 0.5, .25s);
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
}
.maha-tasks-title {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 92);
  color: fadeout($black, 50);
  display: flex;
  .maha-tasks-title-icon {
    &:first-child {
      @include flex-justify-middle-center;
      padding: 0.8rem 0 0.8rem 0.8rem;
    }
    &:last-child {
      padding: 0.8rem 0.8rem 0.8rem 0;
    }
    .maha-icon {
      font-size: 1.2rem;
    }
  }
}
.maha-tasks-title-label {
  padding: 0.8rem;
  flex: 1;
  overflow: hidden;
  line-height: 1.6rem;
}
.maha-tasks-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: $white;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    @include translate-y-transition(100%, 0, .25s);
    max-height: calc(100vh - 1.6rem);
    bottom: 0;
    left: 0;
    right: 0;
    margin: 10px;
  }
  @media only screen and (min-width: 769px) {
    @include opacity-transition(0, 1, .25s);
    width: 320px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .maha-task,
  .maha-tasks-cancel {
    border-top: 1px solid fadeout($black, 90);
    display: block;
    padding: 15px;
    text-align: center;
    color: $blue;
    cursor: pointer;
    @include hover {
      background-color: darken($white, 5%);
    };
    &:first-child {
      border-radius: 5px 5px 0 0;
      border-top: none;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
    }
    &.disabled {
      cursor: default;
      color: fadeout($black, 80);
    }
    &.compact {
      padding: 0 !important;
    }
  }
}
.maha-tasks-list-body {
  .maha-tasks-list-item:last-child {
    border: none;
  }
}
.maha-tasks-list-item {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  @include hover {
    background: fadeout($black, 95);
  };
}

.crm-email-campaign-results {
  .ui.table {
    border: none;
  }
}
.crm-email-campaign-results-header-item {
  padding: 0.8rem;
  overflow: hidden;
  .maha-progress-bar {
    margin: 0.4rem 0;
  }
  ul {
    margin: 0;
    float: right;
    li:first-child {
      &:after {
        margin: 0 0.4rem;
        content: '|';
      }
    }
  }
  li {
    list-style: none;
    display: inline-block;
  }
}
.crm-email-campaign-results-stat {
  display: flex;
}
.crm-email-campaign-results-stat-title {
  flex: 1;
  font-weight: bold;
}
.crm-email-campaign-results-stat-percent {
  text-align: right;
}
@media (max-width: 768px) {
  .crm-email-campaign-results-header {
    .crm-email-campaign-results-header-item:last-child {
      border-bottom: none;
    }
  }
  .crm-email-campaign-results-header-item {
    border-bottom: 1px solid fadeout($black, 90%);
  }
}
@media (min-width: 769px) {
  .crm-email-campaign-results-header {
    display: flex;
  }
  .crm-email-campaign-results-header-item {
    flex: 1;
  }
}

.help-article-token {
  display: flex;
  flex: 1;
}
.help-article-token-icon {
  padding: 0.8rem 0 0.8rem 0.8rem;
  display: flex;
}
.help-article-token-icon-block {
  background-color: $blue;
  border-radius: 3px;
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  text-align: center;
  line-height: 1.6rem;
  font-size: 0.8rem;
  margin: auto;
  .maha-icon {
    margin: auto !important;
    color: $white;
  }
}
.help-article-token-label {
  flex: 1;
  padding: 0.8rem;
}

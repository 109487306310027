.maha-colorfield {
  position: relative;
  &.down {
    .maha-input {
      border-bottom-left-radius: 0;
    }
    .maha-colorfield-chooser {
      top: calc(100% - 1px);
      border-radius: 0 0 0.3rem 0.3rem;
    }
  }
  &.up {
    .maha-input {
      border-top-left-radius: 0;
    }
    .maha-colorfield-chooser {
      bottom: 100%;
      border-radius: 0.3rem 0.3rem 0 0;
    }
  }
}
.maha-colorfield-input {
  &.disabled {
    .maha-colorfield-color {
      background: lighten($black, 97) !important;
    }
  }
}
.maha-colorfield-color {
  position: relative;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  .maha-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    line-height: 1rem;
    font-size: 1rem;
    color: $yellow;
  }
}
.maha-colorfield-input {
  display: flex;
}
.maha-colorfield-selected {
  padding: 0.4rem 0 0.4rem 0.4rem;
  .maha-colorfield-color {
    border: 1px solid lighten($black, 90);
    &.null {
      background: linear-gradient(135deg, $white 0, $white 45%, $red 45%, $red 55%, $white 55%, $white 100%);
    }
  }
}
.maha-colorfield-chooser-builtin {
  .maha-colorfield-chooser-color:first-child {
    background: linear-gradient(135deg, $white 0, $white 45%, $red 45%, $red 55%, $white 55%, $white 100%);
  }
}
.maha-colorfield-value {
  flex: 1;
  display: flex;
  input {
    flex: 1;
    border: none !important;
    outline: none !important;
    background: none !important;
    padding: 0.4rem !important;
  }
}
.maha-colorfield-name {
  flex: 1;
  line-height: 2rem;
  padding: 0.4rem;
}
.maha-colorfield-chooser {
  padding: 0.4rem;
  position: absolute;
  left: 0;
  background-color: $white;
  border: 1px solid fadeout($black, 90);
  z-index: 2;
  width: calc(15rem + 2px);
}
.maha-colorfield-chooser-label {
  text-transform: uppercase;
  color: fadeout($black, 60);
  font-weight: bold;
  font-size: 0.8rem;
}
.maha-colorfield-chooser-colors {
  border-top: 1px solid fadeout($black, 90);
  border-left: 1px solid fadeout($black, 90);
  max-width: calc(14rem + 2px);
  display: inline-flex;
  flex-wrap: wrap;
}
.maha-colorfield-chooser-color {
  border-right: 1px solid fadeout($black, 90);
  border-bottom: 1px solid fadeout($black, 90);
  display: inline-flex;
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  cursor: pointer;
  &.null {
    background: linear-gradient(135deg, $white 0, $white 45%, $red 45%, $red 55%, $white 55%, $white 100%);
  }
  @include hover {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: fadeout($white, 80);
      content: ' ';
    }
  };
  .maha-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    line-height: 1rem;
  }
  .fa-check {
    font-size: 1rem;
    color: $white;
  }
  .fa-star-o {
    font-size: 0.8rem;
    color: $yellow;
  }
}

.integration-token {
  display: flex;
}
.integration-token-logo {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  img {
    width: 1.4rem;
  }
}
.integration-token-label {
  padding: 0.8rem 0;
  flex: 1;
}

.maha-htmlfield {
  flex: 1;
  background-color: $white;
  .ql-editor {
    font-size: 14px;
    p, li {
      line-height: 1.5;
    }
    li {
      padding-left: 0 !important;
    }
    p {
      margin-bottom: 0;
    }
  }
  .ql-toolbar.ql-snow {
    border-radius: 0.4rem 0.4rem 0 0;
    border-color: fadeout($black, 90) !important;
    padding: 0.4rem !important;
    button {
      width: auto !important;
    }
    .ql-picker.ql-expanded {
      .ql-picker-label {
        border-color: transparent;
        color: #06C !important;
      }
      .ql-picker-options {
        width: 172px;
      }
      .ql-picker-item {
        margin: 0;
      }
    }
  }
  .ql-container {
    border-radius: 0 0 0.4rem 0.4rem;
    border-color: fadeout($black, 90) !important;
    &:focus-visible {
      outline: none;
    }
  }
  .quill > .ql-container > .ql-editor.ql-blank::before{
    font-style: normal !important;
    color: fadeout($black, 80);
  }
}

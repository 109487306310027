.maha-prompt {
  flex: 1;
  display: flex;
}
.maha-prompt-overlay {
  @include opacity-transition(0, 0.5, .25s);
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
}
.maha-prompt-options {
  position: absolute;
  border-radius: 5px;
  background-color: $white;
  @media only screen and (max-width: 768px) {
    @include translate-y-transition(100%, 0, .25s);
    bottom: 0;
    left: 0;
    right: 0;
    margin: 10px;
  }
  @media only screen and (min-width: 769px) {
    @include opacity-transition(0, 1, .25s);
    width: 320px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .maha-prompt-title {
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid fadeout($black, 90);
    display: block;
    padding: 0.8rem;
    background-color: fadeout($black, 92);
    color: fadeout($black, 50);
    text-align: center;
  }
  .maha-prompt-message {
    display: block;
    padding: 0.8rem 1.6rem;
    text-align: center;
    border-bottom: 1px solid fadeout($black, 90);
    color: fadeout($black, 60);
  }
  .maha-prompt-item,
  .maha-prompt-cancel {
    @include hover {
      background-color: fadeout($black, 98);
    };
    display: block;
    padding: 0.8rem;
    text-align: center;
    color: #1e70bf;
    cursor: pointer;
    border-bottom: 1px solid fadeout($black, 90);
    &:last-child {
      border-bottom: none;
    }
  }
  .maha-prompt-option {
    border-bottom: 1px solid #CCC;
  }
}

.campaign-variant-token {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: flex;
  margin-bottom: 0.8rem;
}
.campaign-variant-token-icon {
  border-right: 1px solid fadeout($black, 90);
  @include flex-justify-middle-center;
  padding: 0.8rem;
  img {
    width: 2rem;
  }
}
.campaign-variant-token-details {
  padding: 0.8rem;
  flex: 1;
}
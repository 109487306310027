.mjson-designer-follow-icon-token {
  display: flex;
}
.mjson-designer-follow-icon-token-icon {
  @include flex-justify-middle-center;
  padding: 0.4rem 0 0.4rem 0.4rem;
  &.white img {
    background-color: fadeout($black, 40);
  }
  img {
    padding: 4px;
    width: 32px;
    height: 32px;
    display: block;
  }
}
.mjson-designer-follow-icon-token-details {
  flex: 1;
  line-height: 18px;
  font-size: 0.9rem;
  padding: 1rem;
}

.campaigns-social-schedule {
  background: $white;
  display: flex;
  flex: 1;
}
.campaigns-social-schedule-tasks-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: #F0F2F5;
  maha-mjson-screenshot {
    width: 100%;
  }
  p {
    text-align: center;
    margin: 0.8rem 0;
  }
}
.campaigns-social-schedule-unscheduled {
  border-right: 1px solid fadeout($black, 90);
  min-width: 300px;
  flex: 0 0 25%;
  display: flex;
}
.campaigns-social-schedule-scheduled {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.campaigns-social-schedule-scheduled-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  display: flex;
}
.campaigns-social-schedule-scheduled-header-details {
  display: flex;
  flex: 1;
}
.campaigns-social-schedule-scheduled-header-modes {
  padding: 0.4rem;
}
.campaigns-social-schedule-scheduled-header-modes-buttons {
  border: 1px solid $blue;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  .campaigns-social-schedule-scheduled-header-modes-button:last-child {
    border-right: none;
  }
}
.campaigns-social-schedule-scheduled-header-modes-button {
  border-right: 1px solid $blue;
  transition: all 250ms ease-in-out;
  background-color: $white;
  font-size: 0.8rem;
  color: $blue;
  @include hover {
    background-color: fadeout($black, 97);
  };
  &.selected {
    background-color: $blue;
    color: $white;
  }
  cursor: pointer;
  padding: 0.3rem 1.2rem;
}
.campaigns-social-schedule-scheduled-header-label {
  padding: 0.8rem;
}
.campaigns-social-schedule-scheduled-header-action {
  padding: 0.8rem;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }  
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.campaigns-social-schedule-scheduled-body {
  display: flex;
  flex: 1;
}
.campaigns-social-schedule-tiles {
  padding: 0.2rem;
}
.campaigns-social-schedule-tile {
  float: left;
  width: calc(100% / 3);
  padding: 0.2rem;
}
.campaigns-social-schedule-token {
  cursor: grab;
}


.campaigns-social-schedule-edit {
  display: flex;
  flex: 1;
}
.campaigns-social-schedule-preview {
  border-right: 1px solid lighten($black, 90);
  flex: 0 0 50%;
  display: flex;
}
.campaigns-social-schedule-edit-form {
  display: flex;
  flex: 0 0 50%;
  .maha-form {
    flex: 1;
  }
}
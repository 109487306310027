.maha-grid {
  flex: 1;
  display: flex;
  flex-direction: column;
  &.loading {
    visibility: hidden;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
  tr {
    transition: transform 0.1s linear 0s;
    &.held {
      transform: scale(.98);
    }
  }
  td {
    border-bottom: 1px solid fadeout($black, 90);
    border-right: 1px solid fadeout($black, 90);
    &.collapsing {
      width: 0.8rem;
    }
  }
  .fa-caret-down,
  .fa-caret-up,
  .fa-chevron,
  .fa-circle-o,
  .fa-check-circle {
    font-size: 1.2rem;
    line-height: 1rem;
  }
  .fa-circle-o {
    color: fadeout($black, 90);
  }
  .fa-check-circle {
    color: $blue;
  }
  td.config {
    width: 3rem;
    padding: 0.8rem;
  }
  &.sortable .maha-grid-header td {
    cursor: pointer;
    @include hover {
      background-color: lighten($black, 95);
    };
  }
}
.maha-grid-header {
  background: fadeout($black, 90);
  padding-right: 6px;
  td {
    font-weight: bold;
    color: fadeout($black, 50);
    position: relative;
    white-space: nowrap;
    &.padded {
      padding: 0.8rem 2.4rem 0.8rem 0.8rem;
    }
    &.next {
      min-width: 2.8rem !important;
    }
    .maha-icon {
      margin: 0;
    }
    .fa-ellipsis-v,
    .fa-caret-up,
    .fa-caret-down {
      position: absolute;
      right: 0.8rem;
      top: 50%;
      transform: translateY(-50%);
      color: fadeout($black, 50);
    }
  }
  td.config {
    cursor: pointer;
    @include hover {
      background-color: lighten($black, 95);
    };
  }
}
.maha-grid-body {
  flex: 1;
  @include vscroll;
  table {
    width: 100%;
  }
  td {
    border-right-color: transparent;
    &.padded {
      padding: 0.8rem;
    }
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
    &.icon {
      width: 3rem;
      text-align: center;
    }
    &.datetime {
      white-space: nowrap;
    }
    &.nowrap {
      white-space: nowrap;
    }
    .maha-icon {
      margin: 0;
    }
    .fa-chevron-right, .fa-ellipsis-v {
      color: fadeout($black, 80) !important;
    }
    .fa-ellipsis-v {
      font-size: 1.2rem;
      cursor: pointer;
    }
  }
}
.maha-grid-task {
  color: fadeout($black, 60);
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}
.maha-grid-icon {
  background-color: fadeout($black, 95);
  border-radius: 0.4rem;
  display: inline-block;
  padding: 0 0.6rem;
  cursor: pointer;
  margin: auto;
  .maha-icon {
    color: fadeout($black, 80) !important;
  }
  @include hover {
    background-color: fadeout($black, 90);
    .maha-icon {
      color: fadeout($black, 60) !important;
    }
  };
}
.maha-grid-check-cell {
  padding: 0.8rem;
  width: 0.8rem;
  .maha-icon {
    margin: auto;
  }
}
.maha-grid-link {
  @include hover {
    background-color: fadeout($black, 97);
  };
  cursor: pointer;
}

.maha-grid-columns-chooser {
  position: absolute;
  right: 0;
  min-width: 200px;
  border-radius: 0.3rem;
  background-color: $white;
  border: 1px solid fadeout($black, 95);
  z-index: 2;
  font-weight: normal;
  box-shadow: 0 3px 8px fadeout($black, 70);
  top: calc(100% + 10px);
  &:after {
    bottom: 100%;
    border-bottom-color: $white !important;
  }
  &:before {
    bottom: 100%;
    border-bottom-color: fadeout($black, 85) !important;
  }
  &:after,
  &:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-width: 9px;
    margin-left: -9px;
    right: 10px;
  }
  &:before {
    border-width: 10px;
    margin-left: -10px;
    right: 9px;
  }
}
.maha-grid-search {
  padding: 0 !important;
}
.maha-grid-column {
  display: flex;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-grid-column-label {
  padding: 0.4rem 0.8rem;
  flex: 1;
}
.maha-grid-column-icon {
  padding: 0.4rem 0.8rem 0.4rem 0;
  .fa-check {
    color: $green;
  }
  .fa-lock {
    color: fadeout($black, 40);
  }
}

.maha-grid-columns-chooser {
  position: absolute;
  right: 0;
  min-width: 200px;
  border-radius: 0.3rem;
  background-color: $white;
  border: 1px solid fadeout($black, 95);
  z-index: 2;
  font-weight: normal;
  box-shadow: 0 3px 8px fadeout($black, 70);
  top: calc(100% + 10px);
  &:after {
    bottom: 100%;
    border-bottom-color: $white !important;
  }
  &:before {
    bottom: 100%;
    border-bottom-color: fadeout($black, 85) !important;
  }
  &:after,
  &:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-width: 9px;
    margin-left: -9px;
    right: 10px;
  }
  &:before {
    border-width: 10px;
    margin-left: -10px;
    right: 9px;
  }
}
.maha-grid-search {
  padding: 0 !important;
}
.maha-grid-column {
  display: flex;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-grid-column-label {
  padding: 0.4rem 0.8rem;
  flex: 1;
}
.maha-grid-column-icon {
  padding: 0.4rem 0.8rem 0.4rem 0;
  .fa-check {
    color: $green;
  }
  .fa-lock {
    color: fadeout($black, 40);
  }
}

@media only screen and (max-width: 768px) {
  .maha-grid td {
    &:not(.mobile),
    &:not(.mobile) {
      display: none;
    }
  }
}

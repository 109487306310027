.twilio-status-token {
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
  display: block;
  text-align: center;
  color: $white;
  font-size: 0.8rem;
  margin: 0;
  padding: 4px;
  text-transform: uppercase;
  width: 7.5rem;
  &.pending { background-color: $grey; }
  &.queued { background-color: $grey; }
  &.initiated { background-color: $teal; }
  &.sending { background-color: $teal; }
  &.receiving { background-color: $teal; }
  &.ringing { background-color: $teal; }
  &.in-progress { background-color: $orange; }
  &.sent { background-color: $green; }
  &.delivered { background-color: $green; }
  &.received { background-color: $green; }
  &.completed { background-color: $green; }
  &.failed { background-color: $red; }
  &.canceled { background-color: $red; }
}

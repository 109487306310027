.sales-dealboard {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.sales-dealboard-header {
  display: flex;
  .maha-searchbox {
    flex: 1;
  }
}
.sales-dealboard-body {
  overflow-x: scroll;
  flex: 1;
  display: flex;
}
.sales-dealboard-stage {
  border-right: 1px solid fadeout($black, 90);
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: 768px) {
    flex: 0 0 100vw;
  }
  @media only screen and (min-width: 769px) {
    flex: 0 0 300px;
  }
}
.sales-dealboard-stage-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: $white;
  position: relative;
  display: flex;
}
.sales-dealboard-stage-header-label {
  padding: 0.8rem;
  flex: 1;
}
.sales-dealboard-stage-header-title {
  font-weight: bold;
  width: 20rem;
  height: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sales-dealboard-stage-header-progress {
  height: 5px;
  margin: 0.6rem 0;
  display: flex;
  gap: 2px;
}
.sales-dealboard-stage-header-progress-bar {
  background: fadeout($blue, 85);
  flex: 1;
  &.inprogress {
    background: $blue;
  }
  &.won {
    background: $green;
  }
  &.lost {
    background: $red;
  }
}
.sales-dealboard-stage-header-total {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.8rem;
}
.sales-dealboard-stage-body {
  display: flex;
  flex: 1;
}
.sales-dealboard-stage-results {
  padding: 0.8rem;
  .sales-dealboard-deal:last-child {
    margin-bottom: 0;
  }
}
.sales-dealboard-deal {
  margin-bottom: 0.8rem;
  border: 1px solid fadeout($black, 90);
  flex-direction: column;
  border-radius: 0.4rem;
  background: $white;
  position: relative;
  display: flex;
  &.dragging {
    opacity: 0.6;
  }
  &:hover {
    cursor: grab;
  }
  @include hover {
    .sales-dealboard-deal-action {
      display: flex;
    }
  };
}
.sales-dealboard-deal-action {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: fadeout($black, 80);
  padding: 0.8rem;
  @include hover {
    color: fadeout($black, 40);
  };
  .maha-icon {
    margin: auto;
  }
}
.sales-dealboard-deal-details {
  border-bottom: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  color: fadeout($black, 20);
}
.sales-dealboard-deal-owner {
  background: fadeout($black, 97);
  .user-token-avatar {
    padding: 0.6rem;
  }
  .user-token-details {
    padding: 0.6rem 0.6rem 0.6rem 0;
  }
  .maha-avatar {
    width: 1.2rem;
  }
}

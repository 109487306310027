.profile-token {
  flex: 1;
  display: flex;
}
.profile-token-logo {
  padding: 0.8rem 0 0.8rem 0.8rem;
  position: relative;
  display: flex;
}
.profile-token-logo-image {
  position: relative;
  margin: auto;
  & > img {
    border: 1px solid fadeout($black, 90);
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
    width: 3rem;
    height: 3rem;
    display: block;
  }
}
.profile-token-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    display: block;
    border-radius: 0.2rem;
    overflow: hidden;
    height: 1rem;
    width: 1rem;
  }
}
.profile-token-label {
  padding: 0.8rem;
  flex: 1;
  color: fadeout($black, 40);
  line-height: 1.4rem;
  strong {
    color: $black;
  }
}

.crm-workflow-ifthen-branches {
  @include vscroll;
  flex: 1;
}
.crm-workflow-ifthen-branch {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.crm-workflow-ifthen-branch-label {
  flex: 1;
  padding: 0.8rem;
}
.crm-workflow-ifthen-branch-action {
  padding: 0.8rem;
}

.maha-dashboard-panel-grid-toolbar {
  background-color: $white;
  border-radius: 0.4rem;
  display: flex;
}
.maha-dashboard-panel-grid-toolbar-section {
  display: flex;
  flex: 1;
}
.maha-dashboard-panel-grid-toolbar-actions {
  display: flex;
}
.maha-dashboard-panel-grid-toolbar-action {
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.maha-dashboard-panel-grid-toolbar-field {
  border-right: 1px solid fadeout($black, 90);
  width: 200px;
  .maha-dropdown {
    border: none;
    width: 100%;
  }
}
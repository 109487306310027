.maha-textfield {
  .maha-input-extra {
    color: fadeout($black, 50);
    font-size: 0.8rem;
    &.max {
      color: $red;
    }
  }
  .ui.label {
    &:first-child {
      border-right: 1px solid fadeout($black, 90);
    }
    &:last-child {
      border-left: 1px solid fadeout($black, 90);
    }
    background-color: fadeout($black, 97);
    padding: 0.8rem;
  }
}

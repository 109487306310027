.maha-button {
  cursor: pointer;
  font-weight: normal !important;
  &.ui.basic.blue.button .fa-circle-o-notch {
    color: $blue !important;
  }
  &.ui.basic.button {
    background: $white !important;
    &:hover {
      background: darken($white, 5) !important;
    }
  }
  &.iconed {
    .maha-icon {
      margin-right: 5px;
      color: fadeout($black, 60);
    }
  }
  &.right {
    position: relative;
    .maha-button-right {
      @include flex-justify-middle-center;
      border-left: 1px solid fadeout($white, 60);
      position: absolute;
      width: 2.6rem;
      bottom: 0;
      right: 0;
      top: 0;
    }
  }
  &:not(.disabled) {
    cursor: pointer;
  }
}
.links {
	.link {
		&:last-child {
			&:after {
				content: '';
			}
		}
		&:after {
			color: $black;
			content: ',';
			margin: 0 3px;
		}
	}
}
.text {
  display: inline;
}
.link {
	color: $blue;
	display: inline;
  &:not(.disabled) {
    cursor: pointer;
  }
	.maha-icon {
		color: $blue !important;
	}
  .fa-external-link {
    margin-left: 5px;
  }
  &.disabled {
    color: fadeout($black, 80) !important;
    .maha-icon {
      color: fadeout($black, 80) !important;
    }
  }
  &.tiny {
    font-size: 0.9rem;
  }
	@include hover {
		color: darken($blue, 20);
	};
}
.maha-button.icon {
  color: fadeout($black, 80);
  padding: 0.8rem;
  cursor: pointer;
  &:hover {
    color: fadeout($black, 40);
  }
}
.text {
	color: $grey;
	display: inline-block;
}

.maha-fields {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  .maha-field:last-child {
    border-bottom: none;
  }
}
.maha-fields-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  color: fadeout($black, 40);
  font-weight: bold;
  padding: 0.8rem;
}
.maha-fields-empty {
  padding: 0.8rem;
  text-align: center;
}
.maha-fields-disabled {
  border-bottom: 1px solid fadeout($black, 90);
  font-size: 0.8rem;
  cursor: pointer;
  display: flex;
}
.maha-fields-disabled-icon {
  @include flex-justify-middle-center;
  color: fadeout($black, 80);
  flex: 0 0 3.2rem;
}
.maha-fields-disabled-label {
  padding: 0.4rem 0;
  color: fadeout($black, 70);
  font-weight: bold;
  text-transform: uppercase;
  flex: 1;
}
.maha-fields-footer {
  padding: 0.8rem;
  background-color: fadeout($black, 97);
}
.maha-field {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
  @include hover {
    background-color: fadeout($black, 97);    
  };
  overflow: hidden;
  &:not(.disabled) {
    cursor: pointer;
  }
  &.disabled {
    .maha-field-badge {
      background-color: fadeout($blue, 90);
    }
    .maha-field-label {
      strong, span {
        color: fadeout($black, 90);
      }
    }
  }
  &.dragging .maha-field-handle {
    cursor: grabbing !important;
  }
  &.section {
    background-color: fadeout($black, 40);
    color: $white;
    .maha-icon {
      color: $white !important;
    }
  }
}
.maha-field-handle {
  @include flex-justify-middle-center;
  padding: 0.6rem 0 0.6rem 0.6rem;
  flex: 0 0 2.5rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }
}
.maha-field-icon {
  @include flex-justify-middle-center;
  padding: 0.6rem 0 0.6rem 0.6rem;
}
.maha-field-badge {
  @include flex-justify-middle-center;
  background-color: $blue;
  border-radius: 0.2rem;
  width: 1.8rem;
  height: 1.8rem;
  .maha-icon {
    color: $white;
  }
}
.maha-field-label {
  padding: 0.8rem;
  flex: 1;
  span {
    text-transform: uppercase;
    color: fadeout($black, 60);
    font-size: 0.9rem;
  }
}
.maha-field-primary {
  line-height: 2.2rem;
  font-size: 0.8rem;
  padding: 0.8rem;
  color: $green;
}
.maha-field-extra {
  @include flex-justify-middle-center;
  width: 3rem;
}
.maha-field-action {
  @include flex-justify-middle-center;
  background-color: fadeout($black, 95);
  border-radius: 0.4rem;
  display: inline-block;
  padding: 0 0.6rem;
  cursor: pointer;
  .maha-icon {
    color: fadeout($black, 80) !important;
  }
  @include hover {
    background-color: fadeout($black, 90);
    .maha-icon {
      color: fadeout($black, 60) !important;
    }
  };
}
.maha-field-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  .maha-icon {
    color: fadeout($black, 80) !important;
  }
}
@media (max-width: 768px) {
  .maha-field-handle {
    display: none;
  }
  .maha-field-label span {
    display: block;
  }
}

.routing-number-field {
  border: 1px solid fadeout($black, 90);
  .maha-input {
    border:none;
  }
  border-radius: 0.4rem;
  overflow: hidden;
}
.routing-number-field-bank {
  border-top: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}
.routing-number-field-bank-details {
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.designer-fields {
  flex: 1;
  display: flex;
  .maha-menus-item>.maha-menus-header {
    padding-top: 0.8rem !important;
  }
}
.flowchart-designer-blocks {
  p {
    color: fadeout($black, 60);
    line-height: 1.6rem;
  }
}

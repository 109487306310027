.maha-contactfield-options {
  flex: 1;
  @include vscroll;
}
.maha-contactfield-option {
  border-bottom: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}

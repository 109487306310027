.phone-number-token {
  display: flex;
}
.phone-number-token-logo {
  display: flex;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 1.6rem;
  }
}
.phone-number-token-label {
  flex: 1;
  padding: 0.8rem;
}

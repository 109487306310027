.maha-tagfield {
  position: relative;
  flex-direction: column;
  display: flex;
  flex: 1;
  &.suggesting {
    border-radius: 0.4rem 0.4rem 0 0;
  }
}
.maha-tagfield-field {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.maha-tagfield-tags {
  flex: 1;
  min-height: 3.2rem;
}
.maha-tagfield-clear {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.maha-tagfield-input {
  input {
    padding: 0.8rem !important;
    outline: none !important;
    border: none !important;
  }
}
.maha-tagfield-input-options {
  @include vscroll;
  transition: max-height 150ms ease-in-out 0s;
  max-height: 16.2rem;
  border: 1px solid $lightblue;
  border-top: 1px solid fadeout($black, 95);
  border-radius: 0 0 0.4rem 0.4rem;
  background-color: $white;
  position: absolute;
  left: -1px;
  right: -1px;
  top: 100%;
  &.expanded-enter,
  &.expanded-exit.expanded-exit-active {
    max-height: 0;
  }
  &.expanded-enter.expanded-enter-active,
  &.expanded-exit {
    max-height: 16.2rem;
  }
}
.maha-tagfield-input-option {
  padding: 0.8rem;
  cursor: pointer;
  &:hover {
    background-color: fadeout($black, 97);
  }
}
.maha-tagfield-input-options-empty {
  padding: 0.8rem;
  color: fadeout($black, 80);
  font-style: italic;
}
.maha-criteriafield {
  border: 1px solid fadeout($black, 90);
  border-radius: .4rem;
  display: flex;
  background: $white;
  &:focus {
    outline: none !important;
    border-color: $lightblue;
    border-radius: .4rem;
    background: $white;
    box-shadow: 0 0 0 0 fadeout($black, 75) inset;
  }
}
.maha-criteriafield-label {
  flex: 1;
}
.maha-criteriafield-token {
  display: inline-block;
  margin: 0.2rem;
  padding: 0.3rem 0.4rem;
  background-color: fadeout($black, 95);
  color: fadeout($black, 40);
  border-radius: 0.2rem;
}
.maha-criteriafield-prompt {
  padding: 0.8rem;
  line-height: 1.2rem;
  color: fadeout($black, 70) !important;
  flex: 1;
}
.maha-criteriafield-clear {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    @include hover {
      color: fadeout($black, 70);
    };
    color: fadeout($black, 80);
    cursor: pointer;
  }
}

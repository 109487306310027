.maha-dashboard {
  background-color: fadeout($black, 95);
  position: relative;
  display: flex;
  flex: 1;
}
.maha-dashboard-panel {
  flex-direction: column;
  position: relative;
  flex: 1;
  .maha-message,
  .maha-dashboard-panel-grid,
  .maha-dashboard-panel-overlay,
  .maha-dashboard-panel-expanded {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.maha-dashboard-panel-expanded,
.maha-dashboard-card {
  transition: transform 250ms ease-in-out 0s, opacity 250ms ease-in-out 0s;
  &.appear-exit,
  &.appear-enter-active {
    transform: scale(1);
    opacity: 1;
  }
  &.appear-exit-active,
  &.appear-enter {
    transform: scale(0.1);
    opacity: 0;
  }
}
.maha-dashboard-panel-overlay {
  transition: opacity 250ms ease-in-out 0s;
  background: $black;
  &.appear-exit,
  &.appear-enter-active,
  &.appear-enter-done {
    opacity: 0.4;
  }
  &.appear-exit-active,
  &.appear-enter {
    opacity: 0;
  }
}

// grid view
.maha-dashboard-panel-grid {
  background-color: fadeout($black, 97);
  flex-direction: column;
  display: flex;
  .react-grid-item.react-grid-placeholder {
    background: fadeout($black, 97);
    border: 2px dashed fadeout($black, 50);
    z-index: 0;
  }
  .react-draggable-dragging {
    pointer-events: none;
  }
  .maha-dashboard-panel-grid-header {
    padding: 0.8rem 0.8rem 0;
  }
  .maha-dashboard-panel-grid-body {
    display: flex;
    @include vscroll;
    flex: 1;
  }
  .maha-dashboard-panel-grid-layout {
    flex: 1;
  }
  .maha-dashboard-panel-grid-item {
    transition: box-shadow .25s ease-in-out;
    background: $white;
    border-radius: 0.4rem;
    overflow: hidden;
    display: flex;
    z-index: 1;
    .react-resizable-handle {
      opacity: 0.3;
      visibility: hidden;
    }
    .maha-dashboard-card-actions {
      display: none;
    }
    @include hover {
      box-shadow: 0 0 0 2px $blue;
      .maha-dashboard-card-actions {
        display: flex;
      }
      .react-resizable-handle {
        visibility: visible;
      }
    };
  }
  .maha-dashboard-card {
    transition: box-shadow .25s ease-in-out;
    border-radius: 0.4rem;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex: 1;
  }
  .maha-dashboard-card-detail {
    flex-direction: column;
    display: flex;
    gap: 0.8rem;
  }
  .maha-dashboard-card-detail-header {
    flex: 0 0 100px;
  }
  .maha-dashboard-card-detail-chart {
    flex: 0 0 200px;
    padding: 0 0.8rem;
  }
  .maha-dashboard-card-detail-table {
    flex: 0 0 200px;
    @include vscroll;
  }
}


.maha-dashboard-panel-expanded {
  display: flex;
  .maha-dashboard-card {
    background-color: $white;
    @include vscroll;
    flex: 1;
  }
  .maha-dashboard-card-actions {
    display: flex;
  }
  .maha-dashboard-card-detail-chart {
    flex: 0 0 300px;
    height: 300px;
    margin: 0.8rem 0;
  }
}

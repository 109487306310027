.maha-filefield {
  position: relative;
  &:focus {
    outline: none !important;
  }
}
.maha-filefield-tokens {
  overflow: hidden;
}

.field .maha-filefield-tokens .maha-filefield-token {
  margin-bottom: 0.8rem;
  position: relative;
  max-width: 414px;
  .maha-filefield-file-preview {
    background: $white;
  }
  &.plain {
    width: 100%;
    clear: both;
    .maha-filefield-file-preview-details {
      margin-right: 2.4rem;
    }
    .maha-filefield-file-preview {
      max-width: 550px;
    }
    .maha-filefield-remove {
      color: fadeout($black, 70);
      @include hover {
        color: fadeout($black, 50);
      };
    }
  }
  &.image {
    border-radius: 5px;
    display: flex;
    float: left;
    margin-right: 0.8rem;
    .maha-filefield-remove {
      color: $white;
    }
    @media (max-width: 414px) {
      width: 100%;
      margin-right: none;
    }
    @media (min-width: 415px) and (max-width: 768px) {
      width: calc(100% / 2 - 0.8rem);
      &:nth-child(2n+2) {
        margin-right: none;
      }
    }
    @media (min-width: 769px) {
      width: calc(100% / 3 - 0.8rem * 2);
      &:nth-child(3n+3) {
        margin-right: none;
      }
    }
  }
}


.maha-filefield-details {
  flex: 1;
  margin: 0;
  vertical-align: middle;
  position: relative;
}
.maha-filefield-preview-image {
  border-radius: 5px;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  display: block;
  overflow: hidden;
  background-color: #CCC;
  img {
    width: 100%;
  }
}
.maha-filefield-preview-image-cache {
  display: none;
}
.maha-filefield-preview-image-asset {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  display: block;
  background: #333;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.maha-filefield-remove {
  @include flex-justify-middle-center;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
  color: $white;
}
.maha-filefield-button {
  display: inline-block;
}

.maha-filefield-progress {
  margin: 0;
  .ui.progress {
    margin: 0 !important;
    background: #333;
    position: absolute;
    bottom: calc(50% - 12px);
    left: 1rem;
    right: 1rem;
  }
  .bar {
    transition-duration: 300ms;
  }
}
.maha-filefield-file-preview {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: flex;
  width: 100%;
  &.pdf .maha-icon { color: $red; }
  &.xls .maha-icon { color: $green; }
  &.doc .maha-icon { color: $blue; }
  &.ppt .maha-icon { color: $orange; }
}
.maha-filefield-file-preview-icon {
  flex: 0 0 3rem;
  padding: 0.8rem 0.2rem;
  .maha-icon {
    font-size: 2.8rem;
  }
}
.maha-filefield-file-preview-details {
  flex: 1;
  padding: 0.8rem 0.8rem 0.8rem 0;
}
.maha-filefield-file-preview-filename {
  color: $black;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.6rem;
}
.maha-filefield-file-preview-filesize {
  color: fadeout($black, 60);
  font-size: 0.8rem;
  line-height: 1.4rem;
}

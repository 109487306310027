.maha-lookup-field {
  &:focus {
    outline: none !important;
    border-color: $lightblue;
    border-radius: .4rem;
    background: $white;
    box-shadow: 0 0 0 0 fadeout($black, 75) inset;
  }
}
.maha-lookup-field {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  background-color: $white;
  display: flex;
}
.maha-lookup-items {
  flex: 1;
  padding: 0.2rem;
  min-height: 2.5rem;
}
.maha-lookup-item {
  display: inline-flex;
  margin: 0.2rem;
  background-color: fadeout($green, 90);
  color: fadeout($green, 40);
  border-radius: 0.4rem;
}
.maha-lookup-item-label {
  padding: 0.4rem 0.4rem 0.4rem 0.8rem;
  flex: 1;
}
.maha-lookup-item-action {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.8rem 0.4rem 0;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($green, 40);
    }
  };
  .maha-icon {
    color: fadeout($green, 70);
  }
}
.maha-lookup-item-content {
  display: flex;
}
.maha-lookup-item-token {
  flex: 1;
  .compact-user-token-avatar {
    padding: 0.4rem;
  }
  .compact-user-token-details {
    padding: 0.4rem;
  }
  .maha-avatar {
    width: 1.6rem !important;
  }
}
.maha-lookup-item-remove {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.4rem 0.4rem 0;
  .maha-icon {
    @include hover {
      color: fadeout($black, 70);
    };
    font-size: 1.4rem;
    line-height: 1rem;
    color: fadeout($black, 80);
    cursor: pointer;
  }
}
.maha-lookup-placeholder {
  color: fadeout($black, 70);
  margin: 0.2rem;
  padding: 0.4rem 0;
}
.maha-lookup-panel-add {
  padding: 0.8rem;
  border-top: 1px solid fadeout($black, 85);
}

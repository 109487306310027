.maha-attachments-retry {
  padding: 0.8rem;
  background-color: fadeout($black, 97);
  border-bottom: 1px solid fadeout($black, 90);
  text-align: center;
  color: $red;
}
.maha-attachments-multiple {
  flex: 1;
  display: flex;
}
.maha-attachments-single {
  flex: 1;
  display: flex;
}
.maha-attachments-single-progress {
  .maha-filefield-progress {
    position: relative;
    height: 0.8rem;
    .ui.progress {
      bottom: auto;
      .bar {
        transition: none;
      }
    }
  }
}
.maha-attachments-single-text {
  text-align: center;
}

.maha-network-body {
  display: flex;
  flex: 1;
}
.maha-network-status {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: $white;
  text-align: center;
  &.error {
    background-color: $yellow;
  }
  &.success {
    background-color: $green;
  }
}
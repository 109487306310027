.session-token {
  flex: 1;
  padding: 0.8rem;
  color: fadeout($black, 40);
  line-height: 1.6rem;
  strong {
    color: $black;
  }
  .fa-calendar {
    color: $blue;
  }
  .fa-desktop {
    color: $black;
  }
  .fa-map-marker {
    color: $red;
  }
}

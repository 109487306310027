.source-token {
  display: flex;
}
.source-token-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  img {
    border-radius: 0.3rem;
    overflow: hidden;
    width: 1.6rem;
    height: 1.6rem;
    display: block;
  }
}
.source-token-text {
  padding: 0.8rem 0.8rem 0.8rem 0;
  line-height: 1.6rem;
  flex: 1;
}

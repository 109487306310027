.crm-report {
  background-color: $white;
  margin: 0 0 1.6rem;
}
.crm-report-title {
  background-color: $darkblue;
  position: relative;
  color: $white;
  padding: 0.8rem;
}
.crm-report-title-action {
  position: absolute;
  padding: 0.8rem;
  top: 0;
  right: 0;
  bottom: 0;
  .link {
    color: fadeout($white, 30);
    @include hover {
      color: $white;
    };
  }
}
.crm-report-subtitle {
  background-color: fadeout($blue, 90);
  padding: 0.8rem;
}
.crm-report-metrics {
  border-top: 1px solid fadeout($black, 90);
  display: flex;
  .crm-report-metric:last-child {
    border-right: none;
  }
}
.crm-report-metric {
  flex: 1;
  padding: 0.8rem;
  border-right: 1px solid fadeout($black, 90);
}
.crm-report-metric-title {
  text-align: center;
  font-size: 0.8rem;
  color: fadeout($black, 60);
  text-transform: uppercase;
}
.crm-report-metric-value {
  text-align: center;
  font-size: 2rem;
  line-height: 1.4rem;
}
.crm-report-section {
  border-top: 1px solid fadeout($black, 90);
  padding: 0.8rem;
}
.crm-report-section-navigation {
  margin: 0 0 0.8rem;
  display: flex;
}
.crm-report-section-title {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  flex: 1;
}
.crm-report-section-chart-header {
  color: fadeout($black, 40);
  strong {
    color: $black;
  }
  margin: 0 0 0.8rem;
}
.crm-report-section-menu {
  background: fadeout($black, 97);
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
  text-align: right;
  font-size: 0.9rem;
  ul {
    display: inline;
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    li:last-child:after {
      display: none;
    }
  }
  li {
    display: inline-block;
    .selected {
      color: $black;
    }
    &:after {
      color: fadeout($black, 80);
      margin: 0 0.4rem;
      content: '|';
    }  
  }
}
.crm-report-section-chart-metric {
  margin: 0 0 0.8rem;
}
.crm-report-section-chart-tooltip {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  background-color: $white;
  display: flex;
  font-size: 0.8rem;
}
.crm-report-section-chart-tooltip-details {
  padding: 0.4rem 3.2rem 0.4rem 0.4rem;
  align-items: center;
  display: flex;
  flex: 1;
  strong {
    font-size: 1.4rem;
    color: $black;
  }
  span {
    text-transform: uppercase;
    color: fadeout($black, 40);
  }
}
.crm-report-section-chart-tooltip-date {
  align-items: center;
  text-align: right;
  display: flex;
  padding: 0.4rem;

}
.crm-report-breakdown {
  display: flex;
}
.crm-report-breakdown-chart {
  display: flex;
  flex: 0 0 30%;
}
.crm-report-breakdown-chart-canvas {
  @include flex-justify-middle-center;
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  padding: 0.8rem;
  canvas {
    width: 100%;
  }
}
.crm-report-breakdown-table {
  flex: 0 0 70%;
  min-height: 20rem;
  padding: 0 0.8rem 0 0;
  .percentage {
    display: inline-block;
    background-color: fadeout($black, 97);
    color: fadeout($black, 60);
    border-radius: 0.2rem;
    margin: 0 0 0 0.4rem;
    text-align: center;
    padding: 0.2rem;
    width: 3.8rem;
    font-size: 0.9rem;
  }
  .crm-report-breakdown-color {
    display: inline-block;
    border-radius: 0.2rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.4rem;
  }
  tr:nth-child(1) .crm-report-breakdown-color { background-color: $red; }
  tr:nth-child(2) .crm-report-breakdown-color { background-color: $orange; }
  tr:nth-child(3) .crm-report-breakdown-color { background-color: $yellow; }
  tr:nth-child(4) .crm-report-breakdown-color { background-color: $green; }
  tr:nth-child(5) .crm-report-breakdown-color { background-color: $blue; }
  tr:nth-child(6) .crm-report-breakdown-color { background-color: $purple; }
  tr:nth-child(7) .crm-report-breakdown-color { background-color: $violet; }
}
.crm-report-table {
  border-top: 1px solid fadeout($black, 95);
  .ui.table {
    border: none
  }
  tbody {
    tr.heading {
      background: fadeout($black, 95);
      border-top: 1px solid fadeout($black, 95);
      font-weight: bold;
    }
    .maha-icon {
      margin-right: 0.4rem;
      cursor: pointer;
    }
    tr:nth-child(1) .maha-icon { color: $red; }
    tr:nth-child(2) .maha-icon { color: $orange; }
    tr:nth-child(3) .maha-icon { color: $yellow; }
    tr:nth-child(4) .maha-icon { color: $olive; }
    tr:nth-child(5) .maha-icon { color: $green; }
    tr:nth-child(6) .maha-icon { color: $blue; }
    tr:nth-child(7) .maha-icon { color: $teal; }
  }
}
@media (max-width: 769px) {
  .crm-report-metrics {
    flex-wrap: wrap;
    .crm-report-metric {
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: 1px solid fadeout($black, 90);
      }
    }
    .crm-report-metric:nth-child(2n) {
      border-right: none;
    }
  }
  .crm-report-metric {
    flex: 0 50%;
  }
}

.maha-attachments-device {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-attachments-device-body {
  flex: 1;
  display: flex;
  padding: 0.8rem;
}
.maha-attachments-device-drop {
  flex: 1;
  border: 2px dashed fadeout($black, 90);
  background-color: fadeout($black, 97);
  display: flex;
  p {
    color: fadeout($black, 70);
    font-size: 1.5rem;
    margin: 0;
  }
  span {
    display: block;
    margin: 1rem 0 1.4rem;
  }
  @include hover {
    background-color: fadeout($black, 95);
  };
}
.maha-attachments-device-text {
  margin: auto;
  text-align: center
}

.subscriptions-resourcesfield {
  flex: 1;
}
.subscriptions-resourcesfield-resources {
  .subscriptions-resourcesfield-resource {
    border-bottom: 1px solid fadeout($black, 90);
  }
  .subscriptions-resourcesfield-resource:last-child {
    border-bottom: none;
  }
}
.subscriptions-resourcesfield-resource {
  background-color: $white;
  display: flex;
}
.subscriptions-resourcesfield-resource-handle {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.4rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }
}
.subscriptions-resourcesfield-resource-details {
  padding: 0.8rem 0;
  flex: 1;
}
.subscriptions-resourcesfield-resource-action {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  flex: 0 0 2rem;
  cursor: pointer;
  .maha-icon {
    color: fadeout($black, 80);
  }
  &:hover .maha-icon {
    color: fadeout($black, 60);
  }
}
.subscriptions-resourcesfield-add {
  padding: 0.8rem;
}

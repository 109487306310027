.account-token {
  display: flex;
  flex: 1;
}
.account-token-avatar {
  padding: 0.8rem;
  .maha-avatar {
    width: 1.6rem;
  }
}
.account-token-details {
  position: relative;
  flex: 1;
  color: #888888 !important;
  strong {
    color: $black !important;
  }
}
.account-token-details {
  padding: 0.8rem 0.8rem 0.8rem 0;
  display: flex;
}
.account-token-details-inner {
  margin: auto 0;
  line-height: 1.2rem;
}
.account-token-activity {
  color: $red;
  margin-left: 5px;
  font-size: 0.8rem;
  line-height: 2rem;
}

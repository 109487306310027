.unsplash-token {
  position: relative;
  border-radius: .4rem;
  display: flex;
  flex-direction: column;
  background-color: fadeout($black, 95);
  overflow: hidden;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 90);
  };
}
.unsplash-token-photo {
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
  height: 0;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
.unsplash-token-details {
  padding: 0.8rem;
  span {
    color: fadeout($black, 60);
  }
}

.maha-attachments-review {
  background-color: $white;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 3.5rem;
  left: 0;
  width: 100%;
  height: 100%;
}
.maha-attachments-review-header {
  padding: 0.8rem;
  background-color: $yellow;
  color: $white;
  text-align: center;
  cursor: pointer;
}
.maha-attachments-review-body {
  display: flex;
  flex: 1;
  .maha-attachments-list-items {
    flex: 1;
    @include vscroll;
  }
}

.websites-menu-designer {
  background-color: fadeout($black, 97);
  padding: 0.8rem;
  flex: 1;
  @include vscroll;
}
.websites-menu-designer-items {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  .websites-menu-designer-item:last-child {
    border-bottom: none;
  }
}
.websites-menu-designer-item {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.websites-menu-designer-item-handle {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }
}
.websites-menu-designer-item-label {
  flex: 1;
  padding: 0.8rem;
  span {
    color: fadeout($black, 60);
  }
}
.websites-menu-designer-item-action {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  cursor: pointer;
  .maha-icon {
    color: fadeout($black, 80);
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 60);
    }
  }; 
}
.websites-menu-designer-items-add {
  padding: 0.8rem 1.2rem;
}

.platform-appsfield {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
}
.platform-appsfield-app {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.platform-appsfield-app-label {
  flex: 1;
}
.platform-appsfield-app-input {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  cursor: pointer;
  .maha-icon {
    font-size: 1.4rem;
  }
  .fa-toggle-off {
    color: fadeout($black, 80);
  }
  .fa-toggle-on {
    color: $green;
  }
}
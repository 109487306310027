.mjson-designer-layoutfield {
  max-width: 300px;
  width: 100%;
}
.layout-token {
  padding: 0 0.2rem;
}
.layout-token-row {
  display: flex;
}
.layout-token-column {
  padding: 0.2rem;
}
.layout-token-block {
  background-color: $purple;
  color: fadeout($white, 10);
  border-radius: 0.2rem;
  height: 2.6rem;
  font-size: 0.8rem;
  line-height: 2.6rem;
  text-align: center;
}

.sharesfield {
  margin: -0.8rem;
}
.sharesfield-network {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.sharesfield-network-handle {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  cursor: grab;
  .maha-icon {
    font-size: 1.2rem;
    color: fadeout($black, 60);
    @include hover {
      color: fadeout($black, 40);
    };
  }
}
.sharesfield-network-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  img {
    width: 1.4rem;
    height: 1.4rem;
    display: block;
  }
}
.sharesfield-network-details {
  padding: 0.8rem;
  flex: 1;
}
.sharesfield-network-remove {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  cursor: pointer;
  .maha-icon {
    font-size: 1.2rem;
    color: fadeout($black, 60);
    @include hover {
      color: fadeout($black, 40);
    };
  }
}
.sharesfield-network-add {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  display: flex;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.sharesfield-network-add-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.sharesfield-network-add-label {
  padding: 0.8rem;
  flex: 1;
}

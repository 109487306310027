.maha-signin {
  flex: 1;
  position: relative;
  &.backward .maha-signin-panel {
    &.slide-enter { transform: translateX(-100%); }
    &.slide-enter.slide-enter-active { transform: translateX(0); }
    &.slide-exit { transform: translateX(0); }
    &.slide-exit.slide-exit-active { transform: translateX(100%); }
  }
  &.forward .maha-signin-panel {
    &.slide-enter { transform: translateX(100%); }
    &.slide-enter.slide-enter-active { transform: translateX(0); }
    &.slide-exit { transform: translateX(0); }
    &.slide-exit.slide-exit-active { transform: translateX(-100%); }
  }
}
.maha-signin-form {
  width: 100%;
  max-width: 375px;
  margin: auto;
  overflow: hidden;
  text-align: center;
  padding: 0.8rem;
  .ui.form {
    margin-top: 1rem;
  }
}
.maha-signin-error {
  color: lighten($red, 20);
  margin: 0.4em 0;
  .link {
    text-decoration: underline;
    color: lighten($red, 20) !important;
  }
}
.maha-signin-header {
  padding: 0.8rem;
  height: 15.6rem;
}
.maha-signin-footer {
  padding: 0.8rem;
  height: 5.6rem;
  color: $white;
}
.maha-signin-content {
  .fa-mobile {
    color: $white;
  }
  p {
    margin: 0 0 1rem !important;
  }
  .maha-filefield-button {
    width: 100%;

  }
}
.maha-signin-avatar {
  border-radius: 0.4rem;
  margin-bottom: 0.8rem;
  background-color: fadeout($white, 50);
  height: 0;
  padding-bottom: 100%;
  position: relative;
  cursor: pointer;
  @include hover {
    background-color: fadeout($white, 40);
  };
}
.maha-signin-avatar-placeholder {
  @include flex-justify-middle-center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .maha-icon {
    font-size: 15rem;
    color: fadeout($black, 70);
  }
}

.maha-signin-panel {
  transition: transform 500ms ease-in-out 0s;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  .maha-logo,
  .maha-avatar {
    width: 130px;
    margin: 0 auto;
    display: inline-block;
    .maha-avatar-text {
      font-size: 3rem;
    }
  }
  .maha-logo-wrapper {
    border-radius: 15px;
  }
  h1 {
    font-size: 4rem;
    line-height: 6rem;
    margin: 0;
    color: $white;
  }
  h2 {
    text-align: center;
    color: $white;
    margin: 0;
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
  p {
    text-align: center;
    color: $white;
    margin: 0;
    font-size: 1rem;
    line-height: 1.8rem;
  }
  a {
    text-decoration: underline;
    cursor: pointer;
  }
  .maha-filefield-token {
    width: 100%;
    margin: 0 0 1rem;
  }
  .maha-select {
    text-align: left;
  }
  .fa-check-circle {
    color: $olive;
  }
  .fa-warning {
    color: $yellow;
  }
  .link {
    color: $white;
    cursor: pointer;
    @include hover {
      text-decoration: underline;
    }
  }
  .buttons-field {
    display: flex;
    .maha-button {
      flex: 1;
    }
  }
}

.crm-positionfield {
  overflow: hidden;
  display: flex;
  border-right: 1px solid fadeout($black, 90);
  @media (max-width: 768px) {
    flex-direction: column;
    .crm-positionfield-field:first-child {
      border-bottom: 1px solid fadeout($black, 90);
    }
  }
  @media (min-width: 769px) {
    .crm-positionfield-field:first-child {
      border-right: 1px solid fadeout($black, 90);
    }
  }
}
.crm-positionfield-field {
  flex: 1;
  .maha-input {
    border: none;
    border-radius: 0;
  }
}

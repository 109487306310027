.domains-valuefield-value {
  display: flex;
  flex: 1;
}
.domains-valuefield-value-details {
  flex: 1;
  input {
    outline: none;
    border: none;
    width: 100%;
    padding: 0.8rem;
  }
}
.maha-mode-token {
  white-space: nowrap;
  padding: 0.8rem;
  display: flex;
  &.development {
    color: fadeout($black, 60);
  }
  &.production {
    color: $green;
  }
}
.maha-handset-contacts-result-token {
  flex: 1;
}
.maha-handset-contacts-result-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 80);
  }
}

.organizer-token {
  display: flex;
}
.organizer-token-avatar {
  @include flex-justify-middle-center;
  padding: 0.4rem 0 0.4rem 0.8rem;
  .organizer-avatar-token {
    width: 2rem;
  }
}
.organizer-token-label {
  flex: 1;
  padding: 0.8rem;
}

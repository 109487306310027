.crm-rulesfield-rule {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.3rem;
  margin-bottom: 0.8rem;
  display: flex;
}
.crm-rulesfield-rule-label {
  padding: 0.8rem;
  flex: 1;
}
.crm-rulesfield-rule-remove {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 60);
  }
}

.crm-speakfield-dropdown {
  margin-bottom: 0.8rem;
}
.crm-speakfield-speaker {
  cursor: pointer;
  color: $blue;
  font-size: 0.8rem;
  @include hover {
    color: darken($blue, 10)
  };
}

.maha-avatar {
  width: 100%;
  position: relative;
  .presence-token {
    position: absolute;
    bottom: 1px;
    right: 1px;
    font-size: 0.8rem;
  }
  &.circular {
    .maha-avatar-wrapper {
      border-radius: 50%;
    }
  }
  &.rounded {
    .maha-avatar-wrapper {
      border-radius: 0.4rem;
    }
  }
}
.maha-avatar-badge {
  width: 100%;
  padding-top: 100%;
  position: relative;
}
.maha-avatar-wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  color: $white;
  font-family: monospace;
  display: flex;
  overflow: hidden;
  .maha-image {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .maha-icon {
    margin: auto !important;
    font-size: 1rem;
    line-height: 1.8rem;
  }
}
.maha-avatar-text {
  margin: auto;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.maha-avatar-text-wrapper {
  display: flex;
  flex: 1;
  background-color: $grey;
}

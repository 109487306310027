.contactfieldsfield {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
}
.contactfieldsfield-field {
  display: flex;
  border-bottom: 1px solid fadeout($black, 90);
}
.contactfieldsfield-field-label {
  padding: 0.8rem;
  flex: 1;
  span {
    text-transform: uppercase;
    color: fadeout($black, 60);
    font-size: .9rem;
  }
}
.contactfieldsfield-field-action {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 60);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.contactfieldsfield-add {
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}

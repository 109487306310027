.email-composer {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.email-composer-body {
  flex-direction: column;
  display: flex;
  flex: 1;
  .ql-toolbar.ql-snow {
    border: 1px solid fadeout($black, 90);
    background: fadeout($black, 98);
    border-radius: 0.4rem;
    margin: 0.8rem;
    order: 2;
    padding: 0;
    display: inline-flex;
    .ql-color-picker {
      font-size: 1rem;
      width: auto;
      margin: 0.6rem 0.4rem;
    }
    .ql-picker.ql-expanded {
      .ql-picker-label {
        border-color: transparent;
        color: $blue !important;
      }
      .ql-picker-options {
        left: -0.6rem;
        top: auto;
        width: calc(18rem + 12px);
        bottom: 2.4rem;
      }
      .ql-picker-item {
        margin: 0;
        width: 1.8rem;
        height: 1.8rem;
        &:not([data-value]) {
          background: linear-gradient(135deg, $white 0, $white 45%, $red 45%, $red 55%, $white 55%, $white 100%);
        }
      }
    }
    .ql-formats {
      margin-right: 0 !important;
      display: flex !important;
      .maha-icon {
        font-size: 1.2rem;
      }
      button {
        @include flex-justify-middle-center;
        width: auto;
        height: auto;
        div {
          display: flex;
          flex: 1;
          padding: 0.8rem 0.4rem;
        }
      }
    }
  }
  .ql-container.ql-snow {
    order: 1;
    flex: 1;
    border: none;
    &:focus-visible {
      outline: none;
    }
  }
  .ql-editor {
    p {
      font-family: Open Sans,sans-serif;
      font-size: 14px;
      line-height: 1.6rem;
    }
  }
  .quill {
    display: flex;
    flex-direction: column;
  }
}
.email-composer-attachments {
  padding: 0.8rem;
}
.email-composer-attachment {
  width: 180px;
  display: inline-block;
  border: 1px solid fadeout($black, 90);
  margin: 0 0.4rem 0.4rem 0;
  border-radius: 0.4rem;
  position: relative;
  .maha-asset-token {
    border: none;
  }
  .maha-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (max-width: 574px) {
  .email-composer-body {
    .ql-formats {
      flex: 1;
      button {
        flex: 1;
      }
      .maha-icon {
        font-size: 1.2rem;
      }
    }
  }
}

.maha-handset-button-container {
  margin: 0 auto;
}
.maha-handset-button {
  @include flex-justify-middle-center;
  background-color: fadeout($black, 70);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  position: relative;
  &:active {
    transform: scale(0.95, 0.95);
  }
  .maha-icon {
    color: $white;
    font-size: 1.6rem;
  }
  &.disabled {
    cursor: default;
    opacity: 0.5;
    &:active {
      transform: none;
    }
  }
  &.depressed {
    background-color: $blue;
  }
  &.call,
  &.pickup {
    background-color: $green;
  }
  &.hangup {
    background-color: $red;
    .maha-icon {
      transform: rotate(135deg);
      color: $white;
    }
  }
}
.maha-handset-button-label {
  text-align: center;
  font-size: 0.8rem;
}
.maha-collection-table-cell {
  .maha-handset-button {
    width: 1.5rem;
    height: 1.5rem;
    .maha-icon {
      font-size: 0.7rem;
    }
  }
}

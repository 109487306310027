.maha-results {
  background-color: $white;
  @include vscroll;
  flex: 1;
}
.maha-result {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-result-details {
  flex: 1;
  padding: 0.8rem;
  span {
    color: fadeout($black, 40);
  }
}
.maha-result-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 80);
  }
}

.maha-message {
  background-color: $white;
	position: relative;
  display: flex;
	padding: 0;
	flex: 1;
	@include colorize(background-color);
	&.darkblue,&.red,&.orange,&.yellow,&.green,&.blue,&.purple,&.violet,&.teal {
		h3, p, .link {
			color: $white !important;
		}
		.ui.basic.button {
			box-shadow: 0 0 0 1px $white inset !important;
			background: none !important;
			color: $white !important;
			@include hover {
				box-shadow: 0 0 0 1px $white inset !important;
				background: fadeout($white, 80) !important;
				color: $white !important;
			};
		}
		i {
			box-shadow: 0 0 0 0.1rem fadeout($white, 40) inset;
			background: none !important;
			color: $white !important;
		}
	}
}
.maha-message-panel {
	margin: auto;
  text-align: center;
	width: 80%;
  max-width: 600px;
	padding: 3.2rem 0.8rem;
  h3 {
    font-size: 1.6rem;
    line-height: 2.6rem;
    margin: 0;
		color: fadeout($black, 40);
  }
  p {
    color: fadeout($black, 40);
		line-height: 1.8rem;
  }
  .ui.basic.button {
		margin: 1.6rem 0 0;
		background: $white !important;
    display: block;
		cursor: pointer;
  }
	.maha-buttons {
		margin: 0 0 1.6rem;
	}
	.link {
    @include hover {
			text-decoration: underline;
		};
	}
}
.maha-message-panel-preview {
	margin: 0 0 0.4rem;
	img {
		padding: 1.5rem !important;
		width: 100% !important;
		max-width: 300px !important;
	}
}
.maha-message-panel-icon {
	margin: 0 0 0.4rem;
	h2 {
		font-size: 3.6rem;
	}
	i {
		border-radius: 50% !important;
		padding: 1.6rem !important;
		box-shadow: 0 0 0 4px fadeout($black, 90) inset;
		width: 7rem !important;
		height: 7rem !important;
		color: fadeout($black, 40);
		background-color: fadeout($white, 20);
    @include colorize(color);
	}
	img {
		border-radius: 50% !important;
		line-height: 1 !important;
		padding: 1.5rem !important;
		box-shadow: 0 0 0 0.25rem fadeout($black, 90) inset;
		width: 7rem !important;
		height: 7rem !important;
		background-color: fadeout($white, 40);
	}
}

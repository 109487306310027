.truevail-channelmapfield-subscription {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  background-color: $white;
  margin: 0 0 0.8rem;
}
.truevail-channelmapfield-subscription-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  display: flex;
}
.truevail-channelmapfield-subscription-header-details {
  padding: 0.8rem;
  flex: 1;  
}
.truevail-channelmapfield-subscription-header-status {}
.truevail-channelmapfield-subscription-body {
  .maha-input {
    border-radius: 0;
    border: none;
  }
}
.campaigns-social-designer {
  display: flex;
  flex: 1;
}
.campaigns-social-designer-preview {
  display: flex;
  flex: 1;
}
.campaigns-social-designer-canvas {
  display: flex;
  flex: 1;
  border-right: 1px solid fadeout($black, 90);
  iframe {
    border: none;
    flex: 1;
    display: block;
    position: relative;
    z-index: 1;
  }
}
.campaigns-social-designer-form {
  flex: 0 0 350px;
  display: flex;
}

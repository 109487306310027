.numberfield-number {
  cursor: pointer;
  display: flex;
}
.numberfield-number-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-icon {
    font-size: 2rem;
  }
}
.numberfield-number-label {
  padding: 0.8rem;
  flex: 1;
  color: fadeout($black, 40);
  strong {
    color: $black;
  }
}

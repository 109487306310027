.maha-recipientfield {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  background-color: $white;
}
.maha-recipientfield-recipients {
  flex-wrap: wrap;
  padding: 0.2rem;
  display: flex;
  flex: 1;
}
.maha-recipientfield-recipient {
  white-space: nowrap;
  display: inline-flex;
  background-color: fadeout($blue, 90);
  color: fadeout($blue, 30);
  border-radius: 0.4rem;
  margin: 0.2rem;
  &.error {
    background-color: fadeout($red, 90) !important;
    color: fadeout($red, 30) !important;
    .maha-recipientfield-recipient-action {
      @include hover {
        .maha-icon {
          color: fadeout($red, 30);
        }
      };
      .maha-icon {
        color: fadeout($red, 70);
      }
    }
  }
  &:focus {
    outline: none !important;
    background-color: fadeout($blue, 70);
  }
}
.maha-recipientfield-recipient-label {
  padding: 0.4rem;
  flex: 1;
}
.maha-recipientfield-recipient-action {
  @include flex-justify-middle-center;
  padding: 0.2rem 0.4rem 0.2rem 0;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($blue, 30);
    }
  };
  .maha-icon {
    color: fadeout($blue, 70);
  }
}
.maha-recipientfield-field {
  flex: 2 1 calc(50%);
  margin: 0.2rem !important;
  padding: 0.4rem 0 !important;
  border: none !important;
  outline: none !important;
}
.maha-recipientfield-lookup {
  flex-direction: column;
  position: absolute;
  background: $white;
  display: flex;
  z-index: 10;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.maha-recipientfield-lookup-header {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.maha-recipientfield-lookup-header-label {
  padding: 0.8rem;
}
.maha-recipientfield-lookup-header-field {
  display: flex;
  flex: 1;
}
.maha-recipientfield-lookup-header-action {
  @include flex-justify-middle-center;
  cursor: pointer;
  padding: 0.8rem;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.maha-recipientfield-lookup-body {
  display: flex;
  flex: 1;
}
.maha-recipientfield-result {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}

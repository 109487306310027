.card-token {
  display: flex;
}
.card-token-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  img {
    width: 30px;
    height: 20px;
  }
}
.card-token-label {
  padding: 0.8rem;
}

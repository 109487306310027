.sender-token {
  display: flex;
}
.sender-token-logo {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 1.8rem;
    font-size: 0.8rem;
  }
}
.sender-token-label {
  flex: 1;
  padding: 0.8rem;
  @media (max-width: 1024px) {
    span {
      display: block;
    }
  }
}
.sender-token-extra {
  padding: 0.8rem 0.8rem 0.8rem 0;
}

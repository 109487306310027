.maha-numericfield {
  overflow: hidden;
  .maha-input-field input {
    padding: 0.4rem !important;;
  }
  span {
    padding: 0.45rem 0.4rem 0.35rem 0;
    color: fadeout($black, 60);
  }
  &.disabled {
    .maha-numericfield-controls {
      & > div {
        cursor: auto;
      }
      .maha-icon {
        color: fadeout($black, 80) !important;
      }
    }
  }
  @include hover {
    .maha-numericfield-controls {
      opacity: 1;
    }
  };
}
.maha-numericfield-controls {
  border-left: 1px solid fadeout($black, 90);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 16px;
  opacity: 0;
  transition: opacity .2s ease;
  & > div {
    @include flex-justify-middle-center;
    background-color: lighten($black, 97);
    cursor: pointer;
    flex: 1;
    &:nth-child(2) {
      border-top: 1px solid fadeout($black, 90);
      border-bottom: 1px solid fadeout($black, 90);
    }
    @include hover {
      .maha-icon {
        color: fadeout($black, 50);
      }
    };
    .maha-icon {
      font-size: 10px;
      line-height: 10px;
      display: block;
      color: fadeout($black, 70);
    }
    .fa-times {
      font-size: 8px;
    }
  }
}

.store-refundfield {
  border: 1px solid fadeout($black, 90);
}
.store-refundfield-body {
  table {
    border: none;
  }
  tbody tr {
    &.disabled {
      td {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          position: relative;
          &:after {
            background-color: fadeout($white, 50);
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
    td:nth-child(1) {
      cursor: pointer;
      padding: 0.8rem 0 0.8rem 0.6rem;
      width: 30px;
    }
    td:nth-child(3) {
      width: 90px;
    }
    td:nth-child(4) {
      text-align: right;
      width: 75px;
    }
  }
  tfoot tr {
    td:nth-child(3) {
      text-align: right;
      width: 80px;
    }
  }
  .maha-icon {
    font-size: 1.2rem;
  }
  .fa-circle-o {
    color: fadeout($black, 80);
  }
  .fa-check-circle {
    color: $blue;
  }
}
.store-refundfield-footer {
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}

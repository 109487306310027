.maha-options {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  display: inline-flex;
  .maha-options-option:last-child {
    border-right: none;
  }
}
.maha-options-option {
  border-right: 1px solid fadeout($black, 90);
  text-align: center;
  flex: 1;
  cursor: pointer;
  padding: 0.8rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
  color: fadeout($black, 60);
  @include hover {
    background-color: fadeout($black, 97);
  };
  &.active {
    background-color: fadeout($blue, 85);
    color: $blue;
  }
}

.optionsfield-options {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  background: $white;
}
.optionsfield-option {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.optionsfield-option-title {
  padding: 0.8rem;
  flex: 1;
  color: fadeout($black, 40);
  strong {
    color: $black;
  }
}
.optionsfield-option-action {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.optionsfield-add {
  padding: 0.8rem;
}

.mjson-designer {
  .mjson-designer-outline {
    .mjson-designer-outline-item-icon {
      padding: 0.4rem 0;
    }
    .mjson-designer-outline-item-details {
      padding: 0.4rem 0;
    }
    .mjson-designer-outline-item-action {
      padding: 0.4rem;
    }
    .disabled {
      &:hover {
        background: none !important;
        box-shadow: none;
      };
      .mjson-designer-outline-item-icon {
        .maha-icon {
          color: fadeout($black, 60);
        }
      }
      .mjson-designer-outline-item-details {
        color: fadeout($black, 60);
      }
    }
  }
}
.mjson-designer-template,
.mjson-designer-module,
.mjson-designer-modal,
.mjson-designer-page,
.mjson-designer-post,
.mjson-designer-email {
  &:hover, &.hover {
    box-shadow: inset 0 0 0 2px $blue;
  }
  .mjson-designer-outline-item-icon .maha-icon {
    color: $blue;
  }
}
.mjson-designer-container {
  &:hover, &.hover {
    box-shadow: inset 0 0 0 2px $teal;
  }
  .mjson-designer-outline-item-icon .maha-icon {
    color: $teal;
  }
}
.mjson-designer-section {
  &:hover, &.hover {
    box-shadow: inset 0 0 0 2px $teal;
  }
  .mjson-designer-outline-item-icon .maha-icon {
    color: $teal;
  }
}
.mjson-designer-row {
  &:hover, &.hover {
    box-shadow: inset 0 0 0 2px $violet;
  }
  .mjson-designer-outline-item-icon .maha-icon {
    color: $violet;
  }
}
.mjson-designer-column {
  &:hover, &.hover {
    box-shadow: inset 0 0 0 2px $purple;
  }
  .mjson-designer-outline-item-icon .maha-icon {
    color: $purple;
  }
}
.mjson-designer-block {
  &:hover, &.hover {
    box-shadow: inset 0 0 0 2px $pink;
  }
  .mjson-designer-outline-item-icon .maha-icon {
    color: $pink;
  }
}
.mjson-designer-dynamic {
  &:hover, &.hover {
    box-shadow: inset 0 0 0 2px $red;
  }
  .mjson-designer-outline-item-icon .maha-icon {
    color: $red;
  }
}
.mjson-designer-collection {
  &:hover, &.hover {
    box-shadow: inset 0 0 0 2px $green;
  }
  .mjson-designer-outline-item-icon .maha-icon {
    color: $green;
  }
}
.mjson-designer-content {
  &:hover, &.hover {
    box-shadow: inset 0 0 0 2px $red;
  }
  .mjson-designer-outline-item-icon .maha-icon {
    color: $red;
  }
}
.mjson-designer-empty {
  .mjson-designer-outline-item-icon .maha-icon {
    color: $red;
  }
}

.maha-attachments-photos {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.maha-attachments-photos-header {
  background-color: fadeout($black, 95);
  display: flex;
  color: fadeout($black, 50);
}
.maha-attachments-photos-header-icon {
  padding: 0.8rem 0 0.8rem 0.8rem;
  img {
    overflow: hidden;
    border-radius: 0.3rem;
    display: block;
    height: 18px;
    width: 18px;
  }
}
.maha-attachments-photos-header-label {
  padding: 0.8rem;
}
.maha-attachments-photos-body {
  display: flex;
  flex: 1;
}
.maha-attachments-photos-items {
  padding: 0.2rem;
  overflow: hidden;
}
.maha-attachments-photo-item {
  position: relative;
  float: left;
  height: 0;
  overflow: hidden;
  cursor: pointer;
  background-size: cover;
  overflow: hidden;
  margin: 0.2rem;
  display: flex;
  @media (max-width: 768px) {
    width: calc(100% / 3 - 0.4rem);
    padding-bottom: calc(100% / 3 - 0.4rem);
  }
  @media (min-width: 769px) {
    width: calc(100% / 5 - 0.4rem);
    padding-bottom: calc(100% / 5 - 0.4rem);
  }
}
.maha-attachments-photo-image {
  @include flex-justify-middle-center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .maha-icon {
    font-size: 2rem;
    background-color: fadeout($black, 97);
    color: $white;
  }
}

.product-token {
  display: flex;
}
.product-token-photo {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-image {
    overflow: hidden;
  }
  img {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
  }
}
.product-token-icon {
  @include flex-justify-middle-center;
  overflow: hidden;
  background-color: fadeout($blue, 80);
  color: $white;
  width: 1.8rem;
  height: 1.8rem;
  .fa-shopping-bag {
    font-size: 0.9rem;
  }
}
.product-token-label {
  padding: 0.8rem;
  line-height: 1.8rem;
  .active {
    color: $red;
    margin-left: 5px;
    font-size: .8rem;
    line-height: 2rem;
  }
}

.datasets-apifield {
  tr:not(.header) td {
    padding: 0.4rem 0.8rem;
    line-height: 1rem;
  }
  tr.header {
    cursor: pointer;
  }
}
.datasets-apifield-type {
  display: inline-block;
  overflow: hidden;
  margin-left: 0.4rem;
  cursor: pointer;
  display: flex;
  .maha-icon {
    margin: auto 0 auto auto;
    font-size: 1.4rem;
    margin-right: 0.4rem;
  }
  .fa-toggle-off {
    color: fadeout($black, 80);
  }
  .fa-toggle-on {
    color: $green;
  }
  &.disabled {
    color: fadeout($black, 80) !important;
  }
}

.datasets-apifield-option {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  padding: 0.6rem;
  display: inline-block;
  overflow: hidden;
  margin-left: 0.4rem;
  line-height: 1rem;
  cursor: pointer;
  .maha-icon {
    margin-right: 0.4rem;
  }
  .fa-circle-o {
    color: fadeout($black, 80);
  }
  .fa-check-circle {
    color: $blue;
  }
  &.disabled {
    color: fadeout($black, 80) !important;
  }
}
.datasets-apifield-method {
  color: fadeout($black, 60) !important;
}

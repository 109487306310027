.maha-token {
  display: flex;
  flex: 1;
}
.maha-token-details {
  padding: 0.8rem;
  flex: 1;
  line-height: 1.4rem;
  color: $black;
  span {
    color: fadeout($black, 40);
  }
}
.stores-inventoryfield {
  margin: -0.8rem;
  .maha-input {
    width: 50px;
  }
  input {
    padding: 0.4rem !important;
    text-align: right;
  }

}

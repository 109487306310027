.campaigns-social-schedule-week {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.campaigns-social-schedule-week-header {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.campaigns-social-schedule-week-weekday {
  border-right: 1px solid fadeout($black, 90);
  flex: 0 0 calc((100% - 60px) / 7);
  color: fadeout($black, 40);
  text-align: center;
  padding: 0.4rem;
  span {
    padding: 0.2rem 0.4rem;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
  &.today span {
    background-color: $blue;
    color: $white;
  }
}
.campaigns-social-schedule-week-scroller {
  width: 10px;
}
.campaigns-social-schedule-week-body {
  @include vscroll;
  flex: 1;
}
.campaigns-social-schedule-week-days {
  width: 100%;
  display: flex;
}
.campaigns-social-schedule-week-legend {
  border-right: 1px solid fadeout($black, 90);
  flex: 0 0 50px;
}
.campaigns-social-schedule-week-label {
  text-transform: uppercase;
  color: fadeout($black, 40);
  font-size: 0.8rem;
}
.campaigns-social-schedule-week-day {
  border-right: 1px solid fadeout($black, 90);
  flex-direction: column;
  display: flex;
  flex: 0 0 calc((100% - 50px) / 7);
}
.campaigns-social-schedule-week-hour {
  position: relative;
  border-bottom: 1px solid fadeout($black, 90);
  .campaigns-social-schedule-week-slot:last-child {
    border-bottom: none;
  }  
}
.campaigns-social-schedule-week-now {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: $blue;
  &:before, &:after {
    background: $blue;
    border-radius: 50%;
    content: "";
    position: absolute;
    height: 7px;
    top: -2.5px;
    width: 7px;
  }
  &:before {
    left: -3.5px;
  }
  &:after {
    right: -3.5px;
  }
}
.campaigns-social-schedule-week-slot {
  position: relative;
  border-bottom: 1px dashed fadeout($black, 95);
  padding: 0.4rem;
  &.hover {
    background-color: fadeout($blue, 95);
    * {
      pointer-events: none;
    }    
  }
}
.campaigns-social-schedule-scheduled-tokens {
  position: absolute;
  padding: 0.1rem;
  display: flex;
  top: 0;
  left: 0;
}

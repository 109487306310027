.website-token {
  overflow: hidden;
  display: flex;
}
.website-token-image {
  display: flex;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-mjson-screenshot {
    width: 100px;
  }
}
.website-token-details {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0.8rem;
  line-height: 1.6rem;
}

.tickettype-token {
  flex: 1;
  padding: 0.4rem 0.8rem;
  color: fadeout($black, 40);
  line-height: 1.6rem;
  &.inactive {
    strong {
      color: fadeout($black, 80);
    }
    color: fadeout($black, 80);
  }
  strong {
    color: $black;
  }
  .fa-calendar {
    color: $blue;
  }
  .fa-desktop {
    color: $black;
  }
  .fa-map-marker {
    color: $red;
  }
}
.tickettype-toke-description {
  max-width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.call-token {
  display: flex;
}
.call-token-avatar {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  position: relative;
  .maha-logo,
  .maha-avatar {
    width: 2.4rem
  }
}
.call-token-direction {
  position: absolute;
  bottom: 0.8rem;
  right: 0.4rem;
  display: inline-flex;
  border: 1px solid $blue;
  background-color: $white;
  border-radius: 50%;
  padding: 0.2rem;
  .maha-icon {
    margin: auto;
    font-size: 0.6rem;
    color: $blue;
  }
  .fa-circle {
    line-height: 1.1rem;
    transition: color 2s;
    animation: pulse 2s infinite;
  }
}
.call-token-details {
  position: relative;
  padding: 0.8rem 0.8rem 0.8rem 0;
  flex: 1;
}
.call-token-recipient {
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.call-token-number {
  color: fadeout($black, 60);
}
.call-token-timestamp {
  position: absolute;
  top: calc(50% - 0.4rem);
  right: 0;
  font-size: 0.8rem;
  line-height: 1rem;
  color: fadeout($black, 60);
}
.call-token-status {
  position: absolute;
  top: calc(50% - 0.4rem);
  right: 0;
  font-size: 0.8rem;
  line-height: 1rem;
  text-transform: uppercase;
  &.initiated { color: $teal; }
  &.queued { color: $teal; }
  &.pending { color: $teal; }
  &.ringing { color: $blue; }
  &.on-hold { color: $blue; }
  &.transferring { color: $blue; }
  &.in-progress { color: $green; }
}

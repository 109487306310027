.websites-domain-lookup {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.websites-domain-lookup-header {
  border-bottom: 1px solid fadeout($black, 90);
}
.websites-domain-lookup-body {
  display: flex;
  flex: 1;
}
.websites-domain-lookup-results {
  flex: 1;
  @include vscroll;
}
.websites-domain-lookup-results-header {
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.websites-domain-lookup-result {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
  &.unavailable {
    color: $red;
  }
  &:not(.unavailable) {
    cursor: pointer;
  }

}
.websites-domain-lookup-result-name {
  padding: 0.8rem 0 0.8rem 0.8rem;
  flex: 1;
}
.websites-domain-lookup-result-status {
  padding: 0.8rem;
  display: flex;
}
.websites-domain-lookup-result-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black,80);
  }
}

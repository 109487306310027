.maha-progress-token {
  background-color: $grey;
  padding: 0.6rem;
  width: 100%;
  .maha-progress-token-step:last-child {
    &:after,
    &:before {
      display: none;
    }
  }
}
.maha-progress-token-steps {
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  gap: 0.6rem;
}
.maha-progress-token-step {
  background-color: $white;
  flex: 1;
  height: 3rem;
  padding: 0.6rem;
  position: relative;
  text-align: center;
  font-size: 0.8rem;
  color: fadeout($black, 60);
  &:before, &:after {
    z-index: 1;
    position: absolute;
    top: 0;
    content: '';
    width: 0px !important;
    height: 0px !important;
    border-top: 1.5rem solid transparent;
    border-bottom: 1.5rem solid transparent;
  }
  &:before {
    right: -2.1rem;
    border-left: 1.5rem solid $grey
  }
  &:after {
    right: calc(-1.5rem + 1px);
    border-left: 1.5rem solid $white;
  }
  &.inprogress {
    background-color: $blue;
    color: $white;
    &:after {
      border-left-color: $blue;
    }
  }
  &.failure {
    background-color: $red;
    color: $white;
    &:after {
      border-left-color: $red;
    }
  }
  &.success {
    background-color: $green;
    color: $white;
    &:after {
      border-left-color: $green;
    }
  }
}

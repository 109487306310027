.maha-collection {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: lighten($black, 95);
}
.maha-collection-header {
  background-color: $darkblue;
  @media (max-width: 574px) {
    padding: 0 0.8rem 0.8rem;
  }
  @media (min-width: 575px) {
    padding: 0.8rem;
  }
  display: flex;
}
.maha-collection-header-bar {
  background-color: fadeout($white, 50);
  border-radius: 0.4rem;
  display: flex;
  flex: 1;
  & > div {
    border-right: 1px solid $darkblue;
  }
  .maha-searchbox {
    padding: 0;
  }
}
.maha-collection-header-button {
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: $white;
    }
  };
  .maha-icon {
    color: fadeout($white, 20);
  }
}
.maha-collection-header-action {
  @include flex-justify-middle-center;
  position: relative;
  cursor: pointer;
  padding: 0.4rem;
  flex: 0 0 2.5rem;
  .maha-icon {
    color: $white;
    font-size: 1.2rem;
    line-height: 1rem;
  }
  @include hover {
    background-color: fadeout($white, 80);
  };
}
.maha-collection-main {
  display: flex;
  flex: 1;
}
.maha-collection-sidebar {
  background-color: $white;
  display: flex;
}
.maha-collection-body {
  background-color: $white;
  display: flex;
  flex: 1;
  .maha-infinite-body {
    flex-direction: column;
    display: flex;
    flex: 1;
  }
  .maha-infinite {
    flex: 1;
  }
}
.maha-collection-chart {
  padding: 0.8rem;
  display: flex;
  flex: 1;
}
.maha-collection-footer {
  @include translate-y-transition(100%, 0, .15s);
  display: flex;
  background-color: $black;
  button.ui.button {
    &.disabled,
    &:disabled {
      opacity: 1 !important;
      position: relative;
      overflow: hidden;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: fadeout($white, 30);
      }
    }
  }
}
.maha-collection-footer-items {
  margin: auto;
  width: 100%;
  max-width: 800px;
  display: flex;
}
.maha-collection-footer-item {
  flex: 1;
  display: flex;
  padding: 0 0.4rem;
  & > * {
    margin: auto !important;
  }
}
.maha-collection-footer-count {
  @include flex-justify-middle-center;
  flex: 0 0 3.2rem;
  .count {
    color: $white;
  }
}
.maha-collection-footer-text {
  color: $white;
  padding: 0.8rem 0 0.8rem 0.8rem;
  line-height: 2.4rem;
}
.maha-collection-footer-buttons {
  padding: 0.8rem 0.4rem 0.8rem 0;
  flex: 1;
}
.maha-collection-loader {
  flex: 0 0 3.5rem;
  position: relative;
  background: #DDD;
}
.maha-collection-empty {
  flex: 1;
  display: flex;
  margin: 20px;
  border: 3px dashed #DDD;
  background-color: fadeout($black, 98%)
}
.maha-collection-empty-message {
  margin: auto;
  text-align: center;
  padding: 20px;
  height: 19rem;
  h2 {
    margin: 0 0 20px;
    font-size: 50px;
    .maha-icon {
      background-color: $white;
    }
  }
  h3 {
    font-size: 22px;
    line-height: 28px;
    margin: 0;
    color: fadeout($black, 40);
  }
  p {
    margin: 0 0 20px;
    color: #888;
  }
  .ui.basic.red.button {
    display: block;
    background: $white !important;
  }
}


.maha-collection-dropdown {
  position: absolute;
  right: 0;
  min-width: 150px;
  border-radius: 0.3rem;
  background-color: $white;
  border: 1px solid fadeout($black, 95);
  z-index: 2;
  font-weight: normal;
  box-shadow: 0 3px 8px fadeout($black, 70);
  top: calc(100% + 10px);
  &:after {
    bottom: 100%;
    border-bottom-color: $white !important;
  }
  &:before {
    bottom: 100%;
    border-bottom-color: fadeout($black, 85) !important;
  }
  &:after,
  &:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-width: 9px;
    margin-left: -9px;
    right: 10px;
  }
  &:before {
    border-width: 10px;
    margin-left: -10px;
    right: 9px;
  }
}
.maha-collection-dropdown-item {
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  color: fadeout($black, 50);
  @include hover {
    background-color: fadeout($black, 97);
  };
}
@media (max-width: 900px) {
  .maha-collection {
    &:not(.filtering) {
      .maha-collection-sidebar {
        transform: translateY(100%);
      }
    }
    &.filtering {
      .maha-collection-sidebar {
        transform: translateY(0);
      }
    }
  }
  .maha-collection {
    position: relative;
  }
  .maha-collection-sidebar {
    transition: transform .25s ease-in-out;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 901px) {
  .maha-collection {
    &:not(.filtering) {
      .maha-collection-sidebar {
        display: none;
      }
    }
    &.filtering {
      .maha-collection-sidebar {
        display: flex;
      }
    }
  }
  .maha-collection-sidebar {
    flex: 0 0 300px;
    border-right: 1px solid fadeout($black, 95);
    order: 0;
  }
  .maha-collection-body {
    order: 1;
  }
}

.truevail-profilesetupfield-profile-details {
  flex: 1;
}
@media (min-width: 769px) {
  .truevail-profilesetupfield-profile-details {
    display: flex;
  }
  .truevail-profilesetupfield-profile-token {
    flex: 1;
  }
  .truevail-profilesetupfield-profile-hashtags {
    padding: 0.8rem;
    flex: 0 0 300px;
  }
}
@media (max-width: 768px) {
  .truevail-profilesetupfield-profile-hashtags {
    padding: 0 0.8rem 0.8rem;
  }
}

.maha-copytext {
  display: inline-flex;
}
.maha-copytext-message {
  border: 1px solid fadeout($black, 90);
  width: 80px;
  position: absolute;
  background-color: $white;
  top: -6px;
  left: 50%;
  transform: translate(-50%, -100%);
  display: block;
}
.maha-copytext-message-inner {
  position: relative;
  color: darken($green, 10);
  text-align: center;
  padding: 0.4rem 0.8rem;
  &:before, &:after {
    content: '';
  	position: absolute;
  	left: 50%;
  	width: 0;
  	height: 0;
  	border: 8px solid transparent;
  	border-bottom: 0;
  	margin-left: -8px;
  	margin-bottom: -8px;
  }
  &:before {
  	bottom: 0;
  	border-top-color: fadeout($black, 90);
  }
  &:after {
  	bottom: 2px;
  	border-top-color: $white;
  }
  .maha-icon {
    font-size: 1.2rem;
  }
  @include hover {
    .fa-clone {
      color: fadeout($black, 20);
    }
    .maha-copytext-text {
      color: fadeout($black, 20);
    }
  };
}
.maha-copytext-icon {
  @include flex-justify-middle-center;
  position: relative;
  .fa-clone {
    cursor: pointer;
    color: fadeout($black, 60);
    @include hover {
      color: fadeout($black, 20);
    };
  }
  .fa-check-circle {
    color: $green !important;
  }
}
.maha-copytext-text {
  cursor: pointer;
  padding: 0 0 0 0.4rem;
  word-break: break-word;
}

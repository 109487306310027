.maha-dns-table {
  p {
    line-height: 1.8rem;
    color: fadeout($black, 40);
  }
  table {
    font-size: 0.7rem !important;
    .maha-status-token {
      margin: -0.4rem -0.7rem;
    }
  }
  .maha-icon {
    font-size: 1.2rem;
  }
  .fa-check-circle {
    color: $green;
  }
  .fa-times {
    color: $red;
  }
  .ui.table tfoot td {
    background-color: fadeout($black, 97);
    padding: 0.6rem !important;
  }
}
.maha-dns-table-footer {
  width: 100%;
  display: flex;
}
.maha-dns-table-footer-message {
  display: flex;
  align-items: center;
  flex: 1;
  color: fadeout($black, 60);
}
.maha-dns-table-footer-button {
  .ui.button {
    margin: 0;
  }
}
.maha-dns-table-footer-notice {
  background-color: fadeout($yellow, 80);
  padding: 0.8rem;
  border-radius: 0.4rem;
}

.maha-dropzone {
  flex: 1;
  display: flex;
  position: relative;
}
.maha-dropzone-hovering {
  background-color: fadeout($white, 10);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.crm-productfield {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.3rem;
  overflow: hidden;
  &:focus {
    outline: none !important;
    border-color: $lightblue;
    border-radius: .28571429rem;
    background: $white;
    box-shadow: none;
    .phonesfield-number:first-child {
      outline: none !important;
      border-color: $lightblue;
      background: $white;
      box-shadow: none;
    }
  }
}
.crm-productfield-product {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.crm-productfield-product-handle {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  cursor: move;
  .maha-icon {
    color: fadeout($black, 60);
  }
}
.crm-productfield-product-label {
  flex: 1;
  padding: 0.8rem;
}
.crm-productfield-product-price {
  color: fadeout($black, 50);
}
.crm-productfield-product-remove {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 60);
  }
}
.crm-productfield-product-add {
  background-color: fadeout($black, 98);
  padding: 0.8rem;
}
.crm-productfield-chooser-products {
  flex: 1;
}
.crm-productfield-chooser-product {
  border-bottom: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  @include hover {
    background-color: fadeout($black, 97);
  };
}

.emoji {
  display: inline-block;
  background-image: url('/images/emojis/apple.png');
  background-size: 67.60000000000001em 67.60000000000001em;
  width: 1.3em;
  height: 1.3em;
  overflow: hidden;
  text-indent: -9999px;
  margin: 0 0.1em -0.2em;
}
.emoji-apple {
  background-image: url('/images/emojis/apple.png') !important;
}
.emoji-facebook {
  background-image: url('/images/emojis/facebook.png') !important;
}
.emoji-google {
  background-image: url('/images/emojis/google.png') !important;
}
.emoji-messenger {
  background-image: url('/images/emojis/messenger.png') !important;
}
.emoji-twitter {
  background-image: url('/images/emojis/twitter.png') !important;
}
.emoji-0023-fe0f-20e3 {
  background-position: 67.60em 67.60em;
}
.emoji-002a-fe0f-20e3 {
  background-position: 67.60em 66.30em;
}
.emoji-0030-fe0f-20e3 {
  background-position: 67.60em 65.00em;
}
.emoji-0031-fe0f-20e3 {
  background-position: 67.60em 63.70em;
}
.emoji-0032-fe0f-20e3 {
  background-position: 67.60em 62.40em;
}
.emoji-0033-fe0f-20e3 {
  background-position: 67.60em 61.10em;
}
.emoji-0034-fe0f-20e3 {
  background-position: 67.60em 59.80em;
}
.emoji-0035-fe0f-20e3 {
  background-position: 67.60em 58.50em;
}
.emoji-0036-fe0f-20e3 {
  background-position: 67.60em 57.20em;
}
.emoji-0037-fe0f-20e3 {
  background-position: 67.60em 55.90em;
}
.emoji-0038-fe0f-20e3 {
  background-position: 67.60em 54.60em;
}
.emoji-0039-fe0f-20e3 {
  background-position: 67.60em 53.30em;
}
.emoji-00a9-fe0f {
  background-position: 67.60em 52.00em;
}
.emoji-00ae-fe0f {
  background-position: 67.60em 50.70em;
}
.emoji-1f004 {
  background-position: 67.60em 49.40em;
}
.emoji-1f0cf {
  background-position: 67.60em 48.10em;
}
.emoji-1f170-fe0f {
  background-position: 67.60em 46.80em;
}
.emoji-1f171-fe0f {
  background-position: 67.60em 45.50em;
}
.emoji-1f17e-fe0f {
  background-position: 67.60em 44.20em;
}
.emoji-1f17f-fe0f {
  background-position: 67.60em 42.90em;
}
.emoji-1f18e {
  background-position: 67.60em 41.60em;
}
.emoji-1f191 {
  background-position: 67.60em 40.30em;
}
.emoji-1f192 {
  background-position: 67.60em 39.00em;
}
.emoji-1f193 {
  background-position: 67.60em 37.70em;
}
.emoji-1f194 {
  background-position: 67.60em 36.40em;
}
.emoji-1f195 {
  background-position: 67.60em 35.10em;
}
.emoji-1f196 {
  background-position: 67.60em 33.80em;
}
.emoji-1f197 {
  background-position: 67.60em 32.50em;
}
.emoji-1f198 {
  background-position: 67.60em 31.20em;
}
.emoji-1f199 {
  background-position: 67.60em 29.90em;
}
.emoji-1f19a {
  background-position: 67.60em 28.60em;
}
.emoji-1f1e6-1f1e8 {
  background-position: 67.60em 27.30em;
}
.emoji-1f1e6-1f1e9 {
  background-position: 67.60em 26.00em;
}
.emoji-1f1e6-1f1ea {
  background-position: 67.60em 24.70em;
}
.emoji-1f1e6-1f1eb {
  background-position: 67.60em 23.40em;
}
.emoji-1f1e6-1f1ec {
  background-position: 67.60em 22.10em;
}
.emoji-1f1e6-1f1ee {
  background-position: 67.60em 20.80em;
}
.emoji-1f1e6-1f1f1 {
  background-position: 67.60em 19.50em;
}
.emoji-1f1e6-1f1f2 {
  background-position: 67.60em 18.20em;
}
.emoji-1f1e6-1f1f4 {
  background-position: 67.60em 16.90em;
}
.emoji-1f1e6-1f1f6 {
  background-position: 67.60em 15.60em;
}
.emoji-1f1e6-1f1f7 {
  background-position: 67.60em 14.30em;
}
.emoji-1f1e6-1f1f8 {
  background-position: 67.60em 13.00em;
}
.emoji-1f1e6-1f1f9 {
  background-position: 67.60em 11.70em;
}
.emoji-1f1e6-1f1fa {
  background-position: 67.60em 10.40em;
}
.emoji-1f1e6-1f1fc {
  background-position: 67.60em 9.10em;
}
.emoji-1f1e6-1f1fd {
  background-position: 67.60em 7.80em;
}
.emoji-1f1e6-1f1ff {
  background-position: 67.60em 6.50em;
}
.emoji-1f1e7-1f1e6 {
  background-position: 67.60em 5.20em;
}
.emoji-1f1e7-1f1e7 {
  background-position: 67.60em 3.90em;
}
.emoji-1f1e7-1f1e9 {
  background-position: 67.60em 2.60em;
}
.emoji-1f1e7-1f1ea {
  background-position: 67.60em 1.30em;
}
.emoji-1f1e7-1f1eb {
  background-position: 66.30em 67.60em;
}
.emoji-1f1e7-1f1ec {
  background-position: 66.30em 66.30em;
}
.emoji-1f1e7-1f1ed {
  background-position: 66.30em 65.00em;
}
.emoji-1f1e7-1f1ee {
  background-position: 66.30em 63.70em;
}
.emoji-1f1e7-1f1ef {
  background-position: 66.30em 62.40em;
}
.emoji-1f1e7-1f1f1 {
  background-position: 66.30em 61.10em;
}
.emoji-1f1e7-1f1f2 {
  background-position: 66.30em 59.80em;
}
.emoji-1f1e7-1f1f3 {
  background-position: 66.30em 58.50em;
}
.emoji-1f1e7-1f1f4 {
  background-position: 66.30em 57.20em;
}
.emoji-1f1e7-1f1f6 {
  background-position: 66.30em 55.90em;
}
.emoji-1f1e7-1f1f7 {
  background-position: 66.30em 54.60em;
}
.emoji-1f1e7-1f1f8 {
  background-position: 66.30em 53.30em;
}
.emoji-1f1e7-1f1f9 {
  background-position: 66.30em 52.00em;
}
.emoji-1f1e7-1f1fb {
  background-position: 66.30em 50.70em;
}
.emoji-1f1e7-1f1fc {
  background-position: 66.30em 49.40em;
}
.emoji-1f1e7-1f1fe {
  background-position: 66.30em 48.10em;
}
.emoji-1f1e7-1f1ff {
  background-position: 66.30em 46.80em;
}
.emoji-1f1e8-1f1e6 {
  background-position: 66.30em 45.50em;
}
.emoji-1f1e8-1f1e8 {
  background-position: 66.30em 44.20em;
}
.emoji-1f1e8-1f1e9 {
  background-position: 66.30em 42.90em;
}
.emoji-1f1e8-1f1eb {
  background-position: 66.30em 41.60em;
}
.emoji-1f1e8-1f1ec {
  background-position: 66.30em 40.30em;
}
.emoji-1f1e8-1f1ed {
  background-position: 66.30em 39.00em;
}
.emoji-1f1e8-1f1ee {
  background-position: 66.30em 37.70em;
}
.emoji-1f1e8-1f1f0 {
  background-position: 66.30em 36.40em;
}
.emoji-1f1e8-1f1f1 {
  background-position: 66.30em 35.10em;
}
.emoji-1f1e8-1f1f2 {
  background-position: 66.30em 33.80em;
}
.emoji-1f1e8-1f1f3 {
  background-position: 66.30em 32.50em;
}
.emoji-1f1e8-1f1f4 {
  background-position: 66.30em 31.20em;
}
.emoji-1f1e8-1f1f5 {
  background-position: 66.30em 29.90em;
}
.emoji-1f1e8-1f1f7 {
  background-position: 66.30em 28.60em;
}
.emoji-1f1e8-1f1fa {
  background-position: 66.30em 27.30em;
}
.emoji-1f1e8-1f1fb {
  background-position: 66.30em 26.00em;
}
.emoji-1f1e8-1f1fc {
  background-position: 66.30em 24.70em;
}
.emoji-1f1e8-1f1fd {
  background-position: 66.30em 23.40em;
}
.emoji-1f1e8-1f1fe {
  background-position: 66.30em 22.10em;
}
.emoji-1f1e8-1f1ff {
  background-position: 66.30em 20.80em;
}
.emoji-1f1e9-1f1ea {
  background-position: 66.30em 19.50em;
}
.emoji-1f1e9-1f1ec {
  background-position: 66.30em 18.20em;
}
.emoji-1f1e9-1f1ef {
  background-position: 66.30em 16.90em;
}
.emoji-1f1e9-1f1f0 {
  background-position: 66.30em 15.60em;
}
.emoji-1f1e9-1f1f2 {
  background-position: 66.30em 14.30em;
}
.emoji-1f1e9-1f1f4 {
  background-position: 66.30em 13.00em;
}
.emoji-1f1e9-1f1ff {
  background-position: 66.30em 11.70em;
}
.emoji-1f1ea-1f1e6 {
  background-position: 66.30em 10.40em;
}
.emoji-1f1ea-1f1e8 {
  background-position: 66.30em 9.10em;
}
.emoji-1f1ea-1f1ea {
  background-position: 66.30em 7.80em;
}
.emoji-1f1ea-1f1ec {
  background-position: 66.30em 6.50em;
}
.emoji-1f1ea-1f1ed {
  background-position: 66.30em 5.20em;
}
.emoji-1f1ea-1f1f7 {
  background-position: 66.30em 3.90em;
}
.emoji-1f1ea-1f1f8 {
  background-position: 66.30em 2.60em;
}
.emoji-1f1ea-1f1f9 {
  background-position: 66.30em 1.30em;
}
.emoji-1f1ea-1f1fa {
  background-position: 65.00em 67.60em;
}
.emoji-1f1eb-1f1ee {
  background-position: 65.00em 66.30em;
}
.emoji-1f1eb-1f1ef {
  background-position: 65.00em 65.00em;
}
.emoji-1f1eb-1f1f0 {
  background-position: 65.00em 63.70em;
}
.emoji-1f1eb-1f1f2 {
  background-position: 65.00em 62.40em;
}
.emoji-1f1eb-1f1f4 {
  background-position: 65.00em 61.10em;
}
.emoji-1f1eb-1f1f7 {
  background-position: 65.00em 59.80em;
}
.emoji-1f1ec-1f1e6 {
  background-position: 65.00em 58.50em;
}
.emoji-1f1ec-1f1e7 {
  background-position: 65.00em 57.20em;
}
.emoji-1f1ec-1f1e9 {
  background-position: 65.00em 55.90em;
}
.emoji-1f1ec-1f1ea {
  background-position: 65.00em 54.60em;
}
.emoji-1f1ec-1f1eb {
  background-position: 65.00em 53.30em;
}
.emoji-1f1ec-1f1ec {
  background-position: 65.00em 52.00em;
}
.emoji-1f1ec-1f1ed {
  background-position: 65.00em 50.70em;
}
.emoji-1f1ec-1f1ee {
  background-position: 65.00em 49.40em;
}
.emoji-1f1ec-1f1f1 {
  background-position: 65.00em 48.10em;
}
.emoji-1f1ec-1f1f2 {
  background-position: 65.00em 46.80em;
}
.emoji-1f1ec-1f1f3 {
  background-position: 65.00em 45.50em;
}
.emoji-1f1ec-1f1f5 {
  background-position: 65.00em 44.20em;
}
.emoji-1f1ec-1f1f6 {
  background-position: 65.00em 42.90em;
}
.emoji-1f1ec-1f1f7 {
  background-position: 65.00em 41.60em;
}
.emoji-1f1ec-1f1f8 {
  background-position: 65.00em 40.30em;
}
.emoji-1f1ec-1f1f9 {
  background-position: 65.00em 39.00em;
}
.emoji-1f1ec-1f1fa {
  background-position: 65.00em 37.70em;
}
.emoji-1f1ec-1f1fc {
  background-position: 65.00em 36.40em;
}
.emoji-1f1ec-1f1fe {
  background-position: 65.00em 35.10em;
}
.emoji-1f1ed-1f1f0 {
  background-position: 65.00em 33.80em;
}
.emoji-1f1ed-1f1f2 {
  background-position: 65.00em 32.50em;
}
.emoji-1f1ed-1f1f3 {
  background-position: 65.00em 31.20em;
}
.emoji-1f1ed-1f1f7 {
  background-position: 65.00em 29.90em;
}
.emoji-1f1ed-1f1f9 {
  background-position: 65.00em 28.60em;
}
.emoji-1f1ed-1f1fa {
  background-position: 65.00em 27.30em;
}
.emoji-1f1ee-1f1e8 {
  background-position: 65.00em 26.00em;
}
.emoji-1f1ee-1f1e9 {
  background-position: 65.00em 24.70em;
}
.emoji-1f1ee-1f1ea {
  background-position: 65.00em 23.40em;
}
.emoji-1f1ee-1f1f1 {
  background-position: 65.00em 22.10em;
}
.emoji-1f1ee-1f1f2 {
  background-position: 65.00em 20.80em;
}
.emoji-1f1ee-1f1f3 {
  background-position: 65.00em 19.50em;
}
.emoji-1f1ee-1f1f4 {
  background-position: 65.00em 18.20em;
}
.emoji-1f1ee-1f1f6 {
  background-position: 65.00em 16.90em;
}
.emoji-1f1ee-1f1f7 {
  background-position: 65.00em 15.60em;
}
.emoji-1f1ee-1f1f8 {
  background-position: 65.00em 14.30em;
}
.emoji-1f1ee-1f1f9 {
  background-position: 65.00em 13.00em;
}
.emoji-1f1ef-1f1ea {
  background-position: 65.00em 11.70em;
}
.emoji-1f1ef-1f1f2 {
  background-position: 65.00em 10.40em;
}
.emoji-1f1ef-1f1f4 {
  background-position: 65.00em 9.10em;
}
.emoji-1f1ef-1f1f5 {
  background-position: 65.00em 7.80em;
}
.emoji-1f1f0-1f1ea {
  background-position: 65.00em 6.50em;
}
.emoji-1f1f0-1f1ec {
  background-position: 65.00em 5.20em;
}
.emoji-1f1f0-1f1ed {
  background-position: 65.00em 3.90em;
}
.emoji-1f1f0-1f1ee {
  background-position: 65.00em 2.60em;
}
.emoji-1f1f0-1f1f2 {
  background-position: 65.00em 1.30em;
}
.emoji-1f1f0-1f1f3 {
  background-position: 63.70em 67.60em;
}
.emoji-1f1f0-1f1f5 {
  background-position: 63.70em 66.30em;
}
.emoji-1f1f0-1f1f7 {
  background-position: 63.70em 65.00em;
}
.emoji-1f1f0-1f1fc {
  background-position: 63.70em 63.70em;
}
.emoji-1f1f0-1f1fe {
  background-position: 63.70em 62.40em;
}
.emoji-1f1f0-1f1ff {
  background-position: 63.70em 61.10em;
}
.emoji-1f1f1-1f1e6 {
  background-position: 63.70em 59.80em;
}
.emoji-1f1f1-1f1e7 {
  background-position: 63.70em 58.50em;
}
.emoji-1f1f1-1f1e8 {
  background-position: 63.70em 57.20em;
}
.emoji-1f1f1-1f1ee {
  background-position: 63.70em 55.90em;
}
.emoji-1f1f1-1f1f0 {
  background-position: 63.70em 54.60em;
}
.emoji-1f1f1-1f1f7 {
  background-position: 63.70em 53.30em;
}
.emoji-1f1f1-1f1f8 {
  background-position: 63.70em 52.00em;
}
.emoji-1f1f1-1f1f9 {
  background-position: 63.70em 50.70em;
}
.emoji-1f1f1-1f1fa {
  background-position: 63.70em 49.40em;
}
.emoji-1f1f1-1f1fb {
  background-position: 63.70em 48.10em;
}
.emoji-1f1f1-1f1fe {
  background-position: 63.70em 46.80em;
}
.emoji-1f1f2-1f1e6 {
  background-position: 63.70em 45.50em;
}
.emoji-1f1f2-1f1e8 {
  background-position: 63.70em 44.20em;
}
.emoji-1f1f2-1f1e9 {
  background-position: 63.70em 42.90em;
}
.emoji-1f1f2-1f1ea {
  background-position: 63.70em 41.60em;
}
.emoji-1f1f2-1f1eb {
  background-position: 63.70em 40.30em;
}
.emoji-1f1f2-1f1ec {
  background-position: 63.70em 39.00em;
}
.emoji-1f1f2-1f1ed {
  background-position: 63.70em 37.70em;
}
.emoji-1f1f2-1f1f0 {
  background-position: 63.70em 36.40em;
}
.emoji-1f1f2-1f1f1 {
  background-position: 63.70em 35.10em;
}
.emoji-1f1f2-1f1f2 {
  background-position: 63.70em 33.80em;
}
.emoji-1f1f2-1f1f3 {
  background-position: 63.70em 32.50em;
}
.emoji-1f1f2-1f1f4 {
  background-position: 63.70em 31.20em;
}
.emoji-1f1f2-1f1f5 {
  background-position: 63.70em 29.90em;
}
.emoji-1f1f2-1f1f6 {
  background-position: 63.70em 28.60em;
}
.emoji-1f1f2-1f1f7 {
  background-position: 63.70em 27.30em;
}
.emoji-1f1f2-1f1f8 {
  background-position: 63.70em 26.00em;
}
.emoji-1f1f2-1f1f9 {
  background-position: 63.70em 24.70em;
}
.emoji-1f1f2-1f1fa {
  background-position: 63.70em 23.40em;
}
.emoji-1f1f2-1f1fb {
  background-position: 63.70em 22.10em;
}
.emoji-1f1f2-1f1fc {
  background-position: 63.70em 20.80em;
}
.emoji-1f1f2-1f1fd {
  background-position: 63.70em 19.50em;
}
.emoji-1f1f2-1f1fe {
  background-position: 63.70em 18.20em;
}
.emoji-1f1f2-1f1ff {
  background-position: 63.70em 16.90em;
}
.emoji-1f1f3-1f1e6 {
  background-position: 63.70em 15.60em;
}
.emoji-1f1f3-1f1e8 {
  background-position: 63.70em 14.30em;
}
.emoji-1f1f3-1f1ea {
  background-position: 63.70em 13.00em;
}
.emoji-1f1f3-1f1eb {
  background-position: 63.70em 11.70em;
}
.emoji-1f1f3-1f1ec {
  background-position: 63.70em 10.40em;
}
.emoji-1f1f3-1f1ee {
  background-position: 63.70em 9.10em;
}
.emoji-1f1f3-1f1f1 {
  background-position: 63.70em 7.80em;
}
.emoji-1f1f3-1f1f4 {
  background-position: 63.70em 6.50em;
}
.emoji-1f1f3-1f1f5 {
  background-position: 63.70em 5.20em;
}
.emoji-1f1f3-1f1f7 {
  background-position: 63.70em 3.90em;
}
.emoji-1f1f3-1f1fa {
  background-position: 63.70em 2.60em;
}
.emoji-1f1f3-1f1ff {
  background-position: 63.70em 1.30em;
}
.emoji-1f1f4-1f1f2 {
  background-position: 62.40em 67.60em;
}
.emoji-1f1f5-1f1e6 {
  background-position: 62.40em 66.30em;
}
.emoji-1f1f5-1f1ea {
  background-position: 62.40em 65.00em;
}
.emoji-1f1f5-1f1eb {
  background-position: 62.40em 63.70em;
}
.emoji-1f1f5-1f1ec {
  background-position: 62.40em 62.40em;
}
.emoji-1f1f5-1f1ed {
  background-position: 62.40em 61.10em;
}
.emoji-1f1f5-1f1f0 {
  background-position: 62.40em 59.80em;
}
.emoji-1f1f5-1f1f1 {
  background-position: 62.40em 58.50em;
}
.emoji-1f1f5-1f1f2 {
  background-position: 62.40em 57.20em;
}
.emoji-1f1f5-1f1f3 {
  background-position: 62.40em 55.90em;
}
.emoji-1f1f5-1f1f7 {
  background-position: 62.40em 54.60em;
}
.emoji-1f1f5-1f1f8 {
  background-position: 62.40em 53.30em;
}
.emoji-1f1f5-1f1f9 {
  background-position: 62.40em 52.00em;
}
.emoji-1f1f5-1f1fc {
  background-position: 62.40em 50.70em;
}
.emoji-1f1f5-1f1fe {
  background-position: 62.40em 49.40em;
}
.emoji-1f1f6-1f1e6 {
  background-position: 62.40em 48.10em;
}
.emoji-1f1f7-1f1ea {
  background-position: 62.40em 46.80em;
}
.emoji-1f1f7-1f1f4 {
  background-position: 62.40em 45.50em;
}
.emoji-1f1f7-1f1f8 {
  background-position: 62.40em 44.20em;
}
.emoji-1f1f7-1f1fa {
  background-position: 62.40em 42.90em;
}
.emoji-1f1f7-1f1fc {
  background-position: 62.40em 41.60em;
}
.emoji-1f1f8-1f1e6 {
  background-position: 62.40em 40.30em;
}
.emoji-1f1f8-1f1e7 {
  background-position: 62.40em 39.00em;
}
.emoji-1f1f8-1f1e8 {
  background-position: 62.40em 37.70em;
}
.emoji-1f1f8-1f1e9 {
  background-position: 62.40em 36.40em;
}
.emoji-1f1f8-1f1ea {
  background-position: 62.40em 35.10em;
}
.emoji-1f1f8-1f1ec {
  background-position: 62.40em 33.80em;
}
.emoji-1f1f8-1f1ed {
  background-position: 62.40em 32.50em;
}
.emoji-1f1f8-1f1ee {
  background-position: 62.40em 31.20em;
}
.emoji-1f1f8-1f1ef {
  background-position: 62.40em 29.90em;
}
.emoji-1f1f8-1f1f0 {
  background-position: 62.40em 28.60em;
}
.emoji-1f1f8-1f1f1 {
  background-position: 62.40em 27.30em;
}
.emoji-1f1f8-1f1f2 {
  background-position: 62.40em 26.00em;
}
.emoji-1f1f8-1f1f3 {
  background-position: 62.40em 24.70em;
}
.emoji-1f1f8-1f1f4 {
  background-position: 62.40em 23.40em;
}
.emoji-1f1f8-1f1f7 {
  background-position: 62.40em 22.10em;
}
.emoji-1f1f8-1f1f8 {
  background-position: 62.40em 20.80em;
}
.emoji-1f1f8-1f1f9 {
  background-position: 62.40em 19.50em;
}
.emoji-1f1f8-1f1fb {
  background-position: 62.40em 18.20em;
}
.emoji-1f1f8-1f1fd {
  background-position: 62.40em 16.90em;
}
.emoji-1f1f8-1f1fe {
  background-position: 62.40em 15.60em;
}
.emoji-1f1f8-1f1ff {
  background-position: 62.40em 14.30em;
}
.emoji-1f1f9-1f1e6 {
  background-position: 62.40em 13.00em;
}
.emoji-1f1f9-1f1e8 {
  background-position: 62.40em 11.70em;
}
.emoji-1f1f9-1f1e9 {
  background-position: 62.40em 10.40em;
}
.emoji-1f1f9-1f1eb {
  background-position: 62.40em 9.10em;
}
.emoji-1f1f9-1f1ec {
  background-position: 62.40em 7.80em;
}
.emoji-1f1f9-1f1ed {
  background-position: 62.40em 6.50em;
}
.emoji-1f1f9-1f1ef {
  background-position: 62.40em 5.20em;
}
.emoji-1f1f9-1f1f0 {
  background-position: 62.40em 3.90em;
}
.emoji-1f1f9-1f1f1 {
  background-position: 62.40em 2.60em;
}
.emoji-1f1f9-1f1f2 {
  background-position: 62.40em 1.30em;
}
.emoji-1f1f9-1f1f3 {
  background-position: 61.10em 67.60em;
}
.emoji-1f1f9-1f1f4 {
  background-position: 61.10em 66.30em;
}
.emoji-1f1f9-1f1f7 {
  background-position: 61.10em 65.00em;
}
.emoji-1f1f9-1f1f9 {
  background-position: 61.10em 63.70em;
}
.emoji-1f1f9-1f1fb {
  background-position: 61.10em 62.40em;
}
.emoji-1f1f9-1f1fc {
  background-position: 61.10em 61.10em;
}
.emoji-1f1f9-1f1ff {
  background-position: 61.10em 59.80em;
}
.emoji-1f1fa-1f1e6 {
  background-position: 61.10em 58.50em;
}
.emoji-1f1fa-1f1ec {
  background-position: 61.10em 57.20em;
}
.emoji-1f1fa-1f1f2 {
  background-position: 61.10em 55.90em;
}
.emoji-1f1fa-1f1f3 {
  background-position: 61.10em 54.60em;
}
.emoji-1f1fa-1f1f8 {
  background-position: 61.10em 53.30em;
}
.emoji-1f1fa-1f1fe {
  background-position: 61.10em 52.00em;
}
.emoji-1f1fa-1f1ff {
  background-position: 61.10em 50.70em;
}
.emoji-1f1fb-1f1e6 {
  background-position: 61.10em 49.40em;
}
.emoji-1f1fb-1f1e8 {
  background-position: 61.10em 48.10em;
}
.emoji-1f1fb-1f1ea {
  background-position: 61.10em 46.80em;
}
.emoji-1f1fb-1f1ec {
  background-position: 61.10em 45.50em;
}
.emoji-1f1fb-1f1ee {
  background-position: 61.10em 44.20em;
}
.emoji-1f1fb-1f1f3 {
  background-position: 61.10em 42.90em;
}
.emoji-1f1fb-1f1fa {
  background-position: 61.10em 41.60em;
}
.emoji-1f1fc-1f1eb {
  background-position: 61.10em 40.30em;
}
.emoji-1f1fc-1f1f8 {
  background-position: 61.10em 39.00em;
}
.emoji-1f1fd-1f1f0 {
  background-position: 61.10em 37.70em;
}
.emoji-1f1fe-1f1ea {
  background-position: 61.10em 36.40em;
}
.emoji-1f1fe-1f1f9 {
  background-position: 61.10em 35.10em;
}
.emoji-1f1ff-1f1e6 {
  background-position: 61.10em 33.80em;
}
.emoji-1f1ff-1f1f2 {
  background-position: 61.10em 32.50em;
}
.emoji-1f1ff-1f1fc {
  background-position: 61.10em 31.20em;
}
.emoji-1f201 {
  background-position: 61.10em 29.90em;
}
.emoji-1f202-fe0f {
  background-position: 61.10em 28.60em;
}
.emoji-1f21a {
  background-position: 61.10em 27.30em;
}
.emoji-1f22f {
  background-position: 61.10em 26.00em;
}
.emoji-1f232 {
  background-position: 61.10em 24.70em;
}
.emoji-1f233 {
  background-position: 61.10em 23.40em;
}
.emoji-1f234 {
  background-position: 61.10em 22.10em;
}
.emoji-1f235 {
  background-position: 61.10em 20.80em;
}
.emoji-1f236 {
  background-position: 61.10em 19.50em;
}
.emoji-1f237-fe0f {
  background-position: 61.10em 18.20em;
}
.emoji-1f238 {
  background-position: 61.10em 16.90em;
}
.emoji-1f239 {
  background-position: 61.10em 15.60em;
}
.emoji-1f23a {
  background-position: 61.10em 14.30em;
}
.emoji-1f250 {
  background-position: 61.10em 13.00em;
}
.emoji-1f251 {
  background-position: 61.10em 11.70em;
}
.emoji-1f300 {
  background-position: 61.10em 10.40em;
}
.emoji-1f301 {
  background-position: 61.10em 9.10em;
}
.emoji-1f302 {
  background-position: 61.10em 7.80em;
}
.emoji-1f303 {
  background-position: 61.10em 6.50em;
}
.emoji-1f304 {
  background-position: 61.10em 5.20em;
}
.emoji-1f305 {
  background-position: 61.10em 3.90em;
}
.emoji-1f306 {
  background-position: 61.10em 2.60em;
}
.emoji-1f307 {
  background-position: 61.10em 1.30em;
}
.emoji-1f308 {
  background-position: 59.80em 67.60em;
}
.emoji-1f309 {
  background-position: 59.80em 66.30em;
}
.emoji-1f30a {
  background-position: 59.80em 65.00em;
}
.emoji-1f30b {
  background-position: 59.80em 63.70em;
}
.emoji-1f30c {
  background-position: 59.80em 62.40em;
}
.emoji-1f30d {
  background-position: 59.80em 61.10em;
}
.emoji-1f30e {
  background-position: 59.80em 59.80em;
}
.emoji-1f30f {
  background-position: 59.80em 58.50em;
}
.emoji-1f310 {
  background-position: 59.80em 57.20em;
}
.emoji-1f311 {
  background-position: 59.80em 55.90em;
}
.emoji-1f312 {
  background-position: 59.80em 54.60em;
}
.emoji-1f313 {
  background-position: 59.80em 53.30em;
}
.emoji-1f314 {
  background-position: 59.80em 52.00em;
}
.emoji-1f315 {
  background-position: 59.80em 50.70em;
}
.emoji-1f316 {
  background-position: 59.80em 49.40em;
}
.emoji-1f317 {
  background-position: 59.80em 48.10em;
}
.emoji-1f318 {
  background-position: 59.80em 46.80em;
}
.emoji-1f319 {
  background-position: 59.80em 45.50em;
}
.emoji-1f31a {
  background-position: 59.80em 44.20em;
}
.emoji-1f31b {
  background-position: 59.80em 42.90em;
}
.emoji-1f31c {
  background-position: 59.80em 41.60em;
}
.emoji-1f31d {
  background-position: 59.80em 40.30em;
}
.emoji-1f31e {
  background-position: 59.80em 39.00em;
}
.emoji-1f31f {
  background-position: 59.80em 37.70em;
}
.emoji-1f320 {
  background-position: 59.80em 36.40em;
}
.emoji-1f321-fe0f {
  background-position: 59.80em 35.10em;
}
.emoji-1f324-fe0f {
  background-position: 59.80em 33.80em;
}
.emoji-1f325-fe0f {
  background-position: 59.80em 32.50em;
}
.emoji-1f326-fe0f {
  background-position: 59.80em 31.20em;
}
.emoji-1f327-fe0f {
  background-position: 59.80em 29.90em;
}
.emoji-1f328-fe0f {
  background-position: 59.80em 28.60em;
}
.emoji-1f329-fe0f {
  background-position: 59.80em 27.30em;
}
.emoji-1f32a-fe0f {
  background-position: 59.80em 26.00em;
}
.emoji-1f32b-fe0f {
  background-position: 59.80em 24.70em;
}
.emoji-1f32c-fe0f {
  background-position: 59.80em 23.40em;
}
.emoji-1f32d {
  background-position: 59.80em 22.10em;
}
.emoji-1f32e {
  background-position: 59.80em 20.80em;
}
.emoji-1f32f {
  background-position: 59.80em 19.50em;
}
.emoji-1f330 {
  background-position: 59.80em 18.20em;
}
.emoji-1f331 {
  background-position: 59.80em 16.90em;
}
.emoji-1f332 {
  background-position: 59.80em 15.60em;
}
.emoji-1f333 {
  background-position: 59.80em 14.30em;
}
.emoji-1f334 {
  background-position: 59.80em 13.00em;
}
.emoji-1f335 {
  background-position: 59.80em 11.70em;
}
.emoji-1f336-fe0f {
  background-position: 59.80em 10.40em;
}
.emoji-1f337 {
  background-position: 59.80em 9.10em;
}
.emoji-1f338 {
  background-position: 59.80em 7.80em;
}
.emoji-1f339 {
  background-position: 59.80em 6.50em;
}
.emoji-1f33a {
  background-position: 59.80em 5.20em;
}
.emoji-1f33b {
  background-position: 59.80em 3.90em;
}
.emoji-1f33c {
  background-position: 59.80em 2.60em;
}
.emoji-1f33d {
  background-position: 59.80em 1.30em;
}
.emoji-1f33e {
  background-position: 58.50em 67.60em;
}
.emoji-1f33f {
  background-position: 58.50em 66.30em;
}
.emoji-1f340 {
  background-position: 58.50em 65.00em;
}
.emoji-1f341 {
  background-position: 58.50em 63.70em;
}
.emoji-1f342 {
  background-position: 58.50em 62.40em;
}
.emoji-1f343 {
  background-position: 58.50em 61.10em;
}
.emoji-1f344 {
  background-position: 58.50em 59.80em;
}
.emoji-1f345 {
  background-position: 58.50em 58.50em;
}
.emoji-1f346 {
  background-position: 58.50em 57.20em;
}
.emoji-1f347 {
  background-position: 58.50em 55.90em;
}
.emoji-1f348 {
  background-position: 58.50em 54.60em;
}
.emoji-1f349 {
  background-position: 58.50em 53.30em;
}
.emoji-1f34a {
  background-position: 58.50em 52.00em;
}
.emoji-1f34b {
  background-position: 58.50em 50.70em;
}
.emoji-1f34c {
  background-position: 58.50em 49.40em;
}
.emoji-1f34d {
  background-position: 58.50em 48.10em;
}
.emoji-1f34e {
  background-position: 58.50em 46.80em;
}
.emoji-1f34f {
  background-position: 58.50em 45.50em;
}
.emoji-1f350 {
  background-position: 58.50em 44.20em;
}
.emoji-1f351 {
  background-position: 58.50em 42.90em;
}
.emoji-1f352 {
  background-position: 58.50em 41.60em;
}
.emoji-1f353 {
  background-position: 58.50em 40.30em;
}
.emoji-1f354 {
  background-position: 58.50em 39.00em;
}
.emoji-1f355 {
  background-position: 58.50em 37.70em;
}
.emoji-1f356 {
  background-position: 58.50em 36.40em;
}
.emoji-1f357 {
  background-position: 58.50em 35.10em;
}
.emoji-1f358 {
  background-position: 58.50em 33.80em;
}
.emoji-1f359 {
  background-position: 58.50em 32.50em;
}
.emoji-1f35a {
  background-position: 58.50em 31.20em;
}
.emoji-1f35b {
  background-position: 58.50em 29.90em;
}
.emoji-1f35c {
  background-position: 58.50em 28.60em;
}
.emoji-1f35d {
  background-position: 58.50em 27.30em;
}
.emoji-1f35e {
  background-position: 58.50em 26.00em;
}
.emoji-1f35f {
  background-position: 58.50em 24.70em;
}
.emoji-1f360 {
  background-position: 58.50em 23.40em;
}
.emoji-1f361 {
  background-position: 58.50em 22.10em;
}
.emoji-1f362 {
  background-position: 58.50em 20.80em;
}
.emoji-1f363 {
  background-position: 58.50em 19.50em;
}
.emoji-1f364 {
  background-position: 58.50em 18.20em;
}
.emoji-1f365 {
  background-position: 58.50em 16.90em;
}
.emoji-1f366 {
  background-position: 58.50em 15.60em;
}
.emoji-1f367 {
  background-position: 58.50em 14.30em;
}
.emoji-1f368 {
  background-position: 58.50em 13.00em;
}
.emoji-1f369 {
  background-position: 58.50em 11.70em;
}
.emoji-1f36a {
  background-position: 58.50em 10.40em;
}
.emoji-1f36b {
  background-position: 58.50em 9.10em;
}
.emoji-1f36c {
  background-position: 58.50em 7.80em;
}
.emoji-1f36d {
  background-position: 58.50em 6.50em;
}
.emoji-1f36e {
  background-position: 58.50em 5.20em;
}
.emoji-1f36f {
  background-position: 58.50em 3.90em;
}
.emoji-1f370 {
  background-position: 58.50em 2.60em;
}
.emoji-1f371 {
  background-position: 58.50em 1.30em;
}
.emoji-1f372 {
  background-position: 57.20em 67.60em;
}
.emoji-1f373 {
  background-position: 57.20em 66.30em;
}
.emoji-1f374 {
  background-position: 57.20em 65.00em;
}
.emoji-1f375 {
  background-position: 57.20em 63.70em;
}
.emoji-1f376 {
  background-position: 57.20em 62.40em;
}
.emoji-1f377 {
  background-position: 57.20em 61.10em;
}
.emoji-1f378 {
  background-position: 57.20em 59.80em;
}
.emoji-1f379 {
  background-position: 57.20em 58.50em;
}
.emoji-1f37a {
  background-position: 57.20em 57.20em;
}
.emoji-1f37b {
  background-position: 57.20em 55.90em;
}
.emoji-1f37c {
  background-position: 57.20em 54.60em;
}
.emoji-1f37d-fe0f {
  background-position: 57.20em 53.30em;
}
.emoji-1f37e {
  background-position: 57.20em 52.00em;
}
.emoji-1f37f {
  background-position: 57.20em 50.70em;
}
.emoji-1f380 {
  background-position: 57.20em 49.40em;
}
.emoji-1f381 {
  background-position: 57.20em 48.10em;
}
.emoji-1f382 {
  background-position: 57.20em 46.80em;
}
.emoji-1f383 {
  background-position: 57.20em 45.50em;
}
.emoji-1f384 {
  background-position: 57.20em 44.20em;
}
.emoji-1f385 {
  background-position: 57.20em 42.90em;
}
.emoji-1f385-1f3fb {
  background-position: 57.20em 41.60em;
}
.emoji-1f385-1f3fc {
  background-position: 57.20em 40.30em;
}
.emoji-1f385-1f3fd {
  background-position: 57.20em 39.00em;
}
.emoji-1f385-1f3fe {
  background-position: 57.20em 37.70em;
}
.emoji-1f385-1f3ff {
  background-position: 57.20em 36.40em;
}
.emoji-1f386 {
  background-position: 57.20em 35.10em;
}
.emoji-1f387 {
  background-position: 57.20em 33.80em;
}
.emoji-1f388 {
  background-position: 57.20em 32.50em;
}
.emoji-1f389 {
  background-position: 57.20em 31.20em;
}
.emoji-1f38a {
  background-position: 57.20em 29.90em;
}
.emoji-1f38b {
  background-position: 57.20em 28.60em;
}
.emoji-1f38c {
  background-position: 57.20em 27.30em;
}
.emoji-1f38d {
  background-position: 57.20em 26.00em;
}
.emoji-1f38e {
  background-position: 57.20em 24.70em;
}
.emoji-1f38f {
  background-position: 57.20em 23.40em;
}
.emoji-1f390 {
  background-position: 57.20em 22.10em;
}
.emoji-1f391 {
  background-position: 57.20em 20.80em;
}
.emoji-1f392 {
  background-position: 57.20em 19.50em;
}
.emoji-1f393 {
  background-position: 57.20em 18.20em;
}
.emoji-1f396-fe0f {
  background-position: 57.20em 16.90em;
}
.emoji-1f397-fe0f {
  background-position: 57.20em 15.60em;
}
.emoji-1f399-fe0f {
  background-position: 57.20em 14.30em;
}
.emoji-1f39a-fe0f {
  background-position: 57.20em 13.00em;
}
.emoji-1f39b-fe0f {
  background-position: 57.20em 11.70em;
}
.emoji-1f39e-fe0f {
  background-position: 57.20em 10.40em;
}
.emoji-1f39f-fe0f {
  background-position: 57.20em 9.10em;
}
.emoji-1f3a0 {
  background-position: 57.20em 7.80em;
}
.emoji-1f3a1 {
  background-position: 57.20em 6.50em;
}
.emoji-1f3a2 {
  background-position: 57.20em 5.20em;
}
.emoji-1f3a3 {
  background-position: 57.20em 3.90em;
}
.emoji-1f3a4 {
  background-position: 57.20em 2.60em;
}
.emoji-1f3a5 {
  background-position: 57.20em 1.30em;
}
.emoji-1f3a6 {
  background-position: 55.90em 67.60em;
}
.emoji-1f3a7 {
  background-position: 55.90em 66.30em;
}
.emoji-1f3a8 {
  background-position: 55.90em 65.00em;
}
.emoji-1f3a9 {
  background-position: 55.90em 63.70em;
}
.emoji-1f3aa {
  background-position: 55.90em 62.40em;
}
.emoji-1f3ab {
  background-position: 55.90em 61.10em;
}
.emoji-1f3ac {
  background-position: 55.90em 59.80em;
}
.emoji-1f3ad {
  background-position: 55.90em 58.50em;
}
.emoji-1f3ae {
  background-position: 55.90em 57.20em;
}
.emoji-1f3af {
  background-position: 55.90em 55.90em;
}
.emoji-1f3b0 {
  background-position: 55.90em 54.60em;
}
.emoji-1f3b1 {
  background-position: 55.90em 53.30em;
}
.emoji-1f3b2 {
  background-position: 55.90em 52.00em;
}
.emoji-1f3b3 {
  background-position: 55.90em 50.70em;
}
.emoji-1f3b4 {
  background-position: 55.90em 49.40em;
}
.emoji-1f3b5 {
  background-position: 55.90em 48.10em;
}
.emoji-1f3b6 {
  background-position: 55.90em 46.80em;
}
.emoji-1f3b7 {
  background-position: 55.90em 45.50em;
}
.emoji-1f3b8 {
  background-position: 55.90em 44.20em;
}
.emoji-1f3b9 {
  background-position: 55.90em 42.90em;
}
.emoji-1f3ba {
  background-position: 55.90em 41.60em;
}
.emoji-1f3bb {
  background-position: 55.90em 40.30em;
}
.emoji-1f3bc {
  background-position: 55.90em 39.00em;
}
.emoji-1f3bd {
  background-position: 55.90em 37.70em;
}
.emoji-1f3be {
  background-position: 55.90em 36.40em;
}
.emoji-1f3bf {
  background-position: 55.90em 35.10em;
}
.emoji-1f3c0 {
  background-position: 55.90em 33.80em;
}
.emoji-1f3c1 {
  background-position: 55.90em 32.50em;
}
.emoji-1f3c2 {
  background-position: 55.90em 31.20em;
}
.emoji-1f3c2-1f3fb {
  background-position: 55.90em 29.90em;
}
.emoji-1f3c2-1f3fc {
  background-position: 55.90em 28.60em;
}
.emoji-1f3c2-1f3fd {
  background-position: 55.90em 27.30em;
}
.emoji-1f3c2-1f3fe {
  background-position: 55.90em 26.00em;
}
.emoji-1f3c2-1f3ff {
  background-position: 55.90em 24.70em;
}
.emoji-1f3c3-200d-2640-fe0f {
  background-position: 55.90em 23.40em;
}
.emoji-1f3c3-1f3fb-200d-2640-fe0f {
  background-position: 55.90em 22.10em;
}
.emoji-1f3c3-1f3fc-200d-2640-fe0f {
  background-position: 55.90em 20.80em;
}
.emoji-1f3c3-1f3fd-200d-2640-fe0f {
  background-position: 55.90em 19.50em;
}
.emoji-1f3c3-1f3fe-200d-2640-fe0f {
  background-position: 55.90em 18.20em;
}
.emoji-1f3c3-1f3ff-200d-2640-fe0f {
  background-position: 55.90em 16.90em;
}
.emoji-1f3c3-200d-2642-fe0f {
  background-position: 55.90em 15.60em;
}
.emoji-1f3c3-1f3fb-200d-2642-fe0f {
  background-position: 55.90em 14.30em;
}
.emoji-1f3c3-1f3fc-200d-2642-fe0f {
  background-position: 55.90em 13.00em;
}
.emoji-1f3c3-1f3fd-200d-2642-fe0f {
  background-position: 55.90em 11.70em;
}
.emoji-1f3c3-1f3fe-200d-2642-fe0f {
  background-position: 55.90em 10.40em;
}
.emoji-1f3c3-1f3ff-200d-2642-fe0f {
  background-position: 55.90em 9.10em;
}
.emoji-1f3c3 {
  background-position: 55.90em 7.80em;
}
.emoji-1f3c3-1f3fb {
  background-position: 55.90em 6.50em;
}
.emoji-1f3c3-1f3fc {
  background-position: 55.90em 5.20em;
}
.emoji-1f3c3-1f3fd {
  background-position: 55.90em 3.90em;
}
.emoji-1f3c3-1f3fe {
  background-position: 55.90em 2.60em;
}
.emoji-1f3c3-1f3ff {
  background-position: 55.90em 1.30em;
}
.emoji-1f3c4-200d-2640-fe0f {
  background-position: 54.60em 67.60em;
}
.emoji-1f3c4-1f3fb-200d-2640-fe0f {
  background-position: 54.60em 66.30em;
}
.emoji-1f3c4-1f3fc-200d-2640-fe0f {
  background-position: 54.60em 65.00em;
}
.emoji-1f3c4-1f3fd-200d-2640-fe0f {
  background-position: 54.60em 63.70em;
}
.emoji-1f3c4-1f3fe-200d-2640-fe0f {
  background-position: 54.60em 62.40em;
}
.emoji-1f3c4-1f3ff-200d-2640-fe0f {
  background-position: 54.60em 61.10em;
}
.emoji-1f3c4-200d-2642-fe0f {
  background-position: 54.60em 59.80em;
}
.emoji-1f3c4-1f3fb-200d-2642-fe0f {
  background-position: 54.60em 58.50em;
}
.emoji-1f3c4-1f3fc-200d-2642-fe0f {
  background-position: 54.60em 57.20em;
}
.emoji-1f3c4-1f3fd-200d-2642-fe0f {
  background-position: 54.60em 55.90em;
}
.emoji-1f3c4-1f3fe-200d-2642-fe0f {
  background-position: 54.60em 54.60em;
}
.emoji-1f3c4-1f3ff-200d-2642-fe0f {
  background-position: 54.60em 53.30em;
}
.emoji-1f3c4 {
  background-position: 54.60em 52.00em;
}
.emoji-1f3c4-1f3fb {
  background-position: 54.60em 50.70em;
}
.emoji-1f3c4-1f3fc {
  background-position: 54.60em 49.40em;
}
.emoji-1f3c4-1f3fd {
  background-position: 54.60em 48.10em;
}
.emoji-1f3c4-1f3fe {
  background-position: 54.60em 46.80em;
}
.emoji-1f3c4-1f3ff {
  background-position: 54.60em 45.50em;
}
.emoji-1f3c5 {
  background-position: 54.60em 44.20em;
}
.emoji-1f3c6 {
  background-position: 54.60em 42.90em;
}
.emoji-1f3c7 {
  background-position: 54.60em 41.60em;
}
.emoji-1f3c7-1f3fb {
  background-position: 54.60em 40.30em;
}
.emoji-1f3c7-1f3fc {
  background-position: 54.60em 39.00em;
}
.emoji-1f3c7-1f3fd {
  background-position: 54.60em 37.70em;
}
.emoji-1f3c7-1f3fe {
  background-position: 54.60em 36.40em;
}
.emoji-1f3c7-1f3ff {
  background-position: 54.60em 35.10em;
}
.emoji-1f3c8 {
  background-position: 54.60em 33.80em;
}
.emoji-1f3c9 {
  background-position: 54.60em 32.50em;
}
.emoji-1f3ca-200d-2640-fe0f {
  background-position: 54.60em 31.20em;
}
.emoji-1f3ca-1f3fb-200d-2640-fe0f {
  background-position: 54.60em 29.90em;
}
.emoji-1f3ca-1f3fc-200d-2640-fe0f {
  background-position: 54.60em 28.60em;
}
.emoji-1f3ca-1f3fd-200d-2640-fe0f {
  background-position: 54.60em 27.30em;
}
.emoji-1f3ca-1f3fe-200d-2640-fe0f {
  background-position: 54.60em 26.00em;
}
.emoji-1f3ca-1f3ff-200d-2640-fe0f {
  background-position: 54.60em 24.70em;
}
.emoji-1f3ca-200d-2642-fe0f {
  background-position: 54.60em 23.40em;
}
.emoji-1f3ca-1f3fb-200d-2642-fe0f {
  background-position: 54.60em 22.10em;
}
.emoji-1f3ca-1f3fc-200d-2642-fe0f {
  background-position: 54.60em 20.80em;
}
.emoji-1f3ca-1f3fd-200d-2642-fe0f {
  background-position: 54.60em 19.50em;
}
.emoji-1f3ca-1f3fe-200d-2642-fe0f {
  background-position: 54.60em 18.20em;
}
.emoji-1f3ca-1f3ff-200d-2642-fe0f {
  background-position: 54.60em 16.90em;
}
.emoji-1f3ca {
  background-position: 54.60em 15.60em;
}
.emoji-1f3ca-1f3fb {
  background-position: 54.60em 14.30em;
}
.emoji-1f3ca-1f3fc {
  background-position: 54.60em 13.00em;
}
.emoji-1f3ca-1f3fd {
  background-position: 54.60em 11.70em;
}
.emoji-1f3ca-1f3fe {
  background-position: 54.60em 10.40em;
}
.emoji-1f3ca-1f3ff {
  background-position: 54.60em 9.10em;
}
.emoji-1f3cb-fe0f-200d-2640-fe0f {
  background-position: 54.60em 7.80em;
}
.emoji-1f3cb-1f3fb-200d-2640-fe0f {
  background-position: 54.60em 6.50em;
}
.emoji-1f3cb-1f3fc-200d-2640-fe0f {
  background-position: 54.60em 5.20em;
}
.emoji-1f3cb-1f3fd-200d-2640-fe0f {
  background-position: 54.60em 3.90em;
}
.emoji-1f3cb-1f3fe-200d-2640-fe0f {
  background-position: 54.60em 2.60em;
}
.emoji-1f3cb-1f3ff-200d-2640-fe0f {
  background-position: 54.60em 1.30em;
}
.emoji-1f3cb-fe0f-200d-2642-fe0f {
  background-position: 53.30em 67.60em;
}
.emoji-1f3cb-1f3fb-200d-2642-fe0f {
  background-position: 53.30em 66.30em;
}
.emoji-1f3cb-1f3fc-200d-2642-fe0f {
  background-position: 53.30em 65.00em;
}
.emoji-1f3cb-1f3fd-200d-2642-fe0f {
  background-position: 53.30em 63.70em;
}
.emoji-1f3cb-1f3fe-200d-2642-fe0f {
  background-position: 53.30em 62.40em;
}
.emoji-1f3cb-1f3ff-200d-2642-fe0f {
  background-position: 53.30em 61.10em;
}
.emoji-1f3cb-fe0f {
  background-position: 53.30em 59.80em;
}
.emoji-1f3cb-1f3fb {
  background-position: 53.30em 58.50em;
}
.emoji-1f3cb-1f3fc {
  background-position: 53.30em 57.20em;
}
.emoji-1f3cb-1f3fd {
  background-position: 53.30em 55.90em;
}
.emoji-1f3cb-1f3fe {
  background-position: 53.30em 54.60em;
}
.emoji-1f3cb-1f3ff {
  background-position: 53.30em 53.30em;
}
.emoji-1f3cc-fe0f-200d-2640-fe0f {
  background-position: 53.30em 52.00em;
}
.emoji-1f3cc-1f3fb-200d-2640-fe0f {
  background-position: 53.30em 50.70em;
}
.emoji-1f3cc-1f3fc-200d-2640-fe0f {
  background-position: 53.30em 49.40em;
}
.emoji-1f3cc-1f3fd-200d-2640-fe0f {
  background-position: 53.30em 48.10em;
}
.emoji-1f3cc-1f3fe-200d-2640-fe0f {
  background-position: 53.30em 46.80em;
}
.emoji-1f3cc-1f3ff-200d-2640-fe0f {
  background-position: 53.30em 45.50em;
}
.emoji-1f3cc-fe0f-200d-2642-fe0f {
  background-position: 53.30em 44.20em;
}
.emoji-1f3cc-1f3fb-200d-2642-fe0f {
  background-position: 53.30em 42.90em;
}
.emoji-1f3cc-1f3fc-200d-2642-fe0f {
  background-position: 53.30em 41.60em;
}
.emoji-1f3cc-1f3fd-200d-2642-fe0f {
  background-position: 53.30em 40.30em;
}
.emoji-1f3cc-1f3fe-200d-2642-fe0f {
  background-position: 53.30em 39.00em;
}
.emoji-1f3cc-1f3ff-200d-2642-fe0f {
  background-position: 53.30em 37.70em;
}
.emoji-1f3cc-fe0f {
  background-position: 53.30em 36.40em;
}
.emoji-1f3cc-1f3fb {
  background-position: 53.30em 35.10em;
}
.emoji-1f3cc-1f3fc {
  background-position: 53.30em 33.80em;
}
.emoji-1f3cc-1f3fd {
  background-position: 53.30em 32.50em;
}
.emoji-1f3cc-1f3fe {
  background-position: 53.30em 31.20em;
}
.emoji-1f3cc-1f3ff {
  background-position: 53.30em 29.90em;
}
.emoji-1f3cd-fe0f {
  background-position: 53.30em 28.60em;
}
.emoji-1f3ce-fe0f {
  background-position: 53.30em 27.30em;
}
.emoji-1f3cf {
  background-position: 53.30em 26.00em;
}
.emoji-1f3d0 {
  background-position: 53.30em 24.70em;
}
.emoji-1f3d1 {
  background-position: 53.30em 23.40em;
}
.emoji-1f3d2 {
  background-position: 53.30em 22.10em;
}
.emoji-1f3d3 {
  background-position: 53.30em 20.80em;
}
.emoji-1f3d4-fe0f {
  background-position: 53.30em 19.50em;
}
.emoji-1f3d5-fe0f {
  background-position: 53.30em 18.20em;
}
.emoji-1f3d6-fe0f {
  background-position: 53.30em 16.90em;
}
.emoji-1f3d7-fe0f {
  background-position: 53.30em 15.60em;
}
.emoji-1f3d8-fe0f {
  background-position: 53.30em 14.30em;
}
.emoji-1f3d9-fe0f {
  background-position: 53.30em 13.00em;
}
.emoji-1f3da-fe0f {
  background-position: 53.30em 11.70em;
}
.emoji-1f3db-fe0f {
  background-position: 53.30em 10.40em;
}
.emoji-1f3dc-fe0f {
  background-position: 53.30em 9.10em;
}
.emoji-1f3dd-fe0f {
  background-position: 53.30em 7.80em;
}
.emoji-1f3de-fe0f {
  background-position: 53.30em 6.50em;
}
.emoji-1f3df-fe0f {
  background-position: 53.30em 5.20em;
}
.emoji-1f3e0 {
  background-position: 53.30em 3.90em;
}
.emoji-1f3e1 {
  background-position: 53.30em 2.60em;
}
.emoji-1f3e2 {
  background-position: 53.30em 1.30em;
}
.emoji-1f3e3 {
  background-position: 52.00em 67.60em;
}
.emoji-1f3e4 {
  background-position: 52.00em 66.30em;
}
.emoji-1f3e5 {
  background-position: 52.00em 65.00em;
}
.emoji-1f3e6 {
  background-position: 52.00em 63.70em;
}
.emoji-1f3e7 {
  background-position: 52.00em 62.40em;
}
.emoji-1f3e8 {
  background-position: 52.00em 61.10em;
}
.emoji-1f3e9 {
  background-position: 52.00em 59.80em;
}
.emoji-1f3ea {
  background-position: 52.00em 58.50em;
}
.emoji-1f3eb {
  background-position: 52.00em 57.20em;
}
.emoji-1f3ec {
  background-position: 52.00em 55.90em;
}
.emoji-1f3ed {
  background-position: 52.00em 54.60em;
}
.emoji-1f3ee {
  background-position: 52.00em 53.30em;
}
.emoji-1f3ef {
  background-position: 52.00em 52.00em;
}
.emoji-1f3f0 {
  background-position: 52.00em 50.70em;
}
.emoji-1f3f3-fe0f-200d-1f308 {
  background-position: 52.00em 49.40em;
}
.emoji-1f3f3-fe0f {
  background-position: 52.00em 48.10em;
}
.emoji-1f3f4-e0067-e0062-e0065-e006e-e0067-e007f {
  background-position: 52.00em 46.80em;
}
.emoji-1f3f4-e0067-e0062-e0073-e0063-e0074-e007f {
  background-position: 52.00em 45.50em;
}
.emoji-1f3f4-e0067-e0062-e0077-e006c-e0073-e007f {
  background-position: 52.00em 44.20em;
}
.emoji-1f3f4 {
  background-position: 52.00em 42.90em;
}
.emoji-1f3f5-fe0f {
  background-position: 52.00em 41.60em;
}
.emoji-1f3f7-fe0f {
  background-position: 52.00em 40.30em;
}
.emoji-1f3f8 {
  background-position: 52.00em 39.00em;
}
.emoji-1f3f9 {
  background-position: 52.00em 37.70em;
}
.emoji-1f3fa {
  background-position: 52.00em 36.40em;
}
.emoji-1f3fb {
  background-position: 52.00em 35.10em;
}
.emoji-1f3fc {
  background-position: 52.00em 33.80em;
}
.emoji-1f3fd {
  background-position: 52.00em 32.50em;
}
.emoji-1f3fe {
  background-position: 52.00em 31.20em;
}
.emoji-1f3ff {
  background-position: 52.00em 29.90em;
}
.emoji-1f400 {
  background-position: 52.00em 28.60em;
}
.emoji-1f401 {
  background-position: 52.00em 27.30em;
}
.emoji-1f402 {
  background-position: 52.00em 26.00em;
}
.emoji-1f403 {
  background-position: 52.00em 24.70em;
}
.emoji-1f404 {
  background-position: 52.00em 23.40em;
}
.emoji-1f405 {
  background-position: 52.00em 22.10em;
}
.emoji-1f406 {
  background-position: 52.00em 20.80em;
}
.emoji-1f407 {
  background-position: 52.00em 19.50em;
}
.emoji-1f408 {
  background-position: 52.00em 18.20em;
}
.emoji-1f409 {
  background-position: 52.00em 16.90em;
}
.emoji-1f40a {
  background-position: 52.00em 15.60em;
}
.emoji-1f40b {
  background-position: 52.00em 14.30em;
}
.emoji-1f40c {
  background-position: 52.00em 13.00em;
}
.emoji-1f40d {
  background-position: 52.00em 11.70em;
}
.emoji-1f40e {
  background-position: 52.00em 10.40em;
}
.emoji-1f40f {
  background-position: 52.00em 9.10em;
}
.emoji-1f410 {
  background-position: 52.00em 7.80em;
}
.emoji-1f411 {
  background-position: 52.00em 6.50em;
}
.emoji-1f412 {
  background-position: 52.00em 5.20em;
}
.emoji-1f413 {
  background-position: 52.00em 3.90em;
}
.emoji-1f414 {
  background-position: 52.00em 2.60em;
}
.emoji-1f415 {
  background-position: 52.00em 1.30em;
}
.emoji-1f416 {
  background-position: 50.70em 67.60em;
}
.emoji-1f417 {
  background-position: 50.70em 66.30em;
}
.emoji-1f418 {
  background-position: 50.70em 65.00em;
}
.emoji-1f419 {
  background-position: 50.70em 63.70em;
}
.emoji-1f41a {
  background-position: 50.70em 62.40em;
}
.emoji-1f41b {
  background-position: 50.70em 61.10em;
}
.emoji-1f41c {
  background-position: 50.70em 59.80em;
}
.emoji-1f41d {
  background-position: 50.70em 58.50em;
}
.emoji-1f41e {
  background-position: 50.70em 57.20em;
}
.emoji-1f41f {
  background-position: 50.70em 55.90em;
}
.emoji-1f420 {
  background-position: 50.70em 54.60em;
}
.emoji-1f421 {
  background-position: 50.70em 53.30em;
}
.emoji-1f422 {
  background-position: 50.70em 52.00em;
}
.emoji-1f423 {
  background-position: 50.70em 50.70em;
}
.emoji-1f424 {
  background-position: 50.70em 49.40em;
}
.emoji-1f425 {
  background-position: 50.70em 48.10em;
}
.emoji-1f426 {
  background-position: 50.70em 46.80em;
}
.emoji-1f427 {
  background-position: 50.70em 45.50em;
}
.emoji-1f428 {
  background-position: 50.70em 44.20em;
}
.emoji-1f429 {
  background-position: 50.70em 42.90em;
}
.emoji-1f42a {
  background-position: 50.70em 41.60em;
}
.emoji-1f42b {
  background-position: 50.70em 40.30em;
}
.emoji-1f42c {
  background-position: 50.70em 39.00em;
}
.emoji-1f42d {
  background-position: 50.70em 37.70em;
}
.emoji-1f42e {
  background-position: 50.70em 36.40em;
}
.emoji-1f42f {
  background-position: 50.70em 35.10em;
}
.emoji-1f430 {
  background-position: 50.70em 33.80em;
}
.emoji-1f431 {
  background-position: 50.70em 32.50em;
}
.emoji-1f432 {
  background-position: 50.70em 31.20em;
}
.emoji-1f433 {
  background-position: 50.70em 29.90em;
}
.emoji-1f434 {
  background-position: 50.70em 28.60em;
}
.emoji-1f435 {
  background-position: 50.70em 27.30em;
}
.emoji-1f436 {
  background-position: 50.70em 26.00em;
}
.emoji-1f437 {
  background-position: 50.70em 24.70em;
}
.emoji-1f438 {
  background-position: 50.70em 23.40em;
}
.emoji-1f439 {
  background-position: 50.70em 22.10em;
}
.emoji-1f43a {
  background-position: 50.70em 20.80em;
}
.emoji-1f43b {
  background-position: 50.70em 19.50em;
}
.emoji-1f43c {
  background-position: 50.70em 18.20em;
}
.emoji-1f43d {
  background-position: 50.70em 16.90em;
}
.emoji-1f43e {
  background-position: 50.70em 15.60em;
}
.emoji-1f43f-fe0f {
  background-position: 50.70em 14.30em;
}
.emoji-1f440 {
  background-position: 50.70em 13.00em;
}
.emoji-1f441-fe0f-200d-1f5e8-fe0f {
  background-position: 50.70em 11.70em;
}
.emoji-1f441-fe0f {
  background-position: 50.70em 10.40em;
}
.emoji-1f442 {
  background-position: 50.70em 9.10em;
}
.emoji-1f442-1f3fb {
  background-position: 50.70em 7.80em;
}
.emoji-1f442-1f3fc {
  background-position: 50.70em 6.50em;
}
.emoji-1f442-1f3fd {
  background-position: 50.70em 5.20em;
}
.emoji-1f442-1f3fe {
  background-position: 50.70em 3.90em;
}
.emoji-1f442-1f3ff {
  background-position: 50.70em 2.60em;
}
.emoji-1f443 {
  background-position: 50.70em 1.30em;
}
.emoji-1f443-1f3fb {
  background-position: 49.40em 67.60em;
}
.emoji-1f443-1f3fc {
  background-position: 49.40em 66.30em;
}
.emoji-1f443-1f3fd {
  background-position: 49.40em 65.00em;
}
.emoji-1f443-1f3fe {
  background-position: 49.40em 63.70em;
}
.emoji-1f443-1f3ff {
  background-position: 49.40em 62.40em;
}
.emoji-1f444 {
  background-position: 49.40em 61.10em;
}
.emoji-1f445 {
  background-position: 49.40em 59.80em;
}
.emoji-1f446 {
  background-position: 49.40em 58.50em;
}
.emoji-1f446-1f3fb {
  background-position: 49.40em 57.20em;
}
.emoji-1f446-1f3fc {
  background-position: 49.40em 55.90em;
}
.emoji-1f446-1f3fd {
  background-position: 49.40em 54.60em;
}
.emoji-1f446-1f3fe {
  background-position: 49.40em 53.30em;
}
.emoji-1f446-1f3ff {
  background-position: 49.40em 52.00em;
}
.emoji-1f447 {
  background-position: 49.40em 50.70em;
}
.emoji-1f447-1f3fb {
  background-position: 49.40em 49.40em;
}
.emoji-1f447-1f3fc {
  background-position: 49.40em 48.10em;
}
.emoji-1f447-1f3fd {
  background-position: 49.40em 46.80em;
}
.emoji-1f447-1f3fe {
  background-position: 49.40em 45.50em;
}
.emoji-1f447-1f3ff {
  background-position: 49.40em 44.20em;
}
.emoji-1f448 {
  background-position: 49.40em 42.90em;
}
.emoji-1f448-1f3fb {
  background-position: 49.40em 41.60em;
}
.emoji-1f448-1f3fc {
  background-position: 49.40em 40.30em;
}
.emoji-1f448-1f3fd {
  background-position: 49.40em 39.00em;
}
.emoji-1f448-1f3fe {
  background-position: 49.40em 37.70em;
}
.emoji-1f448-1f3ff {
  background-position: 49.40em 36.40em;
}
.emoji-1f449 {
  background-position: 49.40em 35.10em;
}
.emoji-1f449-1f3fb {
  background-position: 49.40em 33.80em;
}
.emoji-1f449-1f3fc {
  background-position: 49.40em 32.50em;
}
.emoji-1f449-1f3fd {
  background-position: 49.40em 31.20em;
}
.emoji-1f449-1f3fe {
  background-position: 49.40em 29.90em;
}
.emoji-1f449-1f3ff {
  background-position: 49.40em 28.60em;
}
.emoji-1f44a {
  background-position: 49.40em 27.30em;
}
.emoji-1f44a-1f3fb {
  background-position: 49.40em 26.00em;
}
.emoji-1f44a-1f3fc {
  background-position: 49.40em 24.70em;
}
.emoji-1f44a-1f3fd {
  background-position: 49.40em 23.40em;
}
.emoji-1f44a-1f3fe {
  background-position: 49.40em 22.10em;
}
.emoji-1f44a-1f3ff {
  background-position: 49.40em 20.80em;
}
.emoji-1f44b {
  background-position: 49.40em 19.50em;
}
.emoji-1f44b-1f3fb {
  background-position: 49.40em 18.20em;
}
.emoji-1f44b-1f3fc {
  background-position: 49.40em 16.90em;
}
.emoji-1f44b-1f3fd {
  background-position: 49.40em 15.60em;
}
.emoji-1f44b-1f3fe {
  background-position: 49.40em 14.30em;
}
.emoji-1f44b-1f3ff {
  background-position: 49.40em 13.00em;
}
.emoji-1f44c {
  background-position: 49.40em 11.70em;
}
.emoji-1f44c-1f3fb {
  background-position: 49.40em 10.40em;
}
.emoji-1f44c-1f3fc {
  background-position: 49.40em 9.10em;
}
.emoji-1f44c-1f3fd {
  background-position: 49.40em 7.80em;
}
.emoji-1f44c-1f3fe {
  background-position: 49.40em 6.50em;
}
.emoji-1f44c-1f3ff {
  background-position: 49.40em 5.20em;
}
.emoji-1f44d {
  background-position: 49.40em 3.90em;
}
.emoji-1f44d-1f3fb {
  background-position: 49.40em 2.60em;
}
.emoji-1f44d-1f3fc {
  background-position: 49.40em 1.30em;
}
.emoji-1f44d-1f3fd {
  background-position: 48.10em 67.60em;
}
.emoji-1f44d-1f3fe {
  background-position: 48.10em 66.30em;
}
.emoji-1f44d-1f3ff {
  background-position: 48.10em 65.00em;
}
.emoji-1f44e {
  background-position: 48.10em 63.70em;
}
.emoji-1f44e-1f3fb {
  background-position: 48.10em 62.40em;
}
.emoji-1f44e-1f3fc {
  background-position: 48.10em 61.10em;
}
.emoji-1f44e-1f3fd {
  background-position: 48.10em 59.80em;
}
.emoji-1f44e-1f3fe {
  background-position: 48.10em 58.50em;
}
.emoji-1f44e-1f3ff {
  background-position: 48.10em 57.20em;
}
.emoji-1f44f {
  background-position: 48.10em 55.90em;
}
.emoji-1f44f-1f3fb {
  background-position: 48.10em 54.60em;
}
.emoji-1f44f-1f3fc {
  background-position: 48.10em 53.30em;
}
.emoji-1f44f-1f3fd {
  background-position: 48.10em 52.00em;
}
.emoji-1f44f-1f3fe {
  background-position: 48.10em 50.70em;
}
.emoji-1f44f-1f3ff {
  background-position: 48.10em 49.40em;
}
.emoji-1f450 {
  background-position: 48.10em 48.10em;
}
.emoji-1f450-1f3fb {
  background-position: 48.10em 46.80em;
}
.emoji-1f450-1f3fc {
  background-position: 48.10em 45.50em;
}
.emoji-1f450-1f3fd {
  background-position: 48.10em 44.20em;
}
.emoji-1f450-1f3fe {
  background-position: 48.10em 42.90em;
}
.emoji-1f450-1f3ff {
  background-position: 48.10em 41.60em;
}
.emoji-1f451 {
  background-position: 48.10em 40.30em;
}
.emoji-1f452 {
  background-position: 48.10em 39.00em;
}
.emoji-1f453 {
  background-position: 48.10em 37.70em;
}
.emoji-1f454 {
  background-position: 48.10em 36.40em;
}
.emoji-1f455 {
  background-position: 48.10em 35.10em;
}
.emoji-1f456 {
  background-position: 48.10em 33.80em;
}
.emoji-1f457 {
  background-position: 48.10em 32.50em;
}
.emoji-1f458 {
  background-position: 48.10em 31.20em;
}
.emoji-1f459 {
  background-position: 48.10em 29.90em;
}
.emoji-1f45a {
  background-position: 48.10em 28.60em;
}
.emoji-1f45b {
  background-position: 48.10em 27.30em;
}
.emoji-1f45c {
  background-position: 48.10em 26.00em;
}
.emoji-1f45d {
  background-position: 48.10em 24.70em;
}
.emoji-1f45e {
  background-position: 48.10em 23.40em;
}
.emoji-1f45f {
  background-position: 48.10em 22.10em;
}
.emoji-1f460 {
  background-position: 48.10em 20.80em;
}
.emoji-1f461 {
  background-position: 48.10em 19.50em;
}
.emoji-1f462 {
  background-position: 48.10em 18.20em;
}
.emoji-1f463 {
  background-position: 48.10em 16.90em;
}
.emoji-1f464 {
  background-position: 48.10em 15.60em;
}
.emoji-1f465 {
  background-position: 48.10em 14.30em;
}
.emoji-1f466 {
  background-position: 48.10em 13.00em;
}
.emoji-1f466-1f3fb {
  background-position: 48.10em 11.70em;
}
.emoji-1f466-1f3fc {
  background-position: 48.10em 10.40em;
}
.emoji-1f466-1f3fd {
  background-position: 48.10em 9.10em;
}
.emoji-1f466-1f3fe {
  background-position: 48.10em 7.80em;
}
.emoji-1f466-1f3ff {
  background-position: 48.10em 6.50em;
}
.emoji-1f467 {
  background-position: 48.10em 5.20em;
}
.emoji-1f467-1f3fb {
  background-position: 48.10em 3.90em;
}
.emoji-1f467-1f3fc {
  background-position: 48.10em 2.60em;
}
.emoji-1f467-1f3fd {
  background-position: 48.10em 1.30em;
}
.emoji-1f467-1f3fe {
  background-position: 46.80em 67.60em;
}
.emoji-1f467-1f3ff {
  background-position: 46.80em 66.30em;
}
.emoji-1f468-200d-1f33e {
  background-position: 46.80em 65.00em;
}
.emoji-1f468-1f3fb-200d-1f33e {
  background-position: 46.80em 63.70em;
}
.emoji-1f468-1f3fc-200d-1f33e {
  background-position: 46.80em 62.40em;
}
.emoji-1f468-1f3fd-200d-1f33e {
  background-position: 46.80em 61.10em;
}
.emoji-1f468-1f3fe-200d-1f33e {
  background-position: 46.80em 59.80em;
}
.emoji-1f468-1f3ff-200d-1f33e {
  background-position: 46.80em 58.50em;
}
.emoji-1f468-200d-1f373 {
  background-position: 46.80em 57.20em;
}
.emoji-1f468-1f3fb-200d-1f373 {
  background-position: 46.80em 55.90em;
}
.emoji-1f468-1f3fc-200d-1f373 {
  background-position: 46.80em 54.60em;
}
.emoji-1f468-1f3fd-200d-1f373 {
  background-position: 46.80em 53.30em;
}
.emoji-1f468-1f3fe-200d-1f373 {
  background-position: 46.80em 52.00em;
}
.emoji-1f468-1f3ff-200d-1f373 {
  background-position: 46.80em 50.70em;
}
.emoji-1f468-200d-1f393 {
  background-position: 46.80em 49.40em;
}
.emoji-1f468-1f3fb-200d-1f393 {
  background-position: 46.80em 48.10em;
}
.emoji-1f468-1f3fc-200d-1f393 {
  background-position: 46.80em 46.80em;
}
.emoji-1f468-1f3fd-200d-1f393 {
  background-position: 46.80em 45.50em;
}
.emoji-1f468-1f3fe-200d-1f393 {
  background-position: 46.80em 44.20em;
}
.emoji-1f468-1f3ff-200d-1f393 {
  background-position: 46.80em 42.90em;
}
.emoji-1f468-200d-1f3a4 {
  background-position: 46.80em 41.60em;
}
.emoji-1f468-1f3fb-200d-1f3a4 {
  background-position: 46.80em 40.30em;
}
.emoji-1f468-1f3fc-200d-1f3a4 {
  background-position: 46.80em 39.00em;
}
.emoji-1f468-1f3fd-200d-1f3a4 {
  background-position: 46.80em 37.70em;
}
.emoji-1f468-1f3fe-200d-1f3a4 {
  background-position: 46.80em 36.40em;
}
.emoji-1f468-1f3ff-200d-1f3a4 {
  background-position: 46.80em 35.10em;
}
.emoji-1f468-200d-1f3a8 {
  background-position: 46.80em 33.80em;
}
.emoji-1f468-1f3fb-200d-1f3a8 {
  background-position: 46.80em 32.50em;
}
.emoji-1f468-1f3fc-200d-1f3a8 {
  background-position: 46.80em 31.20em;
}
.emoji-1f468-1f3fd-200d-1f3a8 {
  background-position: 46.80em 29.90em;
}
.emoji-1f468-1f3fe-200d-1f3a8 {
  background-position: 46.80em 28.60em;
}
.emoji-1f468-1f3ff-200d-1f3a8 {
  background-position: 46.80em 27.30em;
}
.emoji-1f468-200d-1f3eb {
  background-position: 46.80em 26.00em;
}
.emoji-1f468-1f3fb-200d-1f3eb {
  background-position: 46.80em 24.70em;
}
.emoji-1f468-1f3fc-200d-1f3eb {
  background-position: 46.80em 23.40em;
}
.emoji-1f468-1f3fd-200d-1f3eb {
  background-position: 46.80em 22.10em;
}
.emoji-1f468-1f3fe-200d-1f3eb {
  background-position: 46.80em 20.80em;
}
.emoji-1f468-1f3ff-200d-1f3eb {
  background-position: 46.80em 19.50em;
}
.emoji-1f468-200d-1f3ed {
  background-position: 46.80em 18.20em;
}
.emoji-1f468-1f3fb-200d-1f3ed {
  background-position: 46.80em 16.90em;
}
.emoji-1f468-1f3fc-200d-1f3ed {
  background-position: 46.80em 15.60em;
}
.emoji-1f468-1f3fd-200d-1f3ed {
  background-position: 46.80em 14.30em;
}
.emoji-1f468-1f3fe-200d-1f3ed {
  background-position: 46.80em 13.00em;
}
.emoji-1f468-1f3ff-200d-1f3ed {
  background-position: 46.80em 11.70em;
}
.emoji-1f468-200d-1f466-200d-1f466 {
  background-position: 46.80em 10.40em;
}
.emoji-1f468-200d-1f466 {
  background-position: 46.80em 9.10em;
}
.emoji-1f468-200d-1f467-200d-1f466 {
  background-position: 46.80em 7.80em;
}
.emoji-1f468-200d-1f467-200d-1f467 {
  background-position: 46.80em 6.50em;
}
.emoji-1f468-200d-1f467 {
  background-position: 46.80em 5.20em;
}
.emoji-1f468-200d-1f468-200d-1f466 {
  background-position: 46.80em 3.90em;
}
.emoji-1f468-200d-1f468-200d-1f466-200d-1f466 {
  background-position: 46.80em 2.60em;
}
.emoji-1f468-200d-1f468-200d-1f467 {
  background-position: 46.80em 1.30em;
}
.emoji-1f468-200d-1f468-200d-1f467-200d-1f466 {
  background-position: 45.50em 67.60em;
}
.emoji-1f468-200d-1f468-200d-1f467-200d-1f467 {
  background-position: 45.50em 66.30em;
}
.emoji-1f468-200d-1f469-200d-1f466 {
  background-position: 45.50em 65.00em;
}
.emoji-1f468-200d-1f469-200d-1f466-200d-1f466 {
  background-position: 45.50em 63.70em;
}
.emoji-1f468-200d-1f469-200d-1f467 {
  background-position: 45.50em 62.40em;
}
.emoji-1f468-200d-1f469-200d-1f467-200d-1f466 {
  background-position: 45.50em 61.10em;
}
.emoji-1f468-200d-1f469-200d-1f467-200d-1f467 {
  background-position: 45.50em 59.80em;
}
.emoji-1f468-200d-1f4bb {
  background-position: 45.50em 58.50em;
}
.emoji-1f468-1f3fb-200d-1f4bb {
  background-position: 45.50em 57.20em;
}
.emoji-1f468-1f3fc-200d-1f4bb {
  background-position: 45.50em 55.90em;
}
.emoji-1f468-1f3fd-200d-1f4bb {
  background-position: 45.50em 54.60em;
}
.emoji-1f468-1f3fe-200d-1f4bb {
  background-position: 45.50em 53.30em;
}
.emoji-1f468-1f3ff-200d-1f4bb {
  background-position: 45.50em 52.00em;
}
.emoji-1f468-200d-1f4bc {
  background-position: 45.50em 50.70em;
}
.emoji-1f468-1f3fb-200d-1f4bc {
  background-position: 45.50em 49.40em;
}
.emoji-1f468-1f3fc-200d-1f4bc {
  background-position: 45.50em 48.10em;
}
.emoji-1f468-1f3fd-200d-1f4bc {
  background-position: 45.50em 46.80em;
}
.emoji-1f468-1f3fe-200d-1f4bc {
  background-position: 45.50em 45.50em;
}
.emoji-1f468-1f3ff-200d-1f4bc {
  background-position: 45.50em 44.20em;
}
.emoji-1f468-200d-1f527 {
  background-position: 45.50em 42.90em;
}
.emoji-1f468-1f3fb-200d-1f527 {
  background-position: 45.50em 41.60em;
}
.emoji-1f468-1f3fc-200d-1f527 {
  background-position: 45.50em 40.30em;
}
.emoji-1f468-1f3fd-200d-1f527 {
  background-position: 45.50em 39.00em;
}
.emoji-1f468-1f3fe-200d-1f527 {
  background-position: 45.50em 37.70em;
}
.emoji-1f468-1f3ff-200d-1f527 {
  background-position: 45.50em 36.40em;
}
.emoji-1f468-200d-1f52c {
  background-position: 45.50em 35.10em;
}
.emoji-1f468-1f3fb-200d-1f52c {
  background-position: 45.50em 33.80em;
}
.emoji-1f468-1f3fc-200d-1f52c {
  background-position: 45.50em 32.50em;
}
.emoji-1f468-1f3fd-200d-1f52c {
  background-position: 45.50em 31.20em;
}
.emoji-1f468-1f3fe-200d-1f52c {
  background-position: 45.50em 29.90em;
}
.emoji-1f468-1f3ff-200d-1f52c {
  background-position: 45.50em 28.60em;
}
.emoji-1f468-200d-1f680 {
  background-position: 45.50em 27.30em;
}
.emoji-1f468-1f3fb-200d-1f680 {
  background-position: 45.50em 26.00em;
}
.emoji-1f468-1f3fc-200d-1f680 {
  background-position: 45.50em 24.70em;
}
.emoji-1f468-1f3fd-200d-1f680 {
  background-position: 45.50em 23.40em;
}
.emoji-1f468-1f3fe-200d-1f680 {
  background-position: 45.50em 22.10em;
}
.emoji-1f468-1f3ff-200d-1f680 {
  background-position: 45.50em 20.80em;
}
.emoji-1f468-200d-1f692 {
  background-position: 45.50em 19.50em;
}
.emoji-1f468-1f3fb-200d-1f692 {
  background-position: 45.50em 18.20em;
}
.emoji-1f468-1f3fc-200d-1f692 {
  background-position: 45.50em 16.90em;
}
.emoji-1f468-1f3fd-200d-1f692 {
  background-position: 45.50em 15.60em;
}
.emoji-1f468-1f3fe-200d-1f692 {
  background-position: 45.50em 14.30em;
}
.emoji-1f468-1f3ff-200d-1f692 {
  background-position: 45.50em 13.00em;
}
.emoji-1f468-200d-2695-fe0f {
  background-position: 45.50em 11.70em;
}
.emoji-1f468-1f3fb-200d-2695-fe0f {
  background-position: 45.50em 10.40em;
}
.emoji-1f468-1f3fc-200d-2695-fe0f {
  background-position: 45.50em 9.10em;
}
.emoji-1f468-1f3fd-200d-2695-fe0f {
  background-position: 45.50em 7.80em;
}
.emoji-1f468-1f3fe-200d-2695-fe0f {
  background-position: 45.50em 6.50em;
}
.emoji-1f468-1f3ff-200d-2695-fe0f {
  background-position: 45.50em 5.20em;
}
.emoji-1f468-200d-2696-fe0f {
  background-position: 45.50em 3.90em;
}
.emoji-1f468-1f3fb-200d-2696-fe0f {
  background-position: 45.50em 2.60em;
}
.emoji-1f468-1f3fc-200d-2696-fe0f {
  background-position: 45.50em 1.30em;
}
.emoji-1f468-1f3fd-200d-2696-fe0f {
  background-position: 44.20em 67.60em;
}
.emoji-1f468-1f3fe-200d-2696-fe0f {
  background-position: 44.20em 66.30em;
}
.emoji-1f468-1f3ff-200d-2696-fe0f {
  background-position: 44.20em 65.00em;
}
.emoji-1f468-200d-2708-fe0f {
  background-position: 44.20em 63.70em;
}
.emoji-1f468-1f3fb-200d-2708-fe0f {
  background-position: 44.20em 62.40em;
}
.emoji-1f468-1f3fc-200d-2708-fe0f {
  background-position: 44.20em 61.10em;
}
.emoji-1f468-1f3fd-200d-2708-fe0f {
  background-position: 44.20em 59.80em;
}
.emoji-1f468-1f3fe-200d-2708-fe0f {
  background-position: 44.20em 58.50em;
}
.emoji-1f468-1f3ff-200d-2708-fe0f {
  background-position: 44.20em 57.20em;
}
.emoji-1f468-200d-2764-fe0f-200d-1f468 {
  background-position: 44.20em 55.90em;
}
.emoji-1f468-200d-2764-fe0f-200d-1f48b-200d-1f468 {
  background-position: 44.20em 54.60em;
}
.emoji-1f468 {
  background-position: 44.20em 53.30em;
}
.emoji-1f468-1f3fb {
  background-position: 44.20em 52.00em;
}
.emoji-1f468-1f3fc {
  background-position: 44.20em 50.70em;
}
.emoji-1f468-1f3fd {
  background-position: 44.20em 49.40em;
}
.emoji-1f468-1f3fe {
  background-position: 44.20em 48.10em;
}
.emoji-1f468-1f3ff {
  background-position: 44.20em 46.80em;
}
.emoji-1f469-200d-1f33e {
  background-position: 44.20em 45.50em;
}
.emoji-1f469-1f3fb-200d-1f33e {
  background-position: 44.20em 44.20em;
}
.emoji-1f469-1f3fc-200d-1f33e {
  background-position: 44.20em 42.90em;
}
.emoji-1f469-1f3fd-200d-1f33e {
  background-position: 44.20em 41.60em;
}
.emoji-1f469-1f3fe-200d-1f33e {
  background-position: 44.20em 40.30em;
}
.emoji-1f469-1f3ff-200d-1f33e {
  background-position: 44.20em 39.00em;
}
.emoji-1f469-200d-1f373 {
  background-position: 44.20em 37.70em;
}
.emoji-1f469-1f3fb-200d-1f373 {
  background-position: 44.20em 36.40em;
}
.emoji-1f469-1f3fc-200d-1f373 {
  background-position: 44.20em 35.10em;
}
.emoji-1f469-1f3fd-200d-1f373 {
  background-position: 44.20em 33.80em;
}
.emoji-1f469-1f3fe-200d-1f373 {
  background-position: 44.20em 32.50em;
}
.emoji-1f469-1f3ff-200d-1f373 {
  background-position: 44.20em 31.20em;
}
.emoji-1f469-200d-1f393 {
  background-position: 44.20em 29.90em;
}
.emoji-1f469-1f3fb-200d-1f393 {
  background-position: 44.20em 28.60em;
}
.emoji-1f469-1f3fc-200d-1f393 {
  background-position: 44.20em 27.30em;
}
.emoji-1f469-1f3fd-200d-1f393 {
  background-position: 44.20em 26.00em;
}
.emoji-1f469-1f3fe-200d-1f393 {
  background-position: 44.20em 24.70em;
}
.emoji-1f469-1f3ff-200d-1f393 {
  background-position: 44.20em 23.40em;
}
.emoji-1f469-200d-1f3a4 {
  background-position: 44.20em 22.10em;
}
.emoji-1f469-1f3fb-200d-1f3a4 {
  background-position: 44.20em 20.80em;
}
.emoji-1f469-1f3fc-200d-1f3a4 {
  background-position: 44.20em 19.50em;
}
.emoji-1f469-1f3fd-200d-1f3a4 {
  background-position: 44.20em 18.20em;
}
.emoji-1f469-1f3fe-200d-1f3a4 {
  background-position: 44.20em 16.90em;
}
.emoji-1f469-1f3ff-200d-1f3a4 {
  background-position: 44.20em 15.60em;
}
.emoji-1f469-200d-1f3a8 {
  background-position: 44.20em 14.30em;
}
.emoji-1f469-1f3fb-200d-1f3a8 {
  background-position: 44.20em 13.00em;
}
.emoji-1f469-1f3fc-200d-1f3a8 {
  background-position: 44.20em 11.70em;
}
.emoji-1f469-1f3fd-200d-1f3a8 {
  background-position: 44.20em 10.40em;
}
.emoji-1f469-1f3fe-200d-1f3a8 {
  background-position: 44.20em 9.10em;
}
.emoji-1f469-1f3ff-200d-1f3a8 {
  background-position: 44.20em 7.80em;
}
.emoji-1f469-200d-1f3eb {
  background-position: 44.20em 6.50em;
}
.emoji-1f469-1f3fb-200d-1f3eb {
  background-position: 44.20em 5.20em;
}
.emoji-1f469-1f3fc-200d-1f3eb {
  background-position: 44.20em 3.90em;
}
.emoji-1f469-1f3fd-200d-1f3eb {
  background-position: 44.20em 2.60em;
}
.emoji-1f469-1f3fe-200d-1f3eb {
  background-position: 44.20em 1.30em;
}
.emoji-1f469-1f3ff-200d-1f3eb {
  background-position: 42.90em 67.60em;
}
.emoji-1f469-200d-1f3ed {
  background-position: 42.90em 66.30em;
}
.emoji-1f469-1f3fb-200d-1f3ed {
  background-position: 42.90em 65.00em;
}
.emoji-1f469-1f3fc-200d-1f3ed {
  background-position: 42.90em 63.70em;
}
.emoji-1f469-1f3fd-200d-1f3ed {
  background-position: 42.90em 62.40em;
}
.emoji-1f469-1f3fe-200d-1f3ed {
  background-position: 42.90em 61.10em;
}
.emoji-1f469-1f3ff-200d-1f3ed {
  background-position: 42.90em 59.80em;
}
.emoji-1f469-200d-1f466-200d-1f466 {
  background-position: 42.90em 58.50em;
}
.emoji-1f469-200d-1f466 {
  background-position: 42.90em 57.20em;
}
.emoji-1f469-200d-1f467-200d-1f466 {
  background-position: 42.90em 55.90em;
}
.emoji-1f469-200d-1f467-200d-1f467 {
  background-position: 42.90em 54.60em;
}
.emoji-1f469-200d-1f467 {
  background-position: 42.90em 53.30em;
}
.emoji-1f469-200d-1f469-200d-1f466 {
  background-position: 42.90em 52.00em;
}
.emoji-1f469-200d-1f469-200d-1f466-200d-1f466 {
  background-position: 42.90em 50.70em;
}
.emoji-1f469-200d-1f469-200d-1f467 {
  background-position: 42.90em 49.40em;
}
.emoji-1f469-200d-1f469-200d-1f467-200d-1f466 {
  background-position: 42.90em 48.10em;
}
.emoji-1f469-200d-1f469-200d-1f467-200d-1f467 {
  background-position: 42.90em 46.80em;
}
.emoji-1f469-200d-1f4bb {
  background-position: 42.90em 45.50em;
}
.emoji-1f469-1f3fb-200d-1f4bb {
  background-position: 42.90em 44.20em;
}
.emoji-1f469-1f3fc-200d-1f4bb {
  background-position: 42.90em 42.90em;
}
.emoji-1f469-1f3fd-200d-1f4bb {
  background-position: 42.90em 41.60em;
}
.emoji-1f469-1f3fe-200d-1f4bb {
  background-position: 42.90em 40.30em;
}
.emoji-1f469-1f3ff-200d-1f4bb {
  background-position: 42.90em 39.00em;
}
.emoji-1f469-200d-1f4bc {
  background-position: 42.90em 37.70em;
}
.emoji-1f469-1f3fb-200d-1f4bc {
  background-position: 42.90em 36.40em;
}
.emoji-1f469-1f3fc-200d-1f4bc {
  background-position: 42.90em 35.10em;
}
.emoji-1f469-1f3fd-200d-1f4bc {
  background-position: 42.90em 33.80em;
}
.emoji-1f469-1f3fe-200d-1f4bc {
  background-position: 42.90em 32.50em;
}
.emoji-1f469-1f3ff-200d-1f4bc {
  background-position: 42.90em 31.20em;
}
.emoji-1f469-200d-1f527 {
  background-position: 42.90em 29.90em;
}
.emoji-1f469-1f3fb-200d-1f527 {
  background-position: 42.90em 28.60em;
}
.emoji-1f469-1f3fc-200d-1f527 {
  background-position: 42.90em 27.30em;
}
.emoji-1f469-1f3fd-200d-1f527 {
  background-position: 42.90em 26.00em;
}
.emoji-1f469-1f3fe-200d-1f527 {
  background-position: 42.90em 24.70em;
}
.emoji-1f469-1f3ff-200d-1f527 {
  background-position: 42.90em 23.40em;
}
.emoji-1f469-200d-1f52c {
  background-position: 42.90em 22.10em;
}
.emoji-1f469-1f3fb-200d-1f52c {
  background-position: 42.90em 20.80em;
}
.emoji-1f469-1f3fc-200d-1f52c {
  background-position: 42.90em 19.50em;
}
.emoji-1f469-1f3fd-200d-1f52c {
  background-position: 42.90em 18.20em;
}
.emoji-1f469-1f3fe-200d-1f52c {
  background-position: 42.90em 16.90em;
}
.emoji-1f469-1f3ff-200d-1f52c {
  background-position: 42.90em 15.60em;
}
.emoji-1f469-200d-1f680 {
  background-position: 42.90em 14.30em;
}
.emoji-1f469-1f3fb-200d-1f680 {
  background-position: 42.90em 13.00em;
}
.emoji-1f469-1f3fc-200d-1f680 {
  background-position: 42.90em 11.70em;
}
.emoji-1f469-1f3fd-200d-1f680 {
  background-position: 42.90em 10.40em;
}
.emoji-1f469-1f3fe-200d-1f680 {
  background-position: 42.90em 9.10em;
}
.emoji-1f469-1f3ff-200d-1f680 {
  background-position: 42.90em 7.80em;
}
.emoji-1f469-200d-1f692 {
  background-position: 42.90em 6.50em;
}
.emoji-1f469-1f3fb-200d-1f692 {
  background-position: 42.90em 5.20em;
}
.emoji-1f469-1f3fc-200d-1f692 {
  background-position: 42.90em 3.90em;
}
.emoji-1f469-1f3fd-200d-1f692 {
  background-position: 42.90em 2.60em;
}
.emoji-1f469-1f3fe-200d-1f692 {
  background-position: 42.90em 1.30em;
}
.emoji-1f469-1f3ff-200d-1f692 {
  background-position: 41.60em 67.60em;
}
.emoji-1f469-200d-2695-fe0f {
  background-position: 41.60em 66.30em;
}
.emoji-1f469-1f3fb-200d-2695-fe0f {
  background-position: 41.60em 65.00em;
}
.emoji-1f469-1f3fc-200d-2695-fe0f {
  background-position: 41.60em 63.70em;
}
.emoji-1f469-1f3fd-200d-2695-fe0f {
  background-position: 41.60em 62.40em;
}
.emoji-1f469-1f3fe-200d-2695-fe0f {
  background-position: 41.60em 61.10em;
}
.emoji-1f469-1f3ff-200d-2695-fe0f {
  background-position: 41.60em 59.80em;
}
.emoji-1f469-200d-2696-fe0f {
  background-position: 41.60em 58.50em;
}
.emoji-1f469-1f3fb-200d-2696-fe0f {
  background-position: 41.60em 57.20em;
}
.emoji-1f469-1f3fc-200d-2696-fe0f {
  background-position: 41.60em 55.90em;
}
.emoji-1f469-1f3fd-200d-2696-fe0f {
  background-position: 41.60em 54.60em;
}
.emoji-1f469-1f3fe-200d-2696-fe0f {
  background-position: 41.60em 53.30em;
}
.emoji-1f469-1f3ff-200d-2696-fe0f {
  background-position: 41.60em 52.00em;
}
.emoji-1f469-200d-2708-fe0f {
  background-position: 41.60em 50.70em;
}
.emoji-1f469-1f3fb-200d-2708-fe0f {
  background-position: 41.60em 49.40em;
}
.emoji-1f469-1f3fc-200d-2708-fe0f {
  background-position: 41.60em 48.10em;
}
.emoji-1f469-1f3fd-200d-2708-fe0f {
  background-position: 41.60em 46.80em;
}
.emoji-1f469-1f3fe-200d-2708-fe0f {
  background-position: 41.60em 45.50em;
}
.emoji-1f469-1f3ff-200d-2708-fe0f {
  background-position: 41.60em 44.20em;
}
.emoji-1f469-200d-2764-fe0f-200d-1f468 {
  background-position: 41.60em 42.90em;
}
.emoji-1f469-200d-2764-fe0f-200d-1f469 {
  background-position: 41.60em 41.60em;
}
.emoji-1f469-200d-2764-fe0f-200d-1f48b-200d-1f468 {
  background-position: 41.60em 40.30em;
}
.emoji-1f469-200d-2764-fe0f-200d-1f48b-200d-1f469 {
  background-position: 41.60em 39.00em;
}
.emoji-1f469 {
  background-position: 41.60em 37.70em;
}
.emoji-1f469-1f3fb {
  background-position: 41.60em 36.40em;
}
.emoji-1f469-1f3fc {
  background-position: 41.60em 35.10em;
}
.emoji-1f469-1f3fd {
  background-position: 41.60em 33.80em;
}
.emoji-1f469-1f3fe {
  background-position: 41.60em 32.50em;
}
.emoji-1f469-1f3ff {
  background-position: 41.60em 31.20em;
}
.emoji-1f46a {
  background-position: 41.60em 29.90em;
}
.emoji-1f46b {
  background-position: 41.60em 28.60em;
}
.emoji-1f46c {
  background-position: 41.60em 27.30em;
}
.emoji-1f46d {
  background-position: 41.60em 26.00em;
}
.emoji-1f46e-200d-2640-fe0f {
  background-position: 41.60em 24.70em;
}
.emoji-1f46e-1f3fb-200d-2640-fe0f {
  background-position: 41.60em 23.40em;
}
.emoji-1f46e-1f3fc-200d-2640-fe0f {
  background-position: 41.60em 22.10em;
}
.emoji-1f46e-1f3fd-200d-2640-fe0f {
  background-position: 41.60em 20.80em;
}
.emoji-1f46e-1f3fe-200d-2640-fe0f {
  background-position: 41.60em 19.50em;
}
.emoji-1f46e-1f3ff-200d-2640-fe0f {
  background-position: 41.60em 18.20em;
}
.emoji-1f46e-200d-2642-fe0f {
  background-position: 41.60em 16.90em;
}
.emoji-1f46e-1f3fb-200d-2642-fe0f {
  background-position: 41.60em 15.60em;
}
.emoji-1f46e-1f3fc-200d-2642-fe0f {
  background-position: 41.60em 14.30em;
}
.emoji-1f46e-1f3fd-200d-2642-fe0f {
  background-position: 41.60em 13.00em;
}
.emoji-1f46e-1f3fe-200d-2642-fe0f {
  background-position: 41.60em 11.70em;
}
.emoji-1f46e-1f3ff-200d-2642-fe0f {
  background-position: 41.60em 10.40em;
}
.emoji-1f46e {
  background-position: 41.60em 9.10em;
}
.emoji-1f46e-1f3fb {
  background-position: 41.60em 7.80em;
}
.emoji-1f46e-1f3fc {
  background-position: 41.60em 6.50em;
}
.emoji-1f46e-1f3fd {
  background-position: 41.60em 5.20em;
}
.emoji-1f46e-1f3fe {
  background-position: 41.60em 3.90em;
}
.emoji-1f46e-1f3ff {
  background-position: 41.60em 2.60em;
}
.emoji-1f46f-200d-2640-fe0f {
  background-position: 41.60em 1.30em;
}
.emoji-1f46f-200d-2642-fe0f {
  background-position: 40.30em 67.60em;
}
.emoji-1f46f {
  background-position: 40.30em 66.30em;
}
.emoji-1f470 {
  background-position: 40.30em 65.00em;
}
.emoji-1f470-1f3fb {
  background-position: 40.30em 63.70em;
}
.emoji-1f470-1f3fc {
  background-position: 40.30em 62.40em;
}
.emoji-1f470-1f3fd {
  background-position: 40.30em 61.10em;
}
.emoji-1f470-1f3fe {
  background-position: 40.30em 59.80em;
}
.emoji-1f470-1f3ff {
  background-position: 40.30em 58.50em;
}
.emoji-1f471-200d-2640-fe0f {
  background-position: 40.30em 57.20em;
}
.emoji-1f471-1f3fb-200d-2640-fe0f {
  background-position: 40.30em 55.90em;
}
.emoji-1f471-1f3fc-200d-2640-fe0f {
  background-position: 40.30em 54.60em;
}
.emoji-1f471-1f3fd-200d-2640-fe0f {
  background-position: 40.30em 53.30em;
}
.emoji-1f471-1f3fe-200d-2640-fe0f {
  background-position: 40.30em 52.00em;
}
.emoji-1f471-1f3ff-200d-2640-fe0f {
  background-position: 40.30em 50.70em;
}
.emoji-1f471-200d-2642-fe0f {
  background-position: 40.30em 49.40em;
}
.emoji-1f471-1f3fb-200d-2642-fe0f {
  background-position: 40.30em 48.10em;
}
.emoji-1f471-1f3fc-200d-2642-fe0f {
  background-position: 40.30em 46.80em;
}
.emoji-1f471-1f3fd-200d-2642-fe0f {
  background-position: 40.30em 45.50em;
}
.emoji-1f471-1f3fe-200d-2642-fe0f {
  background-position: 40.30em 44.20em;
}
.emoji-1f471-1f3ff-200d-2642-fe0f {
  background-position: 40.30em 42.90em;
}
.emoji-1f471 {
  background-position: 40.30em 41.60em;
}
.emoji-1f471-1f3fb {
  background-position: 40.30em 40.30em;
}
.emoji-1f471-1f3fc {
  background-position: 40.30em 39.00em;
}
.emoji-1f471-1f3fd {
  background-position: 40.30em 37.70em;
}
.emoji-1f471-1f3fe {
  background-position: 40.30em 36.40em;
}
.emoji-1f471-1f3ff {
  background-position: 40.30em 35.10em;
}
.emoji-1f472 {
  background-position: 40.30em 33.80em;
}
.emoji-1f472-1f3fb {
  background-position: 40.30em 32.50em;
}
.emoji-1f472-1f3fc {
  background-position: 40.30em 31.20em;
}
.emoji-1f472-1f3fd {
  background-position: 40.30em 29.90em;
}
.emoji-1f472-1f3fe {
  background-position: 40.30em 28.60em;
}
.emoji-1f472-1f3ff {
  background-position: 40.30em 27.30em;
}
.emoji-1f473-200d-2640-fe0f {
  background-position: 40.30em 26.00em;
}
.emoji-1f473-1f3fb-200d-2640-fe0f {
  background-position: 40.30em 24.70em;
}
.emoji-1f473-1f3fc-200d-2640-fe0f {
  background-position: 40.30em 23.40em;
}
.emoji-1f473-1f3fd-200d-2640-fe0f {
  background-position: 40.30em 22.10em;
}
.emoji-1f473-1f3fe-200d-2640-fe0f {
  background-position: 40.30em 20.80em;
}
.emoji-1f473-1f3ff-200d-2640-fe0f {
  background-position: 40.30em 19.50em;
}
.emoji-1f473-200d-2642-fe0f {
  background-position: 40.30em 18.20em;
}
.emoji-1f473-1f3fb-200d-2642-fe0f {
  background-position: 40.30em 16.90em;
}
.emoji-1f473-1f3fc-200d-2642-fe0f {
  background-position: 40.30em 15.60em;
}
.emoji-1f473-1f3fd-200d-2642-fe0f {
  background-position: 40.30em 14.30em;
}
.emoji-1f473-1f3fe-200d-2642-fe0f {
  background-position: 40.30em 13.00em;
}
.emoji-1f473-1f3ff-200d-2642-fe0f {
  background-position: 40.30em 11.70em;
}
.emoji-1f473 {
  background-position: 40.30em 10.40em;
}
.emoji-1f473-1f3fb {
  background-position: 40.30em 9.10em;
}
.emoji-1f473-1f3fc {
  background-position: 40.30em 7.80em;
}
.emoji-1f473-1f3fd {
  background-position: 40.30em 6.50em;
}
.emoji-1f473-1f3fe {
  background-position: 40.30em 5.20em;
}
.emoji-1f473-1f3ff {
  background-position: 40.30em 3.90em;
}
.emoji-1f474 {
  background-position: 40.30em 2.60em;
}
.emoji-1f474-1f3fb {
  background-position: 40.30em 1.30em;
}
.emoji-1f474-1f3fc {
  background-position: 39.00em 67.60em;
}
.emoji-1f474-1f3fd {
  background-position: 39.00em 66.30em;
}
.emoji-1f474-1f3fe {
  background-position: 39.00em 65.00em;
}
.emoji-1f474-1f3ff {
  background-position: 39.00em 63.70em;
}
.emoji-1f475 {
  background-position: 39.00em 62.40em;
}
.emoji-1f475-1f3fb {
  background-position: 39.00em 61.10em;
}
.emoji-1f475-1f3fc {
  background-position: 39.00em 59.80em;
}
.emoji-1f475-1f3fd {
  background-position: 39.00em 58.50em;
}
.emoji-1f475-1f3fe {
  background-position: 39.00em 57.20em;
}
.emoji-1f475-1f3ff {
  background-position: 39.00em 55.90em;
}
.emoji-1f476 {
  background-position: 39.00em 54.60em;
}
.emoji-1f476-1f3fb {
  background-position: 39.00em 53.30em;
}
.emoji-1f476-1f3fc {
  background-position: 39.00em 52.00em;
}
.emoji-1f476-1f3fd {
  background-position: 39.00em 50.70em;
}
.emoji-1f476-1f3fe {
  background-position: 39.00em 49.40em;
}
.emoji-1f476-1f3ff {
  background-position: 39.00em 48.10em;
}
.emoji-1f477-200d-2640-fe0f {
  background-position: 39.00em 46.80em;
}
.emoji-1f477-1f3fb-200d-2640-fe0f {
  background-position: 39.00em 45.50em;
}
.emoji-1f477-1f3fc-200d-2640-fe0f {
  background-position: 39.00em 44.20em;
}
.emoji-1f477-1f3fd-200d-2640-fe0f {
  background-position: 39.00em 42.90em;
}
.emoji-1f477-1f3fe-200d-2640-fe0f {
  background-position: 39.00em 41.60em;
}
.emoji-1f477-1f3ff-200d-2640-fe0f {
  background-position: 39.00em 40.30em;
}
.emoji-1f477-200d-2642-fe0f {
  background-position: 39.00em 39.00em;
}
.emoji-1f477-1f3fb-200d-2642-fe0f {
  background-position: 39.00em 37.70em;
}
.emoji-1f477-1f3fc-200d-2642-fe0f {
  background-position: 39.00em 36.40em;
}
.emoji-1f477-1f3fd-200d-2642-fe0f {
  background-position: 39.00em 35.10em;
}
.emoji-1f477-1f3fe-200d-2642-fe0f {
  background-position: 39.00em 33.80em;
}
.emoji-1f477-1f3ff-200d-2642-fe0f {
  background-position: 39.00em 32.50em;
}
.emoji-1f477 {
  background-position: 39.00em 31.20em;
}
.emoji-1f477-1f3fb {
  background-position: 39.00em 29.90em;
}
.emoji-1f477-1f3fc {
  background-position: 39.00em 28.60em;
}
.emoji-1f477-1f3fd {
  background-position: 39.00em 27.30em;
}
.emoji-1f477-1f3fe {
  background-position: 39.00em 26.00em;
}
.emoji-1f477-1f3ff {
  background-position: 39.00em 24.70em;
}
.emoji-1f478 {
  background-position: 39.00em 23.40em;
}
.emoji-1f478-1f3fb {
  background-position: 39.00em 22.10em;
}
.emoji-1f478-1f3fc {
  background-position: 39.00em 20.80em;
}
.emoji-1f478-1f3fd {
  background-position: 39.00em 19.50em;
}
.emoji-1f478-1f3fe {
  background-position: 39.00em 18.20em;
}
.emoji-1f478-1f3ff {
  background-position: 39.00em 16.90em;
}
.emoji-1f479 {
  background-position: 39.00em 15.60em;
}
.emoji-1f47a {
  background-position: 39.00em 14.30em;
}
.emoji-1f47b {
  background-position: 39.00em 13.00em;
}
.emoji-1f47c {
  background-position: 39.00em 11.70em;
}
.emoji-1f47c-1f3fb {
  background-position: 39.00em 10.40em;
}
.emoji-1f47c-1f3fc {
  background-position: 39.00em 9.10em;
}
.emoji-1f47c-1f3fd {
  background-position: 39.00em 7.80em;
}
.emoji-1f47c-1f3fe {
  background-position: 39.00em 6.50em;
}
.emoji-1f47c-1f3ff {
  background-position: 39.00em 5.20em;
}
.emoji-1f47d {
  background-position: 39.00em 3.90em;
}
.emoji-1f47e {
  background-position: 39.00em 2.60em;
}
.emoji-1f47f {
  background-position: 39.00em 1.30em;
}
.emoji-1f480 {
  background-position: 37.70em 67.60em;
}
.emoji-1f481-200d-2640-fe0f {
  background-position: 37.70em 66.30em;
}
.emoji-1f481-1f3fb-200d-2640-fe0f {
  background-position: 37.70em 65.00em;
}
.emoji-1f481-1f3fc-200d-2640-fe0f {
  background-position: 37.70em 63.70em;
}
.emoji-1f481-1f3fd-200d-2640-fe0f {
  background-position: 37.70em 62.40em;
}
.emoji-1f481-1f3fe-200d-2640-fe0f {
  background-position: 37.70em 61.10em;
}
.emoji-1f481-1f3ff-200d-2640-fe0f {
  background-position: 37.70em 59.80em;
}
.emoji-1f481-200d-2642-fe0f {
  background-position: 37.70em 58.50em;
}
.emoji-1f481-1f3fb-200d-2642-fe0f {
  background-position: 37.70em 57.20em;
}
.emoji-1f481-1f3fc-200d-2642-fe0f {
  background-position: 37.70em 55.90em;
}
.emoji-1f481-1f3fd-200d-2642-fe0f {
  background-position: 37.70em 54.60em;
}
.emoji-1f481-1f3fe-200d-2642-fe0f {
  background-position: 37.70em 53.30em;
}
.emoji-1f481-1f3ff-200d-2642-fe0f {
  background-position: 37.70em 52.00em;
}
.emoji-1f481 {
  background-position: 37.70em 50.70em;
}
.emoji-1f481-1f3fb {
  background-position: 37.70em 49.40em;
}
.emoji-1f481-1f3fc {
  background-position: 37.70em 48.10em;
}
.emoji-1f481-1f3fd {
  background-position: 37.70em 46.80em;
}
.emoji-1f481-1f3fe {
  background-position: 37.70em 45.50em;
}
.emoji-1f481-1f3ff {
  background-position: 37.70em 44.20em;
}
.emoji-1f482-200d-2640-fe0f {
  background-position: 37.70em 42.90em;
}
.emoji-1f482-1f3fb-200d-2640-fe0f {
  background-position: 37.70em 41.60em;
}
.emoji-1f482-1f3fc-200d-2640-fe0f {
  background-position: 37.70em 40.30em;
}
.emoji-1f482-1f3fd-200d-2640-fe0f {
  background-position: 37.70em 39.00em;
}
.emoji-1f482-1f3fe-200d-2640-fe0f {
  background-position: 37.70em 37.70em;
}
.emoji-1f482-1f3ff-200d-2640-fe0f {
  background-position: 37.70em 36.40em;
}
.emoji-1f482-200d-2642-fe0f {
  background-position: 37.70em 35.10em;
}
.emoji-1f482-1f3fb-200d-2642-fe0f {
  background-position: 37.70em 33.80em;
}
.emoji-1f482-1f3fc-200d-2642-fe0f {
  background-position: 37.70em 32.50em;
}
.emoji-1f482-1f3fd-200d-2642-fe0f {
  background-position: 37.70em 31.20em;
}
.emoji-1f482-1f3fe-200d-2642-fe0f {
  background-position: 37.70em 29.90em;
}
.emoji-1f482-1f3ff-200d-2642-fe0f {
  background-position: 37.70em 28.60em;
}
.emoji-1f482 {
  background-position: 37.70em 27.30em;
}
.emoji-1f482-1f3fb {
  background-position: 37.70em 26.00em;
}
.emoji-1f482-1f3fc {
  background-position: 37.70em 24.70em;
}
.emoji-1f482-1f3fd {
  background-position: 37.70em 23.40em;
}
.emoji-1f482-1f3fe {
  background-position: 37.70em 22.10em;
}
.emoji-1f482-1f3ff {
  background-position: 37.70em 20.80em;
}
.emoji-1f483 {
  background-position: 37.70em 19.50em;
}
.emoji-1f483-1f3fb {
  background-position: 37.70em 18.20em;
}
.emoji-1f483-1f3fc {
  background-position: 37.70em 16.90em;
}
.emoji-1f483-1f3fd {
  background-position: 37.70em 15.60em;
}
.emoji-1f483-1f3fe {
  background-position: 37.70em 14.30em;
}
.emoji-1f483-1f3ff {
  background-position: 37.70em 13.00em;
}
.emoji-1f484 {
  background-position: 37.70em 11.70em;
}
.emoji-1f485 {
  background-position: 37.70em 10.40em;
}
.emoji-1f485-1f3fb {
  background-position: 37.70em 9.10em;
}
.emoji-1f485-1f3fc {
  background-position: 37.70em 7.80em;
}
.emoji-1f485-1f3fd {
  background-position: 37.70em 6.50em;
}
.emoji-1f485-1f3fe {
  background-position: 37.70em 5.20em;
}
.emoji-1f485-1f3ff {
  background-position: 37.70em 3.90em;
}
.emoji-1f486-200d-2640-fe0f {
  background-position: 37.70em 2.60em;
}
.emoji-1f486-1f3fb-200d-2640-fe0f {
  background-position: 37.70em 1.30em;
}
.emoji-1f486-1f3fc-200d-2640-fe0f {
  background-position: 36.40em 67.60em;
}
.emoji-1f486-1f3fd-200d-2640-fe0f {
  background-position: 36.40em 66.30em;
}
.emoji-1f486-1f3fe-200d-2640-fe0f {
  background-position: 36.40em 65.00em;
}
.emoji-1f486-1f3ff-200d-2640-fe0f {
  background-position: 36.40em 63.70em;
}
.emoji-1f486-200d-2642-fe0f {
  background-position: 36.40em 62.40em;
}
.emoji-1f486-1f3fb-200d-2642-fe0f {
  background-position: 36.40em 61.10em;
}
.emoji-1f486-1f3fc-200d-2642-fe0f {
  background-position: 36.40em 59.80em;
}
.emoji-1f486-1f3fd-200d-2642-fe0f {
  background-position: 36.40em 58.50em;
}
.emoji-1f486-1f3fe-200d-2642-fe0f {
  background-position: 36.40em 57.20em;
}
.emoji-1f486-1f3ff-200d-2642-fe0f {
  background-position: 36.40em 55.90em;
}
.emoji-1f486 {
  background-position: 36.40em 54.60em;
}
.emoji-1f486-1f3fb {
  background-position: 36.40em 53.30em;
}
.emoji-1f486-1f3fc {
  background-position: 36.40em 52.00em;
}
.emoji-1f486-1f3fd {
  background-position: 36.40em 50.70em;
}
.emoji-1f486-1f3fe {
  background-position: 36.40em 49.40em;
}
.emoji-1f486-1f3ff {
  background-position: 36.40em 48.10em;
}
.emoji-1f487-200d-2640-fe0f {
  background-position: 36.40em 46.80em;
}
.emoji-1f487-1f3fb-200d-2640-fe0f {
  background-position: 36.40em 45.50em;
}
.emoji-1f487-1f3fc-200d-2640-fe0f {
  background-position: 36.40em 44.20em;
}
.emoji-1f487-1f3fd-200d-2640-fe0f {
  background-position: 36.40em 42.90em;
}
.emoji-1f487-1f3fe-200d-2640-fe0f {
  background-position: 36.40em 41.60em;
}
.emoji-1f487-1f3ff-200d-2640-fe0f {
  background-position: 36.40em 40.30em;
}
.emoji-1f487-200d-2642-fe0f {
  background-position: 36.40em 39.00em;
}
.emoji-1f487-1f3fb-200d-2642-fe0f {
  background-position: 36.40em 37.70em;
}
.emoji-1f487-1f3fc-200d-2642-fe0f {
  background-position: 36.40em 36.40em;
}
.emoji-1f487-1f3fd-200d-2642-fe0f {
  background-position: 36.40em 35.10em;
}
.emoji-1f487-1f3fe-200d-2642-fe0f {
  background-position: 36.40em 33.80em;
}
.emoji-1f487-1f3ff-200d-2642-fe0f {
  background-position: 36.40em 32.50em;
}
.emoji-1f487 {
  background-position: 36.40em 31.20em;
}
.emoji-1f487-1f3fb {
  background-position: 36.40em 29.90em;
}
.emoji-1f487-1f3fc {
  background-position: 36.40em 28.60em;
}
.emoji-1f487-1f3fd {
  background-position: 36.40em 27.30em;
}
.emoji-1f487-1f3fe {
  background-position: 36.40em 26.00em;
}
.emoji-1f487-1f3ff {
  background-position: 36.40em 24.70em;
}
.emoji-1f488 {
  background-position: 36.40em 23.40em;
}
.emoji-1f489 {
  background-position: 36.40em 22.10em;
}
.emoji-1f48a {
  background-position: 36.40em 20.80em;
}
.emoji-1f48b {
  background-position: 36.40em 19.50em;
}
.emoji-1f48c {
  background-position: 36.40em 18.20em;
}
.emoji-1f48d {
  background-position: 36.40em 16.90em;
}
.emoji-1f48e {
  background-position: 36.40em 15.60em;
}
.emoji-1f48f {
  background-position: 36.40em 14.30em;
}
.emoji-1f490 {
  background-position: 36.40em 13.00em;
}
.emoji-1f491 {
  background-position: 36.40em 11.70em;
}
.emoji-1f492 {
  background-position: 36.40em 10.40em;
}
.emoji-1f493 {
  background-position: 36.40em 9.10em;
}
.emoji-1f494 {
  background-position: 36.40em 7.80em;
}
.emoji-1f495 {
  background-position: 36.40em 6.50em;
}
.emoji-1f496 {
  background-position: 36.40em 5.20em;
}
.emoji-1f497 {
  background-position: 36.40em 3.90em;
}
.emoji-1f498 {
  background-position: 36.40em 2.60em;
}
.emoji-1f499 {
  background-position: 36.40em 1.30em;
}
.emoji-1f49a {
  background-position: 35.10em 67.60em;
}
.emoji-1f49b {
  background-position: 35.10em 66.30em;
}
.emoji-1f49c {
  background-position: 35.10em 65.00em;
}
.emoji-1f49d {
  background-position: 35.10em 63.70em;
}
.emoji-1f49e {
  background-position: 35.10em 62.40em;
}
.emoji-1f49f {
  background-position: 35.10em 61.10em;
}
.emoji-1f4a0 {
  background-position: 35.10em 59.80em;
}
.emoji-1f4a1 {
  background-position: 35.10em 58.50em;
}
.emoji-1f4a2 {
  background-position: 35.10em 57.20em;
}
.emoji-1f4a3 {
  background-position: 35.10em 55.90em;
}
.emoji-1f4a4 {
  background-position: 35.10em 54.60em;
}
.emoji-1f4a5 {
  background-position: 35.10em 53.30em;
}
.emoji-1f4a6 {
  background-position: 35.10em 52.00em;
}
.emoji-1f4a7 {
  background-position: 35.10em 50.70em;
}
.emoji-1f4a8 {
  background-position: 35.10em 49.40em;
}
.emoji-1f4a9 {
  background-position: 35.10em 48.10em;
}
.emoji-1f4aa {
  background-position: 35.10em 46.80em;
}
.emoji-1f4aa-1f3fb {
  background-position: 35.10em 45.50em;
}
.emoji-1f4aa-1f3fc {
  background-position: 35.10em 44.20em;
}
.emoji-1f4aa-1f3fd {
  background-position: 35.10em 42.90em;
}
.emoji-1f4aa-1f3fe {
  background-position: 35.10em 41.60em;
}
.emoji-1f4aa-1f3ff {
  background-position: 35.10em 40.30em;
}
.emoji-1f4ab {
  background-position: 35.10em 39.00em;
}
.emoji-1f4ac {
  background-position: 35.10em 37.70em;
}
.emoji-1f4ad {
  background-position: 35.10em 36.40em;
}
.emoji-1f4ae {
  background-position: 35.10em 35.10em;
}
.emoji-1f4af {
  background-position: 35.10em 33.80em;
}
.emoji-1f4b0 {
  background-position: 35.10em 32.50em;
}
.emoji-1f4b1 {
  background-position: 35.10em 31.20em;
}
.emoji-1f4b2 {
  background-position: 35.10em 29.90em;
}
.emoji-1f4b3 {
  background-position: 35.10em 28.60em;
}
.emoji-1f4b4 {
  background-position: 35.10em 27.30em;
}
.emoji-1f4b5 {
  background-position: 35.10em 26.00em;
}
.emoji-1f4b6 {
  background-position: 35.10em 24.70em;
}
.emoji-1f4b7 {
  background-position: 35.10em 23.40em;
}
.emoji-1f4b8 {
  background-position: 35.10em 22.10em;
}
.emoji-1f4b9 {
  background-position: 35.10em 20.80em;
}
.emoji-1f4ba {
  background-position: 35.10em 19.50em;
}
.emoji-1f4bb {
  background-position: 35.10em 18.20em;
}
.emoji-1f4bc {
  background-position: 35.10em 16.90em;
}
.emoji-1f4bd {
  background-position: 35.10em 15.60em;
}
.emoji-1f4be {
  background-position: 35.10em 14.30em;
}
.emoji-1f4bf {
  background-position: 35.10em 13.00em;
}
.emoji-1f4c0 {
  background-position: 35.10em 11.70em;
}
.emoji-1f4c1 {
  background-position: 35.10em 10.40em;
}
.emoji-1f4c2 {
  background-position: 35.10em 9.10em;
}
.emoji-1f4c3 {
  background-position: 35.10em 7.80em;
}
.emoji-1f4c4 {
  background-position: 35.10em 6.50em;
}
.emoji-1f4c5 {
  background-position: 35.10em 5.20em;
}
.emoji-1f4c6 {
  background-position: 35.10em 3.90em;
}
.emoji-1f4c7 {
  background-position: 35.10em 2.60em;
}
.emoji-1f4c8 {
  background-position: 35.10em 1.30em;
}
.emoji-1f4c9 {
  background-position: 33.80em 67.60em;
}
.emoji-1f4ca {
  background-position: 33.80em 66.30em;
}
.emoji-1f4cb {
  background-position: 33.80em 65.00em;
}
.emoji-1f4cc {
  background-position: 33.80em 63.70em;
}
.emoji-1f4cd {
  background-position: 33.80em 62.40em;
}
.emoji-1f4ce {
  background-position: 33.80em 61.10em;
}
.emoji-1f4cf {
  background-position: 33.80em 59.80em;
}
.emoji-1f4d0 {
  background-position: 33.80em 58.50em;
}
.emoji-1f4d1 {
  background-position: 33.80em 57.20em;
}
.emoji-1f4d2 {
  background-position: 33.80em 55.90em;
}
.emoji-1f4d3 {
  background-position: 33.80em 54.60em;
}
.emoji-1f4d4 {
  background-position: 33.80em 53.30em;
}
.emoji-1f4d5 {
  background-position: 33.80em 52.00em;
}
.emoji-1f4d6 {
  background-position: 33.80em 50.70em;
}
.emoji-1f4d7 {
  background-position: 33.80em 49.40em;
}
.emoji-1f4d8 {
  background-position: 33.80em 48.10em;
}
.emoji-1f4d9 {
  background-position: 33.80em 46.80em;
}
.emoji-1f4da {
  background-position: 33.80em 45.50em;
}
.emoji-1f4db {
  background-position: 33.80em 44.20em;
}
.emoji-1f4dc {
  background-position: 33.80em 42.90em;
}
.emoji-1f4dd {
  background-position: 33.80em 41.60em;
}
.emoji-1f4de {
  background-position: 33.80em 40.30em;
}
.emoji-1f4df {
  background-position: 33.80em 39.00em;
}
.emoji-1f4e0 {
  background-position: 33.80em 37.70em;
}
.emoji-1f4e1 {
  background-position: 33.80em 36.40em;
}
.emoji-1f4e2 {
  background-position: 33.80em 35.10em;
}
.emoji-1f4e3 {
  background-position: 33.80em 33.80em;
}
.emoji-1f4e4 {
  background-position: 33.80em 32.50em;
}
.emoji-1f4e5 {
  background-position: 33.80em 31.20em;
}
.emoji-1f4e6 {
  background-position: 33.80em 29.90em;
}
.emoji-1f4e7 {
  background-position: 33.80em 28.60em;
}
.emoji-1f4e8 {
  background-position: 33.80em 27.30em;
}
.emoji-1f4e9 {
  background-position: 33.80em 26.00em;
}
.emoji-1f4ea {
  background-position: 33.80em 24.70em;
}
.emoji-1f4eb {
  background-position: 33.80em 23.40em;
}
.emoji-1f4ec {
  background-position: 33.80em 22.10em;
}
.emoji-1f4ed {
  background-position: 33.80em 20.80em;
}
.emoji-1f4ee {
  background-position: 33.80em 19.50em;
}
.emoji-1f4ef {
  background-position: 33.80em 18.20em;
}
.emoji-1f4f0 {
  background-position: 33.80em 16.90em;
}
.emoji-1f4f1 {
  background-position: 33.80em 15.60em;
}
.emoji-1f4f2 {
  background-position: 33.80em 14.30em;
}
.emoji-1f4f3 {
  background-position: 33.80em 13.00em;
}
.emoji-1f4f4 {
  background-position: 33.80em 11.70em;
}
.emoji-1f4f5 {
  background-position: 33.80em 10.40em;
}
.emoji-1f4f6 {
  background-position: 33.80em 9.10em;
}
.emoji-1f4f7 {
  background-position: 33.80em 7.80em;
}
.emoji-1f4f8 {
  background-position: 33.80em 6.50em;
}
.emoji-1f4f9 {
  background-position: 33.80em 5.20em;
}
.emoji-1f4fa {
  background-position: 33.80em 3.90em;
}
.emoji-1f4fb {
  background-position: 33.80em 2.60em;
}
.emoji-1f4fc {
  background-position: 33.80em 1.30em;
}
.emoji-1f4fd-fe0f {
  background-position: 32.50em 67.60em;
}
.emoji-1f4ff {
  background-position: 32.50em 66.30em;
}
.emoji-1f500 {
  background-position: 32.50em 65.00em;
}
.emoji-1f501 {
  background-position: 32.50em 63.70em;
}
.emoji-1f502 {
  background-position: 32.50em 62.40em;
}
.emoji-1f503 {
  background-position: 32.50em 61.10em;
}
.emoji-1f504 {
  background-position: 32.50em 59.80em;
}
.emoji-1f505 {
  background-position: 32.50em 58.50em;
}
.emoji-1f506 {
  background-position: 32.50em 57.20em;
}
.emoji-1f507 {
  background-position: 32.50em 55.90em;
}
.emoji-1f508 {
  background-position: 32.50em 54.60em;
}
.emoji-1f509 {
  background-position: 32.50em 53.30em;
}
.emoji-1f50a {
  background-position: 32.50em 52.00em;
}
.emoji-1f50b {
  background-position: 32.50em 50.70em;
}
.emoji-1f50c {
  background-position: 32.50em 49.40em;
}
.emoji-1f50d {
  background-position: 32.50em 48.10em;
}
.emoji-1f50e {
  background-position: 32.50em 46.80em;
}
.emoji-1f50f {
  background-position: 32.50em 45.50em;
}
.emoji-1f510 {
  background-position: 32.50em 44.20em;
}
.emoji-1f511 {
  background-position: 32.50em 42.90em;
}
.emoji-1f512 {
  background-position: 32.50em 41.60em;
}
.emoji-1f513 {
  background-position: 32.50em 40.30em;
}
.emoji-1f514 {
  background-position: 32.50em 39.00em;
}
.emoji-1f515 {
  background-position: 32.50em 37.70em;
}
.emoji-1f516 {
  background-position: 32.50em 36.40em;
}
.emoji-1f517 {
  background-position: 32.50em 35.10em;
}
.emoji-1f518 {
  background-position: 32.50em 33.80em;
}
.emoji-1f519 {
  background-position: 32.50em 32.50em;
}
.emoji-1f51a {
  background-position: 32.50em 31.20em;
}
.emoji-1f51b {
  background-position: 32.50em 29.90em;
}
.emoji-1f51c {
  background-position: 32.50em 28.60em;
}
.emoji-1f51d {
  background-position: 32.50em 27.30em;
}
.emoji-1f51e {
  background-position: 32.50em 26.00em;
}
.emoji-1f51f {
  background-position: 32.50em 24.70em;
}
.emoji-1f520 {
  background-position: 32.50em 23.40em;
}
.emoji-1f521 {
  background-position: 32.50em 22.10em;
}
.emoji-1f522 {
  background-position: 32.50em 20.80em;
}
.emoji-1f523 {
  background-position: 32.50em 19.50em;
}
.emoji-1f524 {
  background-position: 32.50em 18.20em;
}
.emoji-1f525 {
  background-position: 32.50em 16.90em;
}
.emoji-1f526 {
  background-position: 32.50em 15.60em;
}
.emoji-1f527 {
  background-position: 32.50em 14.30em;
}
.emoji-1f528 {
  background-position: 32.50em 13.00em;
}
.emoji-1f529 {
  background-position: 32.50em 11.70em;
}
.emoji-1f52a {
  background-position: 32.50em 10.40em;
}
.emoji-1f52b {
  background-position: 32.50em 9.10em;
}
.emoji-1f52c {
  background-position: 32.50em 7.80em;
}
.emoji-1f52d {
  background-position: 32.50em 6.50em;
}
.emoji-1f52e {
  background-position: 32.50em 5.20em;
}
.emoji-1f52f {
  background-position: 32.50em 3.90em;
}
.emoji-1f530 {
  background-position: 32.50em 2.60em;
}
.emoji-1f531 {
  background-position: 32.50em 1.30em;
}
.emoji-1f532 {
  background-position: 31.20em 67.60em;
}
.emoji-1f533 {
  background-position: 31.20em 66.30em;
}
.emoji-1f534 {
  background-position: 31.20em 65.00em;
}
.emoji-1f535 {
  background-position: 31.20em 63.70em;
}
.emoji-1f536 {
  background-position: 31.20em 62.40em;
}
.emoji-1f537 {
  background-position: 31.20em 61.10em;
}
.emoji-1f538 {
  background-position: 31.20em 59.80em;
}
.emoji-1f539 {
  background-position: 31.20em 58.50em;
}
.emoji-1f53a {
  background-position: 31.20em 57.20em;
}
.emoji-1f53b {
  background-position: 31.20em 55.90em;
}
.emoji-1f53c {
  background-position: 31.20em 54.60em;
}
.emoji-1f53d {
  background-position: 31.20em 53.30em;
}
.emoji-1f549-fe0f {
  background-position: 31.20em 52.00em;
}
.emoji-1f54a-fe0f {
  background-position: 31.20em 50.70em;
}
.emoji-1f54b {
  background-position: 31.20em 49.40em;
}
.emoji-1f54c {
  background-position: 31.20em 48.10em;
}
.emoji-1f54d {
  background-position: 31.20em 46.80em;
}
.emoji-1f54e {
  background-position: 31.20em 45.50em;
}
.emoji-1f550 {
  background-position: 31.20em 44.20em;
}
.emoji-1f551 {
  background-position: 31.20em 42.90em;
}
.emoji-1f552 {
  background-position: 31.20em 41.60em;
}
.emoji-1f553 {
  background-position: 31.20em 40.30em;
}
.emoji-1f554 {
  background-position: 31.20em 39.00em;
}
.emoji-1f555 {
  background-position: 31.20em 37.70em;
}
.emoji-1f556 {
  background-position: 31.20em 36.40em;
}
.emoji-1f557 {
  background-position: 31.20em 35.10em;
}
.emoji-1f558 {
  background-position: 31.20em 33.80em;
}
.emoji-1f559 {
  background-position: 31.20em 32.50em;
}
.emoji-1f55a {
  background-position: 31.20em 31.20em;
}
.emoji-1f55b {
  background-position: 31.20em 29.90em;
}
.emoji-1f55c {
  background-position: 31.20em 28.60em;
}
.emoji-1f55d {
  background-position: 31.20em 27.30em;
}
.emoji-1f55e {
  background-position: 31.20em 26.00em;
}
.emoji-1f55f {
  background-position: 31.20em 24.70em;
}
.emoji-1f560 {
  background-position: 31.20em 23.40em;
}
.emoji-1f561 {
  background-position: 31.20em 22.10em;
}
.emoji-1f562 {
  background-position: 31.20em 20.80em;
}
.emoji-1f563 {
  background-position: 31.20em 19.50em;
}
.emoji-1f564 {
  background-position: 31.20em 18.20em;
}
.emoji-1f565 {
  background-position: 31.20em 16.90em;
}
.emoji-1f566 {
  background-position: 31.20em 15.60em;
}
.emoji-1f567 {
  background-position: 31.20em 14.30em;
}
.emoji-1f56f-fe0f {
  background-position: 31.20em 13.00em;
}
.emoji-1f570-fe0f {
  background-position: 31.20em 11.70em;
}
.emoji-1f573-fe0f {
  background-position: 31.20em 10.40em;
}
.emoji-1f574-fe0f {
  background-position: 31.20em 9.10em;
}
.emoji-1f574-1f3fb {
  background-position: 31.20em 7.80em;
}
.emoji-1f574-1f3fc {
  background-position: 31.20em 6.50em;
}
.emoji-1f574-1f3fd {
  background-position: 31.20em 5.20em;
}
.emoji-1f574-1f3fe {
  background-position: 31.20em 3.90em;
}
.emoji-1f574-1f3ff {
  background-position: 31.20em 2.60em;
}
.emoji-1f575-fe0f-200d-2640-fe0f {
  background-position: 31.20em 1.30em;
}
.emoji-1f575-1f3fb-200d-2640-fe0f {
  background-position: 29.90em 67.60em;
}
.emoji-1f575-1f3fc-200d-2640-fe0f {
  background-position: 29.90em 66.30em;
}
.emoji-1f575-1f3fd-200d-2640-fe0f {
  background-position: 29.90em 65.00em;
}
.emoji-1f575-1f3fe-200d-2640-fe0f {
  background-position: 29.90em 63.70em;
}
.emoji-1f575-1f3ff-200d-2640-fe0f {
  background-position: 29.90em 62.40em;
}
.emoji-1f575-fe0f-200d-2642-fe0f {
  background-position: 29.90em 61.10em;
}
.emoji-1f575-1f3fb-200d-2642-fe0f {
  background-position: 29.90em 59.80em;
}
.emoji-1f575-1f3fc-200d-2642-fe0f {
  background-position: 29.90em 58.50em;
}
.emoji-1f575-1f3fd-200d-2642-fe0f {
  background-position: 29.90em 57.20em;
}
.emoji-1f575-1f3fe-200d-2642-fe0f {
  background-position: 29.90em 55.90em;
}
.emoji-1f575-1f3ff-200d-2642-fe0f {
  background-position: 29.90em 54.60em;
}
.emoji-1f575-fe0f {
  background-position: 29.90em 53.30em;
}
.emoji-1f575-1f3fb {
  background-position: 29.90em 52.00em;
}
.emoji-1f575-1f3fc {
  background-position: 29.90em 50.70em;
}
.emoji-1f575-1f3fd {
  background-position: 29.90em 49.40em;
}
.emoji-1f575-1f3fe {
  background-position: 29.90em 48.10em;
}
.emoji-1f575-1f3ff {
  background-position: 29.90em 46.80em;
}
.emoji-1f576-fe0f {
  background-position: 29.90em 45.50em;
}
.emoji-1f577-fe0f {
  background-position: 29.90em 44.20em;
}
.emoji-1f578-fe0f {
  background-position: 29.90em 42.90em;
}
.emoji-1f579-fe0f {
  background-position: 29.90em 41.60em;
}
.emoji-1f57a {
  background-position: 29.90em 40.30em;
}
.emoji-1f57a-1f3fb {
  background-position: 29.90em 39.00em;
}
.emoji-1f57a-1f3fc {
  background-position: 29.90em 37.70em;
}
.emoji-1f57a-1f3fd {
  background-position: 29.90em 36.40em;
}
.emoji-1f57a-1f3fe {
  background-position: 29.90em 35.10em;
}
.emoji-1f57a-1f3ff {
  background-position: 29.90em 33.80em;
}
.emoji-1f587-fe0f {
  background-position: 29.90em 32.50em;
}
.emoji-1f58a-fe0f {
  background-position: 29.90em 31.20em;
}
.emoji-1f58b-fe0f {
  background-position: 29.90em 29.90em;
}
.emoji-1f58c-fe0f {
  background-position: 29.90em 28.60em;
}
.emoji-1f58d-fe0f {
  background-position: 29.90em 27.30em;
}
.emoji-1f590-fe0f {
  background-position: 29.90em 26.00em;
}
.emoji-1f590-1f3fb {
  background-position: 29.90em 24.70em;
}
.emoji-1f590-1f3fc {
  background-position: 29.90em 23.40em;
}
.emoji-1f590-1f3fd {
  background-position: 29.90em 22.10em;
}
.emoji-1f590-1f3fe {
  background-position: 29.90em 20.80em;
}
.emoji-1f590-1f3ff {
  background-position: 29.90em 19.50em;
}
.emoji-1f595 {
  background-position: 29.90em 18.20em;
}
.emoji-1f595-1f3fb {
  background-position: 29.90em 16.90em;
}
.emoji-1f595-1f3fc {
  background-position: 29.90em 15.60em;
}
.emoji-1f595-1f3fd {
  background-position: 29.90em 14.30em;
}
.emoji-1f595-1f3fe {
  background-position: 29.90em 13.00em;
}
.emoji-1f595-1f3ff {
  background-position: 29.90em 11.70em;
}
.emoji-1f596 {
  background-position: 29.90em 10.40em;
}
.emoji-1f596-1f3fb {
  background-position: 29.90em 9.10em;
}
.emoji-1f596-1f3fc {
  background-position: 29.90em 7.80em;
}
.emoji-1f596-1f3fd {
  background-position: 29.90em 6.50em;
}
.emoji-1f596-1f3fe {
  background-position: 29.90em 5.20em;
}
.emoji-1f596-1f3ff {
  background-position: 29.90em 3.90em;
}
.emoji-1f5a4 {
  background-position: 29.90em 2.60em;
}
.emoji-1f5a5-fe0f {
  background-position: 29.90em 1.30em;
}
.emoji-1f5a8-fe0f {
  background-position: 28.60em 67.60em;
}
.emoji-1f5b1-fe0f {
  background-position: 28.60em 66.30em;
}
.emoji-1f5b2-fe0f {
  background-position: 28.60em 65.00em;
}
.emoji-1f5bc-fe0f {
  background-position: 28.60em 63.70em;
}
.emoji-1f5c2-fe0f {
  background-position: 28.60em 62.40em;
}
.emoji-1f5c3-fe0f {
  background-position: 28.60em 61.10em;
}
.emoji-1f5c4-fe0f {
  background-position: 28.60em 59.80em;
}
.emoji-1f5d1-fe0f {
  background-position: 28.60em 58.50em;
}
.emoji-1f5d2-fe0f {
  background-position: 28.60em 57.20em;
}
.emoji-1f5d3-fe0f {
  background-position: 28.60em 55.90em;
}
.emoji-1f5dc-fe0f {
  background-position: 28.60em 54.60em;
}
.emoji-1f5dd-fe0f {
  background-position: 28.60em 53.30em;
}
.emoji-1f5de-fe0f {
  background-position: 28.60em 52.00em;
}
.emoji-1f5e1-fe0f {
  background-position: 28.60em 50.70em;
}
.emoji-1f5e3-fe0f {
  background-position: 28.60em 49.40em;
}
.emoji-1f5e8-fe0f {
  background-position: 28.60em 48.10em;
}
.emoji-1f5ef-fe0f {
  background-position: 28.60em 46.80em;
}
.emoji-1f5f3-fe0f {
  background-position: 28.60em 45.50em;
}
.emoji-1f5fa-fe0f {
  background-position: 28.60em 44.20em;
}
.emoji-1f5fb {
  background-position: 28.60em 42.90em;
}
.emoji-1f5fc {
  background-position: 28.60em 41.60em;
}
.emoji-1f5fd {
  background-position: 28.60em 40.30em;
}
.emoji-1f5fe {
  background-position: 28.60em 39.00em;
}
.emoji-1f5ff {
  background-position: 28.60em 37.70em;
}
.emoji-1f600 {
  background-position: 28.60em 36.40em;
}
.emoji-1f601 {
  background-position: 28.60em 35.10em;
}
.emoji-1f602 {
  background-position: 28.60em 33.80em;
}
.emoji-1f603 {
  background-position: 28.60em 32.50em;
}
.emoji-1f604 {
  background-position: 28.60em 31.20em;
}
.emoji-1f605 {
  background-position: 28.60em 29.90em;
}
.emoji-1f606 {
  background-position: 28.60em 28.60em;
}
.emoji-1f607 {
  background-position: 28.60em 27.30em;
}
.emoji-1f608 {
  background-position: 28.60em 26.00em;
}
.emoji-1f609 {
  background-position: 28.60em 24.70em;
}
.emoji-1f60a {
  background-position: 28.60em 23.40em;
}
.emoji-1f60b {
  background-position: 28.60em 22.10em;
}
.emoji-1f60c {
  background-position: 28.60em 20.80em;
}
.emoji-1f60d {
  background-position: 28.60em 19.50em;
}
.emoji-1f60e {
  background-position: 28.60em 18.20em;
}
.emoji-1f60f {
  background-position: 28.60em 16.90em;
}
.emoji-1f610 {
  background-position: 28.60em 15.60em;
}
.emoji-1f611 {
  background-position: 28.60em 14.30em;
}
.emoji-1f612 {
  background-position: 28.60em 13.00em;
}
.emoji-1f613 {
  background-position: 28.60em 11.70em;
}
.emoji-1f614 {
  background-position: 28.60em 10.40em;
}
.emoji-1f615 {
  background-position: 28.60em 9.10em;
}
.emoji-1f616 {
  background-position: 28.60em 7.80em;
}
.emoji-1f617 {
  background-position: 28.60em 6.50em;
}
.emoji-1f618 {
  background-position: 28.60em 5.20em;
}
.emoji-1f619 {
  background-position: 28.60em 3.90em;
}
.emoji-1f61a {
  background-position: 28.60em 2.60em;
}
.emoji-1f61b {
  background-position: 28.60em 1.30em;
}
.emoji-1f61c {
  background-position: 27.30em 67.60em;
}
.emoji-1f61d {
  background-position: 27.30em 66.30em;
}
.emoji-1f61e {
  background-position: 27.30em 65.00em;
}
.emoji-1f61f {
  background-position: 27.30em 63.70em;
}
.emoji-1f620 {
  background-position: 27.30em 62.40em;
}
.emoji-1f621 {
  background-position: 27.30em 61.10em;
}
.emoji-1f622 {
  background-position: 27.30em 59.80em;
}
.emoji-1f623 {
  background-position: 27.30em 58.50em;
}
.emoji-1f624 {
  background-position: 27.30em 57.20em;
}
.emoji-1f625 {
  background-position: 27.30em 55.90em;
}
.emoji-1f626 {
  background-position: 27.30em 54.60em;
}
.emoji-1f627 {
  background-position: 27.30em 53.30em;
}
.emoji-1f628 {
  background-position: 27.30em 52.00em;
}
.emoji-1f629 {
  background-position: 27.30em 50.70em;
}
.emoji-1f62a {
  background-position: 27.30em 49.40em;
}
.emoji-1f62b {
  background-position: 27.30em 48.10em;
}
.emoji-1f62c {
  background-position: 27.30em 46.80em;
}
.emoji-1f62d {
  background-position: 27.30em 45.50em;
}
.emoji-1f62e {
  background-position: 27.30em 44.20em;
}
.emoji-1f62f {
  background-position: 27.30em 42.90em;
}
.emoji-1f630 {
  background-position: 27.30em 41.60em;
}
.emoji-1f631 {
  background-position: 27.30em 40.30em;
}
.emoji-1f632 {
  background-position: 27.30em 39.00em;
}
.emoji-1f633 {
  background-position: 27.30em 37.70em;
}
.emoji-1f634 {
  background-position: 27.30em 36.40em;
}
.emoji-1f635 {
  background-position: 27.30em 35.10em;
}
.emoji-1f636 {
  background-position: 27.30em 33.80em;
}
.emoji-1f637 {
  background-position: 27.30em 32.50em;
}
.emoji-1f638 {
  background-position: 27.30em 31.20em;
}
.emoji-1f639 {
  background-position: 27.30em 29.90em;
}
.emoji-1f63a {
  background-position: 27.30em 28.60em;
}
.emoji-1f63b {
  background-position: 27.30em 27.30em;
}
.emoji-1f63c {
  background-position: 27.30em 26.00em;
}
.emoji-1f63d {
  background-position: 27.30em 24.70em;
}
.emoji-1f63e {
  background-position: 27.30em 23.40em;
}
.emoji-1f63f {
  background-position: 27.30em 22.10em;
}
.emoji-1f640 {
  background-position: 27.30em 20.80em;
}
.emoji-1f641 {
  background-position: 27.30em 19.50em;
}
.emoji-1f642 {
  background-position: 27.30em 18.20em;
}
.emoji-1f643 {
  background-position: 27.30em 16.90em;
}
.emoji-1f644 {
  background-position: 27.30em 15.60em;
}
.emoji-1f645-200d-2640-fe0f {
  background-position: 27.30em 14.30em;
}
.emoji-1f645-1f3fb-200d-2640-fe0f {
  background-position: 27.30em 13.00em;
}
.emoji-1f645-1f3fc-200d-2640-fe0f {
  background-position: 27.30em 11.70em;
}
.emoji-1f645-1f3fd-200d-2640-fe0f {
  background-position: 27.30em 10.40em;
}
.emoji-1f645-1f3fe-200d-2640-fe0f {
  background-position: 27.30em 9.10em;
}
.emoji-1f645-1f3ff-200d-2640-fe0f {
  background-position: 27.30em 7.80em;
}
.emoji-1f645-200d-2642-fe0f {
  background-position: 27.30em 6.50em;
}
.emoji-1f645-1f3fb-200d-2642-fe0f {
  background-position: 27.30em 5.20em;
}
.emoji-1f645-1f3fc-200d-2642-fe0f {
  background-position: 27.30em 3.90em;
}
.emoji-1f645-1f3fd-200d-2642-fe0f {
  background-position: 27.30em 2.60em;
}
.emoji-1f645-1f3fe-200d-2642-fe0f {
  background-position: 27.30em 1.30em;
}
.emoji-1f645-1f3ff-200d-2642-fe0f {
  background-position: 26.00em 67.60em;
}
.emoji-1f645 {
  background-position: 26.00em 66.30em;
}
.emoji-1f645-1f3fb {
  background-position: 26.00em 65.00em;
}
.emoji-1f645-1f3fc {
  background-position: 26.00em 63.70em;
}
.emoji-1f645-1f3fd {
  background-position: 26.00em 62.40em;
}
.emoji-1f645-1f3fe {
  background-position: 26.00em 61.10em;
}
.emoji-1f645-1f3ff {
  background-position: 26.00em 59.80em;
}
.emoji-1f646-200d-2640-fe0f {
  background-position: 26.00em 58.50em;
}
.emoji-1f646-1f3fb-200d-2640-fe0f {
  background-position: 26.00em 57.20em;
}
.emoji-1f646-1f3fc-200d-2640-fe0f {
  background-position: 26.00em 55.90em;
}
.emoji-1f646-1f3fd-200d-2640-fe0f {
  background-position: 26.00em 54.60em;
}
.emoji-1f646-1f3fe-200d-2640-fe0f {
  background-position: 26.00em 53.30em;
}
.emoji-1f646-1f3ff-200d-2640-fe0f {
  background-position: 26.00em 52.00em;
}
.emoji-1f646-200d-2642-fe0f {
  background-position: 26.00em 50.70em;
}
.emoji-1f646-1f3fb-200d-2642-fe0f {
  background-position: 26.00em 49.40em;
}
.emoji-1f646-1f3fc-200d-2642-fe0f {
  background-position: 26.00em 48.10em;
}
.emoji-1f646-1f3fd-200d-2642-fe0f {
  background-position: 26.00em 46.80em;
}
.emoji-1f646-1f3fe-200d-2642-fe0f {
  background-position: 26.00em 45.50em;
}
.emoji-1f646-1f3ff-200d-2642-fe0f {
  background-position: 26.00em 44.20em;
}
.emoji-1f646 {
  background-position: 26.00em 42.90em;
}
.emoji-1f646-1f3fb {
  background-position: 26.00em 41.60em;
}
.emoji-1f646-1f3fc {
  background-position: 26.00em 40.30em;
}
.emoji-1f646-1f3fd {
  background-position: 26.00em 39.00em;
}
.emoji-1f646-1f3fe {
  background-position: 26.00em 37.70em;
}
.emoji-1f646-1f3ff {
  background-position: 26.00em 36.40em;
}
.emoji-1f647-200d-2640-fe0f {
  background-position: 26.00em 35.10em;
}
.emoji-1f647-1f3fb-200d-2640-fe0f {
  background-position: 26.00em 33.80em;
}
.emoji-1f647-1f3fc-200d-2640-fe0f {
  background-position: 26.00em 32.50em;
}
.emoji-1f647-1f3fd-200d-2640-fe0f {
  background-position: 26.00em 31.20em;
}
.emoji-1f647-1f3fe-200d-2640-fe0f {
  background-position: 26.00em 29.90em;
}
.emoji-1f647-1f3ff-200d-2640-fe0f {
  background-position: 26.00em 28.60em;
}
.emoji-1f647-200d-2642-fe0f {
  background-position: 26.00em 27.30em;
}
.emoji-1f647-1f3fb-200d-2642-fe0f {
  background-position: 26.00em 26.00em;
}
.emoji-1f647-1f3fc-200d-2642-fe0f {
  background-position: 26.00em 24.70em;
}
.emoji-1f647-1f3fd-200d-2642-fe0f {
  background-position: 26.00em 23.40em;
}
.emoji-1f647-1f3fe-200d-2642-fe0f {
  background-position: 26.00em 22.10em;
}
.emoji-1f647-1f3ff-200d-2642-fe0f {
  background-position: 26.00em 20.80em;
}
.emoji-1f647 {
  background-position: 26.00em 19.50em;
}
.emoji-1f647-1f3fb {
  background-position: 26.00em 18.20em;
}
.emoji-1f647-1f3fc {
  background-position: 26.00em 16.90em;
}
.emoji-1f647-1f3fd {
  background-position: 26.00em 15.60em;
}
.emoji-1f647-1f3fe {
  background-position: 26.00em 14.30em;
}
.emoji-1f647-1f3ff {
  background-position: 26.00em 13.00em;
}
.emoji-1f648 {
  background-position: 26.00em 11.70em;
}
.emoji-1f649 {
  background-position: 26.00em 10.40em;
}
.emoji-1f64a {
  background-position: 26.00em 9.10em;
}
.emoji-1f64b-200d-2640-fe0f {
  background-position: 26.00em 7.80em;
}
.emoji-1f64b-1f3fb-200d-2640-fe0f {
  background-position: 26.00em 6.50em;
}
.emoji-1f64b-1f3fc-200d-2640-fe0f {
  background-position: 26.00em 5.20em;
}
.emoji-1f64b-1f3fd-200d-2640-fe0f {
  background-position: 26.00em 3.90em;
}
.emoji-1f64b-1f3fe-200d-2640-fe0f {
  background-position: 26.00em 2.60em;
}
.emoji-1f64b-1f3ff-200d-2640-fe0f {
  background-position: 26.00em 1.30em;
}
.emoji-1f64b-200d-2642-fe0f {
  background-position: 24.70em 67.60em;
}
.emoji-1f64b-1f3fb-200d-2642-fe0f {
  background-position: 24.70em 66.30em;
}
.emoji-1f64b-1f3fc-200d-2642-fe0f {
  background-position: 24.70em 65.00em;
}
.emoji-1f64b-1f3fd-200d-2642-fe0f {
  background-position: 24.70em 63.70em;
}
.emoji-1f64b-1f3fe-200d-2642-fe0f {
  background-position: 24.70em 62.40em;
}
.emoji-1f64b-1f3ff-200d-2642-fe0f {
  background-position: 24.70em 61.10em;
}
.emoji-1f64b {
  background-position: 24.70em 59.80em;
}
.emoji-1f64b-1f3fb {
  background-position: 24.70em 58.50em;
}
.emoji-1f64b-1f3fc {
  background-position: 24.70em 57.20em;
}
.emoji-1f64b-1f3fd {
  background-position: 24.70em 55.90em;
}
.emoji-1f64b-1f3fe {
  background-position: 24.70em 54.60em;
}
.emoji-1f64b-1f3ff {
  background-position: 24.70em 53.30em;
}
.emoji-1f64c {
  background-position: 24.70em 52.00em;
}
.emoji-1f64c-1f3fb {
  background-position: 24.70em 50.70em;
}
.emoji-1f64c-1f3fc {
  background-position: 24.70em 49.40em;
}
.emoji-1f64c-1f3fd {
  background-position: 24.70em 48.10em;
}
.emoji-1f64c-1f3fe {
  background-position: 24.70em 46.80em;
}
.emoji-1f64c-1f3ff {
  background-position: 24.70em 45.50em;
}
.emoji-1f64d-200d-2640-fe0f {
  background-position: 24.70em 44.20em;
}
.emoji-1f64d-1f3fb-200d-2640-fe0f {
  background-position: 24.70em 42.90em;
}
.emoji-1f64d-1f3fc-200d-2640-fe0f {
  background-position: 24.70em 41.60em;
}
.emoji-1f64d-1f3fd-200d-2640-fe0f {
  background-position: 24.70em 40.30em;
}
.emoji-1f64d-1f3fe-200d-2640-fe0f {
  background-position: 24.70em 39.00em;
}
.emoji-1f64d-1f3ff-200d-2640-fe0f {
  background-position: 24.70em 37.70em;
}
.emoji-1f64d-200d-2642-fe0f {
  background-position: 24.70em 36.40em;
}
.emoji-1f64d-1f3fb-200d-2642-fe0f {
  background-position: 24.70em 35.10em;
}
.emoji-1f64d-1f3fc-200d-2642-fe0f {
  background-position: 24.70em 33.80em;
}
.emoji-1f64d-1f3fd-200d-2642-fe0f {
  background-position: 24.70em 32.50em;
}
.emoji-1f64d-1f3fe-200d-2642-fe0f {
  background-position: 24.70em 31.20em;
}
.emoji-1f64d-1f3ff-200d-2642-fe0f {
  background-position: 24.70em 29.90em;
}
.emoji-1f64d {
  background-position: 24.70em 28.60em;
}
.emoji-1f64d-1f3fb {
  background-position: 24.70em 27.30em;
}
.emoji-1f64d-1f3fc {
  background-position: 24.70em 26.00em;
}
.emoji-1f64d-1f3fd {
  background-position: 24.70em 24.70em;
}
.emoji-1f64d-1f3fe {
  background-position: 24.70em 23.40em;
}
.emoji-1f64d-1f3ff {
  background-position: 24.70em 22.10em;
}
.emoji-1f64e-200d-2640-fe0f {
  background-position: 24.70em 20.80em;
}
.emoji-1f64e-1f3fb-200d-2640-fe0f {
  background-position: 24.70em 19.50em;
}
.emoji-1f64e-1f3fc-200d-2640-fe0f {
  background-position: 24.70em 18.20em;
}
.emoji-1f64e-1f3fd-200d-2640-fe0f {
  background-position: 24.70em 16.90em;
}
.emoji-1f64e-1f3fe-200d-2640-fe0f {
  background-position: 24.70em 15.60em;
}
.emoji-1f64e-1f3ff-200d-2640-fe0f {
  background-position: 24.70em 14.30em;
}
.emoji-1f64e-200d-2642-fe0f {
  background-position: 24.70em 13.00em;
}
.emoji-1f64e-1f3fb-200d-2642-fe0f {
  background-position: 24.70em 11.70em;
}
.emoji-1f64e-1f3fc-200d-2642-fe0f {
  background-position: 24.70em 10.40em;
}
.emoji-1f64e-1f3fd-200d-2642-fe0f {
  background-position: 24.70em 9.10em;
}
.emoji-1f64e-1f3fe-200d-2642-fe0f {
  background-position: 24.70em 7.80em;
}
.emoji-1f64e-1f3ff-200d-2642-fe0f {
  background-position: 24.70em 6.50em;
}
.emoji-1f64e {
  background-position: 24.70em 5.20em;
}
.emoji-1f64e-1f3fb {
  background-position: 24.70em 3.90em;
}
.emoji-1f64e-1f3fc {
  background-position: 24.70em 2.60em;
}
.emoji-1f64e-1f3fd {
  background-position: 24.70em 1.30em;
}
.emoji-1f64e-1f3fe {
  background-position: 23.40em 67.60em;
}
.emoji-1f64e-1f3ff {
  background-position: 23.40em 66.30em;
}
.emoji-1f64f {
  background-position: 23.40em 65.00em;
}
.emoji-1f64f-1f3fb {
  background-position: 23.40em 63.70em;
}
.emoji-1f64f-1f3fc {
  background-position: 23.40em 62.40em;
}
.emoji-1f64f-1f3fd {
  background-position: 23.40em 61.10em;
}
.emoji-1f64f-1f3fe {
  background-position: 23.40em 59.80em;
}
.emoji-1f64f-1f3ff {
  background-position: 23.40em 58.50em;
}
.emoji-1f680 {
  background-position: 23.40em 57.20em;
}
.emoji-1f681 {
  background-position: 23.40em 55.90em;
}
.emoji-1f682 {
  background-position: 23.40em 54.60em;
}
.emoji-1f683 {
  background-position: 23.40em 53.30em;
}
.emoji-1f684 {
  background-position: 23.40em 52.00em;
}
.emoji-1f685 {
  background-position: 23.40em 50.70em;
}
.emoji-1f686 {
  background-position: 23.40em 49.40em;
}
.emoji-1f687 {
  background-position: 23.40em 48.10em;
}
.emoji-1f688 {
  background-position: 23.40em 46.80em;
}
.emoji-1f689 {
  background-position: 23.40em 45.50em;
}
.emoji-1f68a {
  background-position: 23.40em 44.20em;
}
.emoji-1f68b {
  background-position: 23.40em 42.90em;
}
.emoji-1f68c {
  background-position: 23.40em 41.60em;
}
.emoji-1f68d {
  background-position: 23.40em 40.30em;
}
.emoji-1f68e {
  background-position: 23.40em 39.00em;
}
.emoji-1f68f {
  background-position: 23.40em 37.70em;
}
.emoji-1f690 {
  background-position: 23.40em 36.40em;
}
.emoji-1f691 {
  background-position: 23.40em 35.10em;
}
.emoji-1f692 {
  background-position: 23.40em 33.80em;
}
.emoji-1f693 {
  background-position: 23.40em 32.50em;
}
.emoji-1f694 {
  background-position: 23.40em 31.20em;
}
.emoji-1f695 {
  background-position: 23.40em 29.90em;
}
.emoji-1f696 {
  background-position: 23.40em 28.60em;
}
.emoji-1f697 {
  background-position: 23.40em 27.30em;
}
.emoji-1f698 {
  background-position: 23.40em 26.00em;
}
.emoji-1f699 {
  background-position: 23.40em 24.70em;
}
.emoji-1f69a {
  background-position: 23.40em 23.40em;
}
.emoji-1f69b {
  background-position: 23.40em 22.10em;
}
.emoji-1f69c {
  background-position: 23.40em 20.80em;
}
.emoji-1f69d {
  background-position: 23.40em 19.50em;
}
.emoji-1f69e {
  background-position: 23.40em 18.20em;
}
.emoji-1f69f {
  background-position: 23.40em 16.90em;
}
.emoji-1f6a0 {
  background-position: 23.40em 15.60em;
}
.emoji-1f6a1 {
  background-position: 23.40em 14.30em;
}
.emoji-1f6a2 {
  background-position: 23.40em 13.00em;
}
.emoji-1f6a3-200d-2640-fe0f {
  background-position: 23.40em 11.70em;
}
.emoji-1f6a3-1f3fb-200d-2640-fe0f {
  background-position: 23.40em 10.40em;
}
.emoji-1f6a3-1f3fc-200d-2640-fe0f {
  background-position: 23.40em 9.10em;
}
.emoji-1f6a3-1f3fd-200d-2640-fe0f {
  background-position: 23.40em 7.80em;
}
.emoji-1f6a3-1f3fe-200d-2640-fe0f {
  background-position: 23.40em 6.50em;
}
.emoji-1f6a3-1f3ff-200d-2640-fe0f {
  background-position: 23.40em 5.20em;
}
.emoji-1f6a3-200d-2642-fe0f {
  background-position: 23.40em 3.90em;
}
.emoji-1f6a3-1f3fb-200d-2642-fe0f {
  background-position: 23.40em 2.60em;
}
.emoji-1f6a3-1f3fc-200d-2642-fe0f {
  background-position: 23.40em 1.30em;
}
.emoji-1f6a3-1f3fd-200d-2642-fe0f {
  background-position: 22.10em 67.60em;
}
.emoji-1f6a3-1f3fe-200d-2642-fe0f {
  background-position: 22.10em 66.30em;
}
.emoji-1f6a3-1f3ff-200d-2642-fe0f {
  background-position: 22.10em 65.00em;
}
.emoji-1f6a3 {
  background-position: 22.10em 63.70em;
}
.emoji-1f6a3-1f3fb {
  background-position: 22.10em 62.40em;
}
.emoji-1f6a3-1f3fc {
  background-position: 22.10em 61.10em;
}
.emoji-1f6a3-1f3fd {
  background-position: 22.10em 59.80em;
}
.emoji-1f6a3-1f3fe {
  background-position: 22.10em 58.50em;
}
.emoji-1f6a3-1f3ff {
  background-position: 22.10em 57.20em;
}
.emoji-1f6a4 {
  background-position: 22.10em 55.90em;
}
.emoji-1f6a5 {
  background-position: 22.10em 54.60em;
}
.emoji-1f6a6 {
  background-position: 22.10em 53.30em;
}
.emoji-1f6a7 {
  background-position: 22.10em 52.00em;
}
.emoji-1f6a8 {
  background-position: 22.10em 50.70em;
}
.emoji-1f6a9 {
  background-position: 22.10em 49.40em;
}
.emoji-1f6aa {
  background-position: 22.10em 48.10em;
}
.emoji-1f6ab {
  background-position: 22.10em 46.80em;
}
.emoji-1f6ac {
  background-position: 22.10em 45.50em;
}
.emoji-1f6ad {
  background-position: 22.10em 44.20em;
}
.emoji-1f6ae {
  background-position: 22.10em 42.90em;
}
.emoji-1f6af {
  background-position: 22.10em 41.60em;
}
.emoji-1f6b0 {
  background-position: 22.10em 40.30em;
}
.emoji-1f6b1 {
  background-position: 22.10em 39.00em;
}
.emoji-1f6b2 {
  background-position: 22.10em 37.70em;
}
.emoji-1f6b3 {
  background-position: 22.10em 36.40em;
}
.emoji-1f6b4-200d-2640-fe0f {
  background-position: 22.10em 35.10em;
}
.emoji-1f6b4-1f3fb-200d-2640-fe0f {
  background-position: 22.10em 33.80em;
}
.emoji-1f6b4-1f3fc-200d-2640-fe0f {
  background-position: 22.10em 32.50em;
}
.emoji-1f6b4-1f3fd-200d-2640-fe0f {
  background-position: 22.10em 31.20em;
}
.emoji-1f6b4-1f3fe-200d-2640-fe0f {
  background-position: 22.10em 29.90em;
}
.emoji-1f6b4-1f3ff-200d-2640-fe0f {
  background-position: 22.10em 28.60em;
}
.emoji-1f6b4-200d-2642-fe0f {
  background-position: 22.10em 27.30em;
}
.emoji-1f6b4-1f3fb-200d-2642-fe0f {
  background-position: 22.10em 26.00em;
}
.emoji-1f6b4-1f3fc-200d-2642-fe0f {
  background-position: 22.10em 24.70em;
}
.emoji-1f6b4-1f3fd-200d-2642-fe0f {
  background-position: 22.10em 23.40em;
}
.emoji-1f6b4-1f3fe-200d-2642-fe0f {
  background-position: 22.10em 22.10em;
}
.emoji-1f6b4-1f3ff-200d-2642-fe0f {
  background-position: 22.10em 20.80em;
}
.emoji-1f6b4 {
  background-position: 22.10em 19.50em;
}
.emoji-1f6b4-1f3fb {
  background-position: 22.10em 18.20em;
}
.emoji-1f6b4-1f3fc {
  background-position: 22.10em 16.90em;
}
.emoji-1f6b4-1f3fd {
  background-position: 22.10em 15.60em;
}
.emoji-1f6b4-1f3fe {
  background-position: 22.10em 14.30em;
}
.emoji-1f6b4-1f3ff {
  background-position: 22.10em 13.00em;
}
.emoji-1f6b5-200d-2640-fe0f {
  background-position: 22.10em 11.70em;
}
.emoji-1f6b5-1f3fb-200d-2640-fe0f {
  background-position: 22.10em 10.40em;
}
.emoji-1f6b5-1f3fc-200d-2640-fe0f {
  background-position: 22.10em 9.10em;
}
.emoji-1f6b5-1f3fd-200d-2640-fe0f {
  background-position: 22.10em 7.80em;
}
.emoji-1f6b5-1f3fe-200d-2640-fe0f {
  background-position: 22.10em 6.50em;
}
.emoji-1f6b5-1f3ff-200d-2640-fe0f {
  background-position: 22.10em 5.20em;
}
.emoji-1f6b5-200d-2642-fe0f {
  background-position: 22.10em 3.90em;
}
.emoji-1f6b5-1f3fb-200d-2642-fe0f {
  background-position: 22.10em 2.60em;
}
.emoji-1f6b5-1f3fc-200d-2642-fe0f {
  background-position: 22.10em 1.30em;
}
.emoji-1f6b5-1f3fd-200d-2642-fe0f {
  background-position: 20.80em 67.60em;
}
.emoji-1f6b5-1f3fe-200d-2642-fe0f {
  background-position: 20.80em 66.30em;
}
.emoji-1f6b5-1f3ff-200d-2642-fe0f {
  background-position: 20.80em 65.00em;
}
.emoji-1f6b5 {
  background-position: 20.80em 63.70em;
}
.emoji-1f6b5-1f3fb {
  background-position: 20.80em 62.40em;
}
.emoji-1f6b5-1f3fc {
  background-position: 20.80em 61.10em;
}
.emoji-1f6b5-1f3fd {
  background-position: 20.80em 59.80em;
}
.emoji-1f6b5-1f3fe {
  background-position: 20.80em 58.50em;
}
.emoji-1f6b5-1f3ff {
  background-position: 20.80em 57.20em;
}
.emoji-1f6b6-200d-2640-fe0f {
  background-position: 20.80em 55.90em;
}
.emoji-1f6b6-1f3fb-200d-2640-fe0f {
  background-position: 20.80em 54.60em;
}
.emoji-1f6b6-1f3fc-200d-2640-fe0f {
  background-position: 20.80em 53.30em;
}
.emoji-1f6b6-1f3fd-200d-2640-fe0f {
  background-position: 20.80em 52.00em;
}
.emoji-1f6b6-1f3fe-200d-2640-fe0f {
  background-position: 20.80em 50.70em;
}
.emoji-1f6b6-1f3ff-200d-2640-fe0f {
  background-position: 20.80em 49.40em;
}
.emoji-1f6b6-200d-2642-fe0f {
  background-position: 20.80em 48.10em;
}
.emoji-1f6b6-1f3fb-200d-2642-fe0f {
  background-position: 20.80em 46.80em;
}
.emoji-1f6b6-1f3fc-200d-2642-fe0f {
  background-position: 20.80em 45.50em;
}
.emoji-1f6b6-1f3fd-200d-2642-fe0f {
  background-position: 20.80em 44.20em;
}
.emoji-1f6b6-1f3fe-200d-2642-fe0f {
  background-position: 20.80em 42.90em;
}
.emoji-1f6b6-1f3ff-200d-2642-fe0f {
  background-position: 20.80em 41.60em;
}
.emoji-1f6b6 {
  background-position: 20.80em 40.30em;
}
.emoji-1f6b6-1f3fb {
  background-position: 20.80em 39.00em;
}
.emoji-1f6b6-1f3fc {
  background-position: 20.80em 37.70em;
}
.emoji-1f6b6-1f3fd {
  background-position: 20.80em 36.40em;
}
.emoji-1f6b6-1f3fe {
  background-position: 20.80em 35.10em;
}
.emoji-1f6b6-1f3ff {
  background-position: 20.80em 33.80em;
}
.emoji-1f6b7 {
  background-position: 20.80em 32.50em;
}
.emoji-1f6b8 {
  background-position: 20.80em 31.20em;
}
.emoji-1f6b9 {
  background-position: 20.80em 29.90em;
}
.emoji-1f6ba {
  background-position: 20.80em 28.60em;
}
.emoji-1f6bb {
  background-position: 20.80em 27.30em;
}
.emoji-1f6bc {
  background-position: 20.80em 26.00em;
}
.emoji-1f6bd {
  background-position: 20.80em 24.70em;
}
.emoji-1f6be {
  background-position: 20.80em 23.40em;
}
.emoji-1f6bf {
  background-position: 20.80em 22.10em;
}
.emoji-1f6c0 {
  background-position: 20.80em 20.80em;
}
.emoji-1f6c0-1f3fb {
  background-position: 20.80em 19.50em;
}
.emoji-1f6c0-1f3fc {
  background-position: 20.80em 18.20em;
}
.emoji-1f6c0-1f3fd {
  background-position: 20.80em 16.90em;
}
.emoji-1f6c0-1f3fe {
  background-position: 20.80em 15.60em;
}
.emoji-1f6c0-1f3ff {
  background-position: 20.80em 14.30em;
}
.emoji-1f6c1 {
  background-position: 20.80em 13.00em;
}
.emoji-1f6c2 {
  background-position: 20.80em 11.70em;
}
.emoji-1f6c3 {
  background-position: 20.80em 10.40em;
}
.emoji-1f6c4 {
  background-position: 20.80em 9.10em;
}
.emoji-1f6c5 {
  background-position: 20.80em 7.80em;
}
.emoji-1f6cb-fe0f {
  background-position: 20.80em 6.50em;
}
.emoji-1f6cc {
  background-position: 20.80em 5.20em;
}
.emoji-1f6cc-1f3fb {
  background-position: 20.80em 3.90em;
}
.emoji-1f6cc-1f3fc {
  background-position: 20.80em 2.60em;
}
.emoji-1f6cc-1f3fd {
  background-position: 20.80em 1.30em;
}
.emoji-1f6cc-1f3fe {
  background-position: 19.50em 67.60em;
}
.emoji-1f6cc-1f3ff {
  background-position: 19.50em 66.30em;
}
.emoji-1f6cd-fe0f {
  background-position: 19.50em 65.00em;
}
.emoji-1f6ce-fe0f {
  background-position: 19.50em 63.70em;
}
.emoji-1f6cf-fe0f {
  background-position: 19.50em 62.40em;
}
.emoji-1f6d0 {
  background-position: 19.50em 61.10em;
}
.emoji-1f6d1 {
  background-position: 19.50em 59.80em;
}
.emoji-1f6d2 {
  background-position: 19.50em 58.50em;
}
.emoji-1f6e0-fe0f {
  background-position: 19.50em 57.20em;
}
.emoji-1f6e1-fe0f {
  background-position: 19.50em 55.90em;
}
.emoji-1f6e2-fe0f {
  background-position: 19.50em 54.60em;
}
.emoji-1f6e3-fe0f {
  background-position: 19.50em 53.30em;
}
.emoji-1f6e4-fe0f {
  background-position: 19.50em 52.00em;
}
.emoji-1f6e5-fe0f {
  background-position: 19.50em 50.70em;
}
.emoji-1f6e9-fe0f {
  background-position: 19.50em 49.40em;
}
.emoji-1f6eb {
  background-position: 19.50em 48.10em;
}
.emoji-1f6ec {
  background-position: 19.50em 46.80em;
}
.emoji-1f6f0-fe0f {
  background-position: 19.50em 45.50em;
}
.emoji-1f6f3-fe0f {
  background-position: 19.50em 44.20em;
}
.emoji-1f6f4 {
  background-position: 19.50em 42.90em;
}
.emoji-1f6f5 {
  background-position: 19.50em 41.60em;
}
.emoji-1f6f6 {
  background-position: 19.50em 40.30em;
}
.emoji-1f6f7 {
  background-position: 19.50em 39.00em;
}
.emoji-1f6f8 {
  background-position: 19.50em 37.70em;
}
.emoji-1f910 {
  background-position: 19.50em 36.40em;
}
.emoji-1f911 {
  background-position: 19.50em 35.10em;
}
.emoji-1f912 {
  background-position: 19.50em 33.80em;
}
.emoji-1f913 {
  background-position: 19.50em 32.50em;
}
.emoji-1f914 {
  background-position: 19.50em 31.20em;
}
.emoji-1f915 {
  background-position: 19.50em 29.90em;
}
.emoji-1f916 {
  background-position: 19.50em 28.60em;
}
.emoji-1f917 {
  background-position: 19.50em 27.30em;
}
.emoji-1f918 {
  background-position: 19.50em 26.00em;
}
.emoji-1f918-1f3fb {
  background-position: 19.50em 24.70em;
}
.emoji-1f918-1f3fc {
  background-position: 19.50em 23.40em;
}
.emoji-1f918-1f3fd {
  background-position: 19.50em 22.10em;
}
.emoji-1f918-1f3fe {
  background-position: 19.50em 20.80em;
}
.emoji-1f918-1f3ff {
  background-position: 19.50em 19.50em;
}
.emoji-1f919 {
  background-position: 19.50em 18.20em;
}
.emoji-1f919-1f3fb {
  background-position: 19.50em 16.90em;
}
.emoji-1f919-1f3fc {
  background-position: 19.50em 15.60em;
}
.emoji-1f919-1f3fd {
  background-position: 19.50em 14.30em;
}
.emoji-1f919-1f3fe {
  background-position: 19.50em 13.00em;
}
.emoji-1f919-1f3ff {
  background-position: 19.50em 11.70em;
}
.emoji-1f91a {
  background-position: 19.50em 10.40em;
}
.emoji-1f91a-1f3fb {
  background-position: 19.50em 9.10em;
}
.emoji-1f91a-1f3fc {
  background-position: 19.50em 7.80em;
}
.emoji-1f91a-1f3fd {
  background-position: 19.50em 6.50em;
}
.emoji-1f91a-1f3fe {
  background-position: 19.50em 5.20em;
}
.emoji-1f91a-1f3ff {
  background-position: 19.50em 3.90em;
}
.emoji-1f91b {
  background-position: 19.50em 2.60em;
}
.emoji-1f91b-1f3fb {
  background-position: 19.50em 1.30em;
}
.emoji-1f91b-1f3fc {
  background-position: 18.20em 67.60em;
}
.emoji-1f91b-1f3fd {
  background-position: 18.20em 66.30em;
}
.emoji-1f91b-1f3fe {
  background-position: 18.20em 65.00em;
}
.emoji-1f91b-1f3ff {
  background-position: 18.20em 63.70em;
}
.emoji-1f91c {
  background-position: 18.20em 62.40em;
}
.emoji-1f91c-1f3fb {
  background-position: 18.20em 61.10em;
}
.emoji-1f91c-1f3fc {
  background-position: 18.20em 59.80em;
}
.emoji-1f91c-1f3fd {
  background-position: 18.20em 58.50em;
}
.emoji-1f91c-1f3fe {
  background-position: 18.20em 57.20em;
}
.emoji-1f91c-1f3ff {
  background-position: 18.20em 55.90em;
}
.emoji-1f91d {
  background-position: 18.20em 54.60em;
}
.emoji-1f91e {
  background-position: 18.20em 53.30em;
}
.emoji-1f91e-1f3fb {
  background-position: 18.20em 52.00em;
}
.emoji-1f91e-1f3fc {
  background-position: 18.20em 50.70em;
}
.emoji-1f91e-1f3fd {
  background-position: 18.20em 49.40em;
}
.emoji-1f91e-1f3fe {
  background-position: 18.20em 48.10em;
}
.emoji-1f91e-1f3ff {
  background-position: 18.20em 46.80em;
}
.emoji-1f91f {
  background-position: 18.20em 45.50em;
}
.emoji-1f91f-1f3fb {
  background-position: 18.20em 44.20em;
}
.emoji-1f91f-1f3fc {
  background-position: 18.20em 42.90em;
}
.emoji-1f91f-1f3fd {
  background-position: 18.20em 41.60em;
}
.emoji-1f91f-1f3fe {
  background-position: 18.20em 40.30em;
}
.emoji-1f91f-1f3ff {
  background-position: 18.20em 39.00em;
}
.emoji-1f920 {
  background-position: 18.20em 37.70em;
}
.emoji-1f921 {
  background-position: 18.20em 36.40em;
}
.emoji-1f922 {
  background-position: 18.20em 35.10em;
}
.emoji-1f923 {
  background-position: 18.20em 33.80em;
}
.emoji-1f924 {
  background-position: 18.20em 32.50em;
}
.emoji-1f925 {
  background-position: 18.20em 31.20em;
}
.emoji-1f926-200d-2640-fe0f {
  background-position: 18.20em 29.90em;
}
.emoji-1f926-1f3fb-200d-2640-fe0f {
  background-position: 18.20em 28.60em;
}
.emoji-1f926-1f3fc-200d-2640-fe0f {
  background-position: 18.20em 27.30em;
}
.emoji-1f926-1f3fd-200d-2640-fe0f {
  background-position: 18.20em 26.00em;
}
.emoji-1f926-1f3fe-200d-2640-fe0f {
  background-position: 18.20em 24.70em;
}
.emoji-1f926-1f3ff-200d-2640-fe0f {
  background-position: 18.20em 23.40em;
}
.emoji-1f926-200d-2642-fe0f {
  background-position: 18.20em 22.10em;
}
.emoji-1f926-1f3fb-200d-2642-fe0f {
  background-position: 18.20em 20.80em;
}
.emoji-1f926-1f3fc-200d-2642-fe0f {
  background-position: 18.20em 19.50em;
}
.emoji-1f926-1f3fd-200d-2642-fe0f {
  background-position: 18.20em 18.20em;
}
.emoji-1f926-1f3fe-200d-2642-fe0f {
  background-position: 18.20em 16.90em;
}
.emoji-1f926-1f3ff-200d-2642-fe0f {
  background-position: 18.20em 15.60em;
}
.emoji-1f926 {
  background-position: 18.20em 14.30em;
}
.emoji-1f926-1f3fb {
  background-position: 18.20em 13.00em;
}
.emoji-1f926-1f3fc {
  background-position: 18.20em 11.70em;
}
.emoji-1f926-1f3fd {
  background-position: 18.20em 10.40em;
}
.emoji-1f926-1f3fe {
  background-position: 18.20em 9.10em;
}
.emoji-1f926-1f3ff {
  background-position: 18.20em 7.80em;
}
.emoji-1f927 {
  background-position: 18.20em 6.50em;
}
.emoji-1f928 {
  background-position: 18.20em 5.20em;
}
.emoji-1f929 {
  background-position: 18.20em 3.90em;
}
.emoji-1f92a {
  background-position: 18.20em 2.60em;
}
.emoji-1f92b {
  background-position: 18.20em 1.30em;
}
.emoji-1f92c {
  background-position: 16.90em 67.60em;
}
.emoji-1f92d {
  background-position: 16.90em 66.30em;
}
.emoji-1f92e {
  background-position: 16.90em 65.00em;
}
.emoji-1f92f {
  background-position: 16.90em 63.70em;
}
.emoji-1f930 {
  background-position: 16.90em 62.40em;
}
.emoji-1f930-1f3fb {
  background-position: 16.90em 61.10em;
}
.emoji-1f930-1f3fc {
  background-position: 16.90em 59.80em;
}
.emoji-1f930-1f3fd {
  background-position: 16.90em 58.50em;
}
.emoji-1f930-1f3fe {
  background-position: 16.90em 57.20em;
}
.emoji-1f930-1f3ff {
  background-position: 16.90em 55.90em;
}
.emoji-1f931 {
  background-position: 16.90em 54.60em;
}
.emoji-1f931-1f3fb {
  background-position: 16.90em 53.30em;
}
.emoji-1f931-1f3fc {
  background-position: 16.90em 52.00em;
}
.emoji-1f931-1f3fd {
  background-position: 16.90em 50.70em;
}
.emoji-1f931-1f3fe {
  background-position: 16.90em 49.40em;
}
.emoji-1f931-1f3ff {
  background-position: 16.90em 48.10em;
}
.emoji-1f932 {
  background-position: 16.90em 46.80em;
}
.emoji-1f932-1f3fb {
  background-position: 16.90em 45.50em;
}
.emoji-1f932-1f3fc {
  background-position: 16.90em 44.20em;
}
.emoji-1f932-1f3fd {
  background-position: 16.90em 42.90em;
}
.emoji-1f932-1f3fe {
  background-position: 16.90em 41.60em;
}
.emoji-1f932-1f3ff {
  background-position: 16.90em 40.30em;
}
.emoji-1f933 {
  background-position: 16.90em 39.00em;
}
.emoji-1f933-1f3fb {
  background-position: 16.90em 37.70em;
}
.emoji-1f933-1f3fc {
  background-position: 16.90em 36.40em;
}
.emoji-1f933-1f3fd {
  background-position: 16.90em 35.10em;
}
.emoji-1f933-1f3fe {
  background-position: 16.90em 33.80em;
}
.emoji-1f933-1f3ff {
  background-position: 16.90em 32.50em;
}
.emoji-1f934 {
  background-position: 16.90em 31.20em;
}
.emoji-1f934-1f3fb {
  background-position: 16.90em 29.90em;
}
.emoji-1f934-1f3fc {
  background-position: 16.90em 28.60em;
}
.emoji-1f934-1f3fd {
  background-position: 16.90em 27.30em;
}
.emoji-1f934-1f3fe {
  background-position: 16.90em 26.00em;
}
.emoji-1f934-1f3ff {
  background-position: 16.90em 24.70em;
}
.emoji-1f935 {
  background-position: 16.90em 23.40em;
}
.emoji-1f935-1f3fb {
  background-position: 16.90em 22.10em;
}
.emoji-1f935-1f3fc {
  background-position: 16.90em 20.80em;
}
.emoji-1f935-1f3fd {
  background-position: 16.90em 19.50em;
}
.emoji-1f935-1f3fe {
  background-position: 16.90em 18.20em;
}
.emoji-1f935-1f3ff {
  background-position: 16.90em 16.90em;
}
.emoji-1f936 {
  background-position: 16.90em 15.60em;
}
.emoji-1f936-1f3fb {
  background-position: 16.90em 14.30em;
}
.emoji-1f936-1f3fc {
  background-position: 16.90em 13.00em;
}
.emoji-1f936-1f3fd {
  background-position: 16.90em 11.70em;
}
.emoji-1f936-1f3fe {
  background-position: 16.90em 10.40em;
}
.emoji-1f936-1f3ff {
  background-position: 16.90em 9.10em;
}
.emoji-1f937-200d-2640-fe0f {
  background-position: 16.90em 7.80em;
}
.emoji-1f937-1f3fb-200d-2640-fe0f {
  background-position: 16.90em 6.50em;
}
.emoji-1f937-1f3fc-200d-2640-fe0f {
  background-position: 16.90em 5.20em;
}
.emoji-1f937-1f3fd-200d-2640-fe0f {
  background-position: 16.90em 3.90em;
}
.emoji-1f937-1f3fe-200d-2640-fe0f {
  background-position: 16.90em 2.60em;
}
.emoji-1f937-1f3ff-200d-2640-fe0f {
  background-position: 16.90em 1.30em;
}
.emoji-1f937-200d-2642-fe0f {
  background-position: 15.60em 67.60em;
}
.emoji-1f937-1f3fb-200d-2642-fe0f {
  background-position: 15.60em 66.30em;
}
.emoji-1f937-1f3fc-200d-2642-fe0f {
  background-position: 15.60em 65.00em;
}
.emoji-1f937-1f3fd-200d-2642-fe0f {
  background-position: 15.60em 63.70em;
}
.emoji-1f937-1f3fe-200d-2642-fe0f {
  background-position: 15.60em 62.40em;
}
.emoji-1f937-1f3ff-200d-2642-fe0f {
  background-position: 15.60em 61.10em;
}
.emoji-1f937 {
  background-position: 15.60em 59.80em;
}
.emoji-1f937-1f3fb {
  background-position: 15.60em 58.50em;
}
.emoji-1f937-1f3fc {
  background-position: 15.60em 57.20em;
}
.emoji-1f937-1f3fd {
  background-position: 15.60em 55.90em;
}
.emoji-1f937-1f3fe {
  background-position: 15.60em 54.60em;
}
.emoji-1f937-1f3ff {
  background-position: 15.60em 53.30em;
}
.emoji-1f938-200d-2640-fe0f {
  background-position: 15.60em 52.00em;
}
.emoji-1f938-1f3fb-200d-2640-fe0f {
  background-position: 15.60em 50.70em;
}
.emoji-1f938-1f3fc-200d-2640-fe0f {
  background-position: 15.60em 49.40em;
}
.emoji-1f938-1f3fd-200d-2640-fe0f {
  background-position: 15.60em 48.10em;
}
.emoji-1f938-1f3fe-200d-2640-fe0f {
  background-position: 15.60em 46.80em;
}
.emoji-1f938-1f3ff-200d-2640-fe0f {
  background-position: 15.60em 45.50em;
}
.emoji-1f938-200d-2642-fe0f {
  background-position: 15.60em 44.20em;
}
.emoji-1f938-1f3fb-200d-2642-fe0f {
  background-position: 15.60em 42.90em;
}
.emoji-1f938-1f3fc-200d-2642-fe0f {
  background-position: 15.60em 41.60em;
}
.emoji-1f938-1f3fd-200d-2642-fe0f {
  background-position: 15.60em 40.30em;
}
.emoji-1f938-1f3fe-200d-2642-fe0f {
  background-position: 15.60em 39.00em;
}
.emoji-1f938-1f3ff-200d-2642-fe0f {
  background-position: 15.60em 37.70em;
}
.emoji-1f938 {
  background-position: 15.60em 36.40em;
}
.emoji-1f938-1f3fb {
  background-position: 15.60em 35.10em;
}
.emoji-1f938-1f3fc {
  background-position: 15.60em 33.80em;
}
.emoji-1f938-1f3fd {
  background-position: 15.60em 32.50em;
}
.emoji-1f938-1f3fe {
  background-position: 15.60em 31.20em;
}
.emoji-1f938-1f3ff {
  background-position: 15.60em 29.90em;
}
.emoji-1f939-200d-2640-fe0f {
  background-position: 15.60em 28.60em;
}
.emoji-1f939-1f3fb-200d-2640-fe0f {
  background-position: 15.60em 27.30em;
}
.emoji-1f939-1f3fc-200d-2640-fe0f {
  background-position: 15.60em 26.00em;
}
.emoji-1f939-1f3fd-200d-2640-fe0f {
  background-position: 15.60em 24.70em;
}
.emoji-1f939-1f3fe-200d-2640-fe0f {
  background-position: 15.60em 23.40em;
}
.emoji-1f939-1f3ff-200d-2640-fe0f {
  background-position: 15.60em 22.10em;
}
.emoji-1f939-200d-2642-fe0f {
  background-position: 15.60em 20.80em;
}
.emoji-1f939-1f3fb-200d-2642-fe0f {
  background-position: 15.60em 19.50em;
}
.emoji-1f939-1f3fc-200d-2642-fe0f {
  background-position: 15.60em 18.20em;
}
.emoji-1f939-1f3fd-200d-2642-fe0f {
  background-position: 15.60em 16.90em;
}
.emoji-1f939-1f3fe-200d-2642-fe0f {
  background-position: 15.60em 15.60em;
}
.emoji-1f939-1f3ff-200d-2642-fe0f {
  background-position: 15.60em 14.30em;
}
.emoji-1f939 {
  background-position: 15.60em 13.00em;
}
.emoji-1f939-1f3fb {
  background-position: 15.60em 11.70em;
}
.emoji-1f939-1f3fc {
  background-position: 15.60em 10.40em;
}
.emoji-1f939-1f3fd {
  background-position: 15.60em 9.10em;
}
.emoji-1f939-1f3fe {
  background-position: 15.60em 7.80em;
}
.emoji-1f939-1f3ff {
  background-position: 15.60em 6.50em;
}
.emoji-1f93a {
  background-position: 15.60em 5.20em;
}
.emoji-1f93c-200d-2640-fe0f {
  background-position: 15.60em 3.90em;
}
.emoji-1f93c-200d-2642-fe0f {
  background-position: 15.60em 2.60em;
}
.emoji-1f93c {
  background-position: 15.60em 1.30em;
}
.emoji-1f93d-200d-2640-fe0f {
  background-position: 14.30em 67.60em;
}
.emoji-1f93d-1f3fb-200d-2640-fe0f {
  background-position: 14.30em 66.30em;
}
.emoji-1f93d-1f3fc-200d-2640-fe0f {
  background-position: 14.30em 65.00em;
}
.emoji-1f93d-1f3fd-200d-2640-fe0f {
  background-position: 14.30em 63.70em;
}
.emoji-1f93d-1f3fe-200d-2640-fe0f {
  background-position: 14.30em 62.40em;
}
.emoji-1f93d-1f3ff-200d-2640-fe0f {
  background-position: 14.30em 61.10em;
}
.emoji-1f93d-200d-2642-fe0f {
  background-position: 14.30em 59.80em;
}
.emoji-1f93d-1f3fb-200d-2642-fe0f {
  background-position: 14.30em 58.50em;
}
.emoji-1f93d-1f3fc-200d-2642-fe0f {
  background-position: 14.30em 57.20em;
}
.emoji-1f93d-1f3fd-200d-2642-fe0f {
  background-position: 14.30em 55.90em;
}
.emoji-1f93d-1f3fe-200d-2642-fe0f {
  background-position: 14.30em 54.60em;
}
.emoji-1f93d-1f3ff-200d-2642-fe0f {
  background-position: 14.30em 53.30em;
}
.emoji-1f93d {
  background-position: 14.30em 52.00em;
}
.emoji-1f93d-1f3fb {
  background-position: 14.30em 50.70em;
}
.emoji-1f93d-1f3fc {
  background-position: 14.30em 49.40em;
}
.emoji-1f93d-1f3fd {
  background-position: 14.30em 48.10em;
}
.emoji-1f93d-1f3fe {
  background-position: 14.30em 46.80em;
}
.emoji-1f93d-1f3ff {
  background-position: 14.30em 45.50em;
}
.emoji-1f93e-200d-2640-fe0f {
  background-position: 14.30em 44.20em;
}
.emoji-1f93e-1f3fb-200d-2640-fe0f {
  background-position: 14.30em 42.90em;
}
.emoji-1f93e-1f3fc-200d-2640-fe0f {
  background-position: 14.30em 41.60em;
}
.emoji-1f93e-1f3fd-200d-2640-fe0f {
  background-position: 14.30em 40.30em;
}
.emoji-1f93e-1f3fe-200d-2640-fe0f {
  background-position: 14.30em 39.00em;
}
.emoji-1f93e-1f3ff-200d-2640-fe0f {
  background-position: 14.30em 37.70em;
}
.emoji-1f93e-200d-2642-fe0f {
  background-position: 14.30em 36.40em;
}
.emoji-1f93e-1f3fb-200d-2642-fe0f {
  background-position: 14.30em 35.10em;
}
.emoji-1f93e-1f3fc-200d-2642-fe0f {
  background-position: 14.30em 33.80em;
}
.emoji-1f93e-1f3fd-200d-2642-fe0f {
  background-position: 14.30em 32.50em;
}
.emoji-1f93e-1f3fe-200d-2642-fe0f {
  background-position: 14.30em 31.20em;
}
.emoji-1f93e-1f3ff-200d-2642-fe0f {
  background-position: 14.30em 29.90em;
}
.emoji-1f93e {
  background-position: 14.30em 28.60em;
}
.emoji-1f93e-1f3fb {
  background-position: 14.30em 27.30em;
}
.emoji-1f93e-1f3fc {
  background-position: 14.30em 26.00em;
}
.emoji-1f93e-1f3fd {
  background-position: 14.30em 24.70em;
}
.emoji-1f93e-1f3fe {
  background-position: 14.30em 23.40em;
}
.emoji-1f93e-1f3ff {
  background-position: 14.30em 22.10em;
}
.emoji-1f940 {
  background-position: 14.30em 20.80em;
}
.emoji-1f941 {
  background-position: 14.30em 19.50em;
}
.emoji-1f942 {
  background-position: 14.30em 18.20em;
}
.emoji-1f943 {
  background-position: 14.30em 16.90em;
}
.emoji-1f944 {
  background-position: 14.30em 15.60em;
}
.emoji-1f945 {
  background-position: 14.30em 14.30em;
}
.emoji-1f947 {
  background-position: 14.30em 13.00em;
}
.emoji-1f948 {
  background-position: 14.30em 11.70em;
}
.emoji-1f949 {
  background-position: 14.30em 10.40em;
}
.emoji-1f94a {
  background-position: 14.30em 9.10em;
}
.emoji-1f94b {
  background-position: 14.30em 7.80em;
}
.emoji-1f94c {
  background-position: 14.30em 6.50em;
}
.emoji-1f950 {
  background-position: 14.30em 5.20em;
}
.emoji-1f951 {
  background-position: 14.30em 3.90em;
}
.emoji-1f952 {
  background-position: 14.30em 2.60em;
}
.emoji-1f953 {
  background-position: 14.30em 1.30em;
}
.emoji-1f954 {
  background-position: 13.00em 67.60em;
}
.emoji-1f955 {
  background-position: 13.00em 66.30em;
}
.emoji-1f956 {
  background-position: 13.00em 65.00em;
}
.emoji-1f957 {
  background-position: 13.00em 63.70em;
}
.emoji-1f958 {
  background-position: 13.00em 62.40em;
}
.emoji-1f959 {
  background-position: 13.00em 61.10em;
}
.emoji-1f95a {
  background-position: 13.00em 59.80em;
}
.emoji-1f95b {
  background-position: 13.00em 58.50em;
}
.emoji-1f95c {
  background-position: 13.00em 57.20em;
}
.emoji-1f95d {
  background-position: 13.00em 55.90em;
}
.emoji-1f95e {
  background-position: 13.00em 54.60em;
}
.emoji-1f95f {
  background-position: 13.00em 53.30em;
}
.emoji-1f960 {
  background-position: 13.00em 52.00em;
}
.emoji-1f961 {
  background-position: 13.00em 50.70em;
}
.emoji-1f962 {
  background-position: 13.00em 49.40em;
}
.emoji-1f963 {
  background-position: 13.00em 48.10em;
}
.emoji-1f964 {
  background-position: 13.00em 46.80em;
}
.emoji-1f965 {
  background-position: 13.00em 45.50em;
}
.emoji-1f966 {
  background-position: 13.00em 44.20em;
}
.emoji-1f967 {
  background-position: 13.00em 42.90em;
}
.emoji-1f968 {
  background-position: 13.00em 41.60em;
}
.emoji-1f969 {
  background-position: 13.00em 40.30em;
}
.emoji-1f96a {
  background-position: 13.00em 39.00em;
}
.emoji-1f96b {
  background-position: 13.00em 37.70em;
}
.emoji-1f980 {
  background-position: 13.00em 36.40em;
}
.emoji-1f981 {
  background-position: 13.00em 35.10em;
}
.emoji-1f982 {
  background-position: 13.00em 33.80em;
}
.emoji-1f983 {
  background-position: 13.00em 32.50em;
}
.emoji-1f984 {
  background-position: 13.00em 31.20em;
}
.emoji-1f985 {
  background-position: 13.00em 29.90em;
}
.emoji-1f986 {
  background-position: 13.00em 28.60em;
}
.emoji-1f987 {
  background-position: 13.00em 27.30em;
}
.emoji-1f988 {
  background-position: 13.00em 26.00em;
}
.emoji-1f989 {
  background-position: 13.00em 24.70em;
}
.emoji-1f98a {
  background-position: 13.00em 23.40em;
}
.emoji-1f98b {
  background-position: 13.00em 22.10em;
}
.emoji-1f98c {
  background-position: 13.00em 20.80em;
}
.emoji-1f98d {
  background-position: 13.00em 19.50em;
}
.emoji-1f98e {
  background-position: 13.00em 18.20em;
}
.emoji-1f98f {
  background-position: 13.00em 16.90em;
}
.emoji-1f990 {
  background-position: 13.00em 15.60em;
}
.emoji-1f991 {
  background-position: 13.00em 14.30em;
}
.emoji-1f992 {
  background-position: 13.00em 13.00em;
}
.emoji-1f993 {
  background-position: 13.00em 11.70em;
}
.emoji-1f994 {
  background-position: 13.00em 10.40em;
}
.emoji-1f995 {
  background-position: 13.00em 9.10em;
}
.emoji-1f996 {
  background-position: 13.00em 7.80em;
}
.emoji-1f997 {
  background-position: 13.00em 6.50em;
}
.emoji-1f9c0 {
  background-position: 13.00em 5.20em;
}
.emoji-1f9d0 {
  background-position: 13.00em 3.90em;
}
.emoji-1f9d1 {
  background-position: 13.00em 2.60em;
}
.emoji-1f9d1-1f3fb {
  background-position: 13.00em 1.30em;
}
.emoji-1f9d1-1f3fc {
  background-position: 11.70em 67.60em;
}
.emoji-1f9d1-1f3fd {
  background-position: 11.70em 66.30em;
}
.emoji-1f9d1-1f3fe {
  background-position: 11.70em 65.00em;
}
.emoji-1f9d1-1f3ff {
  background-position: 11.70em 63.70em;
}
.emoji-1f9d2 {
  background-position: 11.70em 62.40em;
}
.emoji-1f9d2-1f3fb {
  background-position: 11.70em 61.10em;
}
.emoji-1f9d2-1f3fc {
  background-position: 11.70em 59.80em;
}
.emoji-1f9d2-1f3fd {
  background-position: 11.70em 58.50em;
}
.emoji-1f9d2-1f3fe {
  background-position: 11.70em 57.20em;
}
.emoji-1f9d2-1f3ff {
  background-position: 11.70em 55.90em;
}
.emoji-1f9d3 {
  background-position: 11.70em 54.60em;
}
.emoji-1f9d3-1f3fb {
  background-position: 11.70em 53.30em;
}
.emoji-1f9d3-1f3fc {
  background-position: 11.70em 52.00em;
}
.emoji-1f9d3-1f3fd {
  background-position: 11.70em 50.70em;
}
.emoji-1f9d3-1f3fe {
  background-position: 11.70em 49.40em;
}
.emoji-1f9d3-1f3ff {
  background-position: 11.70em 48.10em;
}
.emoji-1f9d4 {
  background-position: 11.70em 46.80em;
}
.emoji-1f9d4-1f3fb {
  background-position: 11.70em 45.50em;
}
.emoji-1f9d4-1f3fc {
  background-position: 11.70em 44.20em;
}
.emoji-1f9d4-1f3fd {
  background-position: 11.70em 42.90em;
}
.emoji-1f9d4-1f3fe {
  background-position: 11.70em 41.60em;
}
.emoji-1f9d4-1f3ff {
  background-position: 11.70em 40.30em;
}
.emoji-1f9d5 {
  background-position: 11.70em 39.00em;
}
.emoji-1f9d5-1f3fb {
  background-position: 11.70em 37.70em;
}
.emoji-1f9d5-1f3fc {
  background-position: 11.70em 36.40em;
}
.emoji-1f9d5-1f3fd {
  background-position: 11.70em 35.10em;
}
.emoji-1f9d5-1f3fe {
  background-position: 11.70em 33.80em;
}
.emoji-1f9d5-1f3ff {
  background-position: 11.70em 32.50em;
}
.emoji-1f9d6-200d-2640-fe0f {
  background-position: 11.70em 31.20em;
}
.emoji-1f9d6-1f3fb-200d-2640-fe0f {
  background-position: 11.70em 29.90em;
}
.emoji-1f9d6-1f3fc-200d-2640-fe0f {
  background-position: 11.70em 28.60em;
}
.emoji-1f9d6-1f3fd-200d-2640-fe0f {
  background-position: 11.70em 27.30em;
}
.emoji-1f9d6-1f3fe-200d-2640-fe0f {
  background-position: 11.70em 26.00em;
}
.emoji-1f9d6-1f3ff-200d-2640-fe0f {
  background-position: 11.70em 24.70em;
}
.emoji-1f9d6-200d-2642-fe0f {
  background-position: 11.70em 23.40em;
}
.emoji-1f9d6-1f3fb-200d-2642-fe0f {
  background-position: 11.70em 22.10em;
}
.emoji-1f9d6-1f3fc-200d-2642-fe0f {
  background-position: 11.70em 20.80em;
}
.emoji-1f9d6-1f3fd-200d-2642-fe0f {
  background-position: 11.70em 19.50em;
}
.emoji-1f9d6-1f3fe-200d-2642-fe0f {
  background-position: 11.70em 18.20em;
}
.emoji-1f9d6-1f3ff-200d-2642-fe0f {
  background-position: 11.70em 16.90em;
}
.emoji-1f9d6 {
  background-position: 11.70em 15.60em;
}
.emoji-1f9d6-1f3fb {
  background-position: 11.70em 14.30em;
}
.emoji-1f9d6-1f3fc {
  background-position: 11.70em 13.00em;
}
.emoji-1f9d6-1f3fd {
  background-position: 11.70em 11.70em;
}
.emoji-1f9d6-1f3fe {
  background-position: 11.70em 10.40em;
}
.emoji-1f9d6-1f3ff {
  background-position: 11.70em 9.10em;
}
.emoji-1f9d7-200d-2640-fe0f {
  background-position: 11.70em 7.80em;
}
.emoji-1f9d7-1f3fb-200d-2640-fe0f {
  background-position: 11.70em 6.50em;
}
.emoji-1f9d7-1f3fc-200d-2640-fe0f {
  background-position: 11.70em 5.20em;
}
.emoji-1f9d7-1f3fd-200d-2640-fe0f {
  background-position: 11.70em 3.90em;
}
.emoji-1f9d7-1f3fe-200d-2640-fe0f {
  background-position: 11.70em 2.60em;
}
.emoji-1f9d7-1f3ff-200d-2640-fe0f {
  background-position: 11.70em 1.30em;
}
.emoji-1f9d7-200d-2642-fe0f {
  background-position: 10.40em 67.60em;
}
.emoji-1f9d7-1f3fb-200d-2642-fe0f {
  background-position: 10.40em 66.30em;
}
.emoji-1f9d7-1f3fc-200d-2642-fe0f {
  background-position: 10.40em 65.00em;
}
.emoji-1f9d7-1f3fd-200d-2642-fe0f {
  background-position: 10.40em 63.70em;
}
.emoji-1f9d7-1f3fe-200d-2642-fe0f {
  background-position: 10.40em 62.40em;
}
.emoji-1f9d7-1f3ff-200d-2642-fe0f {
  background-position: 10.40em 61.10em;
}
.emoji-1f9d7 {
  background-position: 10.40em 59.80em;
}
.emoji-1f9d7-1f3fb {
  background-position: 10.40em 58.50em;
}
.emoji-1f9d7-1f3fc {
  background-position: 10.40em 57.20em;
}
.emoji-1f9d7-1f3fd {
  background-position: 10.40em 55.90em;
}
.emoji-1f9d7-1f3fe {
  background-position: 10.40em 54.60em;
}
.emoji-1f9d7-1f3ff {
  background-position: 10.40em 53.30em;
}
.emoji-1f9d8-200d-2640-fe0f {
  background-position: 10.40em 52.00em;
}
.emoji-1f9d8-1f3fb-200d-2640-fe0f {
  background-position: 10.40em 50.70em;
}
.emoji-1f9d8-1f3fc-200d-2640-fe0f {
  background-position: 10.40em 49.40em;
}
.emoji-1f9d8-1f3fd-200d-2640-fe0f {
  background-position: 10.40em 48.10em;
}
.emoji-1f9d8-1f3fe-200d-2640-fe0f {
  background-position: 10.40em 46.80em;
}
.emoji-1f9d8-1f3ff-200d-2640-fe0f {
  background-position: 10.40em 45.50em;
}
.emoji-1f9d8-200d-2642-fe0f {
  background-position: 10.40em 44.20em;
}
.emoji-1f9d8-1f3fb-200d-2642-fe0f {
  background-position: 10.40em 42.90em;
}
.emoji-1f9d8-1f3fc-200d-2642-fe0f {
  background-position: 10.40em 41.60em;
}
.emoji-1f9d8-1f3fd-200d-2642-fe0f {
  background-position: 10.40em 40.30em;
}
.emoji-1f9d8-1f3fe-200d-2642-fe0f {
  background-position: 10.40em 39.00em;
}
.emoji-1f9d8-1f3ff-200d-2642-fe0f {
  background-position: 10.40em 37.70em;
}
.emoji-1f9d8 {
  background-position: 10.40em 36.40em;
}
.emoji-1f9d8-1f3fb {
  background-position: 10.40em 35.10em;
}
.emoji-1f9d8-1f3fc {
  background-position: 10.40em 33.80em;
}
.emoji-1f9d8-1f3fd {
  background-position: 10.40em 32.50em;
}
.emoji-1f9d8-1f3fe {
  background-position: 10.40em 31.20em;
}
.emoji-1f9d8-1f3ff {
  background-position: 10.40em 29.90em;
}
.emoji-1f9d9-200d-2640-fe0f {
  background-position: 10.40em 28.60em;
}
.emoji-1f9d9-1f3fb-200d-2640-fe0f {
  background-position: 10.40em 27.30em;
}
.emoji-1f9d9-1f3fc-200d-2640-fe0f {
  background-position: 10.40em 26.00em;
}
.emoji-1f9d9-1f3fd-200d-2640-fe0f {
  background-position: 10.40em 24.70em;
}
.emoji-1f9d9-1f3fe-200d-2640-fe0f {
  background-position: 10.40em 23.40em;
}
.emoji-1f9d9-1f3ff-200d-2640-fe0f {
  background-position: 10.40em 22.10em;
}
.emoji-1f9d9-200d-2642-fe0f {
  background-position: 10.40em 20.80em;
}
.emoji-1f9d9-1f3fb-200d-2642-fe0f {
  background-position: 10.40em 19.50em;
}
.emoji-1f9d9-1f3fc-200d-2642-fe0f {
  background-position: 10.40em 18.20em;
}
.emoji-1f9d9-1f3fd-200d-2642-fe0f {
  background-position: 10.40em 16.90em;
}
.emoji-1f9d9-1f3fe-200d-2642-fe0f {
  background-position: 10.40em 15.60em;
}
.emoji-1f9d9-1f3ff-200d-2642-fe0f {
  background-position: 10.40em 14.30em;
}
.emoji-1f9d9 {
  background-position: 10.40em 13.00em;
}
.emoji-1f9d9-1f3fb {
  background-position: 10.40em 11.70em;
}
.emoji-1f9d9-1f3fc {
  background-position: 10.40em 10.40em;
}
.emoji-1f9d9-1f3fd {
  background-position: 10.40em 9.10em;
}
.emoji-1f9d9-1f3fe {
  background-position: 10.40em 7.80em;
}
.emoji-1f9d9-1f3ff {
  background-position: 10.40em 6.50em;
}
.emoji-1f9da-200d-2640-fe0f {
  background-position: 10.40em 5.20em;
}
.emoji-1f9da-1f3fb-200d-2640-fe0f {
  background-position: 10.40em 3.90em;
}
.emoji-1f9da-1f3fc-200d-2640-fe0f {
  background-position: 10.40em 2.60em;
}
.emoji-1f9da-1f3fd-200d-2640-fe0f {
  background-position: 10.40em 1.30em;
}
.emoji-1f9da-1f3fe-200d-2640-fe0f {
  background-position: 9.10em 67.60em;
}
.emoji-1f9da-1f3ff-200d-2640-fe0f {
  background-position: 9.10em 66.30em;
}
.emoji-1f9da-200d-2642-fe0f {
  background-position: 9.10em 65.00em;
}
.emoji-1f9da-1f3fb-200d-2642-fe0f {
  background-position: 9.10em 63.70em;
}
.emoji-1f9da-1f3fc-200d-2642-fe0f {
  background-position: 9.10em 62.40em;
}
.emoji-1f9da-1f3fd-200d-2642-fe0f {
  background-position: 9.10em 61.10em;
}
.emoji-1f9da-1f3fe-200d-2642-fe0f {
  background-position: 9.10em 59.80em;
}
.emoji-1f9da-1f3ff-200d-2642-fe0f {
  background-position: 9.10em 58.50em;
}
.emoji-1f9da {
  background-position: 9.10em 57.20em;
}
.emoji-1f9da-1f3fb {
  background-position: 9.10em 55.90em;
}
.emoji-1f9da-1f3fc {
  background-position: 9.10em 54.60em;
}
.emoji-1f9da-1f3fd {
  background-position: 9.10em 53.30em;
}
.emoji-1f9da-1f3fe {
  background-position: 9.10em 52.00em;
}
.emoji-1f9da-1f3ff {
  background-position: 9.10em 50.70em;
}
.emoji-1f9db-200d-2640-fe0f {
  background-position: 9.10em 49.40em;
}
.emoji-1f9db-1f3fb-200d-2640-fe0f {
  background-position: 9.10em 48.10em;
}
.emoji-1f9db-1f3fc-200d-2640-fe0f {
  background-position: 9.10em 46.80em;
}
.emoji-1f9db-1f3fd-200d-2640-fe0f {
  background-position: 9.10em 45.50em;
}
.emoji-1f9db-1f3fe-200d-2640-fe0f {
  background-position: 9.10em 44.20em;
}
.emoji-1f9db-1f3ff-200d-2640-fe0f {
  background-position: 9.10em 42.90em;
}
.emoji-1f9db-200d-2642-fe0f {
  background-position: 9.10em 41.60em;
}
.emoji-1f9db-1f3fb-200d-2642-fe0f {
  background-position: 9.10em 40.30em;
}
.emoji-1f9db-1f3fc-200d-2642-fe0f {
  background-position: 9.10em 39.00em;
}
.emoji-1f9db-1f3fd-200d-2642-fe0f {
  background-position: 9.10em 37.70em;
}
.emoji-1f9db-1f3fe-200d-2642-fe0f {
  background-position: 9.10em 36.40em;
}
.emoji-1f9db-1f3ff-200d-2642-fe0f {
  background-position: 9.10em 35.10em;
}
.emoji-1f9db {
  background-position: 9.10em 33.80em;
}
.emoji-1f9db-1f3fb {
  background-position: 9.10em 32.50em;
}
.emoji-1f9db-1f3fc {
  background-position: 9.10em 31.20em;
}
.emoji-1f9db-1f3fd {
  background-position: 9.10em 29.90em;
}
.emoji-1f9db-1f3fe {
  background-position: 9.10em 28.60em;
}
.emoji-1f9db-1f3ff {
  background-position: 9.10em 27.30em;
}
.emoji-1f9dc-200d-2640-fe0f {
  background-position: 9.10em 26.00em;
}
.emoji-1f9dc-1f3fb-200d-2640-fe0f {
  background-position: 9.10em 24.70em;
}
.emoji-1f9dc-1f3fc-200d-2640-fe0f {
  background-position: 9.10em 23.40em;
}
.emoji-1f9dc-1f3fd-200d-2640-fe0f {
  background-position: 9.10em 22.10em;
}
.emoji-1f9dc-1f3fe-200d-2640-fe0f {
  background-position: 9.10em 20.80em;
}
.emoji-1f9dc-1f3ff-200d-2640-fe0f {
  background-position: 9.10em 19.50em;
}
.emoji-1f9dc-200d-2642-fe0f {
  background-position: 9.10em 18.20em;
}
.emoji-1f9dc-1f3fb-200d-2642-fe0f {
  background-position: 9.10em 16.90em;
}
.emoji-1f9dc-1f3fc-200d-2642-fe0f {
  background-position: 9.10em 15.60em;
}
.emoji-1f9dc-1f3fd-200d-2642-fe0f {
  background-position: 9.10em 14.30em;
}
.emoji-1f9dc-1f3fe-200d-2642-fe0f {
  background-position: 9.10em 13.00em;
}
.emoji-1f9dc-1f3ff-200d-2642-fe0f {
  background-position: 9.10em 11.70em;
}
.emoji-1f9dc {
  background-position: 9.10em 10.40em;
}
.emoji-1f9dc-1f3fb {
  background-position: 9.10em 9.10em;
}
.emoji-1f9dc-1f3fc {
  background-position: 9.10em 7.80em;
}
.emoji-1f9dc-1f3fd {
  background-position: 9.10em 6.50em;
}
.emoji-1f9dc-1f3fe {
  background-position: 9.10em 5.20em;
}
.emoji-1f9dc-1f3ff {
  background-position: 9.10em 3.90em;
}
.emoji-1f9dd-200d-2640-fe0f {
  background-position: 9.10em 2.60em;
}
.emoji-1f9dd-1f3fb-200d-2640-fe0f {
  background-position: 9.10em 1.30em;
}
.emoji-1f9dd-1f3fc-200d-2640-fe0f {
  background-position: 7.80em 67.60em;
}
.emoji-1f9dd-1f3fd-200d-2640-fe0f {
  background-position: 7.80em 66.30em;
}
.emoji-1f9dd-1f3fe-200d-2640-fe0f {
  background-position: 7.80em 65.00em;
}
.emoji-1f9dd-1f3ff-200d-2640-fe0f {
  background-position: 7.80em 63.70em;
}
.emoji-1f9dd-200d-2642-fe0f {
  background-position: 7.80em 62.40em;
}
.emoji-1f9dd-1f3fb-200d-2642-fe0f {
  background-position: 7.80em 61.10em;
}
.emoji-1f9dd-1f3fc-200d-2642-fe0f {
  background-position: 7.80em 59.80em;
}
.emoji-1f9dd-1f3fd-200d-2642-fe0f {
  background-position: 7.80em 58.50em;
}
.emoji-1f9dd-1f3fe-200d-2642-fe0f {
  background-position: 7.80em 57.20em;
}
.emoji-1f9dd-1f3ff-200d-2642-fe0f {
  background-position: 7.80em 55.90em;
}
.emoji-1f9dd {
  background-position: 7.80em 54.60em;
}
.emoji-1f9dd-1f3fb {
  background-position: 7.80em 53.30em;
}
.emoji-1f9dd-1f3fc {
  background-position: 7.80em 52.00em;
}
.emoji-1f9dd-1f3fd {
  background-position: 7.80em 50.70em;
}
.emoji-1f9dd-1f3fe {
  background-position: 7.80em 49.40em;
}
.emoji-1f9dd-1f3ff {
  background-position: 7.80em 48.10em;
}
.emoji-1f9de-200d-2640-fe0f {
  background-position: 7.80em 46.80em;
}
.emoji-1f9de-200d-2642-fe0f {
  background-position: 7.80em 45.50em;
}
.emoji-1f9de {
  background-position: 7.80em 44.20em;
}
.emoji-1f9df-200d-2640-fe0f {
  background-position: 7.80em 42.90em;
}
.emoji-1f9df-200d-2642-fe0f {
  background-position: 7.80em 41.60em;
}
.emoji-1f9df {
  background-position: 7.80em 40.30em;
}
.emoji-1f9e0 {
  background-position: 7.80em 39.00em;
}
.emoji-1f9e1 {
  background-position: 7.80em 37.70em;
}
.emoji-1f9e2 {
  background-position: 7.80em 36.40em;
}
.emoji-1f9e3 {
  background-position: 7.80em 35.10em;
}
.emoji-1f9e4 {
  background-position: 7.80em 33.80em;
}
.emoji-1f9e5 {
  background-position: 7.80em 32.50em;
}
.emoji-1f9e6 {
  background-position: 7.80em 31.20em;
}
.emoji-203c-fe0f {
  background-position: 7.80em 29.90em;
}
.emoji-2049-fe0f {
  background-position: 7.80em 28.60em;
}
.emoji-2122-fe0f {
  background-position: 7.80em 27.30em;
}
.emoji-2139-fe0f {
  background-position: 7.80em 26.00em;
}
.emoji-2194-fe0f {
  background-position: 7.80em 24.70em;
}
.emoji-2195-fe0f {
  background-position: 7.80em 23.40em;
}
.emoji-2196-fe0f {
  background-position: 7.80em 22.10em;
}
.emoji-2197-fe0f {
  background-position: 7.80em 20.80em;
}
.emoji-2198-fe0f {
  background-position: 7.80em 19.50em;
}
.emoji-2199-fe0f {
  background-position: 7.80em 18.20em;
}
.emoji-21a9-fe0f {
  background-position: 7.80em 16.90em;
}
.emoji-21aa-fe0f {
  background-position: 7.80em 15.60em;
}
.emoji-231a {
  background-position: 7.80em 14.30em;
}
.emoji-231b {
  background-position: 7.80em 13.00em;
}
.emoji-2328-fe0f {
  background-position: 7.80em 11.70em;
}
.emoji-23cf-fe0f {
  background-position: 7.80em 10.40em;
}
.emoji-23e9 {
  background-position: 7.80em 9.10em;
}
.emoji-23ea {
  background-position: 7.80em 7.80em;
}
.emoji-23eb {
  background-position: 7.80em 6.50em;
}
.emoji-23ec {
  background-position: 7.80em 5.20em;
}
.emoji-23ed-fe0f {
  background-position: 7.80em 3.90em;
}
.emoji-23ee-fe0f {
  background-position: 7.80em 2.60em;
}
.emoji-23ef-fe0f {
  background-position: 7.80em 1.30em;
}
.emoji-23f0 {
  background-position: 6.50em 67.60em;
}
.emoji-23f1-fe0f {
  background-position: 6.50em 66.30em;
}
.emoji-23f2-fe0f {
  background-position: 6.50em 65.00em;
}
.emoji-23f3 {
  background-position: 6.50em 63.70em;
}
.emoji-23f8-fe0f {
  background-position: 6.50em 62.40em;
}
.emoji-23f9-fe0f {
  background-position: 6.50em 61.10em;
}
.emoji-23fa-fe0f {
  background-position: 6.50em 59.80em;
}
.emoji-24c2-fe0f {
  background-position: 6.50em 58.50em;
}
.emoji-25aa-fe0f {
  background-position: 6.50em 57.20em;
}
.emoji-25ab-fe0f {
  background-position: 6.50em 55.90em;
}
.emoji-25b6-fe0f {
  background-position: 6.50em 54.60em;
}
.emoji-25c0-fe0f {
  background-position: 6.50em 53.30em;
}
.emoji-25fb-fe0f {
  background-position: 6.50em 52.00em;
}
.emoji-25fc-fe0f {
  background-position: 6.50em 50.70em;
}
.emoji-25fd {
  background-position: 6.50em 49.40em;
}
.emoji-25fe {
  background-position: 6.50em 48.10em;
}
.emoji-2600-fe0f {
  background-position: 6.50em 46.80em;
}
.emoji-2601-fe0f {
  background-position: 6.50em 45.50em;
}
.emoji-2602-fe0f {
  background-position: 6.50em 44.20em;
}
.emoji-2603-fe0f {
  background-position: 6.50em 42.90em;
}
.emoji-2604-fe0f {
  background-position: 6.50em 41.60em;
}
.emoji-260e-fe0f {
  background-position: 6.50em 40.30em;
}
.emoji-2611-fe0f {
  background-position: 6.50em 39.00em;
}
.emoji-2614 {
  background-position: 6.50em 37.70em;
}
.emoji-2615 {
  background-position: 6.50em 36.40em;
}
.emoji-2618-fe0f {
  background-position: 6.50em 35.10em;
}
.emoji-261d-fe0f {
  background-position: 6.50em 33.80em;
}
.emoji-261d-1f3fb {
  background-position: 6.50em 32.50em;
}
.emoji-261d-1f3fc {
  background-position: 6.50em 31.20em;
}
.emoji-261d-1f3fd {
  background-position: 6.50em 29.90em;
}
.emoji-261d-1f3fe {
  background-position: 6.50em 28.60em;
}
.emoji-261d-1f3ff {
  background-position: 6.50em 27.30em;
}
.emoji-2620-fe0f {
  background-position: 6.50em 26.00em;
}
.emoji-2622-fe0f {
  background-position: 6.50em 24.70em;
}
.emoji-2623-fe0f {
  background-position: 6.50em 23.40em;
}
.emoji-2626-fe0f {
  background-position: 6.50em 22.10em;
}
.emoji-262a-fe0f {
  background-position: 6.50em 20.80em;
}
.emoji-262e-fe0f {
  background-position: 6.50em 19.50em;
}
.emoji-262f-fe0f {
  background-position: 6.50em 18.20em;
}
.emoji-2638-fe0f {
  background-position: 6.50em 16.90em;
}
.emoji-2639-fe0f {
  background-position: 6.50em 15.60em;
}
.emoji-263a-fe0f {
  background-position: 6.50em 14.30em;
}
.emoji-2640-fe0f {
  background-position: 6.50em 13.00em;
}
.emoji-2642-fe0f {
  background-position: 6.50em 11.70em;
}
.emoji-2648 {
  background-position: 6.50em 10.40em;
}
.emoji-2649 {
  background-position: 6.50em 9.10em;
}
.emoji-264a {
  background-position: 6.50em 7.80em;
}
.emoji-264b {
  background-position: 6.50em 6.50em;
}
.emoji-264c {
  background-position: 6.50em 5.20em;
}
.emoji-264d {
  background-position: 6.50em 3.90em;
}
.emoji-264e {
  background-position: 6.50em 2.60em;
}
.emoji-264f {
  background-position: 6.50em 1.30em;
}
.emoji-2650 {
  background-position: 5.20em 67.60em;
}
.emoji-2651 {
  background-position: 5.20em 66.30em;
}
.emoji-2652 {
  background-position: 5.20em 65.00em;
}
.emoji-2653 {
  background-position: 5.20em 63.70em;
}
.emoji-2660-fe0f {
  background-position: 5.20em 62.40em;
}
.emoji-2663-fe0f {
  background-position: 5.20em 61.10em;
}
.emoji-2665-fe0f {
  background-position: 5.20em 59.80em;
}
.emoji-2666-fe0f {
  background-position: 5.20em 58.50em;
}
.emoji-2668-fe0f {
  background-position: 5.20em 57.20em;
}
.emoji-267b-fe0f {
  background-position: 5.20em 55.90em;
}
.emoji-267f {
  background-position: 5.20em 54.60em;
}
.emoji-2692-fe0f {
  background-position: 5.20em 53.30em;
}
.emoji-2693 {
  background-position: 5.20em 52.00em;
}
.emoji-2694-fe0f {
  background-position: 5.20em 50.70em;
}
.emoji-2695-fe0f {
  background-position: 5.20em 49.40em;
}
.emoji-2696-fe0f {
  background-position: 5.20em 48.10em;
}
.emoji-2697-fe0f {
  background-position: 5.20em 46.80em;
}
.emoji-2699-fe0f {
  background-position: 5.20em 45.50em;
}
.emoji-269b-fe0f {
  background-position: 5.20em 44.20em;
}
.emoji-269c-fe0f {
  background-position: 5.20em 42.90em;
}
.emoji-26a0-fe0f {
  background-position: 5.20em 41.60em;
}
.emoji-26a1 {
  background-position: 5.20em 40.30em;
}
.emoji-26aa {
  background-position: 5.20em 39.00em;
}
.emoji-26ab {
  background-position: 5.20em 37.70em;
}
.emoji-26b0-fe0f {
  background-position: 5.20em 36.40em;
}
.emoji-26b1-fe0f {
  background-position: 5.20em 35.10em;
}
.emoji-26bd {
  background-position: 5.20em 33.80em;
}
.emoji-26be {
  background-position: 5.20em 32.50em;
}
.emoji-26c4 {
  background-position: 5.20em 31.20em;
}
.emoji-26c5 {
  background-position: 5.20em 29.90em;
}
.emoji-26c8-fe0f {
  background-position: 5.20em 28.60em;
}
.emoji-26ce {
  background-position: 5.20em 27.30em;
}
.emoji-26cf-fe0f {
  background-position: 5.20em 26.00em;
}
.emoji-26d1-fe0f {
  background-position: 5.20em 24.70em;
}
.emoji-26d3-fe0f {
  background-position: 5.20em 23.40em;
}
.emoji-26d4 {
  background-position: 5.20em 22.10em;
}
.emoji-26e9-fe0f {
  background-position: 5.20em 20.80em;
}
.emoji-26ea {
  background-position: 5.20em 19.50em;
}
.emoji-26f0-fe0f {
  background-position: 5.20em 18.20em;
}
.emoji-26f1-fe0f {
  background-position: 5.20em 16.90em;
}
.emoji-26f2 {
  background-position: 5.20em 15.60em;
}
.emoji-26f3 {
  background-position: 5.20em 14.30em;
}
.emoji-26f4-fe0f {
  background-position: 5.20em 13.00em;
}
.emoji-26f5 {
  background-position: 5.20em 11.70em;
}
.emoji-26f7-fe0f {
  background-position: 5.20em 10.40em;
}
.emoji-26f8-fe0f {
  background-position: 5.20em 9.10em;
}
.emoji-26f9-fe0f-200d-2640-fe0f {
  background-position: 5.20em 7.80em;
}
.emoji-26f9-1f3fb-200d-2640-fe0f {
  background-position: 5.20em 6.50em;
}
.emoji-26f9-1f3fc-200d-2640-fe0f {
  background-position: 5.20em 5.20em;
}
.emoji-26f9-1f3fd-200d-2640-fe0f {
  background-position: 5.20em 3.90em;
}
.emoji-26f9-1f3fe-200d-2640-fe0f {
  background-position: 5.20em 2.60em;
}
.emoji-26f9-1f3ff-200d-2640-fe0f {
  background-position: 5.20em 1.30em;
}
.emoji-26f9-fe0f-200d-2642-fe0f {
  background-position: 3.90em 67.60em;
}
.emoji-26f9-1f3fb-200d-2642-fe0f {
  background-position: 3.90em 66.30em;
}
.emoji-26f9-1f3fc-200d-2642-fe0f {
  background-position: 3.90em 65.00em;
}
.emoji-26f9-1f3fd-200d-2642-fe0f {
  background-position: 3.90em 63.70em;
}
.emoji-26f9-1f3fe-200d-2642-fe0f {
  background-position: 3.90em 62.40em;
}
.emoji-26f9-1f3ff-200d-2642-fe0f {
  background-position: 3.90em 61.10em;
}
.emoji-26f9-fe0f {
  background-position: 3.90em 59.80em;
}
.emoji-26f9-1f3fb {
  background-position: 3.90em 58.50em;
}
.emoji-26f9-1f3fc {
  background-position: 3.90em 57.20em;
}
.emoji-26f9-1f3fd {
  background-position: 3.90em 55.90em;
}
.emoji-26f9-1f3fe {
  background-position: 3.90em 54.60em;
}
.emoji-26f9-1f3ff {
  background-position: 3.90em 53.30em;
}
.emoji-26fa {
  background-position: 3.90em 52.00em;
}
.emoji-26fd {
  background-position: 3.90em 50.70em;
}
.emoji-2702-fe0f {
  background-position: 3.90em 49.40em;
}
.emoji-2705 {
  background-position: 3.90em 48.10em;
}
.emoji-2708-fe0f {
  background-position: 3.90em 46.80em;
}
.emoji-2709-fe0f {
  background-position: 3.90em 45.50em;
}
.emoji-270a {
  background-position: 3.90em 44.20em;
}
.emoji-270a-1f3fb {
  background-position: 3.90em 42.90em;
}
.emoji-270a-1f3fc {
  background-position: 3.90em 41.60em;
}
.emoji-270a-1f3fd {
  background-position: 3.90em 40.30em;
}
.emoji-270a-1f3fe {
  background-position: 3.90em 39.00em;
}
.emoji-270a-1f3ff {
  background-position: 3.90em 37.70em;
}
.emoji-270b {
  background-position: 3.90em 36.40em;
}
.emoji-270b-1f3fb {
  background-position: 3.90em 35.10em;
}
.emoji-270b-1f3fc {
  background-position: 3.90em 33.80em;
}
.emoji-270b-1f3fd {
  background-position: 3.90em 32.50em;
}
.emoji-270b-1f3fe {
  background-position: 3.90em 31.20em;
}
.emoji-270b-1f3ff {
  background-position: 3.90em 29.90em;
}
.emoji-270c-fe0f {
  background-position: 3.90em 28.60em;
}
.emoji-270c-1f3fb {
  background-position: 3.90em 27.30em;
}
.emoji-270c-1f3fc {
  background-position: 3.90em 26.00em;
}
.emoji-270c-1f3fd {
  background-position: 3.90em 24.70em;
}
.emoji-270c-1f3fe {
  background-position: 3.90em 23.40em;
}
.emoji-270c-1f3ff {
  background-position: 3.90em 22.10em;
}
.emoji-270d-fe0f {
  background-position: 3.90em 20.80em;
}
.emoji-270d-1f3fb {
  background-position: 3.90em 19.50em;
}
.emoji-270d-1f3fc {
  background-position: 3.90em 18.20em;
}
.emoji-270d-1f3fd {
  background-position: 3.90em 16.90em;
}
.emoji-270d-1f3fe {
  background-position: 3.90em 15.60em;
}
.emoji-270d-1f3ff {
  background-position: 3.90em 14.30em;
}
.emoji-270f-fe0f {
  background-position: 3.90em 13.00em;
}
.emoji-2712-fe0f {
  background-position: 3.90em 11.70em;
}
.emoji-2714-fe0f {
  background-position: 3.90em 10.40em;
}
.emoji-2716-fe0f {
  background-position: 3.90em 9.10em;
}
.emoji-271d-fe0f {
  background-position: 3.90em 7.80em;
}
.emoji-2721-fe0f {
  background-position: 3.90em 6.50em;
}
.emoji-2728 {
  background-position: 3.90em 5.20em;
}
.emoji-2733-fe0f {
  background-position: 3.90em 3.90em;
}
.emoji-2734-fe0f {
  background-position: 3.90em 2.60em;
}
.emoji-2744-fe0f {
  background-position: 3.90em 1.30em;
}
.emoji-2747-fe0f {
  background-position: 2.60em 67.60em;
}
.emoji-274c {
  background-position: 2.60em 66.30em;
}
.emoji-274e {
  background-position: 2.60em 65.00em;
}
.emoji-2753 {
  background-position: 2.60em 63.70em;
}
.emoji-2754 {
  background-position: 2.60em 62.40em;
}
.emoji-2755 {
  background-position: 2.60em 61.10em;
}
.emoji-2757 {
  background-position: 2.60em 59.80em;
}
.emoji-2763-fe0f {
  background-position: 2.60em 58.50em;
}
.emoji-2764-fe0f {
  background-position: 2.60em 57.20em;
}
.emoji-2795 {
  background-position: 2.60em 55.90em;
}
.emoji-2796 {
  background-position: 2.60em 54.60em;
}
.emoji-2797 {
  background-position: 2.60em 53.30em;
}
.emoji-27a1-fe0f {
  background-position: 2.60em 52.00em;
}
.emoji-27b0 {
  background-position: 2.60em 50.70em;
}
.emoji-27bf {
  background-position: 2.60em 49.40em;
}
.emoji-2934-fe0f {
  background-position: 2.60em 48.10em;
}
.emoji-2935-fe0f {
  background-position: 2.60em 46.80em;
}
.emoji-2b05-fe0f {
  background-position: 2.60em 45.50em;
}
.emoji-2b06-fe0f {
  background-position: 2.60em 44.20em;
}
.emoji-2b07-fe0f {
  background-position: 2.60em 42.90em;
}
.emoji-2b1b {
  background-position: 2.60em 41.60em;
}
.emoji-2b1c {
  background-position: 2.60em 40.30em;
}
.emoji-2b50 {
  background-position: 2.60em 39.00em;
}
.emoji-2b55 {
  background-position: 2.60em 37.70em;
}
.emoji-3030-fe0f {
  background-position: 2.60em 36.40em;
}
.emoji-303d-fe0f {
  background-position: 2.60em 35.10em;
}
.emoji-3297-fe0f {
  background-position: 2.60em 33.80em;
}
.emoji-3299-fe0f {
  background-position: 2.60em 32.50em;
}

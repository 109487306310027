.maha-manager {
  background-color: $white;
  overflow: hidden;
  flex: 1
}
.maha-manager-title {
}
.maha-manager-sidebar {
  transition: width 0.15s ease-in-out;
  background-color: $mediumblue;
  position: relative;
  display: flex;
  flex-direction: column;
  .maha-manager-toggle {
    border-radius: 0.4rem;
    position: absolute;
    bottom: 0.6rem;
    right: 0.6rem;
    padding: 0.4rem;
    cursor: pointer;
    @include hover {
      background-color: fadeout($white, 90);
    };
    .maha-icon {
      transition: transform 0.15s ease-in-out;
      color: fadeout($white, 60);
      font-size: 1.4rem;
    }
  }
}
.maha-manager-sidebar-header {
  background-color: $darkblue;
  padding: 0.8rem;
  flex: 0 0 3.5rem;
  text-align: center;
  color: $white;
  line-height: 1.8rem;
}
.maha-manager-sidebar-body {
  background-color: $mediumblue;
  position: relative;
  display: flex;
  flex: 1;
  .maha-finder {
    padding: 0.8rem;
  }
  .maha-finder-item {
    border-radius: 0.4rem;
    margin-bottom: 2px;
    @include hover {
      background-color: fadeout($white, 90);
    };
    &.selected {
      background-color: $blue !important;
    }
  }
  .maha-finder-item-details {
    color: $white;
    span {
      color: fadeout($white, 40);
      font-size: 0.9rem;
      line-height: 1rem;
    }
  }
  .maha-finder-item-icon {
    .maha-icon {
      color: $white;
    }
    .fa-circle-o-notch {
      font-size: 0.6rem;
    }
  }
  .maha-finder-item-toggle .maha-icon {
    color: $white;
  }
  .maha-finder-item-count {
    color: $white;
  }
}
.maha-manager-main {
  background-color: fadeout($black, 85);
  flex-direction: column;
  overflow: hidden;
  display: flex;
  & > .maha-modal-panel > .maha-modal-panel-body {
    background-color: fadeout($black, 85);
  }
  .maha-collection-header-bar > div {
    border-right-color: $darkblue;
  }
  .maha-collection-table-header {
    background-color: fadeout($black, 97);
  }
}
.maha-manager-container {
  @include vscroll;
  flex: 1;
}
.maha-manager-menu {
  @include flex-justify-middle-center;
  position: absolute;
  top: 0;
  left: 0;
  height: 3.5rem;
  width: 3.5rem;
  z-index: 3;
  .maha-icon {
    color: $white;
    font-size: 1.3rem;
  }
}
.maha-manager-panel {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}
.maha-manager-alert-container {
  background-color: $white;
}
.maha-manager-alert {
  &.error {
    background-color: fadeout($red, 80);
    border-bottom: 1px solid $red;
    strong {
      color: $red;
    }
  }
  &.warning {
    background-color: fadeout($yellow, 80);
    border-bottom: 1px solid $yellow;
    strong {
      color: $yellow;
    }
  }
  &.success {
    background-color: fadeout($green, 80);
    border-bottom: 1px solid $green;
    strong {
      color: $green;
    }
  }
}
.maha-manager-alert-inner {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0.8rem;
  text-align: center;
  line-height: 1.8rem;
  color: fadeout($black, 40);
}
@media (max-width: 574px) {
  .maha-manager {
    position: relative;
  }
  .maha-manager-title {
    border: 1px solid fadeout($white, 70);
    border-radius: 0.4rem;
    position: relative;
    padding: 0.4rem 1.6rem 0.4rem 0.4rem;
    line-height: 1.6rem;

    .maha-icon {
      color: fadeout($white, 70);
      transform: translate(0, -50%);
      position: absolute;
      top: 50%;
      right: 0.6rem;
      font-size: 0.7rem;
    }
  }
  .maha-manager-toggle {
    display: none;
  }
  .maha-manager-sidebar  {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-height: calc(100vh - 5rem);
    transition: transform 0.25s ease-in;
    z-index: 3;
  }
  .maha-manager-main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 0.25s ease-in;
    z-index: 1;
  }
  .maha-manager-canvas {
    transition: opacity 0.25s ease-in;
    opacity: 0;
  }
  .maha-manager {
    &:not(.show) {
      .maha-manager-sidebar {
        transform: translateY(-100%);
      }
    }
    &.show {
      .maha-manager-canvas {
        background-color: $black;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        opacity: 0.5;
      }
    }
  }
  .maha-manager-panel {
    padding: 0.8rem 0.8rem 0;
  }
}
@media (min-width: 575px) {
  .maha-manager {
    display: flex;
    &.show {
      .maha-manager-toggle .maha-icon {
        transform: rotate(180deg);
      }
      .maha-manager-sidebar-body {
        display: none;
      }
      .maha-manager-sidebar {
        width: 3.5rem;
      }
    }
  }
  .maha-manager-title {
    .maha-icon {
      display: none;
    }
  }
  .maha-manager-sidebar {
    width: 250px
  }
  .maha-manager-main {
    position: relative;
    flex: 1;
    & > .grey > .maha-modal-panel-header {
      .maha-modal-panel-header-navigation:first-child {
        visibility: hidden;
      }
    }
  }
  .maha-manager-panel {
    padding: 0.8rem;
  }
}

.usage {
	tr.user_type {
		cursor: pointer;
	}
	tr.group td {
		color: fadeout($blue, 30);
	}
	tfoot {
		td, th {
			border-top: 1px solid fadeout($black, 90) !important;
			font-weight: bold;
		}
	}
}
span.pending {
	color: $teal;
}
span.enabled,
span.success {
	color: $green;
}
span.processing {
	color: $orange;
}
span.alert {
	color: $red;
}
span.strikethrough {
  text-decoration: line-through;
}
span.small {
	font-size: 0.8rem;
}
span.disabled {
	color: fadeout($black, 80);
}
span.light {
	color: fadeout($black, 50);
}

.crm-contact-properties {
  .maha-handset-actions {
    width: 300px;
    margin: 0 auto;
  }
}
.crm-contact-properties-contact {
  max-width: 800px;
  margin: 0.8rem 0;
  width: 100%;
  .maha-avatar {
    margin: 0 auto;
    width: 6rem;
    height: 6rem;
  }
  img {
    display: block;
  }
  h2 {
    margin-top: 0.8rem;
    color: $black !important;
    font-weight: normal !important;
  }
}
.crm-contact-properties-item {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.crm-contact-properties-item-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  flex: 0 0 2rem;
  .maha-icon {
    font-size: 1.2rem;
  }
  .fa-check {
    color: $green;
  }
  .fa-times {
    color: fadeout($red, 80);
  }
}
.crm-contact-properties-item-details {
  padding: 0.8rem;
  flex: 1;
}
.crm-contact-properties-item-extra {
  padding: 0.8rem;
  color: fadeout($black, 80);
  cursor: pointer;
  .maha-icon {
    color: fadeout($black, 80);
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}

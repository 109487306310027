.maha-asset-viewer {
  flex: 1;
  display: flex;
  flex-direction: column;
  &.audio {
    min-height: 40px;
  }
  &.video {
    min-height: 240px;
  }
}
.maha-asset-viewer-header {
  border-bottom: 1px solid fadeout($black, 90);
}
.maha-asset-viewer-body {
  position: relative;
  flex: 1;
  display: flex;
  .maha-message-panel p {
    color: $white;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

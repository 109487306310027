.maha-help {
  flex: 1;
  display: flex;
}
.maha-help-body {
  flex: 1;
  display: flex;
}
.maha-help-sidebar {
  flex: 0 0 350px;
  display: flex;
  .maha-modal-panel-body {
    border-left: 1px solid fadeout($black, 90);
  }
}

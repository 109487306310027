.access-token {
  display: flex;
}
.access-token-details {
  flex: 1;
}
.access-token-type {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.8rem;
}

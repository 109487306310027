.assignee-token {
  display: flex;
  flex: 1;
}
.assignee-token-image {
  padding: 0.8rem;
  .maha-avatar {
    width: 2rem;
  }
}
.assignee-token-icon {
  @include flex-justify-middle-center;
  background-color: fadeout($black, 80);
  color: $white;
  border-radius: 50%;
  .maha-icon {
    font-size: 0.8rem;
  }
}
.assignee-token-name {
  flex: 1;
  padding: 0.8rem 0.8rem 0.8rem 0;
  display: flex;
  & > * {
    margin: auto 0;
  }
}

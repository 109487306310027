.subscriptions-frequencyfield {
  border: 1px solid fadeout($black, 90);
  background: $white;
  border-radius: 0.4rem;
  display: inline-flex;
  flex-direction: column;
  .subscriptions-frequencyfield-option:first-child {
    border-bottom: 1px solid fadeout($black, 90);
  }
}
.subscriptions-frequencyfield-option {
  cursor: pointer;
  display: flex;
  &.disabled {
    opacity: 0.6;
  }
  .subscriptions-frequencyfield-option-dropdown:nth-child(2) {
    border-right: 1px solid fadeout($black, 90);
    width: 180px;
  }
  .subscriptions-frequencyfield-option-dropdown:nth-child(3) {
    width: 120px;
  }
}
.subscriptions-frequencyfield-option-label {
  @include flex-justify-middle-center;
  border-right: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  .maha-icon {
    font-size: 1.2rem;
  }
  .fa-circle-o {
    color: fadeout($black, 80);
  }
  .fa-check-circle {
    color: $blue;
  }
}
.subscriptions-frequencyfield-option-dropdown {
  width: 150px;
  .maha-input,
  .ui.selection.dropdown {
    border-radius: 0;
    border: none;
  }
}

.allocations-token {
  padding: 0.8rem;
  .maha-icon {
    color: $black !important;
  }
  th {
    padding: .5rem .7rem !important;
  }
  tbody td {
    cursor: pointer;
    &:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .selected td {
    background-color: fadeout($yellow, 90);
  }
  .expanded > td {
    padding: 0 !important;
    .maha-details-item {
      border: none !important;
    }
  }
  tfoot {
    th, td {
      border-top: 1px solid fadeout($black, 90) !important;
    }
  }
}
@media (max-width: 769px) {
  .line-items-token tbody td {
    max-width: calc(100vw - 17rem);
  }
}

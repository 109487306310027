.maha-link {
  margin-top: 0.4rem;
  width: 100%;
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  overflow: hidden;
  display: block;
  img {
    width: 100%;
    display: block;
  }
  a {
    display: flex;
    flex-direction: column;
  }
}
.maha-link-video-image {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.maha-link-video-play {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  border: 6px solid darken($white, 5);
  border-radius: 50%;
  padding: 10px 12px 10px 18px;
  cursor: pointer;
  @include hover {
    border-color: $white;
    &:after {
      border-left-color: $white;
    }
  };
  &:after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid darken($white, 5);
  }
}
.maha-link-video-player {
  position: relative;
	padding-bottom: 56.25%;
	height: 0;
  iframe {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
}
.maha-link-article {
  cursor: pointer;
}
.maha-link-details {
  background-color: fadeout($black, 95);
  padding: 0.4rem 0.8rem;
}
.maha-link-title {
  font-weight: bold;
  color: $black;
}
.maha-link-text {
  color: fadeout($black, 40);
  font-size: 0.8rem;
  line-height: 1.6rem;
}
.maha-link-service {
  margin-top: 0.25rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: fadeout($black, 60);
  font-weight: bold;
  line-height: 16px;
  img {
    display: inline-block;
    margin-right: 3px;
    height: 16px;
    width: 16px;
    margin-bottom: 4px;
    vertical-align: middle;
  }
}

.drive {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $white;
  position: relative;
  @include unselectable;
}
.drive-dnd {
  display: flex;
  flex: 1;
  min-height: 100%;
}
.drive-dnd-bounding-box {
  border: 1px solid fadeout($blue, 20);
  background-color: fadeout($blue, 90);
  position: absolute;
}
.drive-drag-layer {
  background-color: lighten($blue, 40);
  border: 1px solid lighten($black, 95);
  color: fadeout($black, 13);
  position: absolute;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  border-radius: 0.4rem;
  display: flex;
}
.drive-drag-layer-inner {
  flex: 1;
  padding: 0.8rem;
  position: relative;
}
.drive-drag-layer-thumbnail {
  display: inline-block;
  margin: 0 0.8rem 0 0;
  width: 1.4rem;
  .fa-folder { color: $yellow; }

}
.drive-drag-layer-count {
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  border-radius: 50%;
  background-color: $red;
  color: $white;
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  text-align: center;
  line-height: 1.6rem;
}
.drive-main {
  flex: 1;
  display: flex;
  position: relative;
}
.drive-list {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.drive-heading {
  background-color: $darkblue;
  padding: 0 0.8rem 0.8rem;
}
.drive-heading-box {
  background-color: fadeout($white, 50);
  border-radius: 0.4rem;
  margin: 0 !important;
  display: flex;
}
.drive-heading-search {
  flex: 1;
  .maha-searchbox {
    padding: 0;
  }
  .maha-searchbox-input {
    .maha-searchbox-icon {
      color: $white !important;
    }
    .maha-searchbox-remove-icon {
      @include hover {
        color: fadeout($white, 30)
      };
      color: fadeout($white, 50);
    }
    input {
      color: $white !important;
      &::-webkit-input-placeholder {
        color: fadeout($white, 20);
      }
      &:-moz-placeholder {
        color: fadeout($white, 20);
      }
      &::-moz-placeholder {
        color: fadeout($white, 20);
      }
      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: fadeout($white, 20);
      }
    }
  }
}
.drive-heading-action {
  @include flex-justify-middle-center;
  border-left: 1px solid $darkblue;
  cursor: pointer;
  padding: 0.4rem;
  flex: 0 0 2.5rem;
  .maha-icon {
    color: $white;
    font-size: 1.2rem;
  }
  @include hover {
    background-color: fadeout($white, 80);
  };
}
.drive-folder {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.drive-header {
  background-color: fadeout($black, 95);
  display: flex;
  color: fadeout($black, 50);
  padding-right: 6px;
  height: 3rem;
}
.drive-header-breadcrumb {
  flex: 1;
  display: flex;
  cursor: pointer;
}
.drive-header-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  color: fadeout($black, 80);
  cursor: pointer;
  @include hover {
    color: fadeout($black, 50)
  };
  .maha-icon {
    font-size: 1.2rem;
  }
}

.drive-header-back {
  flex: 0 0 2rem;
  padding: 0.8rem 0 0.8rem 0.8rem;
  cursor: pointer;
}
.drive-header-label {
  padding: 0.8rem;
  height: 2rem;
  overflow: hidden;
  word-break: break-all;
}

.drive-breadcrumbs {
  flex: 1;
  padding: 0.8rem;
  .drive-breadcrumb {
    margin-right: 0.4rem;
    display: inline;
    color: fadeout($black, 50);
    cursor: pointer;
    &.active {
      color: $black;
    }

  }
  .fa-chevron-right {
    margin-right: 0.4rem;
    color: fadeout($black, 70);
    font-size: 0.8rem;
  }
}
.drive-results {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.drive-results-header {
  display: flex;
}
.drive-results-header-item {
  border-bottom: 1px solid fadeout($black, 95);
  border-right: 1px solid fadeout($black, 95);
  position: relative;
  &.drive-name {
    padding: 0.8rem;
  }
  .maha-icon {
    position: absolute;
    right: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
    color: fadeout($black, 80);
  }
}
.drive-results-header-item {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  padding: 0.8rem;
  @include hover {
    background-color: fadeout($black, 97);
  };
  &.selected {
    background-color: fadeout($blue, 90);
    color: darken($blue, 10);
  }
  &.over {
    box-shadow: inset 0 0 0 2px $blue;
  }
  &.dragging {
    opacity: 0.4;
  }
}
.drive-results-body {
  flex: 1;
  display: flex;
}

.drive-root {
  flex: 1;
  display: flex;
  @include vscroll;
}
.drive-items {
  width: 100%;
}
.drive-head {
  display: flex;
}
.drive-head-item {
  border-bottom: 1px solid fadeout($black, 95);
  &.drive-name {
    padding: 0.8rem;
  }
}
.drive-item {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  @include hover {
    background-color: fadeout($black, 97);
  };
  &.selected {
    background-color: fadeout($blue, 90);
    color: darken($blue, 10);
  }
  &.over {
    box-shadow: inset 0 0 0 2px $blue;
  }
  &.dragging {
    opacity: 0.4;
  }
}
.drive-list-item-token {
  display: flex;
}
.drive-list-item-token-icon {
  display: flex;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .fa-hdd-o { color: $blue; }
  .fa-folder { color: $yellow; }
  .fa-handshake-o { color: $teal; }
  .fa-star { color: $yellow; }
  .fa-trash { color: $purple; }
  .maha-asset-thumbnail {
    width: 20px;
    height: 20px;
    img {
      display: block;
      width: 100%;
    }
  }
}
.drive-list-item-token-label {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  padding: 0.8rem;
}
.drive-list-item-meta {
  border-bottom: 1px solid fadeout($black, 95);
  color: fadeout($black, 60);
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &.drive-size {
    text-align: right;
  }
}
.drive-name {
  color: $black;
  flex: 1;
}
.drive-owner {
  flex: 0 0 14rem;
  padding: 0.8rem;
}
.drive-updated {
  flex: 0 0 8rem;
  padding: 0.8rem;
}
.drive-size {
  flex: 0 0 6rem;
  padding: 0.8rem;
}
.drive-action {
  @include flex-justify-middle-center;
  flex: 0 0 2.4rem;
  & > * {
    flex: none;
  }
  .fa-ellipsis-v {
    color: fadeout($black, 80);
    @include hover {
      color: fadeout($black, 50);
    };
    font-size: 1.2rem;
  }
  .fa-check {
    color: $green;
  }
  .fa-refresh {
    color: fadeout($black, 80);
  }
  .fa-chevron-right {
    color: fadeout($black, 80);
  }
}
.maha-portal-sidebar {
  .drive {
    .drive-overlay {
      @include opacity-transition(0, 0.5, .5s);
      opacity: 0;
      background-color: $black;
    }
    &.details {
      .drive-sidebar {
        transform: translateX(0);
      }
      .drive-overlay {
        opacity: 0.5;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}
@media (max-width: 1024px) {
  .drive-owner,
  .drive-updated,
  .drive-size {
    display: none;
  }
  .drive {
    .drive-overlay {
      @include opacity-transition(0, 0.5, .5s);
      opacity: 0;
      background-color: $black;
    }
    &.details {
      .drive-sidebar {
        transform: translateX(0);
      }
      .drive-overlay {
        opacity: 0.5;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}
.drive-grid-items {
  overflow: hidden;
  padding: 0.4rem;
  flex: 1;
  &.info .drive-grid-item {
    @media (min-width: 1241px) {
      width: calc(100% / 6 - 0.8rem);
    }
    @media (max-width: 1440px) {
      width: calc(100% / 5 - 0.8rem);
    }
    @media (max-width: 1280px) {
      width: calc(100% / 4 - 0.8rem);
    }
    @media (max-width: 1024px) {
      width: calc(100% / 3 - 0.8rem);
    }
  }
}
.drive-grid-item {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  position: relative;
  float: left;
  cursor: pointer;
  overflow: hidden;
  margin: 0.4rem;
  display: flex;
  flex-direction: column;
  &.selected {
    background-color: fadeout($blue, 90);
    color: darken($blue, 10);
  }
  @media (min-width: 1241px) {
    width: calc(100% / 8 - 0.8rem);
  }
  @media (max-width: 1440px) {
    width: calc(100% / 7 - 0.8rem);
  }
  @media (max-width: 1280px) {
    width: calc(100% / 6 - 0.8rem);
  }
  @media (max-width: 1024px) {
    width: calc(100% / 5 - 0.8rem);
  }
  @media (max-width: 960px) {
    width: calc(100% / 4 - 0.8rem);
  }
  @media (max-width: 480px) {
    width: calc(100% / 2 - 0.8rem);
  }
}
.drive-grid-item-preview {
  flex: 1;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  background-color: $white;
}
.drive-grid-item-icon {
  @include flex-justify-middle-center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .maha-asset-thumbnail {
    display: flex;
    flex: 1;
  }
  .maha-image {
    flex: 1;
    border-radius: 0;
    background-color: fadeout($black, 97);
  }
  .maha-asset-icon {
    .maha-icon {
      font-size: 6rem;
    }
    .fa-folder {
      color: $yellow;
    }
  }
}
.drive-grid-item-actions {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}
.drive-grid-item-action {
  flex: 1;
  padding: 0.4rem 0.2rem 0.4rem 0;
  display: flex;
  .maha-icon {
    font-size: 1rem;
  }
  .fa-star-o,
  .fa-ellipsis-v {
    color: fadeout($black, 80) !important;
  }
}
.drive-grid-item-label {
  border-top: 1px solid fadeout($black, 90);
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  height: 3.6rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  span {
    color: fadeout($black, 60);
  }
}

.maha-portal-sidebar {
  .drive-heading {
    background-color: $blue !important;
  }
  .drive-heading-action {
    border-color: $blue;
  }
  .drive-items,
  .drive-grid-items {
    width: 100%;
    max-width: 340px;
  }
  .drive-grid-item {
    width: calc(100% / 2 - 0.4rem) !important;
  }
  .drive-photo-item {
    width: calc(100% / 2 - 0.4rem) !important;
    padding-bottom: calc(100% / 2 - 0.4rem);
  }
  .drive-owner,
  .drive-updated,
  .drive-size {
    display: none;
  }
}

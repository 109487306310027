.maha-greeting {
  flex: 1;
  display: flex;
  position: relative;
}
.maha-greeting {
  @include flex-justify-middle-center;
  line-height: normal;
  text-align: center;
  width: 100%;
  height: 100%;
  &.morning {
    background: rgb(246,211,101);
    background: -moz-linear-gradient(120deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
    background: -webkit-linear-gradient(120deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
    background: linear-gradient(120deg, rgba(246,211,101,1) 0%, rgba(253,160,133,1) 100%);
  }
  &.afternoon {
    background: rgb(199,238,251);
    background: -moz-linear-gradient(120deg, rgba(199,238,251,1) 0%, rgba(50,156,226,1) 100%);
    background: -webkit-linear-gradient(120deg, rgba(199,238,251,1) 0%, rgba(50,156,226,1) 100%);
    background: linear-gradient(120deg, rgba(199,238,251,1) 0%, rgba(50,156,226,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c7eefb",endColorstr="#329ce2",GradientType=1);
  }
  &.evening {
    background: #355C7D;
    background: -webkit-linear-gradient(to bottom, #C06C84, #6C5B7B, #355C7D);
    background: linear-gradient(to bottom, #C06C84, #6C5B7B, #355C7D);
    color: $white;
    text-shadow: 2px 2px 30px #000000;
  }
  &.night {
    background: #0F2027;
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
    color:$white;
    text-shadow: 2px 2px 30px #000000;
  }
}
.maha-greeting-wrapper {
  flex: 1;
  display: block;
}
.maha-greeting-welcome {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: bold;
}
.maha-greeting-time {
  font-size: 3.2rem;
  margin: 40px 0;
}
.maha-greeting-date {
  font-size: 1.2rem;
  line-height: normal;
}

.stages-token {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  .stages-token-stage:last-child {
    border-bottom: none;
  }
}
.stages-token-title {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
}
.stages-token-stage {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.stages-token-stage-label {
  padding: 0.8rem;
  flex: 1;
}
.stages-token-stage-probability {
  padding: 0.8rem;
}

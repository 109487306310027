.maha-uploader {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-uploads {
  @include translate-y-transition(100%, 0, .15s);
  border-top: 1px solid fadeout($black, 95);
  background-color: $white;
  .label {
    color: $blue !important;
  }
  padding: 0.2rem;
}
.maha-upload {
  padding: 0.2rem;
  cursor: pointer;
}
.maha-upload-progress {
  display: flex;
  border-radius: 0.4rem;
  overflow: hidden;
  position: relative;
  background-color: #f5f5f5;
  .loader {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index:1;
    background-image:
      repeating-linear-gradient(
        -45deg,
        rgba(33, 186, 69, 0.3),
        rgba(33, 186, 69, 0.3) 11px,
        $white 10px,
        $white 20px
      );
    background-size: 28px 28px;
    animation: move .5s linear infinite;
    transition: width .25s;
    opacity: 0.6;
  }
}

.maha-upload-icon {
  @include flex-justify-middle-center;
  padding: 0.4rem 0 0.4rem 0.4rem;
  position: relative;
  z-index:2;
}
.maha-upload-name {
  padding: 0.4rem;
  flex: 1;
  font-size: .9rem;
  line-height: 1.7rem;
  color: fadeout($black, 40);
  position:relative;
  z-index:2;
}
.maha-upload-action {
  @include flex-justify-middle-center;
  padding: 0.4rem 0.4rem 0.4rem 0;
  font-size: 1.2rem;
  position: relative;
  z-index: 2;
  .fa-check {
    color: $green;
  }
  .fa-times {
    @include hover{
      color: fadeout($black, 50);
    };
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 28px 0;
  }
}

.maha-handset-keypad {
  display: flex;
  flex-wrap: wrap;
}
.maha-handset-keypad-cell {
  flex: 1 0 calc(100% / 3);
  display: flex;
  padding: 0.6rem;
}
.maha-handset-keypad-key {
  margin: auto;
  display: inline-block;
  text-align: center;
  background-color: fadeout($black, 90);
  width: 4rem;
  height: 4rem;
  color: $black;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.8rem 0;
  @include hover {
    background-color: fadeout($black, 80);
  };
  &:active {
    transform: scale(0.95, 0.95);
  }
}
.maha-handset-keypad-number {
  line-height: 1rem;
  font-size: 1.8rem;
  font-weight: bold;
}
.maha-handset-keypad-letters {
  font-size: 0.6rem;
  line-height: 1rem;
}

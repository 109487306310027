.drive-sidebar {
  background-color: $white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & > .maha-message {
    flex: 1;
  }
  .maha-details {
    .maha-message, .maha-image {
      width: 100%;
      height: 200px;
      min-height: 200px;
    }
    .maha-image img {
      width: 100%;
    }
    .maha-image {
      cursor: pointer;
    }
  }
  .maha-audit {
    background-color: $white !important;
  }
  .maha-audit-entry {
    margin-bottom: 0.8rem !important;
  }
  .maha-tabs-header {
    background-color: $blue !important;
  }
  .maha-tab-content {
    padding: 0;
  }
}
.drive-sidebar-title {
  background-color: $blue;
  white-space: nowrap;
  word-break: break-all;
  text-align: center;
  overflow: hidden;
  padding: 0.8rem;
  color: $white;
}

.drive-details {
  .maha-task {
    color: $blue;
  }
}

.drive-details-description {
  padding: 0.8rem;
  line-height: 1.8rem;
}
.drive-details-folder {
  color: $blue;
  cursor: pointer;
}

.drive-access {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.drive-access-photo {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  & > * {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.drive-access-details {
  padding: 0.8rem 0;
  flex: 1;
  line-height: 1.6rem;
  span {
    color: fadeout($black, 60);
  }
}
.drive-access-type {
  @include flex-justify-middle-center;
}

.maha-portal-sidebar {
  .drive-details {
    transition: transform .5s ease-in-out 0s;
    transform: translateX(100%);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}
@media (max-width: 768px) {
  .drive-sidebar {
    transition: transform .5s ease-in-out 0s;
    transform: translateX(100%);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}
@media (min-width: 769px) {
  .drive-sidebar {
    flex: 0 0 20%;
    min-width: 325px;
    border-left: 1px solid fadeout($black, 95);
    .drive-details-title-close {
      display: none;
    }
  }
}

.maha-dashboard-card {  
  flex-direction: column;
  display: flex;
  width: 100%;
  &.over {
    filter: brightness(110%) saturate(140%);
  }
} 
.maha-dashboard-card-actions {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
}
.maha-dashboard-card-action {
  @include flex-justify-middle-center;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  opacity: 0.6;
  flex: 1;
  &.disabled .maha-icon {
    color: fadeout($black, 80);
  }
  @include hover {
    opacity: 1;
  };
  .maha-icon {
    font-size: 1.2rem
  }
}
.maha-dashboard-card-body {
  display: flex;
  flex: 1;
}
.maha-dashboard-card-sidebar {
  border-right: 1px solid fadeout($black, 90);
  flex: 0 0 350px;
  display: flex;
  .maha-search {
    flex: 1;
  }
  .maha-search-item.selected {
    background-color: fadeout($yellow, 90);
  }
}
.maha-dashboard-card-detail {
  flex: 1;
}
.maha-dashboard-card-detail-header {
  padding: 0.8rem 0.8rem 0;
}

.maha-dashboard-card-detail-title,
.maha-dashboard-card-detail-subtitle,
.maha-dashboard-card-detail-metric {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.maha-dashboard-card-detail-title {
  font-weight: bold;
  line-height: 1.6rem;
}
.maha-dashboard-card-detail-subtitle {
  color: fadeout($black, 40);
  font-size: 0.8rem;
  line-height: 1.2rem;  
}
.maha-dashboard-card-detail-metric-delta {
  font-size: 0.8rem;
  line-height: 1.2rem;  
  &.down { color: $red; }
  &.up { color: $green; }
}
.maha-dashboard-card-detail-metric-text {
  font-size: 2.2rem;
  line-height: 2.2rem;
}
.maha-dashboard-card-detail-chart {
  width: 100%;
  display: flex;
  flex: 1;
}
.maha-dashboard-card-detail-table {
  border-top: 1px solid $white;
  table {
    background: $white;
    width: 100%;
    th {
      text-align: left;
      background: $black;
      color: $white;
    }
    tr {
      border-bottom: 1px solid fadeout($black, 90);
      cursor: pointer;
      @include hover {
        td {
          background-color: fadeout($black, 97);
        }
      };
      td:first-child {
        color: $blue;
      }
    }
    td, th { 
      padding: 0.8rem;
      white-space: nowrap;
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 1px;
        text-align: right;
      }
    }
  }
}
.maha-dashboard-card-detail-amount {
  display: inline-block;
  margin-right: 0.4rem;
}
.maha-dashboard-card-detail-percent {
  display: inline-block;
  color: fadeout($black, 60);
  width: 50px;
}
.maha-dashboard-card-detail-delta {
  display: block;
  text-align: right;
  font-size: 0.8rem;
  .maha-icon { margin-right: 0.4rem; }
  &.up { color: $green; }
  &.down { color: $red; }
}
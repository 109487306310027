.program-token {
  display: flex;
}
.program-token-logo {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 1.6rem;
  }
}
.program-token-label {
  flex: 1;
  padding: 0.8rem;
  line-height: 1.6rem;
  span {
    color: fadeout($black, 60);
  }
}

.maha-image-token {
  position: relative;
  border-radius: .4rem;
  display: flex;
  flex-direction: column;
  background-color: fadeout($black, 95);
  overflow: hidden;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 90);
  };
}
.maha-image-token-photo {
  padding-bottom: calc(100% * (2/3));
  position: relative;
  overflow: hidden;
  height: 0;
  .maha-image {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}
.maha-image-token-details {
  padding: 0.4rem 0.8rem;
  width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    color: fadeout($black, 60);
  }
}

.maha-progress-pie {
  text-align: center;
  margin: auto;
  .maha-icon {
    color: fadeout($black, 80);
    position: absolute;
    font-size: 32px;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .maha-progress-pie-text {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
    white-space: nowrap;
    font-size: 15px;
    span {
      font-size: 0.8em;
      color: fadeout($black, 60);
    }
  }
  .pie {
    position: relative;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    margin: 0 auto;
    background: conic-gradient($blue 0deg, #ecf0f1 0deg);
    &::before {
      content: "";
      position: absolute;
      width: calc(10rem - 20px);
      height: calc(10rem - 20px);
      background: white;
      border-radius: 50%;
      top: 10px;
      left: 10px;
    }
  }
  h3 {
    margin: 0.8em 0 0;
  }
  p {
    color: fadeout($black, 60);
  }
}

.email-activity-token {
  display: flex;
}
.email-activity-token-avatar {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  .contact-avatar-token {
    width: 2.4rem;
  }
}
.email-activity-token-label {
  padding: 0.8rem 0.8rem 0.8rem 0;
  line-height: 1.2rem;
  flex: 1;
  span {
    font-size: 0.8rem;
    color: fadeout($black, 60)
  }
}
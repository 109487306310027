.subscriptions-allowancesfield {
  flex: 1;
  .ui.table {
    border: none;
    tr td:nth-child(2),
    tr td:nth-child(3) {
      width: 110px;
      padding: 1px !important;
      .maha-input {
        border: none !important;
        border-radius: 0 !important;
      }
    }
  }
}

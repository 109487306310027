.voice-campaign-play-token {
  @include flex-justify-middle-center;
  padding-top: 1.8rem;
  height: 120px;
}
.voice-campaign-play-token-inner {
  .maha-icon {
    display: block;
    background-color: $red;
    color: $white;
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    font-size: 2.4rem;
  }
}

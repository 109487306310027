.mjson-designer-stylefield {
  &:not(.unpadded) {
    border: 1px solid fadeout($black, 90);
    background-color: fadeout($black, 98);
    border-radius: 0.4rem;
    .mjson-designer-stylefield,
    .linkedfield {
      background-color: $white;
    }
  }
  &.unpadded {
    margin: -0.8rem;
  }
  .maha-form-segment {
    background-color: $white !important;
    .maha-form-segment {
      background-color: fadeout($black, 98) !important;
    }
  }
}

.mjson-designer-options {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  display: inline-flex;
  .maha-columnsfield-option:last-child {
    border-right: none;
  }
}
.mjson-designer-options-option {
  border-right: 1px solid fadeout($black, 90);
  text-align: center;
  flex: 1;
  cursor: pointer;
  padding: 0.4rem;
  text-align: center;
  text-transform: uppercase;
  width: 35px;
  &:hover {
    background-color: fadeout($black, 97);
  }
  &.active {
    background-color: fadeout($blue, 85);
    color: $blue;
  }
}

.phone-sms-client-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($blue, 90);
  text-align: center;
  padding: 0.8rem;
  &.error {
    background-color: fadeout($red, 90);
    .phone-sms-client-message {
      color: $red;
    }
  }
}
.phone-sms-client-recipients {
  .maha-icon {
    color: fadeout($black, 60);
    font-size: 0.8rem;
    margin: 0 0.4rem;
  }
}
.phone-sms-client-recipient {
  display: inline;
  font-weight: bold;
}
.phone-sms-client-message {
  color: fadeout($black, 40);
}
.phone-sms-client {
  background-color: $white;
  flex-direction: column;
  display: flex;
  flex: 1;
}
.phone-sms-client-body {
  @include vscroll;
  scroll-behavior: auto;
  padding: 0.8rem;
  flex: 1;
}
.phone-sms-client-thread {
  width: 100%;
}
.phone-sms-client-footer {
  padding: 0.8rem;
}
.phone-sms-client-date-label {
  text-align: center;
  color: fadeout($black, 60);
  font-size: 0.8rem;
  padding: 0.8rem;
}
.phone-sms-client-token {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 0.4rem;
  &.program {
    .phone-sms-client-token-avatar {
      order: 1;
    }
    .phone-sms-client-token-details {
      text-align: left;
    }
    .phone-sms-client-token-messages {
      .phone-sms-client-token-message {
        justify-content: flex-start;
      }
      &:after {
      	border-right-color: $blue;
        left: -5px;
      }
    }
    .phone-sms-client-token-message-body {
      background-color: $blue;
      color: $white;
      a {
        text-decoration: underline;
        color: $white;
      }
    }
    .phone-sms-client-token-padding {
      order: 3;
    }
  }
  &.contact {
    .phone-sms-client-token-avatar {
      order: 3;
    }
    .phone-sms-client-token-details {
      justify-content: flex-end;
    }
    .phone-sms-client-token-messages {
      .phone-sms-client-token-message {
        justify-content: flex-end;
      }
      &:after {
        right: -5px;
        border-left-color: fadeout($black, 90);
      }
    }
    .phone-sms-client-token-message-body {
      border-radius: 0.4rem 0 0 0.4rem;
      background-color: fadeout($black, 90);
      color: fadeout($black, 40);
    }
    .phone-sms-client-token-padding {
      order: 1;
    }
  }
}
.phone-sms-client-token-identity {
  color: fadeout($black, 40);
  font-size: 0.8rem;
  padding-left: 4.8rem;
}
.phone-sms-client-token-block {
  display: flex;
}
.phone-sms-client-token-avatar {
  flex: 0 0 2.4rem;
  .contact-avatar-token,
  .maha-logo {
    width: 100%;
  }
  .maha-logo-text {
    font-size: 0.8rem;
  }
}
.phone-sms-client-token-details {
  padding: 0 0.4rem;
  display: flex;
  order: 2;
  flex: 1;
}
.phone-sms-client-token-messages {
  position: relative;
  max-width: 350px;
  padding: 0 5px;
  &:after {
    top: 1rem;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 5px;
    margin-top: -1px;
  }
  .phone-sms-client-token-message {
    &:first-child {
      .phone-sms-client-token-message-body {
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
      }
    }
    &:last-child {
      .phone-sms-client-token-message-body {
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }
    }
  }
  .phone-sms-client-token-message:last-child {
    .phone-sms-client-token-message-body {
      margin-bottom: 0;
    }
  }
}
.phone-sms-client-token-message {
  display: flex;
}
.phone-sms-client-token-message-body {
  padding: 0.6rem;
  display: inline-block;
  margin-bottom: 2px;
}
.phone-sms-client-token-message-error {
  padding: 0.6rem;
  .maha-icon {
    color: $red;
  }
}
.phone-sms-client-token-message-attachment {
  width: 200px;
  img {
    width: 100%;
  }
}
.phone-sms-client-token-padding {
  flex: 0 0 3rem;
}

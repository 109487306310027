.maha-handset-phone-numbers {
  background-color: $white;
  flex: 1;
  display: flex;
  position: relative;
  z-index: 1;
}
.maha-handset-phone-numbers-header {
  flex: 1;
  display: flex;
}
.maha-handset-phone-numbers-header-token {
  flex: 1;
}
.maha-handset-phone-numbers-header-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
}
.maha-handset-phone-numbers-chooser {
  background-color: $white;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
.maha-handset-phone-numbers-chooser-item {
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-handset-phone-number {
  flex: 1;
  display: flex;
  cursor: pointer;
}
.maha-handset-phone-number-logo {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 2.2rem;
  }
}
.maha-handset-phone-number-label {
  flex: 1;
  padding: 0.8rem;
  span {
    color:  fadeout($black, 60);
  }
}
